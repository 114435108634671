import React from "react";

export const IconComputer = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="computer" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22.5 22.5" aria-labelledby="title">
      <title id="title">Umbrella</title>
      <path
        fill={fill}
        d="M22,0H0.5C0.2,0,0,0.2,0,0.5V16c0,0.3,0.2,0.5,0.5,0.5h7.7l-2,4.4c-0.1,0.2-0.1,0.3,0,0.5
      	c0.1,0.1,0.3,0.2,0.4,0.2h9.2c0.2,0,0.3-0.1,0.4-0.2s0.1-0.3,0-0.5l-2-4.4H22c0.3,0,0.5-0.2,0.5-0.5V0.5C22.5,0.2,22.3,0,22,0z
      	 M21.5,1v10.2H1V1H21.5z M13.5,15.5c-0.2,0-0.3,0.1-0.4,0.2s-0.1,0.3,0,0.5l2,4.4H7.4l2-4.4c0.1-0.2,0.1-0.3,0-0.5
      	c-0.1-0.1-0.3-0.2-0.4-0.2H1v-3.3h20.5v3.3H13.5z"
      />
    </svg>
  );
};

export const IconCog = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.8 21.8" aria-labelledby="title">
      <title id="title">Cog</title>
      <g>
        <path
          fill={fill}
          d="M11.8,21.8H10c-0.8,0-1.6-0.6-1.7-1.4c-0.2-0.2-0.6-0.5-0.8-0.7c-0.4-0.4-1-0.5-1.5-0.4c-0.7,0.6-1.7,0.5-2.3-0.1L2.6,18
  				c-0.6-0.7-0.6-1.7,0-2.3c0.2-0.2,0.3-0.5,0.1-0.8c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.4-0.5-0.7-0.5c0,0,0,0,0,0
  				c-1,0-1.7-0.8-1.7-1.7v-1.7c0-0.9,0.7-1.7,1.6-1.7c0.4,0,0.6-0.2,0.7-0.5C2.5,7.6,2.6,7.3,2.7,7c0.1-0.3,0.1-0.6-0.1-0.8
  				C2.3,5.9,2.1,5.5,2.1,5s0.2-0.9,0.5-1.2l1.2-1.2C4.5,2,5.5,2,6.2,2.6C6.4,2.8,6.7,2.9,7,2.8c0.3-0.1,0.6-0.3,0.9-0.4
  				C8.2,2.3,8.4,2,8.4,1.7c0-0.5,0.2-0.9,0.5-1.2C9.2,0.2,9.6,0,10,0h1.7c0.4,0,0.9,0.1,1.2,0.4c0.3,0.3,0.5,0.7,0.5,1.2
  				c0,0.4,0.2,0.6,0.5,0.7c0.3,0.1,0.6,0.2,0.9,0.4c0.3,0.1,0.6,0.1,0.8-0.1C16.3,2,17.4,2,18,2.6l1.2,1.2c0.7,0.7,0.7,1.7,0,2.4
  				C19,6.5,19,6.8,19.1,7c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.4,0.5,0.7,0.5c0.9,0,1.7,0.8,1.7,1.7v1.7c0,0.9-0.7,1.7-1.7,1.7
  				c0,0,0,0,0,0c-0.3,0-0.6,0.2-0.7,0.5c-0.1,0.3-0.2,0.6-0.4,0.9c-0.1,0.3-0.1,0.6,0.1,0.8c0.7,0.7,0.7,1.7,0,2.4L18,19.3
  				c-0.7,0.7-1.7,0.7-2.4,0c-0.2-0.2-0.5-0.3-0.8-0.1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.3,0.1-0.5,0.4-0.5,0.7
  				C13.5,21.1,12.7,21.8,11.8,21.8z M6.5,18.2c0.6,0,1.3,0.3,1.8,0.7c0.3,0.3,0.9,0.8,0.9,0.8c0.1,0.1,0.2,0.2,0.2,0.4
  				c0,0.4,0.3,0.7,0.7,0.7h1.7c0.4,0,0.7-0.3,0.7-0.7c0-0.7,0.5-1.4,1.1-1.6c0.3-0.1,0.5-0.2,0.8-0.3c0.6-0.3,1.4-0.2,1.9,0.3
  				c0.3,0.3,0.7,0.3,1,0l1.2-1.2c0.3-0.3,0.3-0.7,0-1c-0.5-0.5-0.6-1.3-0.3-1.9c0.1-0.3,0.2-0.5,0.3-0.8c0.2-0.7,0.9-1.1,1.6-1.1
  				c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-1.7c0-0.4-0.3-0.7-0.7-0.7c-0.7,0-1.4-0.5-1.6-1.1c-0.1-0.3-0.2-0.5-0.3-0.8
  				C17.9,6.8,18,6,18.6,5.5c0.3-0.3,0.3-0.7,0-1l-1.2-1.2c-0.3-0.3-0.7-0.3-1,0c-0.5,0.5-1.3,0.6-1.9,0.3c-0.3-0.1-0.5-0.2-0.8-0.3
  				c-0.7-0.2-1.1-0.9-1.1-1.6c0-0.2-0.1-0.4-0.2-0.5C12.1,1.1,12,1,11.8,1L10,1C9.9,1,9.7,1.1,9.6,1.2C9.4,1.3,9.4,1.5,9.4,1.7
  				c0,0.7-0.4,1.4-1.1,1.7C7.9,3.4,7.7,3.6,7.4,3.7C6.8,4,6,3.9,5.5,3.4c-0.3-0.3-0.7-0.3-1,0L3.4,4.5C3.2,4.6,3.1,4.8,3.1,5
  				c0,0.2,0.1,0.4,0.2,0.5C3.8,6,3.9,6.8,3.6,7.4C3.5,7.7,3.4,7.9,3.3,8.2C3.1,8.9,2.4,9.4,1.7,9.4C1.3,9.4,1,9.7,1,10.1l0,1.7
  				c0,0.4,0.4,0.7,0.8,0.7c0.7,0,1.3,0.4,1.6,1.1c0.1,0.3,0.2,0.6,0.3,0.8c0.3,0.6,0.2,1.4-0.3,1.9c-0.3,0.3-0.3,0.7,0,1l1.2,1.2
  				c0.3,0.3,0.7,0.3,1,0c0.1,0,0.1-0.1,0.2-0.1C5.9,18.3,6.2,18.2,6.5,18.2z"
        />
      </g>
      <g>
        <path
          fill={fill}
          d="M10.9,15.6c-2.7,0-4.8-2.2-4.8-4.8S8.2,6,10.9,6c2.7,0,4.8,2.2,4.8,4.8S13.5,15.6,10.9,15.6z M10.9,7C8.8,7,7,8.7,7,10.8
  				c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C14.7,8.7,13,7,10.9,7z"
        />
      </g>
    </svg>
  );
};

export const IconPenLine = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12.6 17.7" aria-labelledby="title">
      <title id="title">Pen Line</title>
      <g>
        <path fill={fill} d="M12.2,16.2H0.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h11.7c0.3,0,0.5-0.2,0.5-0.5S12.4,16.2,12.2,16.2z" />
        <path
          fill={fill}
          d="M2.4,9.3C2.4,9.3,2.4,9.3,2.4,9.3l0,4.1c0,0.2,0.1,0.4,0.3,0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.3-0.1l2.9-2.8
      		c0,0,0.1-0.1,0.1-0.2L10,1.9c0.1-0.3,0-0.5-0.3-0.7C9.4,1.2,9.1,1.3,9,1.5l-3.7,8.7l-2,1.9l0-2.8L7,0.7C7.1,0.4,7,0.1,6.8,0
      		c-0.3-0.1-0.5,0-0.7,0.3L2.4,9.1c0,0,0,0,0,0c0,0,0,0,0,0.1C2.4,9.3,2.4,9.3,2.4,9.3z"
        />
      </g>
    </svg>
  );
};

export const IconCross = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.8 21.8" aria-labelledby="title">
      <title id="title">Cross</title>
      <g>
        <path
          fill={fill}
          d="M16,5.8c-0.2-0.2-0.5-0.2-0.7,0l-4.4,4.4L6.5,5.8C6.3,5.6,6,5.6,5.8,5.8s-0.2,0.5,0,0.7l4.4,4.4l-4.4,4.4
      		c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l4.4-4.4l4.4,4.4c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1
      		c0.2-0.2,0.2-0.5,0-0.7l-4.4-4.4L16,6.5C16.2,6.3,16.2,6,16,5.8z"
        />
        <path
          fill={fill}
          d="M10.9,0C4.9,0,0,4.9,0,10.9s4.9,10.9,10.9,10.9s10.9-4.9,10.9-10.9S16.9,0,10.9,0z M10.9,20.8c-5.5,0-9.9-4.4-9.9-9.9
      		C1,5.4,5.4,1,10.9,1c5.5,0,9.9,4.4,9.9,9.9C20.8,16.4,16.4,20.8,10.9,20.8z"
        />
      </g>
    </svg>
  );
};

export const IconPhone = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.8 21.8" aria-labelledby="title">
      <title id="title">Phone</title>
      <g>
        <path
          fill={fill}
          d="M18.4,16.4l-2-2c-0.9-0.9-2.3-0.9-3.1,0l-1.9,1.9l-6.2-6.2l1.9-1.9c0.4-0.4,0.6-1,0.6-1.6S7.6,5.5,7.1,5.1c0,0,0,0,0,0
    		l-2-2C4.3,2.2,2.9,2.2,2,3.1C1.9,3.2,1.8,3.3,1.7,3.4C1.6,3.5,1.6,3.7,1.5,3.8L1.4,4C-1,8-0.3,13.2,3.1,16.4l1.9,2
    		c2,2,4.6,3.1,7.3,3.1c1.8,0,3.5-0.5,5.1-1.4c0.2-0.1,0.4-0.2,0.6-0.4c0.5-0.3,0.8-0.8,1-1.4c0.1-0.6,0-1.2-0.3-1.7
    		C18.6,16.6,18.5,16.5,18.4,16.4z M18,18.2c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.1-0.3,0.2-0.5,0.3c-3.7,2.1-8.3,1.5-11.2-1.6l-2-2
    		C0.8,12.8,0.1,8.1,2.2,4.5l0.1-0.1C2.4,4.2,2.5,4.1,2.6,4c0-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.5-0.4,0.9-0.4c0,0,0,0,0,0
    		c0.3,0,0.6,0.1,0.9,0.3l2,2c0,0,0,0,0,0C6.7,6,6.8,6.3,6.8,6.7S6.7,7.3,6.4,7.5L4.2,9.7C4,9.9,4,10.2,4.2,10.4l6.9,6.9
    		c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3-0.1,0.4-0.2l2.2-2.3c0.5-0.5,1.2-0.5,1.7,0l2,2c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2
    		C18.1,17.6,18.1,17.9,18,18.2z"
        />
        <path
          fill={fill}
          d="M11.4,0L11.4,0c-0.3,0-0.5,0.2-0.5,0.5S11.1,1,11.4,1c5,0,9.1,4.1,9.1,9.1c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5
    		C21.5,4.5,17,0,11.4,0z"
        />
        <path
          fill={fill}
          d="M11.5,4.1c3.3,0,6,2.7,6,6c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-3.9-3.2-7-7-7c-0.3,0-0.5,0.2-0.5,0.5
    		S11.2,4.1,11.5,4.1z"
        />
        <path
          fill={fill}
          d="M11.5,7.6c1.4,0,2.5,1.1,2.5,2.5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-1.9-1.6-3.5-3.5-3.5c-0.3,0-0.5,0.2-0.5,0.5
    		S11.2,7.6,11.5,7.6z"
        />
      </g>
    </svg>
  );
};

export const IconHome = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.8 21.8" aria-labelledby="title">
      <title id="title">Home</title>
      <g>
        <path
          fill={fill}
          d="M16.3,18.7h-4c-0.3,0-0.5-0.2-0.5-0.5v-6.8H7.5v6.8c0,0.3-0.2,0.5-0.5,0.5h-4c-0.3,0-0.5-0.2-0.5-0.5v-8l-1.9,0
  			C0.3,10.1,0.1,10,0,9.8s0-0.4,0.1-0.5l9.1-9.1C9.5,0,9.8,0,10,0.1l9.1,9.1c0.1,0.1,0.2,0.4,0.1,0.5s-0.3,0.3-0.5,0.3l0,0l-1.9,0v8
  			C16.8,18.4,16.6,18.7,16.3,18.7z M12.8,17.7h3v-8c0-0.1,0.1-0.3,0.1-0.4s0.2-0.1,0.4-0.1l0,0h1.2L9.6,1.2L1.7,9.1h1.2
  			c0.1,0,0.3,0.1,0.4,0.1s0.1,0.2,0.1,0.4v8h3v-6.8c0-0.3,0.2-0.5,0.5-0.5h5.4c0.3,0,0.5,0.2,0.5,0.5V17.7z"
        />
      </g>
    </svg>
  );
};

export const IconGoals = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.8 21.8" aria-labelledby="title">
      <title id="title">Home</title>
      <g>
        <path
          fill={fill}
          d="M4.24.22,1.92,3.55.8,2.72a.5.5,0,1,0-.6.8L1.74,4.67a.53.53,0,0,0,.3.09h.08a.52.52,0,0,0,.33-.21L5.06.79a.51.51,0,0,0-.12-.7A.5.5,0,0,0,4.24.22Z"
        />
        <path
          fill={fill}
          d="M4.24,6.3,1.92,9.64.8,8.8a.51.51,0,0,0-.7.11.5.5,0,0,0,.1.7l1.54,1.14a.47.47,0,0,0,.3.1h.08a.5.5,0,0,0,.33-.2L5.06,6.87a.5.5,0,0,0-.82-.57Z"
        />
        <path
          fill={fill}
          d="M4.24,12.39,1.92,15.73.8,14.89a.51.51,0,0,0-.7.1.5.5,0,0,0,.1.7l1.54,1.15a.54.54,0,0,0,.3.1h.08a.52.52,0,0,0,.33-.21L5.06,13a.51.51,0,0,0-.12-.7A.5.5,0,0,0,4.24,12.39Z"
        />
        <path fill={fill} d="M7.65,2.88h8.79a.5.5,0,0,0,.5-.5.51.51,0,0,0-.5-.5H7.65a.51.51,0,0,0-.5.5A.5.5,0,0,0,7.65,2.88Z" />
        <path fill={fill} d="M16.44,8H7.65a.5.5,0,0,0,0,1h8.79a.5.5,0,0,0,0-1Z" />
        <path fill={fill} d="M16.44,14.06H7.65a.5.5,0,0,0-.5.5.51.51,0,0,0,.5.5h8.79a.51.51,0,0,0,.5-.5A.5.5,0,0,0,16.44,14.06Z" />
      </g>
    </svg>
  );
};

export const Icon360s = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.8 21.8" aria-labelledby="title">
      <title id="title">Home</title>
      <g>
        <path
          fill={fill}
          d="M14.78,8.46a.5.5,0,0,0-.5.5,6.66,6.66,0,1,1-2.17-4.9l-2.75.62a.5.5,0,0,0,.11,1l.11,0,4.29-1-1-4.3A.5.5,0,0,0,12.31,0a.5.5,0,0,0-.38.6L12.48,3A7.65,7.65,0,1,0,15.28,9,.5.5,0,0,0,14.78,8.46Z"
        />
      </g>
    </svg>
  );
};

export const IconTeam = (props) => {
  const { height, width, fill } = props;
  return (
    <svg className="cls-1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24.45 16.93" aria-labelledby="title">
      <title id="title">Home</title>
      <g id="Scraps">
        <path
          fill={fill}
          d="m13.96,9.93c1.29-.64,2.19-1.97,2.19-3.51,0-2.17-1.76-3.93-3.93-3.93s-3.93,1.76-3.93,3.93c0,1.54.9,2.86,2.19,3.51-2.87.77-5,3.39-5,6.5,0,.28.22.5.5.5s.5-.22.5-.5c0-3.16,2.57-5.74,5.74-5.74s5.74,2.57,5.74,5.74c0,.28.22.5.5.5s.5-.22.5-.5c0-3.11-2.12-5.73-5-6.5Zm-4.67-3.51c0-1.62,1.32-2.93,2.93-2.93s2.93,1.32,2.93,2.93-1.32,2.93-2.93,2.93-2.93-1.32-2.93-2.93Zm15.16,7.18c0,.28-.22.5-.5.5s-.5-.22-.5-.5c0-3.16-2.57-5.74-5.74-5.74-.28,0-.5-.22-.5-.5s.22-.5.5-.5c1.62,0,2.93-1.32,2.93-2.93s-1.32-2.93-2.93-2.93c-.83,0-1.63.36-2.18.98-.18.21-.5.22-.71.04s-.22-.5-.04-.71c.75-.83,1.81-1.31,2.93-1.31,2.17,0,3.93,1.76,3.93,3.93,0,1.37-.71,2.58-1.78,3.29,2.66.9,4.58,3.42,4.58,6.38ZM6.74,7.87c-3.16,0-5.74,2.57-5.74,5.74,0,.28-.22.5-.5.5s-.5-.22-.5-.5c0-2.96,1.92-5.48,4.58-6.38-1.07-.7-1.78-1.91-1.78-3.29,0-2.17,1.76-3.93,3.93-3.93,1.14,0,2.22.49,2.96,1.35.18.21.16.52-.05.71s-.52.16-.71-.05c-.56-.64-1.36-1-2.21-1-1.62,0-2.93,1.32-2.93,2.93s1.32,2.93,2.93,2.93c.28,0,.5.22.5.5s-.22.5-.5.5Z"
        />
      </g>
    </svg>
  );
};

export const IconEditCircle = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="title">Home</title>
      <g>
        <path
          fill={fill}
          d="M12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0z M9.8,12.1C9.8,12,9.8,12,9.8,12.1
      	C9.8,12,9.8,12,9.8,12.1l3.6-8.7c0.1-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.4,0.4,0.3,0.7l-3.6,8.5l0,2.7l1.9-1.9l3.6-8.5
      	c0.1-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.4,0.4,0.3,0.7l-3.6,8.6c0,0.1-0.1,0.1-0.1,0.2l-2.8,2.8c-0.1,0.1-0.2,0.1-0.3,0.1
      	c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.3-0.3-0.5l0-4C9.8,12.2,9.8,12.1,9.8,12.1z M17,19.8H8c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5
      	H17c0.3,0,0.5,0.2,0.5,0.5S17.3,19.8,17,19.8z"
        />
      </g>
    </svg>
  );
};

export const IconCrossCircle = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="title">Cross</title>
      <g>
        <path
          fill={fill}
          d="M12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0z M17.1,16.3c0.2,0.2,0.2,0.5,0,0.7
	c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1l-3.8-3.8L8.9,17c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l3.8-3.8
	L8.2,8.7C8,8.5,8,8.2,8.2,8s0.5-0.2,0.7,0l3.8,3.8L16.4,8c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7l-3.8,3.8L17.1,16.3z"
        />
      </g>
    </svg>
  );
};

export const IconTickCircle = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="title">Tick</title>
      <g>
        <path
          fill={fill}
          d="M12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0z M18.9,7l-7.8,11.6c-0.1,0.1-0.3,0.2-0.4,0.2
      	c-0.1,0-0.2,0-0.3-0.1L6.2,16c-0.2-0.2-0.3-0.5-0.1-0.7C6.2,15,6.5,15,6.8,15.1l3.8,2.6l7.5-11.2c0.2-0.2,0.5-0.3,0.7-0.1
      	C19,6.5,19.1,6.8,18.9,7z"
        />
      </g>
    </svg>
  );
};

export const IconPlusCircle = (props) => {
  // const umbrellafill = props.umbrellafill || "orangered"
  const { height, width, fill } = props;

  return (
    <svg className="plus" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="title">Plus</title>
      <g>
        <path
          fill={fill}
          d="M12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0z M17.5,13H13V13H13v4.5c0,0.3-0.2,0.5-0.5,0.5
      	S12,17.8,12,17.5V13H7.5C7.2,13,7,12.8,7,12.5S7.2,12,7.5,12H12V12H12V7.5C12,7.2,12.3,7,12.5,7S13,7.2,13,7.5V12h4.5
      	c0.3,0,0.5,0.2,0.5,0.5S17.8,13,17.5,13z"
        />
      </g>
    </svg>
  );
};

export const UpArrow = (props) => {
  const { height, width, fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="up">Move Question Up</title>
      <g>
        <path
          fill={fill}
          d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z"
          style={{ fillRule: "evenodd", clipRule: "evenodd", stroke: fill, strokeWidth: 2, strokeMiterlimit: 10 }}
        />
      </g>
    </svg>
  );
};

export const DownArrow = (props) => {
  const { height, width, fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="up">Move Question Down</title>
      <g>
        <path
          d="M21.8 5 12 17.5 2.2 5l-.6.6L12 19 22.4 5.6l-.6-.6z"
          style={{ fillRule: "evenodd", clipRule: "evenodd", stroke: fill, strokeWidth: 2, strokeMiterlimit: 10 }}
        />
      </g>
    </svg>
  );
};
