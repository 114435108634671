import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import SiteFooterBrand from '../../../components/site-footer-brand';
import SiteHeader from '../../../components/site-header';
import SiteNav from '../../../components/site-nav';
import withAuthorisation from '../../../components/withauthorisation';
import Breadcrumbs from '../../../components/breadcrumbs';
import { breadcrumbSelect } from '../../../helpers';
const authCondition = (authUser) => !!authUser;
//

class Edit360Standard extends Component {
  constructor(props) {
    super(props);

    let editLink;
    let editLinkQuestionnaire;
    let editLinkBrand;

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
    };
  }
  render() {
    const {
      match: { params },
    } = this.props;
    return (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={this.state.current360}
            accountData={this.props.accountData}
            current360Groups={this.props.current360Groups}
            current360Relationships={this.props.current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${this.state.accountId}`,
              },
              {
                title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                  this.props.current360.type
                } Projects`,
                to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(this.props.current360.type)}-projects/`,
              },
              {
                title: this.state.current360.title,
                to: '../',
              },
              {
                title: 'Advanced Settings',
                to: null,
              },
            ]}
          />
          <h1>{this.state.current360.title}: Advanced Settings</h1>

          <div className='flex items-center justify-between w100 bg-white shadow pa4 mv4'>
            <div className='w-70'>
              <p className='ma0'>
                <strong>Brand Your {this.props.current360.type}</strong>
                <br />
                You may brand your {this.props.current360.type} by setting a logo and changing the colour scheme
              </p>
            </div>
            <Link
              className='btn'
              to={{
                pathname: 'brand/',
                accountData: this.props.accountData,
                current360: this.props.current360,
                current360Relationships: this.props.current360Relationships,
                current360Groups: this.props.current360Groups,
              }}
            >
              Start
            </Link>
          </div>

          {this.props.current360.type == '360' ? (
            <div className='flex items-center justify-between w100 bg-white shadow pa4 mv4'>
              <div className='w-70'>
                <p className='ma0'>
                  <strong>Edit relationships</strong>
                  <br />
                  Add/Remove/Edit colleague relationships
                  {Object.keys(this.props.current360Groups).length > 0 ? (
                    <p className='pb0 mb0'>Group relationships cannot be altered after a group has been made</p>
                  ) : (
                    ''
                  )}
                </p>
              </div>
              {Object.keys(this.props.current360Groups).length > 0 ? (
                <button disabled className='btn disabled'>
                  Start
                </button>
              ) : (
                <Link
                  className='btn'
                  to={{
                    pathname: 'relationships/',
                    accountData: this.props.accountData,
                    current360: this.props.current360,
                    current360Relationships: this.props.current360Relationships,
                    current360Groups: this.props.current360Groups,
                  }}
                >
                  Start
                </Link>
              )}
            </div>
          ) : (
            ''
          )}

          {this.props.current360.type !== 'Team Assessment' ? (
            <div className='flex items-center justify-between w100 bg-white shadow pa4 mv4'>
              <div className='w-70'>
                <p className='ma0'>
                  <strong>Edit Email Text</strong>
                  <br />
                  Edit the content of each email reminder
                </p>
              </div>
              <Link
                className='btn'
                to={{
                  pathname: 'email/',
                  accountData: this.props.accountData,
                  current360: this.props.current360,
                  current360Relationships: this.props.current360Relationships,
                  current360Groups: this.props.current360Groups,
                }}
              >
                Start
              </Link>
            </div>
          ) : (
            <></>
          )}

          {this.props.current360.type !== 'Goal Tracker' ? (
            <div className='flex items-center justify-between w100 bg-white shadow pa4 mv4'>
              <div className='w-70'>
                <p className='ma0'>
                  <strong>Edit Reviewer Reminder Email Text</strong>
                  <br />
                  Edit the content of each email reviewer reminder
                </p>
              </div>
              <Link
                className='btn'
                to={{
                  pathname: 'revieweremail/',
                  accountData: this.props.accountData,
                  current360: this.props.current360,
                  current360Relationships: this.props.current360Relationships,
                  current360Groups: this.props.current360Groups,
                }}
              >
                Start
              </Link>
            </div>
          ) : (
            ''
          )}

          {this.props.current360.type !== 'Goal Tracker' && (
            <div className='flex items-center justify-between w100 bg-white shadow pa4 mv4'>
              <div className='w-70'>
                <p className='ma0'>
                  <strong>Customise Scale Labels</strong>
                  <br />
                  Edit labels for each multichoice rating option.
                </p>
              </div>
              <Link
                className='btn'
                to={{
                  pathname: 'labels/',
                  accountData: this.props.accountData,
                  current360: this.props.current360,
                  current360Relationships: this.props.current360Relationships,
                  current360Groups: this.props.current360Groups,
                }}
              >
                Start
              </Link>
            </div>
          )}

          <Link to={{ pathname: '../', current360: this.state.current360, userData: this.state.userData }} className='btn btn--back'>
            Return to Project Overview
          </Link>
        </section>
      </div>
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360Standard);
