import React, { Component } from 'react';
import HorizontalBar from './HorizontalBar';
import RelationshipLabel from './RelationshipLabel';
import RowTitle from './RowTitle';
import {valueCheck, percentageCheck} from '../../helpers'

export default class ChartRowPart1 extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    const {key, selfAnswerLabel, selfAnswerScore} = this.props
    const valueRating = valueCheck(selfAnswerScore)
    const barRating = percentageCheck(selfAnswerScore)
    return (
      <div key={key} className='w-100 flex bt b--light-gray'>
        <RowTitle containerClass='w-50 pr2' title={selfAnswerLabel} />
        <HorizontalBar containerClass='w-50' pClass='flex' valueRating={valueRating} barRating={barRating / 5} />
      </div>
    );
  }
}
