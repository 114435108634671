import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { db } from '../../firebase';
import { PDFExport } from '@progress/kendo-react-pdf';
import canvg from 'canvg';
import Breadcrumbs from '../../components/breadcrumbs';
import { Link } from 'react-router-dom';
import { average, arrayGenerator } from '../../helpers';
import RadarChart from '../../components/charts/radarchart';
import LoadingState from '../../components/global/loading-state';
import withAuthorisation from '../../components/withauthorisation';
import ReportPageTemplate from './reportpagetemplate.jsx';
import TitlePage from '../../components/reports/TitlePage';
import ChartRowPart1 from '../../components/charts/ChartRowPart1';
import ChartRowPart2 from '../../components/charts/ChartRowPart2';
import PageHeader from '../../components/reports/PageHeader';
import Contents from '../../components/reports/Contents';
import DottedLines from '../../components/reports/DottedLines';
import Goals from '../../components/reports/Goals';
import GoalProgress from '../../components/reports/GoalProgress';
const authCondition = (authUser) => !!authUser;

class SelfReport extends Component {
  constructor(props) {
    super(props);
    this.exportPDF = this.exportPDF.bind(this);
    this.convertSVGToImage = this.convertSVGToImage.bind(this);
    this.canvLoaded = false;
    this.hiddenCanvas = React.createRef();

    this.state = {
      loading: true,
      error: false,
      captions: {},
      userData: {},
      userId: null,
      groupId: null,
      current360Id: null,
      current360Relationships: this.props.current360Relationships,
      sectionsLegacyMode: false,
    };
  }
  exportPDF = () => {
    this.report.save();
  };
  convertSVGToImage = (htmlString) => {
    try {
      // Take an SVG html string, convert it to static markup, then use canvg to render into hidden canvas, then return dataURL string
      let staticRadarChart = ReactDOMServer.renderToStaticMarkup(htmlString);
      let canv = this.hiddenCanvas.current;
      canv.getContext('2d');

      canvg(canv, staticRadarChart);
      return canv.toDataURL('image/png');
    } catch (err) {
      return null;
    }
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      userId: params.userId,
      groupId: params.groupId,
      current360Id: params.id,
    });

    db.doGetCurrentGroup(this.props.match.params.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
      if (snapshot.data()) {
        this.setState({
          currentGroup: snapshot.data(),
        });

        db.getUserFromGroup(params.accountId, params.userId).onSnapshot((snapshot) => {
          let userFullName = `${snapshot.data()['forename']} ${snapshot.data()['surname']}`;
          let reportPdfFileName = `${snapshot.data()['forename']}-${snapshot.data()['surname']}-self-report.pdf`;

          db.doGetCurrent360(params.accountId, params.id).then((snapshot) => {
            let questionnaireID = snapshot.data()['questionnaireID'];
            let current360Title = snapshot.data()['title'];

            db.doGetSectionsAndQuestionDetailsList(params.accountId, questionnaireID).then((sections) => {
              if (
                Object.values(sections)
                  .map((section) => section.sortOrder)
                  .includes(undefined)
              ) {
                this.setState({
                  sectionsLegacyMode: true,
                });
              }

              let questionnaireSections = sections;
              let captions = {};
              let descriptions = {};
              let questionDetails = {};
              let sectionDetails = questionnaireSections;
              let userAnswers = {};
              let userResponses = {};
              let userCommentsBySection = {};
              let userIndividualAnswers = [];

              Object.keys(questionnaireSections).map((key, index) => {
                let sectionID = key;

                if (questionnaireSections[sectionID].questions) {
                  // Check if only one question AND if that question is a FreeText field!!
                  if (Object.keys(questionnaireSections[sectionID].questions).length == 1) {
                    // MAD:
                    if (questionnaireSections[sectionID].questions[Object.keys(questionnaireSections[sectionID].questions)[0]].answerType === 'FreeText') {
                      return;
                    }
                  }
                  // Set captions + description!
                  captions[[sectionID]] = questionnaireSections[sectionID].title;
                  descriptions[[sectionID]] = questionnaireSections[sectionID].description;

                  Object.keys(questionnaireSections[sectionID].questions).map((key, index) => {
                    questionDetails[[key]] = questionnaireSections[sectionID].questions[key];
                  });
                }
              });
              // Get answers!

              db.doGetUserAnswersBySections(
                params.accountId,
                params.userId,
                params.id,
                params.groupId,
                questionnaireID,
                'self-review',
                questionnaireSections
              ).then((data) => {
                let questionnaireSections = data;

                Object.keys(questionnaireSections).map((key, index) => {
                  let sectionKey = key;
                  let answers = [];
                  let responses = [0, 0, 0, 0, 0, 0];
                  let answerKey = 0;

                  Object.keys(questionnaireSections[sectionKey].answers).map((key, index) => {
                    let answerVal = questionnaireSections[sectionKey].answers[key];
                    // If answer is a number value (IE: not a text field) then set, otherwise set to `0`

                    answerVal = isNaN(Number(answerVal)) ? -1 : parseInt(answerVal);

                    if (answerVal == -1) {
                      let userComment = questionnaireSections[sectionKey].answers[key];

                      if (!userCommentsBySection[[sectionKey]]) {
                        userCommentsBySection[[sectionKey]] = {};
                      }
                      if (!userCommentsBySection[[sectionKey]][[key]]) {
                        userCommentsBySection[[sectionKey]][[key]] = {};
                        userCommentsBySection[[sectionKey]][[key]]['title'] = sectionDetails[sectionKey].questions[key].questionTitle;
                      }

                      userCommentsBySection[[sectionKey]][[key]]['comment'] = userComment;
                    } else {
                      if (!isNaN(parseInt(answerVal))) {
                        responses[answerVal] = responses[answerVal] + 1;
                      }
                      // user `answerKey` instead of `key` to ensure correct average

                      if (answerVal.toString() !== 'NaN') {
                        answers[answerKey] = answerVal;
                        userIndividualAnswers[[key]] = answerVal;
                      }

                      answerKey++;
                    }
                  });
                  if (answers.length) {
                    userAnswers[[sectionKey]] = average(answers) / 5;
                  } else {
                    userAnswers[[sectionKey]] = 0;
                  }
                  userResponses[[sectionKey]] = responses;
                });

                const weightedHighest = Object.entries(userIndividualAnswers)
                  .filter((q) => q[1] > 0)
                  .sort((a, b) => b[1] - a[1])
                  .map((q) => q[0]);

                let secLengthNoFreeText = {};
                let generateGraph = false;
                Object.keys(sectionDetails).forEach((item) => {
                  const questionsObj = sectionDetails[item].questions;
                  const multiChoiceQuestionsLength = Object.values(questionsObj).filter((question) => question.answerType === 'MultiChoice').length;
                  secLengthNoFreeText[item] = multiChoiceQuestionsLength;
                  if (multiChoiceQuestionsLength > 0) {
                    generateGraph = true;
                  }
                });
                this.setState({
                  sectionDetails: sectionDetails,
                  questionDetails: questionDetails,
                  userResponses: userResponses,
                  userAnswers: userAnswers,
                  userFullName: userFullName,
                  reportPdfFileName: reportPdfFileName,
                  captions: captions,
                  current360Title: current360Title,
                  descriptions: descriptions,
                  generateGraph: generateGraph,
                  userCommentsBySection: userCommentsBySection,
                  secLengthNoFreeText: secLengthNoFreeText,
                  userIndividualAnswers: userIndividualAnswers,
                  weightedHighest: weightedHighest,
                  loading: false,
                });
              });
            });
          });
        });
      }
    });
  }
  render() {
    if (!this.state.loading) {
      let withoutZero = {};
      let userData = {};
      Object.keys(this.state.userResponses).forEach((key) => {
        let arr = 0;
        let countArr = 0;
        this.state.userResponses[key].reverse().forEach((val, i) => {
          const n = 5 - i;
          if (n > 0 && val * n > 0) {
            arr += val * n;
            countArr += val;
          }
        });
        withoutZero[key] = arr / countArr;
        userData[key] = arr / countArr / 5;
      });

      let emptyData = {
        data: {},
        meta: {},
      };
      // let userData = this.state.userAnswers;
      let chartCaptions = this.state.captions;
      const data = [
        {
          data: {},
          meta: { color: 'blue' },
        },
      ];
      for (const q in this.state.sectionDetails) {
        let counter = 0;
        for (const p in this.state.sectionDetails[q].questions) {
          if (this.state.sectionDetails[q].questions[p].answerType === 'MultiChoice') {
            counter++;
          }
        }
        if (counter === 0) {
          delete userData[q];
          delete chartCaptions[q];
        }
      }
      const captionsLoaded = chartCaptions;
      data[0]['data'] = userData;

      const chartOptions = {
        size: 358,
        axes: true, // show axes?
        scales: 5, // show scale circles?
        captions: true, // show captions?
        captionMargin: 30,
        dots: true, // show dots?
        zoomDistance: 1.5, // where on the axes are the captions?
        axisProps: () => ({ className: 'axis', fill: 'none', stroke: '#000' }),
        scaleProps: () => ({ className: 'scale', fill: 'none', stroke: '#000' }),
        shapeProps: () => ({ className: 'shape' }),
        captionProps: () => ({
          className: 'caption',
          textAnchor: 'middle',
          fontSize: 14,
          fontFamily: 'sans-serif',
        }),
      };

      return (
        <div className='page-wrapper page-wrapper--report'>
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.props.match.params.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${this.props.current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
                },
                {
                  title: this.props.current360.title,
                  to: '../../../../edit/',
                },
                {
                  title: this.state.currentGroup.title,
                  to: `../../../../edit/group/${this.state.groupId}/`,
                },
                {
                  title: `Self-Report & Development plan for ${this.state.userFullName}`,
                  to: null,
                },
              ]}
            />
          </section>
          <div
            className='user-report-view-only'
            style={{ height: '100vh', width: '100vw', paddingTop: 20, paddingBottom: 100, backgroundColor: 'rgb(82, 86, 89)', overflow: 'hidden' }}
          >
            <p className='tc'>
              <Link className='btn btn--back btn--white ml2 mr3' to={`../../../../edit/group/${this.state.groupId}/`}>
                Back to group overview
              </Link>
              <button className='btn btn--white ml3 mr2' onClick={this.exportPDF}>
                Export as PDF
              </button>
            </p>
            <div
              style={{
                maxWidth: '210mm',
                height: '90vh',
                backgroundColor: 'white',
                boxShadow: '0px 0px 5px #000',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 100,
                padding: '0px',
                overflowX: 'scroll',
                overflowY: 'scroll',
              }}
            >
              <PDFExport
                paperSize={'A4'}
                forcePageBreak='.page-break'
                keepTogether={'div'}
                margin={{ bottom: 50 }}
                pageTemplate={ReportPageTemplate}
                fileName={this.state.reportPdfFileName}
                title=''
                subject=''
                keywords=''
                ref={(r) => (this.report = r)}
              >
                <div
                  className='user-report'
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '0px',
                  }}
                >
                  <header className='user-report__header'>
                    <img crossorigin='anonymous' src={this.props.current360.settings.logo} class='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>{this.state.current360Title}</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Self-Report & Development plan</strong>
                      </p>
                      <p className='user-report__header-name'>For {this.state.userFullName}</p>
                    </div>
                  </header>
                  <Contents partTitles={['Your Feedback Summary', 'Your Detailed Feedback', 'Your Development Plan']} />

                  <TitlePage h2Text='Introduction' />

                  {/* introduction */}
                  <div className='bg-white user-report__content'>
                    <PageHeader h2Class='mt4' h2Text={<strong>Introduction</strong>} h3Text='Getting the most from your feedback' line={true} />

                    <h3 className='f5 mt4'>
                      <strong>Where to begin?</strong>
                    </h3>
                    <p>
                      It’s a good idea to read the report in the order provided. However, you may prefer to dive into a specific section and then pull out
                      related information from other parts of the report. You can see how the different elements of the report relate to each other, in the
                      section ‘How your report is compiled’.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Make notes</strong>
                    </h3>
                    <p>
                      There are coaching questions throughout the report, these are provided to encourage you to reflect on your assessment. Additionally, the
                      report provides space for you to capture notes, as you read through.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Build your development plan</strong>
                    </h3>
                    <p>
                      Your self-report will help you to learn, develop and grow. Take the time to consider how you can use the assessment to help shape your
                      future development. What is your report telling you about your opportunities to develop? Reflect on how you can put this into action, by
                      fleshing out your development plan with practical and positive actions and activities.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Don’t be afraid to ask for help</strong>
                    </h3>
                    <p>
                      Asking for help is a positive step! If you are unsure about some of the report or feel uncertain about how to proceed with your goals;
                      talk to a trusted colleague, manager, coach or colleague within HR/Learning. Book some time to sit down and discuss your concerns and ask
                      them for help with your progression. If needed, they can help you to build some effective developmental solutions in response to your
                      report.
                    </p>

                    <PageHeader h2Class='mt4 page-break' h2Text={<strong>Introduction</strong>} h3Text='How your report is compiled' line={true} />

                    <h3 className='f5 mt4'>
                      <strong>Two things to note</strong>
                    </h3>
                    <p>Your feedback is made up of two key elements: competencies and statements.</p>

                    <h3 className='f5 mt4'>
                      <strong>Competencies</strong>
                    </h3>
                    <p>Competencies are high-level collections/groups of skills or knowledge. Each competency is made up of a series of statements.</p>

                    <h3 className='f5 mt4'>
                      <strong>Statements</strong>
                    </h3>
                    <p>Statements are a series of actions or behaviours, which are used to describe each competency area.</p>

                    <h3 className='f5 mt4'>
                      <strong>Rating calculations</strong>
                    </h3>
                    <p>
                      When completing the online questionnaire, you rated each statement against a scale. These ratings are collated and averaged to show the
                      mean rating for for each of the overall competencies.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Unable to comment rating</strong>
                    </h3>
                    <p>
                      There are occasions where you may have felt unable to provide you with feedback to a specific statement. In these instances, you might
                      have selected the ‘unable to comment’ rating.
                    </p>
                    <p>
                      Whenever you choose this rating, it is not included in the rating calculation for that statement, or in the overall competency rating.
                    </p>
                  </div>

                  <TitlePage h1Text='Part 1:' h2Text='Your Feedback Summary' />

                  {/* part 1 step 1 */}
                  {this.state.generateGraph ? (
                    <div className='bg-white user-report__content'>
                      <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 1' h3Text='Competencies: an overview of your competencies' line={true} />

                      <p>The competency chart gives you a summary picture of how you perceive your general strengths and development needs.</p>
                      <p>Delivering highly-rated competencies indicates your strengths.</p>
                      <p>Conversely, delivering consistently lowly-rated competencies can point to areas where you may need to change/improve.</p>
                      <p>In the ’Your detailed feedback’ section of the report, you'll be able to explore your capabilites in more detail.</p>
                      <div className='flex w-100 chartTable'>
                        <div className='w-50'>
                          <p>
                            <span className='w1 h1 dib br-100 bg-blue v-mid mr2'></span> <strong>Self</strong>
                          </p>
                        </div>
                        <div className='w-50'>
                          <p className='tr'>
                            <strong>Outer ring on graph</strong> = greater frequency of behaviours
                            <br />
                            <strong>Inner ring</strong> = lower frequency of behaviours
                          </p>
                        </div>
                      </div>

                      <div className='radar'>
                        {
                          <img
                            style={chartOptions.size === 358 ? { margin: 'auto' } : {}}
                            src={this.convertSVGToImage(<RadarChart captions={captionsLoaded} data={data} size={chartOptions.size} options={chartOptions} />)}
                          />
                        }
                      </div>

                      <div className='page-break mv4'>
                        <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 1' h3Text='Competencies: an overview of your competencies' line={true} />

                        <h3 className='f5 gray mt4'>Reflection</h3>
                        <p>What initial impressions do you have from this graph about your possible strengths or areas for development?</p>
                        <DottedLines numberOfLines={26} />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* part 1 step 2 */}
                  <>
                    {/* part 1 step 2 intro and graph */}
                    <div className='bg-white page-break user-report__content'>
                      <div>
                        <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 2' h3Text='Capabilities: ranked highest to lowest' line={true} />
                      </div>
                      <p>
                        Here is a list of your competencies, ranked highest to lowest, based on your self-review responses. This will give you an indication of
                        your general strengths and developmental needs.
                      </p>

                      <div className='bb b--light-gray mv5'>
                        {withoutZero
                          ? Object.keys(withoutZero)
                              .sort(function(a, b) {
                                return withoutZero[b] - withoutZero[a];
                              })
                              .map((key, index) => {
                                if (withoutZero[key] > 0 && index < 12) {
                                  return (
                                    <ChartRowPart1
                                      key={key}
                                      step={2}
                                      selfAnswerLabel={this.state.captions[key]}
                                      selfAnswerScore={withoutZero[key].toFixed(1)}
                                    />
                                  );
                                }
                              })
                          : ''}
                      </div>
                    </div>

                    {/* part 1 step 2 graph overflow */}
                    {Object.keys(withoutZero).length > 12 && (
                      <div>
                        <div className='bg-white page-break user-report__content'>
                          <div>
                            <PageHeader
                              h2Class='mt4'
                              h2Text='Part 1: '
                              strong='Step 2'
                              h3Text='Capabilities: ranked highest to lowest (continued)'
                              line={true}
                            />
                          </div>
                          {
                            <div className='bb b--light-gray mv5'>
                              {withoutZero
                                ? Object.keys(withoutZero)
                                    .sort(function(a, b) {
                                      return withoutZero[b] - withoutZero[a];
                                    })
                                    .map((key, index) => {
                                        if (withoutZero[key] > 0 && index >= 12 ) {
                                        return (
                                          <ChartRowPart1
                                            key={key}
                                            step={2}
                                            selfAnswerLabel={this.state.captions[key]}
                                            selfAnswerScore={withoutZero[key].toFixed(1)}
                                          />
                                        );
                                      }
                                    })
                                : ''}
                            </div>
                          }
                        </div>
                      </div>
                    )}

                    {/* part 1 step 2 reflection */}
                    <div className='bg-white page-break user-report__content'>
                      <div>
                        <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 2' h3Text='Capabilities: ranked highest to lowest' line={true} />
                      </div>
                      <div className='mb3'>
                        <h3 className='f5 gray mt2'>Reflection</h3>
                        <p>Based on this chart, what appear to be your strongest and least strong capability areas?</p>
                        <DottedLines numberOfLines={6} />
                        <p>How does this align with your self perceptions?</p>
                        <DottedLines numberOfLines={6} />
                        <p>Which capabilities may be the most relevant for you to look into further?</p>
                        <DottedLines numberOfLines={6} />
                      </div>
                    </div>
                  </>

                  <TitlePage h1Text='Part 2:' h2Text='Your Detailed Feedback' />

                  {/* part 2 intro */}
                  <div className='bg-white page-break user-report__content'>
                    <PageHeader h2Class='mt4' h2Text='Part 2: ' strong='Step 1' h3Text='Navigating the detail' line={true} />
                    <p>This section of the report looks in detail at each of your self-report competency areas.</p>
                  </div>

                  {/* part 2 chart */}
                  {Object.keys(this.state.sectionDetails)
                    .sort((a, b) => {
                      const sectionDetails = this.state.sectionDetails;
                      return this.state.sectionsLegacyMode
                        ? sectionDetails[a].created - sectionDetails[b].created
                        : sectionDetails[a].sortOrder - sectionDetails[b].sortOrder;
                    })
                    .map((key, i) => {
                      let sectionID = key;
                      let sectionNum = i;
                      return (
                        <>
                          {this.state.sectionDetails[sectionID].title == 'Additional' ? (
                            <div className='bg-white page-break user-report__content'>
                              <h2 className='f2 mv0 mt4'>
                                Part 2: <strong>Step {Object.keys(this.state.sectionDetails).length + 1}</strong>
                              </h2>
                              <h3 className='f5 gray'>Your Detailed Feedback</h3>
                              <PageHeader
                                h2Class='mt4'
                                h2Text='Part 2: '
                                strong={`Step ${Object.keys(this.state.sectionDetails).length + 1}`}
                                h3Text='Your Detailed Feedback'
                              />
                              <h2 className='f4 mt4 mb1'>
                                <strong>Self:</strong>
                              </h2>
                              <p>{this.state.userCommentsBySection[sectionID]}</p>
                            </div>
                          ) : (
                            <>
                              {(this.state.userAnswers[key] * 5).toFixed(1) !== 0 &&
                              this.state.userAnswers[key] !== undefined &&
                              this.state.secLengthNoFreeText[key] > 0 ? (
                                <>
                                  <div key={sectionID} id={sectionID} className='mb4 user-report__content user-report__content--no-pad'>
                                    <div className='average-rating-section'>
                                      <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${sectionNum + 2}`} h3Text='Your Detailed Feedback' />

                                      <div className='ba b--moon-gray mb10 table page-break'>
                                        <div className='row flex bg-light-gray'>
                                          <div className='w-30 pt1 pb0'></div>
                                          <div className='w-70'>
                                            <div className='w-100 flex flex-wrap table__row pl1'>
                                              <div className='w-60 pt1 pb0'>
                                                <strong>Average Rating</strong>
                                              </div>
                                              <div className='w-40 pt1 pb0'>
                                                <div className='tc'>
                                                  <strong>Number of responses</strong>
                                                </div>
                                                <div className='w-100 flex'>
                                                  {[5, 4, 3, 2, 1, '?'].map((c) => (
                                                    <p className='w-100 mv0 tc'>{c}</p>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <ChartRowPart2
                                          containerClass='row pt1 pb1 pl2 flex bt bb b--moon-gray'
                                          isSection={true}
                                          title={<strong>{this.state.sectionDetails[key].title}</strong>}
                                          valueData={withoutZero[key].toFixed(1)}
                                          barData={this.state.userAnswers[key].toFixed(1)}
                                          responses={this.state.userResponses[sectionID]}
                                        />

                                        {Object.keys(this.state.sectionDetails[sectionID].questions).map((key, index) => {
                                          let questionID = key;
                                          return index <= 5 && this.state.sectionDetails[sectionID].questions[questionID]['answerType'] != 'FreeText' ? (
                                            <ChartRowPart2
                                              key={questionID}
                                              containerClass='row pt1 pb1 pl2 flex bt b--moon-gray page-break'
                                              title={this.state.sectionDetails[sectionID].questions[questionID].questionTitle}
                                              valueData={this.state.userIndividualAnswers[questionID].toFixed(1)}
                                              barData={this.state.userIndividualAnswers[questionID].toFixed(1) / 5}
                                              responses={this.state.userIndividualAnswers[questionID]}
                                            />
                                          ) : (
                                            ''
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                              {/* EXTRA Questions 6+ */}
                              {this.state.secLengthNoFreeText[key] > 6 ? (
                                <div className='mb4 page-break user-report__content user-report__content--no-pad'>
                                  <div className='page-break average-rating-section'>
                                    <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${sectionNum + 2}`} h3Text='Your detailed feedback (continued)' />

                                    <div className='ba b--moon-gray mb10 table page-break'>
                                      {Object.keys(this.state.sectionDetails[sectionID].questions).map((key, index) => {
                                        let questionID = key;

                                        return index >= 6 && this.state.sectionDetails[sectionID].questions[questionID]['answerType'] != 'FreeText' ? (
                                          <ChartRowPart2
                                            key={questionID}
                                            containerClass='row pt1 pb1 pl2 flex bt b--moon-gray'
                                            title={this.state.sectionDetails[sectionID].questions[questionID].questionTitle}
                                            valueData={this.state.userIndividualAnswers[questionID].toFixed(1)}
                                            barData={this.state.userIndividualAnswers[questionID].toFixed(1) / 5}
                                            responses={this.state.userIndividualAnswers[questionID]}
                                          />
                                        ) : (
                                          ''
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              {/* text answers */}
                              {this.state.userCommentsBySection[sectionID] ? (
                                <div className='bg-white page-break user-report__content'>
                                  <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${sectionNum + 2}`} h3Text='Free text comments' line={true} />
                                </div>
                              ) : (
                                <></>
                              )}
                              {this.state.userCommentsBySection[sectionID]
                                ? Object.keys(this.state.userCommentsBySection[sectionID]).map((questionKey, i) => {
                                    return (
                                      <>
                                        <div className='mb2 average-rating-section-reflection'>
                                          {this.state.userAnswers[key] === undefined ? (
                                            i === 0 ? (
                                              <>
                                                <h2 className='f2 mv0 mt4'>
                                                  Part 2: <strong>Step {sectionNum + 2}</strong>
                                                </h2>
                                                <hr />
                                              </>
                                            ) : (
                                              <>
                                                <h2 className='f2 mv0 mt4'>
                                                  Part 2: <strong>Step {sectionNum + 2}</strong>
                                                </h2>
                                                <h3 className='f5 gray'>Your detailed feedback (continued)</h3>
                                              </>
                                            )
                                          ) : (
                                            ''
                                          )}
                                          {/* {this.state.userAnswers[key] === undefined ? (
                                            <PageHeader
                                              h2Class='mt4'
                                              h2Text='Part 2: '
                                              strong={`Step ${sectionNum + 2}`}
                                              h3Text={i > 0 ? 'Your detailed feedback (continued)' : ''}
                                              line={i > 0 ? false : true}
                                            />
                                          ) : (
                                            <></>
                                          )} */}
                                          <h4 className='f5 mv0'>
                                            <strong>{this.state.userCommentsBySection[sectionID][questionKey]['title']}</strong>
                                          </h4>
                                        </div>
                                        {this.state.userCommentsBySection[sectionID] &&
                                        this.state.userCommentsBySection[sectionID][questionKey] !== undefined &&
                                        this.state.userCommentsBySection[sectionID][questionKey]['comment'] ? (
                                          <div className='average-rating-section-reflection'>
                                            <p>
                                              <strong>Self:</strong>
                                            </p>
                                            <p>{this.state.userCommentsBySection[sectionID][questionKey]['comment']}</p>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    );
                                  })
                                : ''}
                              {/* <div className='mb5 page-break average-rating-section-reflection'> */}
                              <div className='bg-white page-break user-report__content'>
                                <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${sectionNum + 2}`} h3Text='Your detailed feedback' line={true} />
                                <h3 className='f4 mb1 pt3 mt1'>
                                  <strong>Reflection</strong>
                                </h3>
                                <h4 className='gray mb1'>What are the key themes or messages from the feedback in this section?</h4>
                                <DottedLines numberOfLines={26} />
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}

                  <TitlePage h1Text='Part 3:' h2Class=' mt4' h2Text='Your Development Plan' />

                  <div className='bg-white user-report__content'>
                    <PageHeader h2Class='mt4' h2Text='Your Development Plan' h3Text='Guide' line={true} />

                    <p>You need to create a development plan, with specific goals, to get the most out of your self-report.</p>
                    <p>There are three stages to creating an effective development plan:</p>
                    <p>
                      &bull; Reviewing your 360 Self-Report
                      <br />
                      &bull; Creating some SMART goals
                      <br />
                      &bull; Reviewing and tracking your progress
                    </p>
                    <p>If you follow these stages, particularly the regular review of your progress, you will get the most value from your feedback.</p>
                  </div>

                  <div className='page-break bg-white user-report__content'>
                    <PageHeader h2Class='mt4' h2Text='Part 3: ' strong='Step 1' h3Text='Review your report' line={true} />

                    <p>Go back through the notes, reflections and observations that you have made throughout your report.</p>
                    <p>
                      Use these to build a summary of the key messages that stand out from the assessment. This summary will help you to focus your thinking
                      when it comes to creating some relevant goals.
                    </p>

                    <DottedLines numberOfLines={16} />
                  </div>

                  <div className='page-break bg-white user-report__content'>
                    <PageHeader h2Class='mt4' h2Text='Part 3: ' strong='Step 2' h3Text='My goals' line={true} />

                    <p>
                      Use your summary findings to guide you through setting a small number of developmental goals that are stretching but achievable. The
                      statements in the report can form a sound basis for your goals. We recommend that you put some dates into your diary, to review your
                      progress on your developmental goals.
                    </p>
                  </div>

                  {/* maps through the array to print each goal from the respective function */}
                  {[1, 2, 3].map((n) => (
                    <Goals num={n} />
                  ))}

                  <div className='page-break bg-white user-report__content'>
                    <PageHeader h2Class='mt4' h2Text='Part 3: ' strong='Step 3' h3Text='Review progress on your development goals' line={true} />
                    <p>
                      Regularly reviewing progress on your development goals is recommended, as this will help you to see progress made and refocus on those
                      goals if necessary.
                    </p>
                  </div>

                  {/* maps through the array to print each goal review progress step from the respective function */}
                  {[1, 2, 3].map((n) => (
                    <GoalProgress num={n} />
                  ))}

                  <header className='page-break user-report__header'>
                    <img crossorigin='anonymous' src={this.props.current360.settings.logo} class='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>Good Luck</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Own the change you want to see! </strong>
                      </p>
                    </div>
                  </header>
                </div>
              </PDFExport>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <canvas ref={this.hiddenCanvas}></canvas>
          {this.state.error ? (
            <div className='error-404 bg-blue fixed left-0 top-0'>
              <h1 className='white mt4'>System Error - Error loading report</h1>
            </div>
          ) : (
            <LoadingState />
          )}
        </div>
      );
    }
  }
}

export default withAuthorisation(authCondition)(SelfReport);
// export default User360Report
