import React, { Component } from 'react';
// import ReactExport from "react-export-excel";
import ReactExport from 'react-data-export';
import { db } from '../firebase';
import moment from 'moment';
import Modal from './global/modal';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportScoresButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalWindow: false,
      clicked: false,
      loading: true,
      excelData: null,
      groupUsers: this.props.currentGroupUsers,
      reviewers: this.props.currentGroupUsersReviewers,
      accountId: this.props.accountId,
      current360Id: this.props.current360Id,
      currentGroupId: this.props.currentGroupId,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ modalWindow: false });
  }
  openModal(type) {
    this.exportResults();
    this.setState({ modalWindow: true });
  }

  componentDidMount() {
    this.setState({ loading: false });
    let questions = {};
    Object.keys(this.props.questionnaireSections).map((secKey, n) => {
      questions[secKey] = {};
      db.getQuestions(this.state.accountId, this.props.questionnaireID, secKey).then((res) => {
        res.docs.map((doc) => {
          questions[secKey][doc.id] = doc.data();
        });
        if (n + 1 === Object.keys(this.props.questionnaireSections).length) {
          this.setState({ questions: questions });
        }
      });
    });
  }

  exportResults = () => {
    this.setState({ loading: true });
    if (this.state.excelData) {
      this.setState({ loading: false });
    } else {
      db.exportResults(
        this.props.accountId,
        this.props.current360Id,
        this.props.currentGroupId,
        this.props.questionnaireID,
        this.props.currentGroupUsersReviewers,
        this.props.currentGroupUsers,
        this.props.questionnaireSections
      )
        .then((res) => {
          this.setState({
            exportData: res,
          });
          this.getExcelData(res);
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  //this calls the firestore db and loads the data into state for export
  getExcelData(exportData) {
    let data = [];
    let excelData = [];
    let count = 0;

    this.setState({
      clicked: true,
    });
    // console.log('test');
    // MUSH THROUGH THE REPORT DATA OBJECT
    let userCount = 0;

    // a check to see if there is any data within the exceldata in the state, if there is then it doesn't make another db call
    if (!this.state.excelData) {
      this.setState({ excelData: null });
      Object.keys(this.state.groupUsers).map((revieweeKey) => {
        userCount++;
        // Loop through each users Reviewer List
        db.doGetCurrentGroupUserReviewers(this.state.accountId, this.state.current360Id, this.state.currentGroupId, revieweeKey).onSnapshot((snapshot) => {
          count++;

          let reviewersByType = {};
          snapshot.docs.map((doc) => {
            if (!reviewersByType[doc.data().relationship]) {
              reviewersByType[doc.data().relationship] = {};
            }

            reviewersByType[doc.data().relationship][doc.id] = doc.data();
          });
          if (snapshot.docs.length === 0) {
            // console.log(`${this.state.groupUsers[revieweeKey].forename} ${this.state.groupUsers[revieweeKey].surname}`);
          }
          if (this.state.groupUsers[revieweeKey].completed) {
            let revieweeData = [
              {
                value: `${this.state.groupUsers[revieweeKey].forename} ${this.state.groupUsers[revieweeKey].surname}`,
                style: {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: '65db4d' },
                  },
                },
              },
              {
                value: this.state.groupUsers[revieweeKey].email,
                style: {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: '65db4d' },
                  },
                },
              },
              {
                value: `Self Review`,
                style: {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: '65db4d' },
                  },
                },
              },
            ];

            Object.keys(this.state.questions).map((secKey) => {
              Object.keys(this.state.questions[secKey]).map((ansKey) => {
                revieweeData.push({
                  value: `${exportData[0][revieweeKey][secKey][ansKey]}`,
                  style: {
                    fill: {
                      patternType: 'solid',
                      fgColor: { rgb: '65db4d' },
                    },
                  },
                });
              });
            });
            data.push(revieweeData);
          } else {
            data.push([
              `${this.state.groupUsers[revieweeKey].forename} ${this.state.groupUsers[revieweeKey].surname}`,
              this.state.groupUsers[revieweeKey].email,
              'Self',
              this.state.groupUsers[revieweeKey].completed ? 'Completed' : 'Incomplete',
            ]);
          }

          if (snapshot.docs) {
            Object.keys(reviewersByType).map((relKey) => {
              Object.keys(reviewersByType[relKey]).map((revID) => {
                const reviewer = reviewersByType[relKey][revID];
                if (reviewer.completed) {
                  let reviewerDetails = [
                    {
                      value: `${this.state.reviewers[revID].name}`,
                    },
                    {
                      value: this.state.reviewers[revID].email,
                    },
                    {
                      value: relKey,
                    },
                  ];
                  Object.keys(this.state.questions).map((secKey) => {
                    Object.keys(this.state.questions[secKey]).map((ansKey) => {
                      reviewerDetails.push({
                        value: `${exportData[1][revID][secKey][ansKey]}`,
                      });
                    });
                  });
                  data.push(reviewerDetails);
                } else {
                  data.push([
                    `${this.state.groupUsers[revieweeKey].forename} ${this.state.groupUsers[revieweeKey].surname}`,
                    this.state.groupUsers[revieweeKey].email,
                    reviewer.name ? reviewer.name : 'N/A',
                    reviewer.relationship,
                    reviewer.completed ? 'Completed' : 'Incomplete',
                  ]);
                }
              });
            });
          } else {
            // console.log(`${this.state.groupUsers[revieweeKey].forename} ${this.state.groupUsers[revieweeKey].surname}`);
          }

          if (Object.keys(this.state.groupUsers).length == userCount) {
            let cols = [
              { title: 'Name', width: { wpx: 200 } },
              { title: 'Email', width: { wpx: 200 } },
              { title: 'Relationship', width: { wpx: 200 } },
            ];

            Object.keys(this.state.questions).map((secKey) => {
              Object.keys(this.state.questions[secKey]).map((key) => {
                cols.push({
                  title: this.state.questions[secKey][key].questionTitle,
                  width: { wpx: 200 },
                });
              });
            });
            excelData = [
              {
                columns: cols,
                data: data,
              },
            ];

            this.setState({
              loading: false,
              excelData: excelData,
            });
          }
        });
      });

      //this else is used when this function is called and theres already excel data loaded
    } else {
      return;
    }
  }

  render() {
    return (
      <>
        <div>
          <button className='btn' onClick={() => this.openModal()}>
            Export Scores
          </button>
          <Modal
            isOpen={this.state.modalWindow}
            contentLabel='Test'
            onClose={() => this.closeModal()}
            content={
              <div className='pa2'>
                <h3>Confirm Export</h3>
                <p>
                  Please click below to confirm your export:
                  <br />
                  <strong>
                    group-export-
                    {moment(Date.now()).format('DD-MM-YY')}
                    .xls
                  </strong>
                </p>
                <div className='tc'>
                  <ExcelFile
                    filename={`group-export-${moment(Date.now()).format('DD-MM-YY')}`}
                    element={
                      <button
                        className='btn'
                        disabled={this.state.loading}
                        onClick={() => {
                          this.setState({ clicked: false });
                        }}
                      >
                        {this.state.loading ? 'Loading...' : 'Confirm Export'}
                      </button>
                    }
                  >
                    <ExcelSheet dataSet={this.state.excelData} name={`group-export-${moment(Date.now()).format('DD-MM-YY')}`} />
                  </ExcelFile>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  }
}

export default ExportScoresButton;
