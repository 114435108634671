import React, { Component } from 'react';
import { db } from '../firebase';
import { Link, Redirect } from 'react-router-dom';
import Dropdown from './global/dropdown';
import { convertRelationshipTextToKey } from '../helpers';
import Modal from './global/modal';
import axios from 'axios';
import AuthUserContext from './authusercontext';

export default class SendEmailCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedSend: this.props.loadedSend,
      currentTaskNo: this.props.currentTaskNo,
      reviewers: this.props.reviewers,
      status: 'loading',
      sending: this.props.sending,
    };
  }

  componentDidMount() {
    if (this.state.currentTaskNo == 1) {
      this.setState({
        status: 'Emails not sent yet',
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.loadedSend !== prevProps.loadedSend) {
      if (this.props.loadedSend[0] !== undefined) {
        this.setState({
          loadedSend: this.props.loadedSend,
        });
        this.checkStatus(this.props.loadedSend);
      }
    } else if (this.state.loadedSend !== null) {
      if (this.props.currentTaskNo > 1 && this.state.loadedSend.length > 0 && this.state.status == 'loading' && this.props.loadedSend[0] !== undefined) {
        this.checkStatus();
        //this.setState({ status: 'loaded' });
      }
    }
  }

  checkStatus(propsSend) {
    let i = 0;
    let errors = [];
    let loadedSendNull;
    if (this.props.loadedSend === undefined || this.props.loadedSend === 'loading') {
      return;
    }
    if (this.props.loadedSend[0][0] == undefined) {
      // console.log('chceeckk');
      this.setState({
        tooltip: 'Please wait 30 seconds and reload the page',
        status: 'Status not available yet',
      });
      return;
    }
    if (propsSend !== undefined && propsSend.length > this.state.loadedSend) {
      this.setState({
        loadedSend: this.props.loadedSend,
      });
      // console.log('state check');
      // console.log(this.state.loadedSend);
      loadedSendNull = propsSend;
    } else {
      loadedSendNull = this.state.loadedSend;
    }

    let reviewers = this.state.reviewers;
    if (this.state.loadedSend === null || loadedSendNull.length === 0) {
      loadedSendNull = [[]];
    }

    if (loadedSendNull[0] !== undefined) {
      if ((loadedSendNull[0].length === 0 && this.state.currentTaskNo > 1) || this.props.loadedSend[0][0].To == 'n/a') {
        this.setState({
          tooltip: 'Please wait 30 seconds and reload the page',
          status: 'Status not available yet',
        });
      }
      //checking if an email has been sent first
      else if (this.state.currentTaskNo > 1 && this.state.loadedSend.length !== 0) {
        //checking if there are any reviewers, if there aren't then only check for self-review email errors
        if (reviewers === undefined || Object.keys(reviewers).length === 0) {
          const loadedArray = loadedSendNull[0];
          // console.log('there are no reviewers');
          //loop over the loaded email events array
          for (let i = 0; i < loadedArray.length; i++) {
            //break the current object in the array into an array of it's keys
            //see if the current email address is in the array (-1 means it isn't)
            //check to see if the current email event is an error
            if (Object.values(loadedArray[i]).indexOf(this.props.groupUserDetails.email) !== -1 && loadedArray[i].Because !== 'success') {
              this.setState({
                status: 'Error sending self-review',
              });
            } else if (Object.values(loadedArray[i]).indexOf(this.props.groupUserDetails.email) !== -1) {
              // console.log('who even knows');
              this.setState({
                status: loadedArray[i].Because,
              });
            } else if (i == loadedSendNull[0].length) {
              this.setState({
                status: 'Success',
              });
            }
          }
          if (loadedSendNull[0].indexOf(this.props.groupUserDetails.email) == -1) {
            return;
          }
        } else {
          //if there are reviewers then check for errors sending them emails
          for (let n = 0; n < loadedSendNull[0].length; n++) {
            for (const key in reviewers) {
              let objArr = Object.values(reviewers[key]);
              //check if the email address is in the email events array
              //check if the event found is an error/wasn't a success
              if (objArr.indexOf(loadedSendNull[0][n].To) !== -1 && loadedSendNull[0][n].Because !== 'success') {
                //check if the value is already in the errors array, if it isn't then add it
                if (errors.indexOf(loadedSendNull[0][n].To) === -1) {
                  i = 0;
                  errors.push(loadedSendNull[0][n].To);
                }
                i = 0;
              }
            }
          }
        }
        // console.log(errors);
        if (errors.length == 0) {
          this.setState({ status: 'Delivered' });
        } else {
          let toStr = errors.toString();
          this.setState({
            status: 'Error',
          });
        }
      } else {
        this.setState({ status: 'Instructions not sent' });
      }
    } else {
      this.setState({
        status: 'Email(s) in transit',
        tooltip: 'Please wait 30 seconds and reload the page',
      });
    }
  }

  render() {
    return this.state.status == 'loading' ? (
      <div>
        <p className={'reset-mar'}>Loading</p>
      </div>
    ) : this.state.status === 'Status not available yet' || this.state.status == 'Unable to load' ? (
      <div>
        <p className={'reset-mar has-tooltip'} data-tooltip={this.state.tooltip}>
          {this.state.status}
        </p>
      </div>
    ) : (
      <div>
        <p className={'reset-mar'}>{this.state.status}</p>
      </div>
    );
  }
}
