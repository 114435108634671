import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/site-header'
import { compose } from 'recompose'
import withSuperUser from '../components/withsuperuser'
import SignOutButton from '../components/signoutbutton'
import logo from '../logo.svg'
const authCondition = (authUser) => !!authUser
//

class Admin extends Component {
  render() {
    return (
      <main>
        <header className='site-header'>
          <div className='container'>
            <div className='site-header__row'>
              <img src={logo} className="header-logo mr4" />
              <p className="reset-mar">Howdy, <strong>Super Admin!</strong></p>
              <div className="flex items-center">
                <Link className="btn btn--white ml2" to="/admin/accounts/">View Accounts</Link> <Link className="btn btn--white ml2 mr4" to="/admin/signup/">Create New Account</Link>
                <SignOutButton />
              </div>
            </div>
          </div>
        </header>
        <section className="page-wrapper">
          <div className="container container--slim">
            <h1>Boomerang Admin</h1>
            <p>Welcome to the Admin Dashboard. You can Edit, View or Create Boomerang accounts using the buttons below.</p>
            <p><Link className="btn" to="/admin/accounts/">View Accounts</Link></p>
            <p><Link className="btn" to="/admin/signup/">Create New Account</Link></p>
          </div>
        </section>
      </main>
    );
  }
}

export default compose(
  withSuperUser(authCondition),
)(Admin)
