import React, { Component } from 'react';
import HorizontalBar from './HorizontalBar';
import RelationshipLabel from './RelationshipLabel';
import RowTitle from './RowTitle';
import ResponseNumbers from './ResponseNumbers';
import {valueCheck, percentageCheck} from '../../helpers'

export default class ChartRowPart2 extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    const {key, isSection, valueData, containerClass, title, barData, responses} = this.props
    const valueRating = valueCheck(valueData)
    const barRating = percentageCheck(valueData)
    return (
      <div key={key} className={containerClass}>
        <RowTitle
          containerClass={`w-30 ${isSection ? '' : 'pr3'}`}
          pClass={`ma0 ${isSection ? 'f6' : ''}`}
          title={isSection ? <strong>{title}</strong> : title}
        />
        <div className={`w-70 ${isSection ? '' : ' flex flex-wrap'}`}>
          <div className={`w-100 flex table__row ${isSection ? 'flex-wrap' : ''}`}>
            <HorizontalBar containerClass='w-60 mv1' pClass='flex ma0' valueRating={valueRating} barRating={barRating / 5} />
            <div className={`w-40 ${isSection ? 'p' : 'm'}v1`}>
              <ResponseNumbers responses={responses} isSection={isSection} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
