import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../../firebase';
import SiteFooterBrand from '../../../../components/site-footer-brand';
import SiteHeader from '../../../../components/site-header';
import SiteNav from '../../../../components/site-nav';
import EmailEditForm from '../../../../components/email-edit-form';
import Modal from '../../../../components/global/modal';
import withAuthorisation from '../../../../components/withauthorisation';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { breadcrumbSelect, projectTypeText } from '../../../../helpers';
const authCondition = (authUser) => !!authUser;
//

class Edit360ReviewerEmail extends Component {
  constructor(props) {
    super(props);

    let editLinkQuestionnaire;

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      loaded360: false,
      displayPreviewModal: false,
      loaded: false,
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    window.scrollTo(0, 0);

    db.doGetCurrent360ReviewerEmailText(this.state.accountId, params.id).onSnapshot((snapshot) => {
      if (snapshot.data() === undefined) {
        // console.log('it was empty');
        this.setState({
          current360EmailText: [
            { body_text: '[NAME] has requested you to complete their review.', button_text: 'Click here to complete their review', title: 'Hi [NAME]!' },
          ],
          loaded: true,
        });
      } else {
        this.setState({
          current360EmailText: snapshot.data(),
          loaded: true,
        });
      }
    });
    // db.doGetCurrent360EmailText(this.state.accountId, params.id).onSnapshot((snapshot) => {
    //   this.setState({
    //     current360EmailText: snapshot.data(),
    //     loaded: true,
    //   });
    // });
  }

  render() {
    return this.state.loaded ? (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={this.state.current360}
            accountData={this.props.accountData}
            current360Groups={this.props.current360Groups}
            current360Relationships={this.props.current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${this.state.accountId}`,
              },
              {
                title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                  this.props.current360.type
                } Projects`,
                to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(this.props.current360.type)}-projects/`,
              },
              {
                title: this.state.current360.title,
                to: '../../',
              },
              {
                title: 'Advanced Settings',
                to: '../',
              },
              {
                title: 'Reviewer Email Settings',
                to: null,
              },
            ]}
          />

          <h1>{this.state.current360.title}: Reviewer Email Text Settings</h1>

          {this.state.loaded ? (
            <div className='bg-white mv4 ph4 pv4 shadow br2'>
              <div className='flex'>
                <div className='w-50'>
                  <p>
                    <strong>Change Email Text</strong>
                  </p>
                  <p>
                    Here you can change the content of each reminder email sent out via a{' '}
                    {this.state.current360['type'] === 'Goal Tracker' ? 'goal tracker' : 'Questionnaire'} project.
                    <br />
                    Note: using [NAME] will render the {projectTypeText(this.state.current360.type, "reviewee's", "reviewee's", 'team')} name in the email.
                  </p>
                </div>
              </div>
              {Object.keys(this.state.current360EmailText).map((key, i) => {
                return (
                  <EmailEditForm
                    key={key}
                    accountId={this.state.accountId}
                    current360Id={this.state.current360ID}
                    current360StatusCode={this.props.current360.statusCode}
                    emailNum={i + 1}
                    emailKey={key}
                    email={this.state.current360EmailText[key]}
                    reviewerReminder={true}
                  />
                );
              })}
            </div>
          ) : (
            ''
          )}

          <div className='w-100 flex justify-between'>
            <Link to={{ pathname: '../', current360: this.props.current360, accountData: this.props.accountData }} className='btn btn--back'>
              Return to Advanced Settings
            </Link>
            <button className='btn' onClick={(e) => this.setState({ displayPreviewModal: true })}>
              Prevew Mail Merges
            </button>
          </div>
          <Modal
            isOpen={this.state.displayPreviewModal}
            contentLabel='Preview Email Content'
            onClose={() => this.setState({ displayPreviewModal: false })}
            content={
              <div>
                <h2>Preview Email Content</h2>
                <strong>{this.state.current360EmailText[0].title.replace('[NAME]', `${this.state.accountData.fname} ${this.state.accountData.lname}`)}</strong>
                <br />
                <br />
                <p>{this.state.current360EmailText[0].body_text.replace('[NAME]', `${this.state.accountData.fname} ${this.state.accountData.lname}`)}</p>
                <br />
                <div className='w-100 tc'>
                  <button className='btn' onClick={(e) => e.preventDefault()}>
                    {this.state.current360EmailText[0].button_text.replace('[NAME]', `${this.state.accountData.fname} ${this.state.accountData.lname}`)}
                  </button>
                </div>
              </div>
            }
          />
        </section>
      </div>
    ) : (
      ''
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360ReviewerEmail);
