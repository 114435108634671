import React, { Component } from 'react';

export default class PageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    return (
      <div>
        <h2 className={`f2 mv0 ${this.props.h2Class}`}>
          {this.props.h2Text ? this.props.h2Text : ''}
          {this.props.strong ? <strong>{this.props.strong}</strong> : ''}
        </h2>
        {this.props.h3Text ? <h3 className='f5 gray'>{this.props.h3Text}</h3> : <></>}
        {this.props.line ? <hr className='' /> : <></>}
      </div>
    );
  }
}
