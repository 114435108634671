import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { auth, db } from '../firebase'
import RegisterAccessForm from '../components/registeraccessform'
import SiteHeader from '../components/site-header'
import { compose } from 'recompose'
import withSuperUser from '../components/withsuperuser'
import SignOutButton from '../components/signoutbutton'
import withAuthorisation from '../components/withauthorisation'
import logo from '../logo.svg'
//


class AccessPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      valid: false,
      userObj: {},
      userEmail: this.props.match.params.userEmail,
      accountId: this.props.match.params.accountId,
      anonUser: null
    }
  }

  componentDidMount() {
    auth.doSignInAnon().then((authUser) => {
      this.setState({
        anonUser: authUser
      })

      db.doGetAdminUserAccount(this.props.match.params.accountId, this.props.match.params.userEmail).then((data) => {
        this.setState({
          loading: false,
          valid: true,
          userObj: data
        })
      }).catch(() => {
        this.setState({
          loading: false,
          valid: false
        })
      })

    });


  }

  render() {
    return (
      <main>

          {
            (!this.state.loading && this.state.valid) ? (
              <section className="sign-in">
                {
                  (this.state.userEmail && this.state.accountId) ? (
                    <RegisterAccessForm history={ this.props.history } anonUser={this.state.anonUser} accountId={this.state.accountId} fname={this.state.userObj.fname} lname={this.state.userObj.lname} email={this.state.userEmail} />
                  ) : ''
                }
              </section>
            ) : (
              <section className="sign-in">
                {
                  (!this.state.loading && !this.state.valid) ? (
                    <p>Invitation link invalid or expired</p>
                  ) : ''
                }
              </section>
            )
          }


      </main>
    )
  }
}

export default AccessPage
