import React, { Component } from 'react';
import PasswordForgetForm from '../components/passwordforgetform'
//

class PasswordForgetPage extends Component {
  render() {
    return (
      <main>
        <section className="sign-in">
          <PasswordForgetForm />
        </section>
      </main>
    );
  }
}

//

export default PasswordForgetPage;
