// import firebase from 'firebase';
import firebase from 'firebase/app';
import { auth } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);

// Sign In anon
export const doSignInAnon = () => auth.signInAnonymously();

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (newPassword) => auth.currentUser.updatePassword(newPassword);

// Re-auth (before password change)
export const doReauthenticateUser = (currentPassword) => {
  let user = auth.currentUser;
  let cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
  return user.reauthenticateAndRetrieveDataWithCredential(cred);
};

export const checkMFA = (email) => {
  return firebase.auth().currentUser;
};

export const mfaLogin = (phoneNo, recaptchaVerifier) => {
  const user = auth.currentUser;
  return user.multiFactor
    .getSession()
    .then((mfaSession) => {
      const phoneInfoOptions = {
        phoneNumber: phoneNo,
        session: mfaSession,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    })
    .catch((err) => {
      // console.log(err);
      return err;
    });
};

export const smsTokVerify = (ID, smsCode) => {
  const cred = firebase.auth.PhoneAuthProvider.credential(ID, smsCode);
  const multiFactorAssertsion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  return auth.currentUser.multiFactor.enroll(multiFactorAssertsion, 'SMS');
};

export const loginPageMFA = (resolver, recaptchaVerifier) => {
  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  };
  const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
  // Send SMS verification code.
  return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
};

export const verify2faCode = (ID, smsCode, resolver) => {
  const cred = firebase.auth.PhoneAuthProvider.credential(ID, smsCode);
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

  return resolver.resolveSignIn(multiFactorAssertion);
};
