import React, { Component } from 'react';

class DFChartHeadings extends Component {
  render() {
    return (
      <div className='row flex bg-light-gray'>
        <div className='w-30 pt1 pb0'></div>
        <div className='w-70'>
          <div className='w-100 flex flex-wrap table__row pl1'>
            <div className='w-20 pt1 pb0'>
              <strong>Relationship</strong>
            </div>
            <div className='w-50 pt1 pb0'>
              <strong>Average Rating</strong>
            </div>
            <div className='w-30 pt1 pb0'>
              <div className='tc'>
                <strong>Number of responses</strong>
              </div>
              <div className='w-100 flex'>
                {[5, 4, 3, 2, 1, '?'].map((c) => (
                  <p key={c} className='w-100 mv0 tc'>{c}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DFChartHeadings;
