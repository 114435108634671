import React, { Component } from 'react';

class DFChartQuestions extends Component {
  render() {
    const questionID = this.props.questionID;
    const questionTitle = this.props.questionTitle;
    const relationshipTitle = this.props.relationshipTitle;
    const relationshipColor = this.props.relationshipColor;
    const questionAverage = this.props.questionAverage;
    const questionResponses = this.props.questionResponses;

    return (
      <div key={questionID} className='row pt1 pb1 pl2 flex bt b--moon-gray page-break'>
        <div className='w-30 pr3 class'>
          <p className='ma0'>{questionTitle}</p>
        </div>
        <div className='w-70 flex flex-wrap'>
          <div className='w-100 flex table__row'>
            <div className='w-20 mv1'>
              <p className='ma0' style={{ color: `${relationshipColor}` }}>
                <strong>{relationshipTitle}</strong>
              </p>
            </div>
            <div className='w-50 mv1'>
              <div className='w-100'>
                <p className='flex ma0'>
                  <strong className='w-10'>{questionAverage.toFixed(1) != 0.0 ? questionAverage.toFixed(1) : 'N/A'}</strong>
                  <span className='w-100 bg-light-gray'>
                    <span
                      className='h-100 db'
                      style={{ width: `${(questionAverage.toFixed(1) / 5) * 100}%`, backgroundColor: `${relationshipColor}` }}
                    ></span>
                  </span>
                </p>
              </div>
            </div>
            <div className='w-30 mv1'>
              <div className='w-100 flex flex-row-reverse'>
                {questionResponses.map((response, i) => {
                  return (
                    <p key={`questionResponse${i}`} className='w-100 mv0 tc'>
                      <strong>{response}</strong>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DFChartQuestions;
