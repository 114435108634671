import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { db } from '../../firebase';
import { PDFExport } from '@progress/kendo-react-pdf';
import canvg from 'canvg';
import Breadcrumbs from '../../components/breadcrumbs';
import { Link } from 'react-router-dom';
import { average } from '../../helpers';
import RadarChart from '../../components/charts/radarchart';
import LoadingState from '../../components/global/loading-state';
import withAuthorisation from '../../components/withauthorisation';
import TeamAssessmentReportPageTemplate from './teamAssessmentPageTemplate'
import Contents from '../../components/reports/Contents';
import TitlePage from '../../components/TitlePage';
import PageHeader from '../../components/PageHeader';
import TeamDetailedFeedbackSection from '../../components/reports/TeamDetailedFeedbackSection';
const authCondition = (authUser) => !!authUser;

class teamReport extends Component {
  constructor(props) {
    super(props);
    this.exportPDF = this.exportPDF.bind(this);
    this.convertSVGToImage = this.convertSVGToImage.bind(this);
    this.canvLoaded = false;
    this.hiddenCanvas = React.createRef();

    this.state = {
      loading: true,
      error: false,
      captions: {},
      userData: {},
      userId: null,
      groupId: null,
      current360Id: null,
      current360Relationships: this.props.current360Relationships,
      sectionsLegacyMode: false,
    };
  }
  exportPDF = () => {
    this.report.save();
  };
  convertSVGToImage = (htmlString) => {
    try {
      // Take an SVG html string, convert it to static markup, then use canvg to render into hidden canvas, then return dataURL string
      let staticRadarChart = ReactDOMServer.renderToStaticMarkup(htmlString);
      let canv = this.hiddenCanvas.current;
      canv.getContext('2d');
      canvg(canv, staticRadarChart);
      return canv.toDataURL('image/png');
    } catch (err) {
      console.log('err', err);
      return null;
    }
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      userId: params.userId,
      groupId: params.groupId,
      current360Id: params.id,
    });
    db.doGetCurrentGroup(this.props.match.params.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
      if (snapshot.data()) {
        this.setState({
          currentGroup: snapshot.data(),
        });
        db.doGetSectionsAndQuestionDetailsList(params.accountId, this.props.current360.questionnaireID).then((sections) => {
          let sectionDetails = {};
          let MultiChoiceQuestionsList = [];
          let questionDetails = {};
          let captions = {};
          let secLengthNoFreeText = {};

          for (const sectionID in sections) {
            const questionList = sections[sectionID].questions;
            if (!secLengthNoFreeText[sectionID]) {
              secLengthNoFreeText[sectionID] = 0;
            }
            sectionDetails[sectionID] = sections[sectionID];
            Object.keys(questionList).forEach((question) => {
              questionDetails[question] = questionList[question];
              if (questionList[question].answerType === 'MultiChoice') {
                MultiChoiceQuestionsList.push(question);
                secLengthNoFreeText[sectionID] = secLengthNoFreeText[sectionID] + 1;
                if (!captions[sectionID]) {
                  captions[sectionID] = sections[sectionID].title;
                }
              }
            });
          }
          db.doGetCompletedReviewers(params.accountId, params.id, params.groupId, params.userId).then((snapshot) => {
            if (!snapshot.empty) {
              let reviewers = {};
              snapshot.docs.map((doc, i) => {
                reviewers[doc.id] = doc.data();
              });
              let reviewerAnswersAverageSection = {};
              let radarChartValues = {};
              let reviewerAnswersSectionArray = {};
              let reviewerAnswersAverageQuestion = {};
              let reviewerSectionResponses = {};
              let reviewerQuesionResponses = {};
              const QuestionAnswers = {};
              let reviewerData = [];
              let freeTextAnswers = {};
              for (const reviewerID in reviewers) {
                db.doGetReviewerAnswers(params.accountId, reviewerID, sections)
                  .then((data) => {
                    reviewerData.push(data);

                    Object.keys(data).forEach((sectionID) => {
                      const sectionQuestions = data[sectionID]['answers'];
                      if (!reviewerAnswersSectionArray[sectionID]) {
                        reviewerAnswersSectionArray[sectionID] = [];
                      }
                      let numberAnswersToAdd = [];
                      let textAnswersToAdd = [];
                      for (const qID in sectionQuestions) {
                        const answer = sectionQuestions[qID];
                        const type = questionDetails[qID].answerType;

                        if (type === 'MultiChoice') {
                          numberAnswersToAdd.push(Number(answer));
                        } else {
                          if (!freeTextAnswers[sectionID]) {
                            freeTextAnswers[sectionID] = {};
                          }
                          if (!freeTextAnswers[sectionID][qID]) {
                            freeTextAnswers[sectionID][qID] = [];
                          }
                          freeTextAnswers[sectionID][qID].push(answer);
                        }
                      }
                      reviewerAnswersSectionArray[sectionID].push(...numberAnswersToAdd);
                      if (!reviewerSectionResponses[sectionID]) {
                        reviewerSectionResponses[sectionID] = [0, 0, 0, 0, 0, 0];
                      }
                      const questions = data[sectionID]['answers'];
                      if (questions) {
                        let answers = [];
                        let responses = [0, 0, 0, 0, 0, 0];
                        let answerKey = 0;
                        for (const qID in questions) {
                          if (!QuestionAnswers[qID]) {
                            QuestionAnswers[qID] = [];
                          }
                          if (!reviewerQuesionResponses[qID]) {
                            reviewerQuesionResponses[qID] = [0, 0, 0, 0, 0, 0];
                          }
                          let answerVal = isNaN(Number(questions[qID])) ? -1 : parseInt(questions[qID]);
                          if (!isNaN(parseInt(answerVal))) {
                            responses[answerVal] = responses[answerVal] + 1;
                            reviewerQuesionResponses[qID][answerVal] = reviewerQuesionResponses[qID][answerVal] + 1;
                          }
                          if (answerVal.toString() !== 'NaN') {
                            answers[answerKey] = answerVal;
                          }
                          if (answerVal > 0) {
                            QuestionAnswers[qID].push(answerVal);
                          }
                          answerKey++;
                        }
                        reviewerSectionResponses[[sectionID]].forEach((n, i) => {
                          const newResponseValue = reviewerSectionResponses[[sectionID]][i] + responses[i];
                          reviewerSectionResponses[[sectionID]][i] = newResponseValue;
                        });
                      }
                    });

                    for (const sectionID in reviewerAnswersSectionArray) {
                      const sectionAverage = average(reviewerAnswersSectionArray[sectionID]);
                      reviewerAnswersAverageSection[sectionID] = sectionAverage;
                      radarChartValues[sectionID] = sectionAverage / 5;
                    }

                    for (const qID in QuestionAnswers) {
                      reviewerAnswersAverageQuestion[qID] = average(QuestionAnswers[qID]);
                    }

                    return Promise.resolve();
                  })
                  .then(() => {
                    if (reviewerData.length === Object.keys(reviewers).length) {
                      const weightedHighest = [];
                      Object.entries(reviewerAnswersAverageQuestion)
                        .sort((a, b) => b[1] - a[1])
                        .forEach((q) => {
                          if (q[1] > 0) {
                            if (!weightedHighest.includes(q[0])) {
                              weightedHighest.push(q[0]);
                            }
                          }
                        });
                      this.setState({
                        freeTextAnswers,
                        reviewerSectionResponses,
                        reviewerQuesionResponses,
                        radarChartValues,
                        reviewerAnswersAverageSection,
                        reviewerAnswersAverageQuestion,
                        weightedHighest,
                        sectionDetails,
                        questionDetails,
                        captions,
                        generateGraph: MultiChoiceQuestionsList.length > 0 ? true : false,
                        secLengthNoFreeText,
                        loading: false,
                      });
                    }
                  });
              }
            }
          });
        });
      }
    });
  }
  render() {
    if (!this.state.loading) {
      let data = [{ data: this.state.radarChartValues, meta: { color: '#F563B9' } }];
      const captionsLoaded = this.state.captions;
      const chartOptions = {
        size: Object.keys(this.props.current360Relationships).length === 6 ? 490 : 700,
        axes: true, // show axes?
        scales: 5, // show scale circles?
        captions: true, // show captions?
        captionMargin: 30,
        dots: true, // show dots?
        zoomDistance: 1.3, // where on the axes are the captions?
        axisProps: () => ({ className: 'axis', fill: 'none', stroke: '#000' }),
        scaleProps: () => ({ className: 'scale', fill: 'none', stroke: '#000' }),
        shapeProps: () => ({ className: 'shape' }),
        captionProps: () => ({
          className: 'caption',
          textAnchor: 'middle',
          fontSize: 14,
          fontFamily: 'sans-serif',
        }),
      };
      const reviewerAnswersAverageSection = this.state.reviewerAnswersAverageSection;
      const reviewerAnswersAverageQuestion = this.state.reviewerAnswersAverageQuestion;

      return (
        <div className='page-wrapper page-wrapper--report'>
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.props.match.params.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `../../../../../../../team-assessment-projects/`,
                },
                {
                  title: this.props.current360.title,
                  to: '../../../../',
                },
                {
                  title: this.state.currentGroup.title,
                  // to: `../../../edit/group/${this.state.groupId}/`,
                  to: `../../`,
                },
                {
                  title: `Report & Development plan for ${this.state.userFullName}`,
                  to: null,
                },
              ]}
            />
          </section>
          <div
            className='user-report-view-only'
            style={{ height: '100vh', width: '100vw', paddingTop: 20, paddingBottom: 100, backgroundColor: 'rgb(82, 86, 89)', overflow: 'hidden' }}
          >
            <p className='tc'>
              <Link className='btn btn--back btn--white ml2 mr3' to={`../../`}>
                Back to group overview
              </Link>
              <button className='btn btn--white ml3 mr2' onClick={this.exportPDF}>
                Export as PDF
              </button>
            </p>
            <div
              style={{
                maxWidth: '210mm',
                height: '90vh',
                backgroundColor: 'white',
                boxShadow: '0px 0px 5px #000',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 100,
                padding: '0px',
                overflowX: 'scroll',
                overflowY: 'scroll',
              }}
            >
              <PDFExport
                paperSize={'A4'}
                forcePageBreak='.page-break'
                keepTogether={'div'}
                margin={{ bottom: 50 }}
                pageTemplate={TeamAssessmentReportPageTemplate}
                fileName={this.state.reportPdfFileName}
                title=''
                subject=''
                keywords=''
                ref={(r) => (this.report = r)}
              >
                <div
                  className='user-report'
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '0px',
                  }}
                >
                  <header className='user-report__header'>
                    <img crossOrigin='anonymous' src={this.props.current360.settings.logo} className='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>{this.props.current360.title}</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Team Assessment Report</strong>
                      </p>
                      <p className='user-report__header-name'>For {this.state.currentGroup.title}</p>
                    </div>
                  </header>

                  {/* <Contents partTitles={['Team feedback summary', 'Team Detailed Feedback', 'Free text feedback']} /> */}
                  <Contents partTitles={['Team feedback summary', 'Team Detailed Feedback']} />

                  <TitlePage h2Text='Introduction' />

                  <div className='bg-white user-report__content'>
                    <PageHeader h2Class='mt4' h2Text={<strong>Introduction</strong>} h3Text='Getting the most from team feedback' line={true} />
                    <h3 className='f5 mt4'>
                      <strong>Where to begin?</strong>
                    </h3>
                    <p>
                      It’s a good idea to read the report in the order provided. However, you may prefer to dive into a specific section and then pull out
                      related information from other parts of the report. You can see how the different elements of the report relate to each other, in the
                      section ‘How your report is compiled’.
                    </p>
                    <h3 className='f5 mt4'>
                      <strong>Look for consistent messages</strong>
                    </h3>
                    <p>
                      The key to understanding the team feedback is to look for clear and consistent themes across your report. Look for competencies and
                      statements where team members have given similar ratings or comments. These consistent themes will provide an indication of where the
                      team's strengths, or opportunities for improvements, can be found.
                    </p>
                    <p>
                      You should interpret one-off or isolated ratings or comments carefully. If you do find these, consider the context in which they may have
                      been provided. Ask yourself if there is any truth in these findings, or if they can be classified as ‘outliers’?
                    </p>
                    <PageHeader
                      containerClass='page-break'
                      h2Class='mt4'
                      h2Text={<strong>Introduction</strong>}
                      h3Text='How your report is compiled'
                      line={true}
                    />
                    <h3 className='f5 mt4'>
                      <strong>Two things to note</strong>
                    </h3>
                    <p>The feedback is made up of two key elements: competencies and statements.</p>
                    <h3 className='f5 mt4'>
                      <strong>Competencies</strong>
                    </h3>
                    <p>Competencies are high-level collections/groups of skills or knowledge. Each competency is made up of a series of statements.</p>
                    <h3 className='f5 mt4'>
                      <strong>Statements</strong>
                    </h3>
                    <p>Statements are a series of actions or behaviours, which are used to describe each competency area.</p>
                    <h3 className='f5 mt4'>
                      <strong>Rating calculations</strong>
                    </h3>
                    <p>
                      When completing the online questionnaire, team members rated each statement against a rating scale. These ratings are collated and
                      averaged to show the mean rating for each statement, and for each of the overall competencies.
                    </p>
                    <h3 className='f5 mt4'>
                      <strong>Unable to comment rating</strong>
                    </h3>
                    <p>
                      There are occasions where some team members may feel unable to provide feedback to a specific statement. In these instances, they might
                      have selected the ‘unable to comment’ rating.
                    </p>
                    <p>
                      Whenever the reviewer chooses this rating, it is not included in the rating calculation for that statement, or in the overall competency
                      rating.
                    </p>
                  </div>

                  <TitlePage h1Text='Part 1:' h2Text='Team Feedback Summary' />

                  {/* Part 1 Step 1 */}

                  {this.state.generateGraph ? (
                    <div className='bg-white user-report__content'>
                      <h2 className='f2 mt4 mv0'>
                        Part 1: <strong>Step 1</strong>
                      </h2>
                      <h3 className='f5 gray'>Competencies: comparison by team member ratings</h3>
                      <hr className='' />
                      <p>
                        The competency chart gives you a summary picture of how reviewers perceive the general strengths and development needs of their team.
                      </p>
                      <p>
                        The areas where the team has competencies with similar feedback ratings shows that it delivers that competency consistently. Delivering
                        consistently highly-rated competencies indicates the teams strengths.{' '}
                      </p>
                      <p>Conversely, delivering consistently lowly-rated competencies can point to areas where the team may need to change/improve.</p>
                      <p>Competencies can be explored in more detail in the ’Your detailed feedback’ section of the report.</p>
                      <div className='flex w-100 page-break chartTable'>
                        <div className='w-50'>
                          <p>
                            <span className='w1 h1 dib br-100 v-mid mr2' style={{ backgroundColor: '#F563B9' }}></span> <strong>Team Members</strong>
                          </p>
                        </div>
                        <div className='w-50'>
                          <p className='tr'>
                            <strong>Outer ring on graph</strong> = greater frequency of behaviours
                            <br />
                            <strong>Inner ring</strong> = lower frequency of behaviours
                          </p>
                        </div>
                      </div>

                      <div className='radar'>
                        {
                          <img
                            style={chartOptions.size === 490 ? { margin: 'auto' } : {}}
                            src={this.convertSVGToImage(<RadarChart captions={captionsLoaded} data={data} size={chartOptions.size} options={chartOptions} />)}
                          />
                        }
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* Part 1 Step 2 */}

                  {this.state.generateGraph ? (
                    <div className='bg-white page-break user-report__content'>
                      <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 2' h3Text='Capabilities: ranked highest to lowest' line={true} />
                      <p>
                        Here is a list of team competencies, ranked highest to lowest, based on the feedback from all reviewers. This will give an indication of
                        the teams general strengths and developmental needs.
                      </p>

                      <div className='bb b--light-gray mv5'>
                        {/* Loop through reviewerAnswersAverageSection (sorting in numerical order!) */}
                        {reviewerAnswersAverageSection
                          ? Object.keys(reviewerAnswersAverageSection)
                              .sort(function(a, b) {
                                return reviewerAnswersAverageSection[b] - reviewerAnswersAverageSection[a];
                              })
                              .map((key, index) => {
                                const lenCheck =
                                  Object.keys(reviewerAnswersAverageSection).length > 7 ? Object.keys(reviewerAnswersAverageSection).length - 6 : 99;
                                if (reviewerAnswersAverageSection[key] > 0 && index <= lenCheck) {
                                  return (
                                    <div key={key} className='w-100 flex bt b--light-gray'>
                                      <div className='w-40'>
                                        <p>{this.state.captions[key]}</p>
                                      </div>
                                      <div className='w-20'>
                                        <p style={{ color: '#F563B9' }}>
                                          <strong>Team Members</strong>
                                        </p>
                                      </div>
                                      <div className='w-40'>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <strong className='w-10'>{parseFloat(reviewerAnswersAverageSection[key]).toFixed(1)}</strong>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='h-100 db'
                                                style={{ background: '#F563B9', width: `${(reviewerAnswersAverageSection[key] / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                          : ''}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {Object.keys(reviewerAnswersAverageSection).length > 7 && (
                    <div>
                      <div className='bg-white page-break user-report__content'>
                        <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 2' h3Text='Capabilities: ranked highest to lowest (continued)' line={true} />
                        {
                          <div className='bb b--light-gray mv5'>
                            {/* Loop through reviewerAnswersAverageSection (sorting in numerical order!) */}
                            {reviewerAnswersAverageSection
                              ? Object.keys(reviewerAnswersAverageSection)
                                  .sort(function(a, b) {
                                    return reviewerAnswersAverageSection[b] - reviewerAnswersAverageSection[a];
                                  })
                                  .map((key, index) => {
                                    if (reviewerAnswersAverageSection[key] > 0 && index >= 5) {
                                      return (
                                        <div key={key} className='w-100 flex bt b--light-gray'>
                                          <div className='w-40'>
                                            <p>{this.state.captions[key]}</p>
                                          </div>
                                          <div className='w-20'>
                                            <p style={{ color: '#F563B9' }}>
                                              <strong>Team Members</strong>
                                            </p>
                                          </div>
                                          <div className='w-40'>
                                            <div className='w-100'>
                                              <p className='flex'>
                                                <strong className='w-10'>{parseFloat(reviewerAnswersAverageSection[key]).toFixed(1)}</strong>
                                                <span className='w-100 bg-light-gray'>
                                                  <span
                                                    className='h-100 db'
                                                    style={{ background: '#F563B9', width: `${(reviewerAnswersAverageSection[key] / 5) * 100}%` }}
                                                  ></span>
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })
                              : ''}
                          </div>
                        }
                      </div>
                    </div>
                  )}

                  {/* Part 1 Step 3 */}

                  <div className='bg-white page-break user-report__content'>
                    <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 3' h3Text='Statements: ranked highest and lowest' line={true} />
                    <p>This chart shows the teams highest and lowest ranked statements, based on ratings from team members.</p>
                    <p>
                      This will show you the specific statements that have been observed by the team members as strongest and the least strong. These should
                      generally align with the capability rankings and can be explored in more detail in 'Team detailed feedback'.
                    </p>
                    <div className='bb b--light-gray mv5'>
                      <p>
                        <strong>Highest Average Statements</strong>
                      </p>
                      {/* Loop through reviewerAnswersAverageQuestion (sorted in numerical order!) */}
                      {this.state.weightedHighest
                        ? this.state.weightedHighest.map((key, index) => {
                            if (reviewerAnswersAverageQuestion[key] > 0) {
                              return index < 5 ? (
                                <div key={key} className='w-100 flex bt b--light-gray'>
                                  <div className='w-40 pr2'>
                                    <p>{this.state.questionDetails[key].questionTitle}</p>
                                  </div>
                                  <div className='w-20'>
                                    <p style={{ color: '#F563B9' }}>
                                      <strong>Team Members</strong>
                                    </p>
                                  </div>
                                  <div className='w-40'>
                                    <div className='w-100'>
                                      <p className='flex'>
                                        <strong className='w-10'>{parseFloat(reviewerAnswersAverageQuestion[key]).toFixed(1)}</strong>
                                        <span className='w-100 bg-light-gray'>
                                          <span
                                            className='h-100 db'
                                            style={{ background: '#F563B9', width: `${(reviewerAnswersAverageQuestion[key] / 5) * 100}%` }}
                                          ></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              );
                            }
                          })
                        : ''}
                    </div>
                  </div>

                  <div className='bg-white page-break user-report__content'>
                    <PageHeader h2Class='mt4' h2Text='Part 1: ' strong='Step 3' h3Text='Statements: ranked highest and lowest' line={true} />
                    <div className='bb b--light-gray mv5'>
                      <p>
                        <strong>Lowest Average Statements</strong>
                      </p>
                      {this.state.weightedHighest
                        ? this.state.weightedHighest.reverse().map((key, index) => {
                            if (reviewerAnswersAverageQuestion[key] > 0) {
                              return index < 5 ? (
                                <div key={key} className='w-100 flex bt b--light-gray'>
                                  <div className='w-40 pr2'>
                                    <p>{this.state.questionDetails[key].questionTitle}</p>
                                  </div>
                                  <div className='w-20'>
                                    <p style={{ color: '#F563B9' }}>
                                      <strong>Team Members</strong>
                                    </p>
                                  </div>
                                  <div className='w-40'>
                                    <div className='w-100'>
                                      <p className='flex'>
                                        <strong className='w-10'>{parseFloat(reviewerAnswersAverageQuestion[key]).toFixed(1)}</strong>
                                        <span className='w-100 bg-light-gray'>
                                          <span
                                            className='h-100 db'
                                            style={{ background: '#F563B9', width: `${(reviewerAnswersAverageQuestion[key] / 5) * 100}%` }}
                                          ></span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              );
                            }
                          })
                        : ''}
                    </div>
                  </div>

                  {/* Part 2 */}
                  <TeamDetailedFeedbackSection sectionDetails={this.state.sectionDetails} reviewerAnswersAverageSection={this.state.reviewerAnswersAverageSection} reviewerSectionResponses={this.state.reviewerSectionResponses} reviewerAnswersAverageQuestion={this.state.reviewerAnswersAverageQuestion} freeTextAnswers={this.state.freeTextAnswers} reviewerQuesionResponses={this.state.reviewerQuesionResponses} questionDetails={this.state.questionDetails} loading={this.state.loading} />
                 

                  {/* Ending */}
                  <header className='page-break user-report__header'>
                    <img crossOrigin='anonymous' src={this.props.current360.settings.logo} className='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>Good Luck</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Own the change you want to see! </strong>
                      </p>
                    </div>
                  </header>
                </div>
              </PDFExport>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <canvas ref={this.hiddenCanvas}></canvas>
          {this.state.error ? (
            <div className='error-404 bg-blue fixed left-0 top-0'>
              <h1 className='white mt4'>System Error - Error loading report</h1>
            </div>
          ) : (
            <LoadingState />
          )}
        </div>
      );
    }
  }
}

export default withAuthorisation(authCondition)(teamReport);
