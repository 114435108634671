import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { lightBoxStyles } from '../../constants/lightbox.js'

ReactModal.setAppElement('#root')

class Modal extends Component{
  constructor(props){
    super(props)
    this.state = {
      isOpen: this.props.isOpen
    }
    this.close = this.close.bind(this)
  }

  componentWillReceiveProps(updatedProps) {
    if (updatedProps.isOpen !== this.props.isOpen) {
      this.setState({
        isOpen: updatedProps.isOpen
      });
    }
  }


  close(e){
    this.setState({
      isOpen: false
    }, this.props.onClose())
    e.preventDefault()
  }

  render(){
    return(
      <ReactModal
        isOpen={ this.state.isOpen }
        contentLabel="Delete this 360"
        style={ lightBoxStyles }
      >
          { this.props.content }
          {
            (!this.props.hideCloseButton) ?

              <button onClick={this.close} className="ReactModalClose"></button>

            : ""
          }
      </ReactModal>
    )
  }
}

export default Modal
