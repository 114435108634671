import React, { Component } from 'react';

export default class ResponseNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    const responseCheck = [5, 4, 3, 2, 1, 0];
    return (
      <div className='w-100 flex'>
        {this.props.isSection
          ? this.props.responses.map((rating) => (
              <p className='w-100 mv0 tc'>
                <strong>{rating}</strong>
              </p>
            ))
          : responseCheck.map((n) => {
              return (
                <p className='w-100 mv0 tc'>
                  <strong>{n === this.props.responses ? '1' : '0'}</strong>
                </p>
              );
            })}
      </div>
    );
  }
}
