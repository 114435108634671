import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import Autocomplete from 'react-autocomplete';
import LoadingState from '../../../components/global/loading-state';
import Breadcrumbs from '../../../components/breadcrumbs';
import SiteFooter from '../../../components/site-footer';
import SiteHeader from '../../../components/site-header';
import SiteNav from '../../../components/site-nav';
import QuestionnaireSectionEditor from '../../../components/questionnairesectioneditor';
import Modal from '../../../components/global/modal';
import { breadcrumbSelect } from '../../../helpers';

//
import { compose } from 'recompose';
import withAuthorisation from '../../../components/withauthorisation';
import withUser360s from '../../../components/withuser360s';
const authCondition = (authUser) => !!authUser;
//

let existing360ItemList = [];

class Edit360Questionnaire extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    let dashboardLink = '/dashboard/';
    let editLink = `/dashboard/360s/${params.id}/edit/`;
    let coreSettingsLink = `/dashboard/360s/${params.id}/edit/basic/`;

    this.openNewSectionModal = this.openNewSectionModal.bind(this);
    this.openCopyQuestionnaireModal = this.openCopyQuestionnaireModal.bind(this);
    this.onSubmitNewSection = this.onSubmitNewSection.bind(this);
    this.copyCompetencies = this.copyCompetencies.bind(this);
    // this.duplicateSection = this.duplicateSection.bind(this)
    this.updateSectionsCount = this.updateSectionsCount.bind(this);
    this.updateSectionsLegacyMode = this.updateSectionsLegacyMode.bind(this);

    this.state = {
      loading: true,
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      questionnaireId: null,
      existing360QuestionnaireIDs: null,
      questionnaireIdToCopy: '',
      questionnaireLoaded: null,
      newSectionModalOpen: false,
      copyQuestionnaireModalOpen: false,
      duplicatingSectionId: null,
      dashboardLink: dashboardLink,
      editLink: editLink,
      coreSettingsLink: coreSettingsLink,
      creatingNewSection: false,
      sectionsCount: 0,
      sectionsLegacyMode: false,
    };
  }

  updateSectionsCount(count) {
    this.setState({
      sectionsCount: count,
    });
  }

  updateSectionsLegacyMode(snapshot) {
    // Checks if for any section objects which lack a sortOrder property and updates sectionsLegacyMode state to true if any found
    const sortOrderPresence = snapshot.docs.map((doc) => doc.data().sortOrder);
    if (sortOrderPresence.includes(undefined)) {
      this.setState({
        sectionsLegacyMode: true,
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const {accountId} = this.state;
    const {current360} = this.props;

    db.doGet360s(accountId).then((snapshot) => {
      if (!snapshot.empty) {
        let user360s = {};
        snapshot.docs.map((doc) => {
          user360s[doc.id] = doc.data();
        });

        this.setState({
          existing360QuestionnaireIDs: user360s,
          loaded360: true,
          loading: false,
        });
      }
    });
  }

  openNewSectionModal() {
    this.setState({
      newSectionModalOpen: true,
    });
  }

  openCopyQuestionnaireModal() {
    this.setState({
      copyQuestionnaireModalOpen: true,
    });
  }

  onSubmitNewSection(event) {
    this.setState({
      creatingNewSection: true,
    });
    let questionnaireRef;
    let currentForm = event.currentTarget;
    db.doCreateNewQuestionnaireSection(
      this.state.accountId,
      this.state.current360.questionnaireID,
      this.title.value,
      this.description.value,
      this.state.sectionsCount,
      this.state.current360.type
    ).then((snapshot) => {
      currentForm.reset();
      this.setState({
        newSectionModalOpen: false,
        creatingNewSection: false,
      });
    });
    event.preventDefault();
  }

  copyCompetencies(e) {
    let currentForm = e.currentTarget;

    db.copyCompetenciesTo360(this.state.accountId, this.existing360.value, this.state.current360.questionnaireID, this.state.sectionsCount)
      .then((data) => {
        currentForm.reset();
        this.setState({
          copyQuestionnaireModalOpen: false,
          questionnaireIdToCopy: null,
        });
      })
      .catch((error) => {
        alert('cannot copy!');
      });
    currentForm.reset();
    e.preventDefault();
  }

  render() {
    const {accountId, loading, current360, accountData, userDetails, accountTitle, current360ID, btnBrandCol, sectionsCount, sectionsLegacyMode, newSectionModalOpen, creatingNewSection, copyQuestionnaireModalOpen, existing360QuestionnaireIDs, current360Type} = this.state
    const {current360Groups, current360Relationships} = this.props
    return (
      <div className='page-wrapper'>
        {loading ? (
          <LoadingState />
        ) : (
          <section className='container container--slim'>
            <Breadcrumbs
              current360={current360}
              userDetails={userDetails}
              accountTitle={accountTitle}
              accountData={accountData}
              current360Groups={current360Groups}
              current360Relationships={current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: '/dashboard/',
                },
                {
                  title: `${accountData.accountTitle ? accountData.accountTitle : accountData.title} ${
                    current360.type
                  } Projects`,
                  to: `/dashboard/${accountId}/${breadcrumbSelect(current360.type)}-projects/`,
                },
                {
                  title: current360.title,
                  to: '../',
                },
                {
                  title: `${current360.type === 'Goal Tracker' ? 'Goal' : 'Questionnaire'} Set-Up`,
                  to: null,
                },
              ]}
            />

            <h1>
              {current360.title}: {current360.type === 'Goal Tracker' ? 'Goal' : 'Questionnaire'} Set-Up
            </h1>

            <div className='bg-white mv4 ph4 pv4 shadow br2'>
              {current360.type === 'Goal Tracker' ? (
                <div className='w-100 flex justify-between'>
                  <div className='w-50'>
                    <p>Add/Edit Goals</p>
                    <p>
                      Each goal is made up of a series of SMART questions. Click ‘Edit Goal' to amend or delete items within each goal, in line with your
                      project requirements.
                    </p>
                    <p>
                      Hit the <strong>Create A New Goal</strong> button to add the number of goals you want to track.
                    </p>
                  </div>
                  <button onClick={this.openNewSectionModal} className='btn'>
                    Create A New Goal
                  </button>
                </div>
              ) : (
                <div>
                  <p>
                    <strong>Add Competencies & Statements</strong>
                  </p>
                  <p>
                    Competencies can be understood as high-level collections/groups of skills or knowledge. Statements are the series of actions or behaviours,
                    which are used to describe each competency area. Participants provide their feedback in response to the statements you define here.
                  </p>
                  <div className='flex justify-between w-100'>
                    <button onClick={this.openNewSectionModal} className='btn input-reset button-reset bn w-40'>
                      Create New Competency
                    </button>
                    <button onClick={this.openCopyQuestionnaireModal} className='btn input-reset button-reset bn w-40'>
                      Copy Existing Competencies & Statements
                    </button>
                  </div>
                </div>
              )}

              <hr className='mv4' />
              <QuestionnaireSectionEditor
                accountId={accountId}
                current360={current360}
                current360Id={current360ID}
                accountData={accountData}
                questionnaireID={current360.questionnaireID}
                current360StatusCode={current360.statusCode}
                btnBrandCol={btnBrandCol}
                sectionsCount={sectionsCount}
                updateSectionsCount={this.updateSectionsCount}
                updateSectionsLegacyMode={this.updateSectionsLegacyMode}
                sectionsLegacyMode={sectionsLegacyMode}
              />
            </div>

            <div className='flex justify-between'>
              <Link to={{ pathname: '../' }} className='btn btn--back'>
                Return to Project Settings
              </Link>
              <Link
                to={{
                  pathname: `preview/`,
                  current360: current360,
                  accountData: accountData,
                  sectionsLegacyMode: sectionsLegacyMode,
                }}
                className={`btn ${sectionsCount < 1 ? 'btn--disabled' : ''}`}
              >
                Preview Your {current360.type === 'Goal Tracker' ? 'Goals' : 'Questionnaire'}
              </Link>
            </div>

            <Modal
              isOpen={newSectionModalOpen}
              contentLabel='Add a new 360'
              onClose={() => this.setState({ newSectionModalOpen: false })}
              content={
                <div>
                  <h2>Create new {current360.type === 'Goal Tracker' ? 'Goal' : 'competency'}</h2>
                  <form className='mw6 center pt2 pb2' onSubmit={this.onSubmitNewSection}>
                    <label htmlFor='title' className='f7 b db mb2'>
                      Name
                    </label>
                    <input
                      required
                      ref={(input) => (this.title = input)}
                      aria-describedby='name-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      type='text'
                      placeholder={current360.type === 'Goal Tracker' ? 'Name your goal' : 'Name your section'}
                    />
                    <label htmlFor='description' className={current360.type === 'Goal Tracker' ? 'f7 b dn mb2' : 'f7 b db mb2'}>
                      Description
                    </label>
                    <textarea
                      ref={(input) => (this.description = input)}
                      aria-describedby='description-desc'
                      className='input-reset ba b--black-20 pa2 mb2 db w-100'
                      rows='6'
                      placeholder='Give your competency a description'
                      hidden={current360.type === 'Goal Tracker'}
                    />
                    <button className={creatingNewSection ? 'btn btn--disabled' : 'btn'} type='submit' disabled={creatingNewSection}>
                      Submit
                    </button>
                  </form>
                </div>
              }
            />

            <Modal
              isOpen={copyQuestionnaireModalOpen}
              contentLabel='Add a new 360'
              onClose={() => this.setState({ copyQuestionnaireModalOpen: false })}
              content={
                <div>
                  <form className='mw6 center pt2 pb2' onSubmit={this.copyCompetencies}>
                    {/*
                            // TODO: MAKE THIS DROPDOWN into a styled dropdown
                          */}

                    <label htmlFor='existing360' className='f7 b db mb2'>
                      Select an existing 360 to copy from
                    </label>
                    <select className='grey' required ref={(input) => (this.existing360 = input)} aria-describedby='name-desc'>
                      <option>Select a previous 360</option>
                      {Object.keys(existing360QuestionnaireIDs)
                        .filter((key) => {
                          return !existing360QuestionnaireIDs[key].comparisonProject;
                        })
                        .map((key, i) => {
                          if (existing360QuestionnaireIDs[key]['questionnaireID']) {
                            if (existing360QuestionnaireIDs[key].type === current360.type) {
                              return (
                                <option
                                  key={existing360QuestionnaireIDs[key]['questionnaireID']}
                                  value={existing360QuestionnaireIDs[key]['questionnaireID']}
                                >
                                  {existing360QuestionnaireIDs[key]['title']}
                                </option>
                              );
                            }
                          }
                        })}
                    </select>
                    <button className='btn mt4' type='submit'>
                      Copy competencies
                    </button>
                  </form>
                </div>
              }
            />
          </section>
        )}
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Edit360Questionnaire);

