import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import reactCSS from 'reactcss';
import { db } from '../firebase';
import { BlockPicker, ChromePicker } from 'react-color';
import Modal from './global/modal';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class RelationshipForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      firstInstance: true,
      displayPrimaryColourPicker: false,
      relationshipcolour: this.props.relationship.colour,
      showDeleteModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    let relationshipData = {};
    relationshipData = {
      name: this.relationshipName.value,
      colour: this.relationshipColour.value,
      description: this.relationshipDescription.value,
    };
    this.setState({
      saving: true,
    });
    if (this.relationshipKey.value == this.props.relationshipKey) {
      // console.log(relationshipData);
      // Update relationship data
      db.doUpdateRelationship(this.props.accountId, this.props.current360Id, this.props.relationshipKey, relationshipData).then((data) => {
        // Update relationship props
        this.props.relationship.name = relationshipData.name;
        this.props.relationship.colour = relationshipData.colour;
        this.props.relationship.description = relationshipData.description;

        setTimeout(
          function() {
            this.setState({
              saving: false,
              firstInstance: false,
            });
          }.bind(this),
          500
        );
      });
    } else {
      // Remove and recreate relationship (as the Key has changed)
      db.doDeleteRelationship(this.props.accountId, this.props.current360Id, this.props.relationshipKey).then((data) => {
        db.doCreateNewRelationship(this.props.accountId, this.props.current360Id, this.relationshipKey.value, relationshipData).then((data) => {
          setTimeout(
            function() {
              this.setState({
                saving: false,
                firstInstance: false,
              });
            }.bind(this),
            500
          );
        });
      });
    }
  }

  deleteRelationship(e) {
    e.preventDefault();
    // console.log(this.props.relationshipKey);
    this.props.deleteCurrent(this.props.accountId, this.props.current360Id, this.props.relationshipKey);

    // db.doDeleteRelationship(
    //   this.props.accountId,
    //   this.props.current360Id,
    //   this.props.relationshipKey
    // ).then((data) => {
    //   // console.log('deleted');
    //   // console.log(data);
    // });
  }

  handleChange(e) {
    // console.log(this.state.current360);
    // let questionData = {
    //   'answerType': this.answerType.value,
    //   'questionTitle': this.questionTitle.value,
    //   'required': this.questionRequired.value
    // }
    e.preventDefault();
  }

  render() {
    const pickerStyles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        primarycolorinput: {
          borderColor: this.state.relationshipcolour,
          borderWidth: '2px',
        },
      },
    });

    return (
      <form action='/' className='pv3' onSubmit={this.handleSubmit}>
        <p>
          <strong>Relationship #{this.props.relationshipNum}</strong>
        </p>
        <div className='flex w-70 justify-start'>
          <div className='w-10 mr3'>
            <label className='db'>Key</label>
            <input
              maxLength='3'
              required
              ref={(input) => (this.relationshipKey = input)}
              type='text'
              className='w-100'
              defaultValue={this.props.relationshipKey}
            />
          </div>
          <div className='w-20'>
            <label className='db'>Colour</label>
            <input
              required
              style={pickerStyles.primarycolorinput}
              ref={(input) => (this.relationshipColour = input)}
              type='text'
              className='w-100'
              onClick={(e) => {
                this.setState({ displayPrimaryColourPicker: true });
              }}
              value={this.state.relationshipcolour}
            />
            {this.state.displayPrimaryColourPicker ? (
              <div style={pickerStyles.popover}>
                <div
                  style={pickerStyles.cover}
                  onClick={(e) => {
                    this.setState({ displayPrimaryColourPicker: false });
                  }}
                />
                <ChromePicker color={this.state.relationshipcolour} onChange={(color) => this.setState(byPropKey('relationshipcolour', color.hex))} />
              </div>
            ) : null}
          </div>
        </div>
        <div className=''>
          <div className='w-60'>
            <label className='db'>Name</label>
            <input required ref={(input) => (this.relationshipName = input)} type='text' className='w-100' defaultValue={this.props.relationship.name} />
          </div>
          <div className='w-80'>
            <label className='db'>Description</label>
            <textarea ref={(input) => (this.relationshipDescription = input)} className='w-100' defaultValue={this.props.relationship.description} />
          </div>
        </div>

        <div className='flex justify-between'>
          <button type='submit' disabled={this.props.current360StatusCode === 1 ? false : true} className={this.state.saving ? 'btn btn--disabled' : 'btn'}>
            Save Relationship
          </button>
          {Object.keys(this.props.relationships).length <= 2 ? (
            <button type='button' className='btn btn--red disabled' disabled>
              Minimum Relationships Reached
            </button>
          ) : (
            <button
              type='button'
              className='btn btn--red'
              onClick={(e) => {
                this.setState({ showDeleteModal: true });
              }}
            >
              Delete this relationship
            </button>
          )}
        </div>
        <span className={this.state.saving ? 'loading-spinner' : !this.state.firstInstance ? 'loading-done' : ''} />
        <hr className='mt4' />
        <Modal
          isOpen={this.state.showDeleteModal}
          contentLabel='Delete this relationship'
          onClose={() => this.setState({ showDeleteModal: false })}
          content={
            <div className='tc'>
              <h2>Are you sure you wish to delete this relationship?</h2>

              <div className=''>
                <button
                  className='btn mb1'
                  onClick={(e) => {
                    this.props.deleteCurrent(this.props.accountId, this.props.current360Id, this.props.relationshipKey);
                  }}
                >
                  Yes, delete this relationship
                </button>
                <br />
                <button
                  className='btn btn--red mt1'
                  onClick={(e) => {
                    this.setState({ showDeleteModal: false });
                  }}
                >
                  No, keep this relationship
                </button>
              </div>
            </div>
          }
        />
      </form>
    );
  }
}

//
export default RelationshipForm;
