import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthUserContext from './components/authusercontext';
import axios from 'axios';
// Routes
import pageRoutes from './constants/routes';
//
import NoMatch from './components/no-match';
import withAuthentication from './components/withauthentication'; // withAuthentication higher level component - abstract the auth logic away

class App extends Component {
  static contextType = AuthUserContext;

  componentDidMount() {
    // // console.log(this.context);
    axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL;

    if (this.context == null) {
      // console.log('null');
      return;
    } else {
      axios.defaults.headers = {
        Authorization: this.context._lat,
      };
    }
  }

  componentDidUpdate() {
    if (!axios.defaults.headers.Authorization) {
      axios.defaults.headers = {
        Authorization: this.context._lat,
      };
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          {pageRoutes.map(({ path, component, exact }, key) => (
            <Route exact={exact} path={path} key={key} component={component} />
          ))}
          <Route component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}

export default withAuthentication(App);
