import React, { Component } from 'react'
import loadingGif from '../../ripple.gif'

class LoadingState extends Component {
  render() {
    return (
      <div className="vh-50 w-100 flex items-center justify-center">
        <img src={ loadingGif } />
      </div>
    )
  }
}

export default LoadingState
