import React, { Component } from 'react';
import { db } from '../firebase';
// import QuestionnaireSectionAccordian from '../components/questionnairesectionaccordian'
// import { Link, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Dropdown from '../components/global/dropdown';
import Modal from '../components/global/modal';
import { UpArrow, DownArrow } from './../svg/svgs';

import moment from 'moment';

class QuestionnaireSectionEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: this.props.accountId,
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      current360Id: this.props.current360Id,
      questionnaireID: this.props.questionnaireID,
      btnBrandCol: this.props.btnBrandCol,
      duplicateSectionModalOpen: false,
      duplicatingSectionId: null,
      questionnaireSections: null,
      loading: true,
      sectionsLegacyMode: false,
    };

    this.deleteSection = this.deleteSection.bind(this);
    this.fireSectionAction = this.fireSectionAction.bind(this);
    // this.onDuplicateSection = this.onDuplicateSection.bind(this);
    this.openDuplicateSectionModal = this.openDuplicateSectionModal.bind(this);
    this.getSectionQuestions = this.getSectionQuestions.bind(this);
    this.reorderSectionsAfterDeletion = this.reorderSectionsAfterDeletion.bind(this);
    this.sectionUp = this.sectionUp.bind(this);
    this.sectionDown = this.sectionDown.bind(this);
  }

  componentDidMount() {
    // db.doGetSectionsListOrdered(this.state.accountId, this.state.questionnaireID, this.props.sectionsLegacyMode).onSnapshot((snapshot) => {
    db.doGetSectionsList(this.state.accountId, this.state.questionnaireID).onSnapshot((snapshot) => {
      if (snapshot.empty) {
        // db.doGetSectionsList(this.state.accountId, this.state.questionnaireID).onSnapshot((snapshot) => {
        if (snapshot.empty) {
          this.setState({
            loading: false,
            questionnaireSections: null,
          });
        } else {
          this.props.updateSectionsLegacyMode(snapshot);
          this.getSectionQuestions(snapshot);
        }
        // });
      } else {
        this.props.updateSectionsLegacyMode(snapshot);
        this.getSectionQuestions(snapshot);
      }
    });
  }

  updateSectionsLegacyMode(snapshot) {
    // Checks if for any section objects which lack a sortOrder property and updates sectionsLegacyMode state to true if any found
    const sortOrderPresence = snapshot.docs.map((doc) => doc.data().sortOrder);
    if (sortOrderPresence.includes(undefined)) {
      this.setState({
        sectionsLegacyMode: true,
      });
    }
  }

  getSectionQuestions(snapshot) {
    let sections = {};
    let numSections = snapshot.docs.length;

    this.props.updateSectionsCount(numSections);

    snapshot.docs.map((doc, i) => {
      sections[doc.id] = doc.data();

      db.doGetSectionQuestions(this.state.accountId, this.state.questionnaireID, doc.id).onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          sections[doc.id]['numQuestions'] = snapshot.docs.length;
        }

        // // console.log(sections[doc.id]['created'].toDate());
        // // console.log( new Date( sections[doc.id]['created'] ) );

        if (i == numSections - 1) {
          // got all questions...
          this.setState({
            loading: false,
            questionnaireSections: sections,
          });

          let populatedSections = 0;

          Object.keys(sections).map((key, index) => {
            if (sections[key].numQuestions && sections[key].numQuestions > 0) {
              populatedSections++;
            }
          });

          // set StatusCode based on number of populated sections
          // (2 or more = statusCode: 1)
          // (less than 2 = statusCode: 0)
          let statusCodeFunc = () => {
            if (this.state.current360.type === 'Goal Tracker' && populatedSections >= 1) {
              return 1;
            } else if (this.state.current360.type !== 'Goal Tracker' && populatedSections > 1) {
              return 1;
            } else {
              return 0;
            }
          };

          let statusCode = statusCodeFunc();
          // console.log(statusCode);

          db.update360StatusCode(this.state.accountId, this.state.current360Id, statusCode);
        }
      });
    });
  }

  fireSectionAction(id, key) {
    // console.log(id, key);
    // let temp = JSON.parse(JSON.stringify(sectionActions));
    // temp.forEach(item => item.selected = false);
    // temp[id].selected = true;
    // // console.log(key);
    //
    switch (id) {
      case 0:
        this.openDuplicateSectionModal(key);
        break;
      case 1:
        this.deleteSection(key);
        break;
      default:
        return;
    }
  }

  openDuplicateSectionModal(sectionID) {
    this.setState({
      duplicatingSectionId: sectionID,
      duplicateSectionModalOpen: true,
    });
  }

  onDuplicateSection(event) {
    let questionnaireRef;
    let currentForm = event.currentTarget;
    // let history = useHistory();
    const dupeSection = async () => {
      await db.doDuplicateQuestionnaireSection(
        this.state.accountId,
        this.state.current360.questionnaireID,
        this.state.duplicatingSectionId,
        this.title.value,
        this.description.value,
        this.props.sectionsCount,
        this.state.duplicateSectionModalOpen
      );
    };
    dupeSection().then((newSectionId) => {
      // currentForm.reset();
      // console.log(newSectionId);

      // this.setState({
      //   duplicateSectionModalOpen: false,
      //   duplicatingSectionId: null,
      // });
      event.preventDefault();
      // Forward to edit page
      // history.push(`/dashboard/${this.state.accountId}/360s/${this.state.current360Id}/edit/questionnaire/${newSectionId}/`)
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });

    event.preventDefault();
  }

  deleteSection(sectionId) {
    let sectionIndex = this.state.questionnaireSections[sectionId].sortOrder;
    db.doDeleteSection(this.state.accountId, this.state.questionnaireID, sectionId);
    this.props.updateSectionsCount(this.props.sectionsCount - 1);
    this.reorderSectionsAfterDeletion(sectionId, sectionIndex);
  }

  // when deleting a section, the sections after the deleted one need to be updated to reduce their sort order by 1
  reorderSectionsAfterDeletion(sectionId, orderBy) {

    let sectionsObject = this.state.questionnaireSections;

    delete sectionsObject[sectionId]

    // loops through sections and decrements sortOrder for any sections ordered after the deleted item
    for (const sectionID in sectionsObject) {
      const section = sectionsObject[sectionID]
      const order = section.sortOrder
      if (order > orderBy) {
        sectionsObject[sectionID].sortOrder = order - 1
        db.updateSection(this.state.accountId, this.state.questionnaireID, sectionID, section)
      }
    }

    this.setState({
      questionnaireSections: sectionsObject,
    });
  }

  // loops through the sections and amends the sortOrder value where necessary, then updates those section in the db
  sectionUp(e, orderBy) {
    e.preventDefault();
    let sectionsObject = this.state.questionnaireSections;
    for (const section in sectionsObject) {
      if (sectionsObject[section].sortOrder === orderBy - 1) {
        // section to go down/increment sortOrder
        sectionsObject[section].sortOrder = sectionsObject[section].sortOrder + 1;
        db.updateSection(this.state.accountId, this.state.questionnaireID, section, sectionsObject[section]);
      } else if (sectionsObject[section].sortOrder === orderBy) {
        // section to go up/decrement sortOrder
        sectionsObject[section].sortOrder = sectionsObject[section].sortOrder - 1;
        db.updateSection(this.state.accountId, this.state.questionnaireID, section, sectionsObject[section]);
      }
    }
    this.setState({
      questionnaireSections: sectionsObject,
    });
  }

  // loops through the sections and amends the sortOrder value where necessary, then updates those section in the db
  sectionDown(e, orderBy) {
    e.preventDefault();
    let sectionsObject = this.state.questionnaireSections;
    for (const section in sectionsObject) {
      if (sectionsObject[section].sortOrder === orderBy) {
        // section to go down/increment sortOrder
        sectionsObject[section].sortOrder = sectionsObject[section].sortOrder + 1;
        db.updateSection(this.state.accountId, this.state.questionnaireID, section, sectionsObject[section]);
      } else if (sectionsObject[section].sortOrder === orderBy + 1) {
        // section to go up/decrement sortOrder
        sectionsObject[section].sortOrder = sectionsObject[section].sortOrder - 1;
        db.updateSection(this.state.accountId, this.state.questionnaireID, section, sectionsObject[section]);
      }
    }
    this.setState({
      questionnaireSections: sectionsObject,
    });
  }

  render() {
    if (this.state.loading) {
      return <div>Loading</div>;
    } else {
      return (
        <div>
          {this.state.questionnaireSections ? (
            Object.keys(this.state.questionnaireSections)
              .sort((a, b) => {
                const sectionDetails = this.state.questionnaireSections;
                return this.props.sectionsLegacyMode
                  ? sectionDetails[a].created - sectionDetails[b].created
                  : sectionDetails[a].sortOrder - sectionDetails[b].sortOrder;
              })
              .map((key, index) => (
                <div key={key} className='flex justify-between items-center pa4 f6 bg--pale mb2'>
                  <div className='w-50'>
                    <p className='ma0'>
                      <strong>{this.state.questionnaireSections[key].title} </strong>
                      {this.state.current360.type != 'Goal Tracker' ? (
                        <>
                          <small>
                            Number of questions: {this.state.questionnaireSections[key].numQuestions ? this.state.questionnaireSections[key].numQuestions : '0'}
                          </small>
                          <br />
                          <small>{this.state.questionnaireSections[key].description}</small>
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                  {/* <div className={`w-100 ${this.props.sectionsLegacyMode ? 'flex justify-end' : ''}`}> */}
                  <div className={'w-100 flex justify-end'}>
                    <div className='dib mr3'>
                      <Dropdown
                        title='Alternate actions'
                        list={[
                          {
                            id: 0,
                            title: 'Duplicate',
                            selected: false,
                            key: key,
                          },
                          {
                            id: 1,
                            title: 'Delete',
                            selected: false,
                            key: key,
                          },
                        ]}
                        setTitleOnSelect={false}
                        resetThenSet={this.fireSectionAction}
                      />
                    </div>
                    {/*
                          <a className="btn btn--red" onClick={ (e) => { this.deleteSection(e) } }>Delete</a>

                          */}

                    <div className={this.props.sectionsLegacyMode ? '' : 'mr3'}>
                      <Link
                        className='btn'
                        key={key}
                        to={{
                          pathname: `${key}/`,
                          current360: this.props.current360,
                          accountData: this.props.accountData,
                        }}
                      >
                        Edit {this.state.current360.type == 'Goal Tracker' ? 'Goal' : 'Statements'}
                      </Link>
                    </div>

                    {/* Displays arrow buttons if not in sectionsLegacyMode */}
                    {this.props.sectionsLegacyMode ? (
                      <></>
                    ) : (
                      <div className='w2 flex'>
                        {/* will display UpArrow only if the section isn't top of the list */}
                        {index === 0 ? (
                          <></>
                        ) : (
                          <button
                            className='mh1 has-tooltip'
                            data-tooltip='Move Question Up'
                            onClick={(e) => this.sectionUp(e, this.state.questionnaireSections[key].sortOrder)}
                          >
                            <UpArrow width='15' height='15' fill='#3b4e7e' />
                          </button>
                        )}
                        {/* will display DownArrow only if the section isn't bottom of the list */}
                        {index === Object.keys(this.state.questionnaireSections).length - 1 ? (
                          <></>
                        ) : (
                          <button
                            className='mh1 has-tooltip'
                            data-tooltip='Move Question Down'
                            onClick={(e) => this.sectionDown(e, this.state.questionnaireSections[key].sortOrder)}
                          >
                            <DownArrow width='15' height='15' fill='#3b4e7e' />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <p>Create a new Competency section using the buttons above</p>
          )}

          <Modal
            isOpen={this.state.duplicateSectionModalOpen}
            contentLabel='Duplicate Questionnare Section'
            onClose={() =>
              this.setState({
                duplicateSectionModalOpen: false,
                duplicatingSectionId: null,
              })
            }
            content={
              <div>
                <div className='mw6 center'>
                  <h2 className='ma0 mb2'>Duplicating {this.state.current360.type == 'Goal Tracker' ? 'Goal' : 'Section'}</h2>
                  <p>Enter details for the new {this.state.current360.type == 'Goal Tracker' ? 'Goal' : 'Section'} - all data will be copied across</p>
                  <label htmlFor='title' className='f7 b db mb2'>
                    Name
                  </label>
                  <input
                    required
                    ref={(input) => (this.title = input)}
                    aria-describedby='name-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    type='text'
                    placeholder={this.state.current360.type == 'Goal Tracker' ? 'Name your goal' : 'Name your section'}
                  />
                  <label htmlFor='description' className={this.state.current360.type == 'Goal Tracker' ? 'f7 b dn mb2' : 'f7 b db mb2'}>
                    Description
                  </label>
                  <textarea
                    ref={(input) => (this.description = input)}
                    aria-describedby='description-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    rows='6'
                    placeholder='Give your competency a description'
                    hidden={this.state.current360.type == 'Goal Tracker'}
                  />
                  <button
                    className='btn'
                    type='button'
                    onClick={(e) => {
                      this.onDuplicateSection(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            }
          />
        </div>
      );
    }
  }
}

export default QuestionnaireSectionEditor;
