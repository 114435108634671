import React, { Component } from 'react';
import PageHeader from '../PageHeader';

export default class FreeTextTeamFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading });
  }

  componentDidUpdate(prevProps, prevState) {
    const {answers} = this.props
    if (prevState.loading !== this.state.loading) {
      let freeTextPages = []
      let currentHeightUsed = 0
      let lowerLimit = 0
      let upperLimit = 0
      // loops through each answer and calculates which index will need a page-split to prevent overflow. Pushes this data to a pages array
      answers.forEach((answer, answerIndex) => {
        const answerHeight = this[`freetextAnswer${answerIndex}`].clientHeight + 15
        const newHeightUsed = currentHeightUsed + answerHeight
        if (newHeightUsed > 570) {
          upperLimit = answerIndex
          freeTextPages.push({lowerLimit, upperLimit})
          currentHeightUsed = answerHeight
          lowerLimit = answerIndex
        } else {
          currentHeightUsed = currentHeightUsed + answerHeight
        }
      })
      upperLimit = answers.length
      freeTextPages.push({lowerLimit, upperLimit})
      this.setState({ freeTextPages });
    }
  }

  render() {
    const { loading, freeTextPages } = this.state;
    const {
      sectionID,
      sectionIndex,
      questionDetails,
      questionID,
      questionIndex,
      answers,
    } = this.props;

    const pages = freeTextPages ? freeTextPages : [{lowerLimit: 0, upperLimit: answers.length}]

    if (!loading) {
      return pages.map((page, pageIndex) => {
        return (
          <div key={sectionID + questionID + pageIndex} className='mb4 page-break user-report__content user-report__content--no-pad'>
            <div className='average-rating-section'>
              <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${sectionIndex + 2}`} h3Text={`Free text comments${pageIndex > 0 || questionIndex > 0 ? ' (continued)' : ''}`} line={true} />
              <h4 className='f5 mt2'>
                <strong>{questionDetails[questionID].questionTitle}{pageIndex > 0 ? ' (continued)' : ''}</strong>
              </h4>
              {/* checks if question has any responses and renders message if not */}
              {answers.length > 0 ? 
                  <p style={{ color: '#F563B9' }}>
                    <strong>Team Members:</strong>
                  </p>
                :
                <p>No completions for this question</p>
              }
              <ul>
              {answers.map((answer, answerIndex) => {
                if (answerIndex >= page.lowerLimit && answerIndex < page.upperLimit) {
                  return (
                    <li key={sectionID + questionID + pageIndex + answerIndex} ref={(answerRow) => {this[`freetextAnswer${answerIndex}`] = answerRow}}>
                      {answer}
                    </li>
                  )
                }
                })}
              </ul>
            </div>
          </div>
        )
      })
    } else {
      return <p>loading</p>;
    }
  }
}

