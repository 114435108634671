import React, { Component } from 'react';
import { auth } from '../firebase';
import logo from '../logo-colour.svg';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import { loginPageMFA, verify2faCode } from '../firebase/auth';
import Modal from './global/modal';
import axios from 'axios';
//

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

//

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then((data) => {
        this.setState({ ...INITIAL_STATE });
        if (this.props.page === 'mfa') {
          this.props.closeModal();
        } else {
          data.user.getIdToken().then((res) => {
            window.localStorage.setItem('loginAuth', res);
            axios
              .post('/sessionLogin', { token: res })
              .then((res) => {
                window.localStorage.setItem('sessionExp', res.data.options.maxAge);
                window.localStorage.setItem('Auth', res.data.cookie);
                if (this.props.page === 'modal') {
                  window.location.reload();
                } else {
                  if (email === 'admin@boomerang-360.com') {
                    history.push('/admin/');
                  } else {
                    history.push('/dashboard/');
                  }
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          });
        }
      })
      .catch((error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.mfaReq(error.resolver);
        } else {
          this.setState(byPropKey('error', error));
        }
      });

    event.preventDefault();
  };

  mfaReq = (resolver) => {
    //if there is an instance of recaptcha already within the state i.e, they clicked off mid way through set up
    //then dont render a new one, use the one from the state
    let recaptchaVerifier;
    if (!this.state.mfaCaptcha) {
      // console.log('new');
      recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('mfaBtn'), {
        size: 'invisible',
        callback: function(response) {
          // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
        },
      });
    } else {
      // console.log('use state');
      recaptchaVerifier = this.state.mfaCaptcha;
    }
    if (!this.state.resolver) {
      loginPageMFA(resolver, recaptchaVerifier)
        .then((res) => {
          this.setState({ smsModal: true, smsID: res, resolver: resolver, mfaCaptcha: recaptchaVerifier });
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      loginPageMFA(resolver, this.state.mfaCaptcha)
        .then((res) => {
          this.setState({ smsModal: true, smsID: res });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  submitSMS = (e) => {
    const { history } = this.props;
    const smsCode = this.smsVerify.value;
    const resolver = this.state.resolver;
    verify2faCode(this.state.smsID, smsCode, resolver)
      .then((res) => {
        // console.log(res);
        if (this.props.page === 'modal') {
          window.location.reload();
        } else {
          res.user.getIdToken().then((res) => {
            window.localStorage.setItem('loginAuth', res);
            axios
              .post('/sessionLogin', { token: res })
              .then((res) => {
                window.localStorage.setItem('sessionExp', res.data.options.maxAge);
                window.localStorage.setItem('Auth', res.data.cookie);
                if (this.props.page === 'modal' || this.props.page === 'mfa') {
                  window.location.reload();
                } else {
                  if (res.email === 'admin@boomerang-360.com') {
                    history.push('/admin/');
                  } else {
                    history.push('/dashboard/');
                  }
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          });
        }
      })
      .catch((error) => {
        this.setState({ smsErr: error });
      });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form
        className={
          this.props.page === 'modal' || this.props.page === 'mfa'
            ? 'sign-in__form bg--white animated fadeInUp'
            : 'sign-in__form bg--white shadow animated fadeInUp'
        }
        style={this.props.page === 'modal' || this.props.page === 'mfa' ? { margin: 0, padding: '5%' } : {}}
        onSubmit={this.onSubmit}
      >
        {this.props.page === 'modal' ? '' : <img src={logo} />}

        <label htmlFor='email' className='f6 b db mb2'>
          Email Address
        </label>
        <input
          aria-describedby='email-desc'
          className='input-reset ba b--black-20 pa2 mb2 db w-100 mb3'
          value={email}
          onChange={(event) => this.setState(byPropKey('email', event.target.value))}
          type='text'
          placeholder='Email Address'
        />
        <label htmlFor='password' className='f6 b db mb2'>
          Password
        </label>
        <input
          aria-describedby='password-desc'
          className='input-reset ba b--black-20 pa2 mb2 db w-100 mb3'
          onChange={(event) => this.setState(byPropKey('password', event.target.value))}
          type='password'
          placeholder='Password'
        />
        <button className='btn btn--full' disabled={isInvalid} type='submit'>
          <span>Login</span>
        </button>
        {error && <p>{error.message}</p>}
        {this.props.page === 'modal' ? (
          ''
        ) : (
          <div className='mt3'>
            <p>
              <Link to='/password-reset/'>Forgotten Password?</Link>
            </p>
          </div>
        )}
        <div id='mfaBtn' className='mfaBtn' />
        <Modal
          isOpen={this.state.smsModal}
          contentLabel='Prompt user to log in'
          onClose={() => {
            this.setState({ smsModal: false });
          }}
          content={
            <div>
              <div>
                <h1>SMS Verification Required</h1>
                <p>Please enter the SMS verification code that was sent.</p>
              </div>
              <input type='text' ref={(input) => (this.smsVerify = input)} />
              <button
                className='btn'
                onClick={(e) => {
                  this.submitSMS(e);
                }}
              >
                Submit
              </button>
              {this.state.smsErr ? (
                <p className='red mt3'>
                  <strong>{this.state.smsErr.message}</strong>
                </p>
              ) : (
                ''
              )}
            </div>
          }
        />
      </form>
    );
  }
}

export default SignInForm;
