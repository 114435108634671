import React, { Component } from 'react';
import Question from '../components/question';

class QuestionPreview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { scaleLabels, reverseOrderedLabelIDs, goalActive, questions, questionnaireType, title, description, index, answers, questionUpdated, showSavedModal } = this.props;
    let questionnaireClass = `bg-white mv4 ph4 pv4 shadow o-50`;
    if (goalActive) {
      questionnaireClass = `bg-white mv4 ph4 pv4 shadow`;
    }
    const scaleCount = reverseOrderedLabelIDs.length
    return (
      <div className={questionnaireClass}>
        {Object.keys(questions).map((key, i) => {
          return (
            <div key={`questionnaire_${key}`}>
              {i == 0 && questions[key].answerType == 'MultiChoice' && questionnaireType != 'Goal Tracker' ? (
                <div className='flex-l justify-center items-end'>
                  <div className='w-40 mb5'>
                    <h3 className='mb0'>{title}</h3>
                    <p>{description}</p>
                  </div>
                  <div className='w-60 flex-l justify-around items-end tc ml2'>
                    {reverseOrderedLabelIDs.map((labelID, i) => {
                      const {text, frequency} = scaleLabels[labelID]
                      return (
                        <p
                          key={labelID}
                          className={`questionnaire-rating-label w-100-l dn db-l ${i < scaleCount-1 ? 'has-tooltip' : ''}`}
                          data-tooltip={i < scaleCount-1 ? frequency : ''}
                        >
                          {text}
                          <br />
                          <strong></strong>
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}

              {i == 0 && questions[key].answerType == 'FreeText' ? (
                <div className='flex-l justify-start items-end'>
                  <div className='w-40 mb3'>
                    <h3 className='mb0'>{title}</h3>
                    <p>{description}</p>
                  </div>
                </div>
              ) : (
                ''
              )}

              <Question
                key={key}
                index={key}
                section={index}
                question={questions[key]}
                answer={answers && answers[key] ? answers[key] : null}
                questionUpdated={questionUpdated}
                questionnaireType={questionnaireType}
                questionActive={goalActive}
                scaleLabels={scaleLabels}
                reverseOrderedLabelIDs={reverseOrderedLabelIDs}
              />
            </div>
          );
        })}

        {questionnaireType == 'Goal Tracker' ? (
          <button disabled={!goalActive} onClick={(e) => showSavedModal(index)} className='btn'>
            Save this Goal
          </button>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default QuestionPreview;
