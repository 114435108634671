import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { db } from '../../firebase';
import { PDFExport } from '@progress/kendo-react-pdf';
import canvg from 'canvg';
import Breadcrumbs from '../../components/breadcrumbs';
import { Link } from 'react-router-dom';
import { average, relationshipColor, convertRelationships, difference, convertRelationshipTextToKey, valueCheck, percentageCheck } from '../../helpers';
import RadarChart from '../../components/charts/radarchart';
import SiteHeader from '../../components/site-header';
import LoadingState from '../../components/global/loading-state';
import withAuthorisation from '../../components/withauthorisation';
import UserReportPageTemplate from './userReportPageTemplate';
// import '../../css/userreport.css';
const authCondition = (authUser) => !!authUser;
//

// const captions = {}

class User360Report extends Component {
  constructor(props) {
    super(props);
    this.exportPDF = this.exportPDF.bind(this);
    this.convertSVGToImage = this.convertSVGToImage.bind(this);
    this.canvLoaded = false;
    this.hiddenCanvas = React.createRef();

    this.state = {
      loading: true,
      error: false,
      captions: {},
      userData: {},
      userId: null,
      groupId: null,
      current360Id: null,
      current360Relationships: this.props.current360Relationships,
      sectionsLegacyMode: false,
    };
  }
  exportPDF = () => {
    this.report.save();
  };
  convertSVGToImage = (htmlString) => {
    try {
      // Take an SVG html string, convert it to static markup, then use canvg to render into hidden canvas, then return dataURL string
      let staticRadarChart = ReactDOMServer.renderToStaticMarkup(htmlString);
      let canv = this.hiddenCanvas.current;
      // console.log(canv.getContext('2d'));
      canv.getContext('2d');

      canvg(canv, staticRadarChart);
      return canv.toDataURL('image/png');
    } catch (err) {
      // console.log(err);
      return null;
    }
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      userId: params.userId,
      groupId: params.groupId,
      current360Id: params.id,
    });

    db.doGetCurrentGroup(this.props.match.params.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
      if (snapshot.data()) {
        this.setState({
          currentGroup: snapshot.data(),
        });

        db.getUserFromGroup(params.accountId, params.userId).onSnapshot((snapshot) => {
          let userFullName = `${snapshot.data()['forename']} ${snapshot.data()['surname']}`;
          let reportPdfFileName = `${snapshot.data()['forename']}-${snapshot.data()['surname']}-report.pdf`;

          db.doGetCurrent360(params.accountId, params.id).then((snapshot) => {
            let questionnaireID = snapshot.data()['questionnaireID'];
            let current360Title = snapshot.data()['title'];
            // let current360Relationships = snapshot.data()['relationships']
            let current360Relationships = this.state.current360Relationships;

            db.doGetSectionsAndQuestionDetailsList(params.accountId, questionnaireID).then((sections) => {
              if (
                Object.values(sections)
                  .map((section) => section.sortOrder)
                  .includes(undefined)
              ) {
                this.setState({
                  sectionsLegacyMode: true,
                });
              }

              let questionnaireSections = sections;
              let captions = {};
              let descriptions = {};
              let questionDetails = {};
              let sectionDetails = questionnaireSections;
              let userAnswers = {};
              let userResponses = {};
              let userCommentsBySection = {};
              let userIndividualAnswers = [];
              let userIndividualResponses = [];
              // let reviewerAnswers = {}

              // // console.log("questionnaireSections");
              // // console.log(questionnaireSections);

              Object.keys(questionnaireSections).map((key, index) => {
                let sectionID = key;

                if (questionnaireSections[sectionID].questions) {
                  // Check if only one question AND if that question is a FreeText field!!
                  if (Object.keys(questionnaireSections[sectionID].questions).length == 1) {
                    // MAD:
                    if (questionnaireSections[sectionID].questions[Object.keys(questionnaireSections[sectionID].questions)[0]].answerType === 'FreeText') {
                      return;
                    }
                  }
                  // Set captions + description!
                  captions[[sectionID]] = questionnaireSections[sectionID].title;
                  descriptions[[sectionID]] = questionnaireSections[sectionID].description;

                  Object.keys(questionnaireSections[sectionID].questions).map((key, index) => {
                    // // console.log(questionnaireSections[sectionID].questions[key])
                    questionDetails[[key]] = questionnaireSections[sectionID].questions[key];
                  });
                }
              });

              // // console.log(questionDetails);
              // Get answers!

              db.doGetUserAnswersBySections(
                params.accountId,
                params.userId,
                params.id,
                params.groupId,
                questionnaireID,
                'self-review',
                questionnaireSections
              ).then((data) => {
                let questionnaireSections = data;

                Object.keys(questionnaireSections).map((key, index) => {
                  let sectionKey = key;
                  let answers = [];
                  let comments = [];
                  let responses = [0, 0, 0, 0, 0, 0];
                  let answerKey = 0;

                  //// console.log(questionnaireSections);

                  Object.keys(questionnaireSections[sectionKey].answers).map((key, index) => {
                    let answerVal = questionnaireSections[sectionKey].answers[key];
                    // If answer is a number value (IE: not a text field) then set, otherwise set to `0`

                    answerVal = isNaN(Number(answerVal)) ? -1 : parseInt(answerVal);

                    if (answerVal == -1) {
                      let userComment = questionnaireSections[sectionKey].answers[key];

                      if (!userCommentsBySection[[sectionKey]]) {
                        userCommentsBySection[[sectionKey]] = {};
                      }
                      if (!userCommentsBySection[[sectionKey]][[key]]) {
                        userCommentsBySection[[sectionKey]][[key]] = {};
                        userCommentsBySection[[sectionKey]][[key]]['title'] = sectionDetails[sectionKey].questions[key].questionTitle;
                      }

                      userCommentsBySection[[sectionKey]][[key]]['comment'] = userComment;
                    } else {
                      if (!isNaN(parseInt(answerVal))) {
                        responses[answerVal] = responses[answerVal] + 1;
                      }
                      // user `answerKey` instead of `key` to ensure correct average

                      if (answerVal.toString() !== 'NaN') {
                        answers[answerKey] = answerVal;
                        userIndividualAnswers[[key]] = answerVal;
                      }

                      //// console.log(userIndividualAnswers);

                      answerKey++;
                    }

                    // if(index == Object.keys( questionnaireSections[sectionKey].answers ).length - 1) {
                    //   // console.log('done');
                    //
                    //   // console.log("sectionKey = ");
                    //   // console.log(sectionKey);
                    //   // // console.log( sectionDetails[sectionKey].questions[key].questionTitle );
                    //   // console.log("answers = ");
                    //   // console.log(answers);
                    //   // console.log(average(answers));
                    // }
                  });

                  // // console.log(answers)
                  if (answers.length) {
                    userAnswers[[sectionKey]] = average(answers) / 5;
                  } else {
                    userAnswers[[sectionKey]] = 0;
                  }
                  userResponses[[sectionKey]] = responses;

                  // // console.log("sectionKey = ");
                  // // console.log(sectionKey);
                  // // // console.log( sectionDetails[sectionKey].questions[key].questionTitle );
                  // // console.log("answers = ");
                  // // console.log(answers);
                  // // console.log(average(answers));
                  // userCommentsBySection[[sectionKey]] = ( comments )
                });

                // // console.log(userAnswers);

                // Get Reviewer Answers.
                db.doGetCompletedReviewers(params.accountId, params.id, params.groupId, params.userId).then((snapshot) => {
                  if (!snapshot.empty) {
                    let reviewers = {};
                    snapshot.docs.map((doc, i) => {
                      reviewers[doc.id] = doc.data();
                    });

                    let numberOfReviewers = Object.keys(reviewers).length;
                    let completedReviewerAnswersAverage = {};
                    let completedReviewerComments = {};
                    let completedReviewerIndividualQuestionsAverage = {};
                    let completedReviewerAnswers = {};

                    Object.keys(current360Relationships).map((key) => {
                      completedReviewerAnswers[key] = {};
                    });

                    let completedReviewerAnswersByRelationship = {};
                    let completedIndividualQuestionsDifferences = {};

                    let completedReviewerResponsesByRelationship = {};

                    let completedReviewerResponses = {};
                    let completedReviewerResponsesAverage = {};

                    let completedReviewerCommentsBySection = {};

                    Object.keys(current360Relationships).map((key) => {
                      completedReviewerResponses[key] = {};
                      completedReviewerResponsesAverage[key] = {};
                    });

                    // Loop through all completed reviewers + split into correct relationship Groups
                    // and then get average of section scores
                    Object.keys(reviewers).map((key, index) => {
                      let loopNum = index + 1;
                      let reviewerRelationship = reviewers[key]['relationship'];

                      db.doGetReviewerAnswers(params.accountId, key, questionnaireSections).then((data) => {
                        let reviewerAnswersSections = data;
                        // // console.log(key);
                        // // console.log(data);
                        let reviewerIDD = key;
                        let answers = [];
                        // // console.log(reviewerAnswersSections);
                        Object.keys(reviewerAnswersSections).map((key, index) => {
                          let sectionKey = key;
                          let answers = [];
                          let comments = [];
                          let responses = [0, 0, 0, 0, 0, 0];

                          // // console.log(reviewerAnswersSections[key]);

                          // Loop through answers in each Section
                          Object.keys(reviewerAnswersSections[key].answers).map((key, index) => {
                            let count = index;
                            count++;

                            let answerVal = reviewerAnswersSections[sectionKey].answers[key];
                            // If answer is a number value (IE: not a text field) then set, otherwise set to `0`
                            // answerVal = isNaN(parseInt(answerVal)) ? -1 : parseInt(answerVal)
                            answerVal = isNaN(Number(answerVal)) ? -1 : parseInt(answerVal);
                            answers[index] = answerVal;

                            if (answerVal == -1) {
                              comments[index] = reviewerAnswersSections[sectionKey].answers[key];

                              let reviewerComment = reviewerAnswersSections[sectionKey].answers[key];

                              if (!completedReviewerCommentsBySection[[sectionKey]]) {
                                completedReviewerCommentsBySection[[sectionKey]] = {};
                              }
                              if (!completedReviewerCommentsBySection[[sectionKey]][[key]]) {
                                // // console.log(reviewerIDD);
                                // // console.log(sectionDetails);
                                // // console.log(sectionKey);
                                // // console.log(key);
                                // // console.log(sectionDetails[sectionKey]);
                                completedReviewerCommentsBySection[[sectionKey]][[key]] = {};
                                completedReviewerCommentsBySection[[sectionKey]][[key]]['title'] = sectionDetails[sectionKey].questions[key].questionTitle;
                                completedReviewerCommentsBySection[[sectionKey]][[key]]['comments'] = [];
                                completedReviewerCommentsBySection[[sectionKey]][[key]]['relationships'] = [];
                                completedReviewerCommentsBySection[[sectionKey]][[key]]['commentsByRelationship'] = {};
                              }

                              if (!completedReviewerCommentsBySection[[sectionKey]][[key]]['commentsByRelationship'][[reviewerRelationship]]) {
                                //// console.log(completedReviewerCommentsBySection[[sectionKey]][[key]]['commentsByRelationship'][[reviewerRelationship]]);
                                completedReviewerCommentsBySection[[sectionKey]][[key]]['commentsByRelationship'][[reviewerRelationship]] = [];
                              }

                              // reviewerRelationship

                              completedReviewerCommentsBySection[[sectionKey]][[key]]['comments'].push(reviewerAnswersSections[sectionKey].answers[key]);
                              completedReviewerCommentsBySection[[sectionKey]][[key]]['commentsByRelationship'][[reviewerRelationship]].push(
                                reviewerAnswersSections[sectionKey].answers[key]
                              );
                              // completedReviewerCommentsBySection[[sectionKey]].push( reviewerAnswersSections[sectionKey].answers[key] )
                            } else {
                              responses[answerVal] = responses[answerVal] + 1;

                              //
                              if (!completedReviewerIndividualQuestionsAverage[[key]]) {
                                completedReviewerIndividualQuestionsAverage[[key]] = [];
                              }
                              completedReviewerIndividualQuestionsAverage[[key]].push(answerVal);

                              if (!isNaN(parseInt(answerVal))) {
                                if (!completedReviewerAnswersByRelationship[[key]]) {
                                  completedReviewerAnswersByRelationship[[key]] = [];
                                }

                                if (!completedReviewerAnswersByRelationship[[key]][[reviewerRelationship]]) {
                                  completedReviewerAnswersByRelationship[[key]][[reviewerRelationship]] = [];
                                }

                                // // console.log(answerVal);
                                // if(answerVal != 0) {
                                completedReviewerAnswersByRelationship[[key]][[reviewerRelationship]].push(answerVal);
                              }
                              // }

                              if (!completedReviewerResponsesByRelationship[[key]]) {
                                completedReviewerResponsesByRelationship[[key]] = [];
                              }

                              if (!completedReviewerResponsesByRelationship[[key]][[reviewerRelationship]]) {
                                completedReviewerResponsesByRelationship[[key]][[reviewerRelationship]] = [];
                              }

                              completedReviewerResponsesByRelationship[[key]][[reviewerRelationship]].push(responses);
                            }

                            // if(count == Object.keys( reviewerAnswersSections[sectionKey].answers ).length) {
                            //
                            // }
                          });

                          completedReviewerAnswers[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]] = average(answers) / 5;

                          if (completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]]) {
                            Object.keys(completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]]).map((key, index) => {
                              // increase each number
                              completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]][index] =
                                completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]][index] + responses[index];
                            });
                          } else {
                            completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]] = responses;
                          }

                          // // console.log("responses");
                          // // console.log(responses);
                          // // console.log("completedReviewerResponses[reviewerRelationship][[sectionKey]]");
                          // // console.log(completedReviewerResponses[reviewerRelationship][[sectionKey]]);

                          // completedReviewerResponsesAverage[reviewerRelationship][[sectionKey]] = average(
                          //   completedReviewerResponses[reviewerRelationship][[sectionKey]]
                          // )

                          completedReviewerResponsesAverage[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]] = [];
                          // Loop through
                          Object.keys(completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]]).map((key, index) => {
                            // completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]][key]
                            // listOfAnswers.push()

                            for (let i = 0; i < completedReviewerResponses[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]][key]; i++) {
                              // // console.log(index);
                              if (index != 0) {
                                completedReviewerResponsesAverage[convertRelationshipTextToKey(reviewerRelationship)][[sectionKey]].push(index);
                              }
                            }
                          });

                          // if(sectionKey == "-LuHOcb0L6cIyrjAeIQ1" && reviewerRelationship == "DR") {
                          //   // console.log("responses");
                          //   // console.log("reviewerRelationship - "+reviewerRelationship);
                          //   // console.log("sectionKey - "+sectionKey);
                          //   // // console.log(responses);
                          //   // console.log("listOfAnswers");
                          //   // console.log(listOfAnswers);
                          //
                          // }

                          // completedReviewerResponses[reviewerRelationship][[sectionKey]] = responses

                          if (!completedReviewerAnswersAverage[[sectionKey]]) {
                            completedReviewerAnswersAverage[[sectionKey]] = [];
                          }
                          // // console.log(sectionKey);
                          // // console.log(answers);
                          const answersMinusNegative = answers.filter((e) => e !== -1);
                          // // console.log(average(answers));
                          // // console.log(average(answersMinusNegative));
                          // completedReviewerAnswersAverage[[sectionKey]].push( average(answers) )

                          if (answersMinusNegative.length) {
                            completedReviewerAnswersAverage[[sectionKey]].push(average(answersMinusNegative));
                          } else {
                            completedReviewerAnswersAverage[[sectionKey]].push(0);
                          }

                          // Get all user Comments per-section (anonymously)
                          if (!completedReviewerComments[[sectionKey]]) {
                            completedReviewerComments[[sectionKey]] = [];
                          }
                          // // console.log('comments');
                          // // console.log(comments);
                          completedReviewerComments[[sectionKey]][[loopNum]] = comments[0];
                        });

                        if (loopNum == numberOfReviewers) {
                          // // console.log('got all reviewer scores!')

                          // // console.log(completedReviewerIndividualQuestionsAverage)

                          // Convert all the reviews to averages.

                          let completedReviewerResponsesConvertedToAverages = {};
                          let completedReviewerResponsesConvertedToAveragesPercentage = {};

                          let completedReviewerResponsesSectionAverages = {};
                          let completedReviewerResponsesSectionAveragesNEW = {};
                          let allReviewerAnswers = {};

                          for (const i in completedReviewerResponsesAverage) {
                            for (const n in completedReviewerResponsesAverage[i]) {
                              if (completedReviewerResponsesAverage[i][n].length === 0) {
                                delete completedReviewerResponsesAverage[i][n];
                              }
                            }
                          }
                          // // console.log(completedReviewerResponsesAverage);
                          Object.keys(completedReviewerResponsesAverage).map((relationshipKey) => {
                            // // console.log(completedReviewerResponsesAverage[key]);
                            // completedReviewerResponsesConvertedToAverages[[key]] = completedReviewerResponsesAverage[key]
                            // loop through each key
                            completedReviewerResponsesConvertedToAverages[[relationshipKey]] = {};
                            completedReviewerResponsesConvertedToAveragesPercentage[[relationshipKey]] = {};
                            // allReviewerAnswers[[relationshipKey]] = []

                            Object.keys(completedReviewerResponsesAverage[relationshipKey]).map((sectionKey) => {
                              // // console.log(completedReviewerResponsesAverage[relationshipKey][sectionKey].length)
                              if (completedReviewerResponsesAverage[relationshipKey][sectionKey].length > 0) {
                                completedReviewerResponsesConvertedToAverages[relationshipKey][[sectionKey]] = Number(
                                  average(completedReviewerResponsesAverage[relationshipKey][sectionKey]).toFixed(1)
                                );
                                completedReviewerResponsesConvertedToAveragesPercentage[relationshipKey][[sectionKey]] = Number(
                                  average(completedReviewerResponsesAverage[relationshipKey][sectionKey]).toFixed(1) / 5
                                );

                                // :|
                                if (!completedReviewerResponsesSectionAverages[[sectionKey]]) {
                                  completedReviewerResponsesSectionAverages[[sectionKey]] = [];
                                }

                                completedReviewerResponsesSectionAverages[[sectionKey]].push(
                                  // Number( average( completedReviewerResponsesAverage[relationshipKey][sectionKey] ) )
                                  // Number( average( completedReviewerResponsesAverage[relationshipKey][sectionKey] ).toFixed(2) )
                                  ...completedReviewerResponsesAverage[relationshipKey][sectionKey]
                                );

                                if (!allReviewerAnswers[[sectionKey]]) {
                                  allReviewerAnswers[[sectionKey]] = [];
                                }

                                allReviewerAnswers[[sectionKey]].push(...completedReviewerResponsesAverage[relationshipKey][sectionKey]);

                                // allReviewerAnswers.concat(completedReviewerResponsesAverage[relationshipKey][sectionKey])
                                // if(sectionKey == "-MKR5nUIP_QkJpDFNSQm") {
                                //   // console.log("sectionKey");
                                //   // console.log(sectionKey);
                                //   // console.log( completedReviewerResponsesAverage[relationshipKey][sectionKey] );
                                //   // console.log( average(completedReviewerResponsesAverage[relationshipKey][sectionKey]) );
                                //   // console.log(allReviewerAnswers);
                                // }
                              }
                            });
                          });

                          // Object
                          //   .keys( allReviewerAnswers )
                          //   .map((sectionKey) => {
                          //
                          //   })
                          //
                          // // // console.log("sectionKey");
                          // // // console.log(sectionKey);
                          // // console.log(allReviewerAnswers);

                          // GET CORRECT SECTION AVERAGES!
                          Object.keys(completedReviewerResponsesSectionAverages).map((key, index) => {
                            let completedAverage = average(completedReviewerResponsesSectionAverages[key]).toFixed(2);
                            completedReviewerResponsesSectionAverages[key] = completedAverage;
                          });
                          // Object
                          //   .keys(completedReviewerAnswersAverage)
                          //   .map((key,index) => {
                          //     let completedAverage = (average(completedReviewerAnswersAverage[key])).toFixed(2)
                          //     completedReviewerAnswersAverage[key] = completedAverage
                          //   })

                          // // console.log("completedReviewerIndividualQuestionsAverage");
                          // // console.log(completedReviewerIndividualQuestionsAverage);

                          let averageOfRelationshipAverages = {};
                          let questionAverageByRelationship = {};
                          // // console.log(completedReviewerAnswersByRelationship);
                          Object.keys(completedReviewerAnswersByRelationship).map((answerKey) => {
                            Object.keys(completedReviewerAnswersByRelationship[answerKey]).map((relationshipKey) => {
                              if (!averageOfRelationshipAverages[answerKey]) {
                                averageOfRelationshipAverages[answerKey] = [];
                              }
                              if (!Object.keys(questionAverageByRelationship).includes(relationshipKey)) {
                                questionAverageByRelationship[relationshipKey] = {};
                              }
                              if (!Object.keys(questionAverageByRelationship[relationshipKey]).includes(answerKey)) {
                                questionAverageByRelationship[relationshipKey][answerKey] = [];
                              }

                              const completedReviewerAnswersByRelationshipMinusZero = completedReviewerAnswersByRelationship[answerKey][relationshipKey].filter(
                                (e) => e !== 0
                              );
                              // // console.log(answerKey);
                              // // console.log(completedReviewerAnswersByRelationship[answerKey][relationshipKey]);
                              // // console.log(completedReviewerAnswersByRelationshipMinusZero);
                              // // console.log(completedReviewerAnswersByRelationshipMinusZero);
                              // // console.log(completedReviewerAnswersByRelationship[answerKey][relationshipKey]);
                              // // console.log(average(completedReviewerAnswersByRelationship[answerKey][relationshipKey]));
                              if (completedReviewerAnswersByRelationshipMinusZero.length) {
                                completedReviewerAnswersByRelationshipMinusZero.forEach((val, count) => {
                                  averageOfRelationshipAverages[answerKey].push(completedReviewerAnswersByRelationshipMinusZero[count]);
                                });

                                // // console.log(completedReviewerAnswersByRelationshipMinusZero);
                                questionAverageByRelationship[relationshipKey][answerKey].push(
                                  Number(average(completedReviewerAnswersByRelationshipMinusZero).toFixed(2))
                                );
                              }
                            });
                          });
                          let mergedAverageOfAnswersByRelationship = {};
                          Object.keys(averageOfRelationshipAverages).map((answerKey) => {
                            if (!mergedAverageOfAnswersByRelationship[answerKey]) {
                              mergedAverageOfAnswersByRelationship[answerKey] = [];
                            }

                            // // console.log(averageOfRelationshipAverages);
                            mergedAverageOfAnswersByRelationship[answerKey].push(parseFloat(average(averageOfRelationshipAverages[answerKey])).toFixed(2));
                          });
                          // // console.log(mergedAverageOfAnswersByRelationship);

                          let calculatedAverage = {};

                          Object.keys(mergedAverageOfAnswersByRelationship).map((key, index) => {
                            // let completedAverage = (average(mergedAverageOfAnswersByRelationship[key])).toFixed(2)
                            let completedAverage = mergedAverageOfAnswersByRelationship[key];
                            mergedAverageOfAnswersByRelationship[key] = completedAverage;
                            calculatedAverage[[key]] = completedAverage;

                            let descrepency = difference(userIndividualAnswers[key], average(mergedAverageOfAnswersByRelationship[key]).toFixed(2)).toFixed(2);
                            if (userIndividualAnswers[key] > 0) {
                                completedIndividualQuestionsDifferences[key] = descrepency;
                            }
                          });

                          let sectionAverageByRelationship = {};
                          for (const relKey in current360Relationships) {
                            sectionAverageByRelationship[relKey] = {};
                            for (const secID in sectionDetails) {
                              sectionAverageByRelationship[relKey][secID] = {};
                              if (Object.keys(completedReviewerResponsesAverage[relKey]).includes(secID)) {
                                // sectionAverageByRelationship[relKey][secID] = average(completedReviewerResponsesAverage[relKey][secID]).toFixed(2);

                                const calcSecAvg = () => {
                                  let avgCountVol = 0;
                                  let avgCountSum = 0;
                                  //this loops through the score count in ascending order, 0's, 1's, 2's etc
                                  completedReviewerResponses[relKey][secID].forEach((count, j) => {
                                    if (`${count}` !== `NaN`) {
                                      const score = (j += 0);
                                      if (score > 0) {
                                        let scoreVol = count * score;
                                        avgCountVol += count;
                                        avgCountSum += scoreVol;
                                      }
                                    }
                                  });
                                  let averageReturn = avgCountSum / avgCountVol;
                                //   return `${averageReturn.toFixed(2)}`;
                                  return `${averageReturn.toFixed(1)}`;
                                };
                                sectionAverageByRelationship[relKey][secID] = calcSecAvg();
                              }
                            }
                          }

                          let generateGraph = false;
                          let lCount = 0;
                          Object.keys(questionDetails).map((QID, l) => {
                            if (questionDetails[QID].answerType === 'MultiChoice') {
                              lCount++;
                            }
                          });
                          if (lCount > 0) {
                            generateGraph = true;
                          }

                          let secLengthNoFreeText = {};
                          Object.keys(sectionDetails).map((item) => {
                            let questionArr = [];
                            Object.keys(sectionDetails[item].questions).map((question) => {
                              if (sectionDetails[item].questions[question].answerType === 'MultiChoice') {
                                questionArr.push(question);
                              }
                            });
                            secLengthNoFreeText[item] = questionArr.length;
                          });

                          const highestRated = Object.keys(averageOfRelationshipAverages).sort(function(a, b) {
                            return average(averageOfRelationshipAverages[b]) - average(averageOfRelationshipAverages[a]);
                          });
                          let weightedArr = [];
                          highestRated.map((val, i) => {
                            if (i > 0) {
                              let current = average(averageOfRelationshipAverages[highestRated[i]]);
                              let prev = average(averageOfRelationshipAverages[highestRated[i - 1]]);
                              let currLen = averageOfRelationshipAverages[highestRated[i]];
                              let prevLen = (x, y) => averageOfRelationshipAverages[highestRated[x - y]];
                              if (current === prev) {
                                if (currLen.length > prevLen(i, 1).length) {
                                  for (let n = 2; n < weightedArr.length; n++) {
                                    if (!currLen.length > prevLen(i, n).length) {
                                      weightedArr.splice(weightedArr.length - n, 0, val);
                                      n = weightedArr.length;
                                    }
                                  }
                                } else if (currLen.length === prevLen(i, 1).length) {
                                  const currentArr = currLen.sort(function(a, b) {
                                    return b - a;
                                  });
                                  const previousArr = prevLen(i, 1).sort(function(a, b) {
                                    return b - a;
                                  });

                                  if (currentArr[currentArr.length - 1] > previousArr[previousArr.length - 1]) {
                                    for (let n = 1; n < weightedArr.length; n++) {
                                      if (currentArr[currentArr.length - 1] > previousArr[previousArr.length - n]) {
                                        weightedArr.splice(weightedArr.length - n, 0, val);
                                        n = weightedArr.length;
                                      }
                                    }
                                  } else {
                                    weightedArr.push(val);
                                    // // console.log(weightedArr);
                                  }
                                } else {
                                  weightedArr.push(val);
                                  // // console.log(weightedArr);
                                }

                                // // console.log('current vals ' + averageOfRelationshipAverages[highestRated[i]]);
                                // // console.log('previous vals ' + averageOfRelationshipAverages[highestRated[i - 1]]);
                              } else {
                                weightedArr.push(val);
                              }
                            } else {
                              weightedArr.push(val);
                            }
                          });
                          //remove the text only questions from the highest rated array
                          Object.keys(sectionDetails).map((secKey) => {
                            Object.keys(sectionDetails[secKey].questions).map((ansKey, n) => {
                              if (sectionDetails[secKey].questions[ansKey].answerType === 'FreeText') {
                                const toRemove = weightedArr.indexOf(ansKey);
                                if (toRemove !== -1) {
                                  weightedArr.splice(toRemove, 1);
                                }
                              }
                            });
                          });

                          this.setState({
                            weightedHighest: weightedArr,
                            secLengthNoFreeText: secLengthNoFreeText,
                            userAnswers: userAnswers,
                            userResponses: userResponses,
                            userCommentsBySection: userCommentsBySection,
                            completedReviewerComments: completedReviewerComments,
                            userIndividualAnswers: userIndividualAnswers,
                            reviewerAnswers: completedReviewerAnswers,
                            reviewerResponses: completedReviewerResponses,
                            reviewerResponsesAverage: completedReviewerResponsesAverage,
                            reviewerResponsesConvertedToAverages: completedReviewerResponsesConvertedToAverages,
                            reviewerResponsesConvertedToAveragesPercentage: completedReviewerResponsesConvertedToAveragesPercentage,
                            // reviewerAnswersAverage: completedReviewerAnswersAverage,
                            reviewerAnswersAverage: completedReviewerResponsesSectionAverages,
                            // completedReviewerIndividualQuestionsAverage: completedReviewerIndividualQuestionsAverage,
                            averageOfRelationshipAverages: averageOfRelationshipAverages,
                            mergedAverageOfAnswersByRelationship: mergedAverageOfAnswersByRelationship,
                            completedReviewerIndividualQuestionsAverage: calculatedAverage,
                            completedReviewerAnswersByRelationship: completedReviewerAnswersByRelationship,
                            completedReviewerResponsesByRelationship: completedReviewerResponsesByRelationship,
                            completedIndividualQuestionsDifferences: completedIndividualQuestionsDifferences,
                            completedReviewerCommentsBySection: completedReviewerCommentsBySection,
                            questionAverageByRelationship: questionAverageByRelationship,
                            generateGraph: generateGraph,
                            captions: captions,
                            descriptions: descriptions,
                            questionDetails: questionDetails,
                            current360Title: current360Title,
                            sectionDetails: sectionDetails,
                            userFullName: userFullName,
                            reportPdfFileName: reportPdfFileName,
                            current360Relationships: current360Relationships,
                            sectionAverageByRelationship: sectionAverageByRelationship,
                            reviewerAnswersCountBySection: completedReviewerResponses,
                            loading: false,
                          });
                        }
                      });
                    });
                  } else {
                    this.setState({
                      userAnswers: userAnswers,
                      captions: captions,
                      descriptions: descriptions,
                      loading: false,
                    });
                  }
                });
              });
            });
          });
        });
      }
    });
    // .catch((data) => {
    //   this.setState({
    //     error: true
    //   })
    // })
  }
  render() {
    if (!this.state.loading) {
      let withoutZero = {};
      let userData = {};
      Object.keys(this.state.userResponses).forEach((key) => {
        let arr = 0;
        let countArr = 0;
        this.state.userResponses[key].reverse().forEach((val, i) => {
          const n = 5 - i;
          if (n > 0 && val * n > 0) {
            arr += val * n;
            countArr += val;
          }
        });
        withoutZero[key] = arr / countArr;
        userData[key] = arr / countArr / 5;
      });

      let emptyData = {
        data: {},
        meta: {},
      };
      // let userData = this.state.userAnswers;
      let chartCaptions = this.state.captions;
      const data = [
        {
          data: {},
          meta: { color: 'blue' },
        },
      ];
      for (const q in this.state.sectionDetails) {
        let counter = 0;
        for (const p in this.state.sectionDetails[q].questions) {
          if (this.state.sectionDetails[q].questions[p].answerType === 'MultiChoice') {
            counter++;
          }
        }
        if (counter === 0) {
          delete userData[q];
          delete chartCaptions[q];
        }
      }
      const captionsLoaded = chartCaptions;
      data[0]['data'] = userData;

      // if(this.state.reviewerAnswers) {
      //   Object
      //     .keys(this.state.reviewerAnswers)
      //     .map((key,index) => {
      //       if(Object.keys(this.state.reviewerAnswers[key]).length) {
      //         data.push({
      //           data: this.state.reviewerAnswers[key],
      //           meta: {
      //             color: this.state.current360Relationships[key]['colour']
      //           }
      //         });
      //       }
      //     })
      // }

      if (this.state.reviewerResponsesConvertedToAveragesPercentage) {
        Object.keys(this.state.reviewerResponsesConvertedToAveragesPercentage).map((key, index) => {
          if (Object.keys(this.state.reviewerResponsesConvertedToAveragesPercentage[key]).length) {
            data.push({
              data: this.state.reviewerResponsesConvertedToAveragesPercentage[key],
              meta: {
                color: this.state.current360Relationships[key]['colour'],
              },
            });
          }
        });
      }
      // console.log(data);

      // // console.log(data);

      // if(this.state.reviewerResponsesConvertedToAverages) {
      //   Object
      //     .keys(this.state.reviewerResponsesConvertedToAverages)
      //     .map((key,index) => {
      //       if(Object.keys(this.state.reviewerResponsesConvertedToAverages[key]).length) {
      //         data.push({
      //           // data: this.state.reviewerResponsesConvertedToAverages[key] / 5,
      //           data: 0.5,
      //           meta: {
      //             color: this.state.current360Relationships[key]['colour']
      //           }
      //         });
      //       }
      //     })
      // }

      const chartOptions = {
        size: Object.keys(this.props.current360Relationships).length === 6 ? 490 : 700,
        axes: true, // show axes?
        scales: 5, // show scale circles?
        captions: true, // show captions?
        captionMargin: 30,
        dots: true, // show dots?
        zoomDistance: 1.3, // where on the axes are the captions?
        axisProps: () => ({ className: 'axis', fill: 'none', stroke: '#000' }),
        scaleProps: () => ({ className: 'scale', fill: 'none', stroke: '#000' }),
        shapeProps: () => ({ className: 'shape' }),
        captionProps: () => ({
          className: 'caption',
          textAnchor: 'middle',
          fontSize: 14,
          fontFamily: 'sans-serif',
        }),
      };

      let reviewerAnswersAverage = this.state.reviewerAnswersAverage;
      // let completedReviewerIndividualQuestionsAverage = this.state.completedReviewerIndividualQuestionsAverage
      let mergedAverageOfAnswersByRelationship = this.state.mergedAverageOfAnswersByRelationship;
      let completedIndividualQuestionsDifferences = this.state.completedIndividualQuestionsDifferences;

      return (
        <div className='page-wrapper page-wrapper--report'>
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.props.match.params.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${this.props.current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
                },
                {
                  title: this.props.current360.title,
                  to: '../../../edit/',
                },
                {
                  title: this.state.currentGroup.title,
                  to: `../../../edit/group/${this.state.groupId}/`,
                },
                {
                  title: `Report & Development plan for ${this.state.userFullName}`,
                  to: null,
                },
              ]}
            />
          </section>
          <div
            className='user-report-view-only'
            style={{ height: '100vh', width: '100vw', paddingTop: 20, paddingBottom: 100, backgroundColor: 'rgb(82, 86, 89)', overflow: 'hidden' }}
          >
            <p className='tc'>
              <Link className='btn btn--back btn--white ml2 mr3' to={`../../../edit/group/${this.state.groupId}/`}>
                Back to group overview
              </Link>
              <button className='btn btn--white ml3 mr2' onClick={this.exportPDF}>
                Export as PDF
              </button>
            </p>
            <div
              style={{
                maxWidth: '210mm',
                height: '90vh',
                backgroundColor: 'white',
                boxShadow: '0px 0px 5px #000',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 100,
                padding: '0px',
                overflowX: 'scroll',
                overflowY: 'scroll',
              }}
            >
              <PDFExport
                paperSize={'A4'}
                forcePageBreak='.page-break'
                keepTogether={'div'}
                margin={{ bottom: 50 }}
                pageTemplate={UserReportPageTemplate}
                fileName={this.state.reportPdfFileName}
                title=''
                subject=''
                keywords=''
                ref={(r) => (this.report = r)}
              >
                <div
                  className='user-report'
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '0px',
                  }}
                >
                  <header className='user-report__header'>
                    <img crossorigin='anonymous' src={this.props.current360.settings.logo} class='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>{this.state.current360Title}</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Report & Development Plan</strong>
                      </p>
                      <p className='user-report__header-name'>For {this.state.userFullName}</p>
                    </div>
                  </header>
                  <div className='user-report__contents'>
                    <h1>Contents</h1>
                    <hr />
                    <h2 className='f3 mv4 mb3'>
                      <strong>Instructions</strong>
                    </h2>
                    <hr />
                    <h2 className='f3 mt4 mb0'>
                      <strong>Part 1</strong>
                    </h2>
                    <h4 className='f4 mb4 mt0'>Your Feedback Summary</h4>
                    <hr />
                    <h2 className='f3 mt4 mb0'>
                      <strong>Part 2</strong>
                    </h2>
                    <h4 className='f4 mb4 mt0'>Your Detailed Feedback</h4>
                    <hr />
                    <h2 className='f3 mt4 mb0'>
                      <strong>Part 3</strong>
                    </h2>
                    <h4 className='f4 mb4 mt0'>Handling Feedback</h4>
                    <hr />
                    <h2 className='f3 mt4 mb0'>
                      <strong>Part 4</strong>
                    </h2>
                    <h4 className='f4 mb4 mt0'>Your Development Plan</h4>
                  </div>
                  <div className='title-page page-break'>
                    <div className='w-60'>
                      {/*<h1 className="f3 mv0 mb3 light-blue">Part 1:</h1>*/}
                      <h2 className='f2 mv0'>Introduction</h2>
                    </div>
                  </div>
                  <div className='bg-white user-report__content'>
                    <h2 className='f2 mv0 mt4'>
                      <strong>Introduction</strong>
                    </h2>
                    <h3 className='f5 gray'>Getting the most from your feedback</h3>
                    <hr className='' />

                    <h3 className='f5 mt4'>
                      <strong>Where to begin?</strong>
                    </h3>
                    <p>
                      It’s a good idea to read the report in the order provided. However, you may prefer to dive into a specific section and then pull out
                      related information from other parts of the report. You can see how the different elements of the report relate to each other, in the
                      section ‘How your report is compiled’.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Look for consistent messages</strong>
                    </h3>
                    <p>
                      The key to understanding your feedback is to look for clear and consistent themes across your report. Look for competencies and statements
                      where you, and those providing feedback have given similar ratings or comments. These consistent themes will provide you with an
                      indication of where your strengths, or opportunities for improvements, can be found.
                    </p>
                    <p>
                      Similarly, you may notice that some rater groups (groups of reviewers; e.g., ‘direct reports’) recognise strengths/opportunities in
                      specific competencies and statements more frequently than others. In these instances, you should consider why, when, and how this might
                      occur. Ask yourself: what is one rater group seeing/not seeing that the others are/are not?
                    </p>
                    <p>
                      You should interpret one-off or isolated ratings or comments carefully. If you do find these, consider the context in which they may have
                      been provided. Ask yourself if there is any truth in these findings, or if they can be classified as ‘outliers’?
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Make notes</strong>
                    </h3>
                    <p>
                      There are coaching questions throughout the report, these are provided to encourage you to reflect on your feedback. Additionally, the
                      report provides space for you to capture notes, as you read through.
                    </p>

                    <h3 className='f5 mt4 page-break'>
                      <strong>Build your development plan</strong>
                    </h3>
                    <p>
                      All your feedback will help you to learn, develop and grow. Take the time to consider how you can use the feedback to help shape your
                      future development. What is your report telling you about your opportunities to develop? Reflect on how you can put this feedback into
                      action, by fleshing out your development plan with practical and positive actions and activities.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Don’t be afraid to ask for help</strong>
                    </h3>
                    <p>
                      Asking for help is a positive step! If you are unsure about some of the feedback you have received or feel uncertain about how to respond
                      to the feedback positively; talk to a trusted colleague, manager, coach or colleague within HR/Learning. Book some time to sit down and
                      discuss your concerns and ask them for help interpreting the feedback. If needed, they can help you to build some effective developmental
                      solutions in response to the feedback.
                    </p>

                    <h2 className='f2 mv0 mt4 page-break'>
                      <strong>Introduction</strong>
                    </h2>
                    <h3 className='f5 gray'>How your report is compiled</h3>
                    <hr className='' />

                    <h3 className='f5 mt4'>
                      <strong>Two things to note</strong>
                    </h3>
                    <p>Your feedback is made up of two key elements: competencies and statements.</p>

                    <h3 className='f5 mt4'>
                      <strong>Competencies</strong>
                    </h3>
                    <p>Competencies are high-level collections/groups of skills or knowledge. Each competency is made up of a series of statements.</p>

                    <h3 className='f5 mt4'>
                      <strong>Statements</strong>
                    </h3>
                    <p>Statements are a series of actions or behaviours, which are used to describe each competency area.</p>

                    <h3 className='f5 mt4'>
                      <strong>Rater groups</strong>
                    </h3>
                    <p>
                      Rater groups are also known as ‘relationship groups’ or ‘reviewer groups’. They are the collection of reviewers who have provided you with
                      feedback, enabling this report to be produced. The language used to describe these groups varies, depending on your organisation’s
                      preferences. As a norm, you can expect the following terms to be used:
                    </p>

                    <p>
                      &bull; Self / Own (your feedback)
                      <br />
                      &bull; Direct Reports / Reports / Team (those people that report into you)
                      <br />
                      &bull; Line Manager / Higher / Manager / Senior (those people that you report in to)
                      <br />
                      &bull; Peers / Same (people who operate at the same or a similar level to you in the organisation)
                      <br />
                      &bull; Other / External (people who work externally to the organisation; e.g., suppliers)
                      <br />
                    </p>

                    <h3 className='f5 mt4 page-break'>
                      <strong>Rating calculations</strong>
                    </h3>
                    <p>
                      When completing the online questionnaire, you and your colleagues rated each statement against a rating scale. These ratings are collated
                      and averaged to show the mean rating for each statement, and for each of the overall competencies.
                    </p>
                    <p>
                      Your self-review ratings are shown separately from those of your colleagues. Self-review ratings are not included in the average ratings.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Unable to comment rating</strong>
                    </h3>
                    <p>
                      There are occasions where some of your reviewers may feel unable to provide you with feedback to a specific statement. In these instances,
                      they might have selected the ‘unable to comment’ rating.{' '}
                    </p>
                    <p>
                      Whenever you, or your reviewer, choose this rating, it is not included in the rating calculation for that statement, or in the overall
                      competency rating.
                    </p>
                  </div>
                  <div className='title-page page-break'>
                    <div className='w-60'>
                      <h1 className='f3 mv0 mb3 light-blue'>Part 1:</h1>
                      <h2 className='f2 mv0'>Your Feedback Summary</h2>
                    </div>
                  </div>
                  {this.state.generateGraph ? (
                    <div className='bg-white user-report__content'>
                      <h2 className='f2 mt4 mv0'>
                        Part 1: <strong>Step 1</strong>
                      </h2>
                      <h3 className='f5 gray'>Competencies: comparison by reviewer group</h3>
                      <hr className='' />

                      <p>
                        The competency chart gives you a summary picture of how you perceive your general strengths and development needs, compared with your
                        reviewers' perceptions.
                      </p>
                      <p>
                        The areas where you have competencies with similar feedback ratings, from each rater group, shows that you deliver that competency
                        consistently. Delivering consistently highly-rated competencies indicates your strengths.{' '}
                      </p>
                      <p>Conversely, delivering consistently lowly-rated competencies can point to areas where you may need to change/improve.</p>
                      <p>
                        Areas where you might notice significant ‘gaps’ between the ratings provided by rater groups (e.g., line manager compared to direct
                        reports), suggests a mismatch between their experiences in working with you, or different perceptions. These gaps can be explored in
                        more detail in the ’Your detailed feedback’ section of the report.
                      </p>
                      <div className='flex w-100 page-break chartTable'>
                        <div className='w-50'>
                          <p>
                            <span className='w1 h1 dib br-100 bg-blue v-mid mr2'></span> <strong>Self</strong>
                          </p>
                          {this.state.reviewerAnswers
                            ? Object.keys(this.state.reviewerAnswers).map((key, index) => {
                                if (Object.keys(this.state.reviewerAnswers[key]).length) {
                                  return (
                                    <p key={key}>
                                      <span
                                        className='w1 h1 dib br-100 v-mid mr2'
                                        style={{ background: this.state.current360Relationships[key]['colour'] }}
                                      ></span>{' '}
                                      <strong>{this.state.current360Relationships[key]['name']}</strong>
                                      <br />
                                    </p>
                                  );
                                }
                              })
                            : ''}
                        </div>
                        <div className='w-50'>
                          <p className='tr'>
                            <strong>Outer ring on graph</strong> = greater frequency of behaviours
                            <br />
                            <strong>Inner ring</strong> = lower frequency of behaviours
                          </p>
                        </div>
                      </div>

                      <div className='radar'>
                        {
                          <img
                            style={chartOptions.size === 490 ? { margin: 'auto' } : {}}
                            src={this.convertSVGToImage(<RadarChart captions={captionsLoaded} data={data} size={chartOptions.size} options={chartOptions} />)}
                          />
                        }
                      </div>

                      <div className='page-break mv4'>
                        <h3 className='f5 gray '>Reflection</h3>
                        <p>What initial impressions do you have from this graph about your possible strengths or areas for development?</p>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.generateGraph ? (
                    <div className='bg-white page-break user-report__content'>
                      <h2 className='f2 mv0 mt4'>
                        Part 1: <strong>Step 2</strong>
                      </h2>
                      <h3 className='f5 gray'>Capabilities: ranked highest to lowest</h3>
                      <hr className='' />

                      <p>
                        Here is a list of your competencies, ranked highest to lowest, based on your feedback from all your reviewers. You can compare each
                        rating against your own self-review. This will give you an indication of your general strengths and developmental needs.{' '}
                      </p>

                      <div className='bb b--light-gray mv5'>
                        {/* Loop through reviewersAnswersAverage (sorting in numerical order!) */

                        reviewerAnswersAverage
                          ? Object.keys(reviewerAnswersAverage)
                              .sort(function(a, b) {
                                return reviewerAnswersAverage[b] - reviewerAnswersAverage[a];
                              })
                              .map((key, index) => {
                                if (reviewerAnswersAverage[key] > 0 && index < 7) {
                                  return (
                                    <div key={key} className='w-100 flex bt b--light-gray'>
                                      <div className='w-40'>
                                        <p style={{ marginRight: '15px' }}>{this.state.captions[key]}</p>
                                      </div>
                                      <div className='w-20'>
                                        <p style={{ color: '#F563B9' }}>
                                          <strong>Reviewers</strong>
                                        </p>
                                        <p className='blue'>
                                          <strong>Self</strong>
                                        </p>
                                      </div>
                                      <div className='w-40'>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <strong className='w-10'>{parseFloat(reviewerAnswersAverage[key]).toFixed(1)}</strong>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='h-100 db'
                                                style={{ background: '#F563B9', width: `${(reviewerAnswersAverage[key] / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                          </p>
                                        </div>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <strong className='w-10'>{withoutZero[key].toFixed(1)}</strong>
                                            <span className='w-100 bg-light-gray'>
                                              <span className='bg-blue h-100 db' style={{ width: `${(withoutZero[key].toFixed(1) / 5) * 100}%` }}></span>
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                          : ''}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {Object.keys(reviewerAnswersAverage).length > 7 && (
                    <div>
                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 2</strong>
                        </h2>
                        <h3 className='f5 gray'>Capabilities: ranked highest to lowest (continued)</h3>
                        <hr className='' />
                        {
                          <div className='bb b--light-gray mv5'>
                            {/* Loop through reviewersAnswersAverage (sorting in numerical order!) */
                            reviewerAnswersAverage
                              ? Object.keys(reviewerAnswersAverage)
                                  .sort(function(a, b) {
                                    return reviewerAnswersAverage[b] - reviewerAnswersAverage[a];
                                  })
                                  .map((key, index) => {
                                    if (reviewerAnswersAverage[key] > 0 && index > 6) {
                                      return (
                                        <div key={key} className='w-100 flex bt b--light-gray'>
                                          <div className='w-40'>
                                            <p style={{ marginRight: '15px' }}>{this.state.captions[key]}</p>
                                          </div>
                                          <div className='w-20'>
                                            <p style={{ color: '#F563B9' }}>
                                              <strong>Reviewers</strong>
                                            </p>
                                            <p className='blue'>
                                              <strong>Self</strong>
                                            </p>
                                          </div>
                                          <div className='w-40'>
                                            <div className='w-100'>
                                              <p className='flex'>
                                                <strong className='w-10'>{parseFloat(reviewerAnswersAverage[key]).toFixed(1)}</strong>
                                                <span className='w-100 bg-light-gray'>
                                                  <span
                                                    className='h-100 db'
                                                    style={{ background: '#F563B9', width: `${(reviewerAnswersAverage[key] / 5) * 100}%` }}
                                                  ></span>
                                                </span>
                                              </p>
                                            </div>
                                            <div className='w-100'>
                                              <p className='flex'>
                                                <strong className='w-10'>{withoutZero[key].toFixed(1)}</strong>
                                                <span className='w-100 bg-light-gray'>
                                                  <span className='bg-blue h-100 db' style={{ width: `${(withoutZero[key].toFixed(1) / 5) * 100}%` }}></span>
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })
                              : ''}
                          </div>
                        }
                      </div>
                    </div>
                  )}
                  {this.state.generateGraph ? (
                    <div>
                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 2</strong>
                        </h2>
                        <h3 className='f5 gray'>Capabilities: ranked highest to lowest</h3>
                        <hr className='' />

                        <div className='mb3'>
                          <h3 className='f5 gray mt2'>Reflection</h3>
                          <p>Based on this chart, what appear to be your strongest and least strong capability areas?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <p>How does this align with your self perceptions?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <p>Which capabilities may be the most relevant for you to look into further?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        </div>
                      </div>

                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 3</strong>
                        </h2>
                        <h3 className='f5 gray'>Statements: ranked highest and lowest</h3>
                        <hr className='' />
                        <p>
                          This chart shows your highest ranked statements, and your lowest ranked statements, based on your colleagues' ratings. You can also
                          compare each rating against your self-review for the same statement.
                        </p>
                        <p>
                          This will show you the specific statements that have been observed by your colleagues as strongest and the least strong. These should
                          generally align with the capability rankings and can be explored in more detail in 'Your detailed feedback'.
                        </p>

                        <div className='bb b--light-gray mv5'>
                          <p>
                            <strong>Highest Average Statements</strong>
                          </p>
                          {/* Loop through mergedAverageOfAnswersByRelationship (sorting in numerical order!) */
                          this.state.weightedHighest
                            ? this.state.weightedHighest.map((key, index) => {
                                if (mergedAverageOfAnswersByRelationship[key] > 0) {
                                  return index < 5 ? (
                                    <div key={key} className='w-100 flex bt b--light-gray'>
                                      <div className='w-40 pr2'>
                                        <p style={{ marginRight: '15px' }}>{this.state.questionDetails[key].questionTitle}</p>
                                      </div>
                                      <div className='w-20'>
                                        <p style={{ color: '#F563B9' }}>
                                          <strong>Reviewers</strong>
                                        </p>
                                        <p className='blue'>
                                          <strong>Self</strong>
                                        </p>
                                      </div>
                                      <div className='w-40'>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <strong className='w-10'>{parseFloat(mergedAverageOfAnswersByRelationship[key]).toFixed(1)}</strong>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='h-100 db'
                                                style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[key] / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                          </p>
                                        </div>

                                        <div className='w-100'>
                                          <p className='flex'>
                                            <strong className='w-10'>{this.state.userIndividualAnswers[key].toFixed(1) > 0 ? this.state.userIndividualAnswers[key].toFixed(1) : 'N/A'}</strong>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='bg-blue h-100 db'
                                                style={{ width: `${(this.state.userIndividualAnswers[key].toFixed(1) / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  );
                                }
                              })
                            : ''}
                        </div>
                      </div>
                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 3</strong>
                        </h2>
                        <h3 className='f5 gray'>Statements: ranked highest and lowest</h3>
                        <hr className='' />

                        <div className='bb b--light-gray mv5'>
                          <p>
                            <strong>Lowest Average Statements</strong>
                          </p>
                          {/* Loop through mergedAverageOfAnswersByRelationship (sorting in numerical order!) */
                          this.state.weightedHighest
                            ? this.state.weightedHighest.reverse().map((key, index) => {
                                if (mergedAverageOfAnswersByRelationship[key] > 0) {
                                  return index < 5 ? (
                                    <div key={key} className='w-100 flex bt b--light-gray'>
                                      <div className='w-40 pr2'>
                                        <p style={{ marginRight: '15px' }}>{this.state.questionDetails[key].questionTitle}</p>
                                      </div>
                                      <div className='w-20'>
                                        <p style={{ color: '#F563B9' }}>
                                          <strong>Reviewers</strong>
                                        </p>
                                        <p className='blue'>
                                          <strong>Self</strong>
                                        </p>
                                      </div>
                                      <div className='w-40'>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <strong className='w-10'>{parseFloat(mergedAverageOfAnswersByRelationship[key]).toFixed(1)}</strong>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='h-100 db'
                                                style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[key] / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                          </p>
                                        </div>
                                        <div className='w-100'>
                                          <p className='flex'>
                                            <strong className='w-10'>{(this.state.userIndividualAnswers[key].toFixed(1) == 0.0) ? "N/A" : this.state.userIndividualAnswers[key].toFixed(1) }</strong>
                                            <span className='w-100 bg-light-gray'>
                                              <span
                                                className='bg-blue h-100 db'
                                                style={{ width: `${(this.state.userIndividualAnswers[key].toFixed(1) / 5) * 100}%` }}
                                              ></span>
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  );
                                }
                              })
                            : ''}
                        </div>
                      </div>

                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 3</strong>
                        </h2>
                        <h3 className='f5 gray'>Statements: ranked highest and lowest</h3>
                        <hr className='' />
                        <div className='mb5'>
                          <h3 className='f5 gray mt2'>Reflection</h3>
                          <p>Based on this chart, what appear to be your strongest and least strong capability areas?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <p>How does this align with your self perceptions?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <p>Which capabilities may be the most relevant for you to look into further?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        </div>
                      </div>

                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 4</strong>
                        </h2>
                        <h3 className='f5 gray'>Statements: greatest variations</h3>
                        <hr className='' />
                        <p>
                          Here is a list of the statements that had the most significant variation between your self-review and your reviewer’s ratings. You
                          will find both over and under-ratings listed.
                        </p>
                        <p>
                          Statements which you over-rated in comparison to your colleagues may indicate that you perceive that you display these
                          behaviours/actions more frequently than others see them.
                        </p>
                        <p>
                          Statements which you under-rated in comparison your colleagues, may indicate that you display these behaviours/actions more frequently
                          than you initially perceived.
                        </p>

                        <div className='bb b--light-gray mv5'>
                          <p>
                            <strong>Statements which you have over-rated the most</strong>
                          </p>
                          {/* Loop through completedIndividualQuestionsDifferences (sorting in numerical order!) */
                          completedIndividualQuestionsDifferences
                            ? Object.keys(completedIndividualQuestionsDifferences)
                                .sort(function(a, b) {
                                  return completedIndividualQuestionsDifferences[b] - completedIndividualQuestionsDifferences[a];
                                })
                                .map((key, index) => {
                                  if (completedIndividualQuestionsDifferences[key] > 0) {
                                    return index < 5 ? (
                                      <div key={key} className='w-100 flex bt b--light-gray'>
                                        <div className='w-40 pr2'>
                                          <p style={{ marginRight: '15px' }}>{this.state.questionDetails[key].questionTitle}</p>
                                        </div>
                                        <div className='w-20'>
                                          <p style={{ color: '#F563B9' }}>
                                            <strong>Reviewers</strong>
                                          </p>
                                          <p className='blue'>
                                            <strong>Self</strong>
                                          </p>
                                        </div>
                                        <div className='w-40'>
                                          <div className='w-100'>
                                            <p className='flex'>
                                              <span className='w-100 bg-light-gray'>
                                                <span
                                                  className='h-100 db'
                                                  style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[key] / 5) * 100}%` }}
                                                ></span>
                                              </span>
                                              <strong className='w-30 tc'>{parseFloat(mergedAverageOfAnswersByRelationship[key]).toFixed(1)}</strong>
                                            </p>
                                          </div>
                                          <div className='w-100'>
                                            <p className='flex'>
                                              <span className='w-100 bg-light-gray'>
                                                <span
                                                  className='bg-blue h-100 db'
                                                  style={{ width: `${(this.state.userIndividualAnswers[key].toFixed(1) / 5) * 100}%` }}
                                                ></span>
                                              </span>
                                              <strong className='w-30 tc'>
                                                {this.state.userIndividualAnswers[key].toFixed(1)}{' '}
                                                <small>
                                                  (+
                                                  {difference(
                                                    this.state.userIndividualAnswers[key],
                                                    average(mergedAverageOfAnswersByRelationship[key]).toFixed(1)
                                                  ).toFixed(1)}
                                                  )
                                                </small>
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ''
                                    );
                                  } else {
                                    return index === 0 ? (
                                      <div>
                                        <h4>There are no statements where you have over-rated in comparison to the feedback you have received</h4>
                                      </div>
                                    ) : (
                                      ''
                                    );
                                  }
                                })
                            : ''}
                        </div>
                      </div>

                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 4</strong>
                        </h2>
                        <h3 className='f5 gray'>Statements: greatest variations</h3>
                        <hr className='' />

                        <div className='bb b--light-gray mv5 page-break'>
                          <p>
                            <strong>Statements which you have under-rated the most</strong>
                          </p>
                          {/* Loop through completedIndividualQuestionsDifferences (sorting in numerical order!) */
                          completedIndividualQuestionsDifferences
                            ? Object.keys(completedIndividualQuestionsDifferences)
                                .sort(function(a, b) {
                                  return completedIndividualQuestionsDifferences[a] - completedIndividualQuestionsDifferences[b];
                                })
                                .map((key, index) => {
                                  // // console.log("completedIndividualQuestionsDifferences")
                                  // // console.log(completedIndividualQuestionsDifferences)
                                  //   // console.log(this.state.questionDetails)
                                  //   // console.log(this.state.questionDetails)
                                  if (parseFloat(completedIndividualQuestionsDifferences[key]) < 0) {
                                    return index < 5 ? (
                                      <div key={key} className='w-100 flex bt b--light-gray'>
                                        <div className='w-40 pr2'>
                                          <p style={{ marginRight: '15px' }}>{this.state.questionDetails[key].questionTitle}</p>
                                        </div>
                                        <div className='w-20'>
                                          <p style={{ color: '#F563B9' }}>
                                            <strong>Reviewers</strong>
                                          </p>
                                          <p className='blue'>
                                            <strong>Self</strong>
                                          </p>
                                        </div>
                                        <div className='w-40'>
                                          <div className='w-100'>
                                            <p className='flex'>
                                              <span className='w-100 bg-light-gray'>
                                                <span
                                                  className='h-100 db'
                                                  style={{ background: '#F563B9', width: `${(mergedAverageOfAnswersByRelationship[key] / 5) * 100}%` }}
                                                ></span>
                                              </span>
                                              <strong className='w-30 tc'>{parseFloat(mergedAverageOfAnswersByRelationship[key]).toFixed(1)}</strong>
                                            </p>
                                          </div>
                                          <div className='w-100'>
                                            <p className='flex'>
                                              <span className='w-100 bg-light-gray'>
                                                <span
                                                  className='bg-blue h-100 db'
                                                  style={{ width: `${(parseInt(this.state.userIndividualAnswers[key]).toFixed(1) / 5) * 100}%` }}
                                                ></span>
                                              </span>
                                              <strong className='w-30 tc'>
                                                {this.state.userIndividualAnswers[key].toFixed(1)}{' '}
                                                {/**
                                                 * HOTFIX - Fudge difference when its 0.55 (it should round down instead of up to 0.6)
                                                 */}
                                                <small>({ (parseFloat(completedIndividualQuestionsDifferences[key]) == -0.55) ? '-0.5' : parseFloat(completedIndividualQuestionsDifferences[key]).toFixed(1) })</small>
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ''
                                    );
                                  } else {
                                    return index === 0 ? (
                                      <div>
                                        <h4>There are no statements where you have under-rated in comparison to the feedback you have received</h4>
                                      </div>
                                    ) : (
                                      ''
                                    );
                                  }
                                })
                            : ''}
                        </div>
                      </div>

                      <div className='bg-white page-break user-report__content'>
                        <h2 className='f2 mv0 mt4'>
                          Part 1: <strong>Step 4</strong>
                        </h2>
                        <h3 className='f5 gray'>Statements: greatest variations</h3>
                        <hr className='' />
                        <div className='mb5 page-break'>
                          <h3 className='f5 gray mt2'>Reflection</h3>
                          <p>What possible reasons can you think of for the variations in the rating on the statements above?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <p>How do the variations above compare with the other information in this report?</p>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                          <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className='title-page page-break'>
                    <div className='w-60'>
                      <h1 className='f3 mv0 mb3 light-blue'>Part 2:</h1>
                      <h2 className='f2 mv0'>Your Detailed Feedback</h2>
                    </div>
                  </div>

                  <div className='bg-white page-break user-report__content'>
                    <h2 className='f2 mv0 mt4'>
                      Part 2: <strong>Step 1</strong>
                    </h2>
                    <h3 className='f5 gray'>Navigating the detail</h3>
                    <hr className='' />
                    <p>
                      This section of the report looks in detail at the feedback that you have received in each competency area. It shows the average feedback
                      rating from each of your rater groups (groups of reviewers, e.g., ‘direct reports’) for every statement (behaviour/action), along with
                      your self-review rating.
                    </p>
                    <p>The statement ratings are also added together to show the headline ratings for each competency.</p>
                    <p>
                      Where you received close or similar ratings for statements from your reviewers, means that you are consistent when displaying that
                      behaviour/action. Conversely, delivering consistently lowly-rated statements (behaviours/actions), can point to areas where you might need
                      to change/improve.
                    </p>
                    <p>
                      Statements where there is a big gap between the ratings provided by different rater groups (e.g., line manager compared to direct
                      reports), suggests a mismatch between their experiences of working with you, or different perceptions. Take some time to consider why this
                      may be the case? Why is one group of people seeing something that the others are not?
                    </p>
                    <p>
                      The number of responses shows the range of ratings that have been provided within each rater group. You can use these numbers to identify
                      where there might be a wide range of ratings from a specific rater group. A wide range of ratings may suggest you have an inconsistent
                      approach within a specific rater group.
                    </p>
                    <p>Finally, you can also use this area of the report to help you identify any potential ‘outlier’ ratings.</p>
                    <h3 className='f5 mt4'>
                      <strong>The bars show the following:</strong>
                    </h3>
                    <p>
                      &bull; The blue bar shows your self-review rating
                      <br />
                      &bull; The remaining bars depict the various rater groups; showing each of their average ratings
                      <br />
                    </p>
                  </div>

                  {/* Loop through each Section and display table of overall and individual Question values */
                  Object.keys(this.state.sectionDetails)
                    .sort((a, b) => {
                      const sectionDetails = this.state.sectionDetails;
                      return this.state.sectionsLegacyMode
                        ? sectionDetails[a].created - sectionDetails[b].created
                        : sectionDetails[a].sortOrder - sectionDetails[b].sortOrder;
                    })
                    .map((key, i) => {
                      let sectionID = key;
                      let sectionNum = i;
                      return (
                        <>
                          {this.state.sectionDetails[sectionID].title == 'Additional' ? (
                            <div className='bg-white page-break user-report__content'>
                              <h2 className='f2 mv0 mt4'>
                                Part 2: <strong>Step {Object.keys(this.state.sectionDetails).length + 1}</strong>
                              </h2>
                              <h3 className='f5 gray'>Your Detailed Feedback</h3>
                              <h2 className='f4 mt4 mb1'>
                                <strong>Self:</strong>
                              </h2>
                              <p>{this.state.userCommentsBySection[sectionID]}</p>

                              <h2 className='f4 mt4 mb1'>
                                <strong>Reviewers:</strong>
                              </h2>
                              {Object.keys(this.state.completedReviewerComments[sectionID]).map((key) => {
                                return this.state.completedReviewerComments[sectionID][key] ? (
                                  <div>
                                    <p>{this.state.completedReviewerComments[sectionID][key]}</p>
                                    <hr className='bb bt-0 b--light-silver mv3' />
                                  </div>
                                ) : (
                                  ''
                                );
                              })}
                            </div>
                          ) : (
                            <>
                              {(this.state.userAnswers[key] * 5).toFixed(1) !== 0 &&
                              this.state.userAnswers[key] !== undefined &&
                              this.state.secLengthNoFreeText[key] > 0 ? (
                                <>
                                  {/* && (this.state.userAnswers[key] * 5).toFixed(1) !== NaN */}
                                  <div key={sectionID} id={sectionID} className='mb4 page-break user-report__content user-report__content--no-pad'>
                                    <div className='average-rating-section'>
                                      <h2 className='f2 mv0 mt4'>
                                        Part 2: <strong>Step {sectionNum + 2}</strong>
                                      </h2>
                                      <h3 className='f5 gray'>Your detailed feedback</h3>

                                      <div className='ba b--moon-gray mb10 table page-break'>
                                        <div className='row flex bg-light-gray'>
                                          <div className='w-30 pt1 pb0'></div>
                                          <div className='w-70'>
                                            <div className='w-100 flex flex-wrap table__row pl1'>
                                              <div className='w-20 pt1 pb0'>
                                                <strong>Relationship</strong>
                                              </div>
                                              <div className='w-50 pt1 pb0'>
                                                <strong>Average Rating</strong>
                                              </div>
                                              <div className='w-30 pt1 pb0'>
                                                <div className='tc'>
                                                  <strong>Number of responses</strong>
                                                </div>
                                                <div className='w-100 flex'>
                                                  <p className='w-100 mv0 tc'>5</p>
                                                  <p className='w-100 mv0 tc'>4</p>
                                                  <p className='w-100 mv0 tc'>3</p>
                                                  <p className='w-100 mv0 tc'>2</p>
                                                  <p className='w-100 mv0 tc'>1</p>
                                                  <p className='w-100 mv0 tc'>?</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='row pt1 pb1 pl2 flex bt bb b--moon-gray'>
                                          <div className='w-30'>
                                            <p className='ma0 f6'>
                                              <strong>{this.state.sectionDetails[key].title}</strong>
                                              {/*<br/><small>{ this.state.sectionDetails[key].description }</small>*/}
                                            </p>
                                          </div>
                                          <div className='w-70'>
                                            <div className='w-100 flex flex-wrap table__row '>
                                              <div className='w-20 mv1'>
                                                <p className='ma0 blue'>
                                                  <strong>Self</strong>
                                                </p>
                                              </div>
                                              <div className='w-50 mv1'>
                                                <div className='w-100'>
                                                  <p className='flex ma0'>
                                                    <strong className='w-10'>{valueCheck(withoutZero[key].toFixed(1))}</strong>
                                                    <span className='w-100 bg-light-gray'>
                                                    <span className='bg-blue h-100 db' style={{ width: `${(percentageCheck(withoutZero[key].toFixed(1)) / 5) * 100}%` }} />
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                              <div className='w-30 pv1'>
                                                <div className='w-100 flex'>
                                                  {Object.keys(this.state.userResponses[sectionID]).map((key) => {
                                                    return this.state.userResponses[sectionID][key].toString() !== 'NaN' ? (
                                                      <p className='w-100 mv0 tc'>
                                                        <strong>{this.state.userResponses[sectionID][key]}</strong>
                                                      </p>
                                                    ) : (
                                                      ''
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                            {/* aaaaa */}
                                            {Object.keys(this.state.sectionAverageByRelationship)
                                              .reverse()
                                              .map((key, index) => {
                                                let originalRelationshipKey = this.state.current360Relationships[key].name;
                                                let relationshipID = key;
                                                let secAverage = parseFloat(this.state.sectionAverageByRelationship[relationshipID][sectionID]).toFixed(1);
                                                return (
                                                  <div key={relationshipID} className='w-100 flex table__row'>
                                                    <div className='w-20 mv1'>
                                                      <p className='ma0' style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}>
                                                        <strong>{this.state.current360Relationships[relationshipID]['name']}</strong>
                                                      </p>
                                                    </div>
                                                    <div className='w-50 mv1'>
                                                      <div className='w-100'>
                                                        <p className='flex ma0'>
                                                          <strong
                                                            className='w-10'
                                                            style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}
                                                          >
                                                            {isNaN(secAverage) == false
                                                              ? this.state.reviewerAnswersCountBySection[relationshipID][sectionID] !== undefined
                                                                ? secAverage
                                                                : 'N/A'
                                                              : 'N/A'}
                                                          </strong>
                                                          <span className='w-100 bg-light-gray'>
                                                            <span
                                                              className='bg-blue h-100 db'
                                                              style={{
                                                                background: this.state.current360Relationships[relationshipID]['colour'],
                                                                width: `${
                                                                  isNaN(secAverage) == false
                                                                    ? this.state.reviewerAnswersCountBySection[relationshipID][sectionID] !== undefined
                                                                      ? (secAverage / 5) * 100
                                                                      : 0
                                                                    : 0
                                                                }%`,
                                                              }}
                                                            ></span>
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className='w-30 mv1'>
                                                      <div className='w-100 flex flex-row-reverse'>
                                                        {Object.keys(this.state.reviewerAnswersCountBySection[relationshipID]).includes(sectionID)
                                                          ? Object.keys(this.state.reviewerAnswersCountBySection[relationshipID][sectionID]).map((val, i) => {
                                                              return val <= 5 ? (
                                                                <p className='w-100 mv0 tc'>
                                                                  {this.state.reviewerAnswersCountBySection[relationshipID][sectionID] !== undefined
                                                                    ? isNaN(this.state.reviewerAnswersCountBySection[relationshipID][sectionID][val]) == false
                                                                      ? this.state.reviewerAnswersCountBySection[relationshipID][sectionID][val]
                                                                      : '0'
                                                                    : '0'}
                                                                </p>
                                                              ) : (
                                                                ''
                                                              );
                                                            })
                                                          : ''}

                                                        {/*Object.keys(this.state.userResponses[sectionID]).map((key, i) => {
                                                        const counts = {};
                                                        const sampleArray = this.state.reviewerResponsesAverage[relationshipID][sectionID];
                                                        // console.log(sampleArray);
                                                        sampleArray.forEach(function(x) {
                                                          counts[x] = (counts[x] || 0) + 1;
                                                        });
                                                        // console.log(counts);
                                                        let count;
                                                        Object.values(counts).map((val, index) => {
                                                          // console.log(val);
                                                          return <p className='w-100 mv0 tc'>{val}</p>;
                                                        });

                                                        return <p className='w-100 mv0 tc'>{count}</p>;
                                                      })*/}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            {/* aaaaaa */}
                                          </div>
                                        </div>

                                        {Object.keys(this.state.sectionDetails[sectionID].questions).map((key, index) => {
                                          let questionID = key;
                                          return index <= 5 && this.state.sectionDetails[sectionID].questions[questionID]['answerType'] != 'FreeText' ? (
                                            <>
                                              <div key={questionID} className='row pt1 pb1 pl2 flex bt b--moon-gray page-break'>
                                                <div className='w-30 pr3 class'>
                                                  <p className='ma0'>{this.state.sectionDetails[sectionID].questions[questionID].questionTitle}</p>
                                                </div>
                                                <div className='w-70 flex flex-wrap'>
                                                  <div className='w-100 flex table__row'>
                                                    <div className='w-20 mv1'>
                                                      <p className='ma0 blue'>
                                                        <strong>Self</strong>
                                                      </p>
                                                    </div>
                                                    <div className='w-50 mv1'>
                                                      <div className='w-100'>
                                                        <p className='flex ma0'>
                                                          <strong className='w-10'>
                                                            {this.state.userIndividualAnswers[questionID].toFixed(1) != 0.0
                                                              ? this.state.userIndividualAnswers[questionID].toFixed(1)
                                                              : 'N/A'}
                                                          </strong>

                                                          <span className='w-100 bg-light-gray'>
                                                            <span
                                                              className='bg-blue h-100 db'
                                                              style={{ width: `${(this.state.userIndividualAnswers[questionID].toFixed(1) / 5) * 100}%` }}
                                                            ></span>
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className='w-30 mv1'>
                                                      <div className='w-100 flex flex-row-reverse'>
                                                        {Object.keys(this.state.userResponses[sectionID]).map((key, i) => {
                                                          return (
                                                            <p className='w-100 mv0 tc'>
                                                              <strong>{this.state.userIndividualAnswers[questionID] == i ? '1' : '0'}</strong>
                                                            </p>
                                                          );
                                                        })}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {Object.keys(this.state.sectionAverageByRelationship)
                                                    .reverse()
                                                    .map((key, index) => {
                                                      let originalRelationshipKey = key;
                                                      let relationshipID = convertRelationshipTextToKey(key);
                                                      let ansAvg;

                                                      if (this.state.questionAverageByRelationship[originalRelationshipKey]) {
                                                        ansAvg =
                                                          parseFloat(
                                                            this.state.questionAverageByRelationship[originalRelationshipKey][questionID]
                                                          ).toString() == 'NaN'
                                                            ? '0.0'
                                                            : this.state.questionAverageByRelationship[originalRelationshipKey][questionID];
                                                      } else {
                                                        ansAvg = '0.0';
                                                      }
                                                      return (
                                                        <div key={relationshipID} className='w-100 flex table__row'>
                                                          <div className='w-20 mv1'>
                                                            <p className='ma0' style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}>
                                                              <strong>
                                                                {this.state.questionAverageByRelationship[originalRelationshipKey] !== undefined
                                                                  ? this.state.current360Relationships[relationshipID]['name']
                                                                  : ''}
                                                              </strong>
                                                            </p>
                                                          </div>
                                                          <div className='w-50 mv1'>
                                                            <div className='w-100'>
                                                              <p className='flex ma0'>
                                                                <strong
                                                                  className='w-10'
                                                                  style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}
                                                                >
                                                                  {ansAvg == 0.0 ? (
                                                                    <>{this.state.questionAverageByRelationship[originalRelationshipKey] ? 'N/A' : ''}</>
                                                                  ) : (
                                                                    <>
                                                                      {this.state.questionAverageByRelationship[originalRelationshipKey]
                                                                        ? this.state.questionAverageByRelationship[originalRelationshipKey][questionID]
                                                                          ? parseFloat(ansAvg).toFixed(1)
                                                                          : '0'
                                                                        : ''}
                                                                    </>
                                                                  )}
                                                                </strong>
                                                                <span className='w-100 bg-light-gray'>
                                                                  <span
                                                                    className='bg-blue h-100 db'
                                                                    style={{
                                                                      background: this.state.current360Relationships[relationshipID]['colour'],
                                                                      width: `${
                                                                        this.state.questionAverageByRelationship[originalRelationshipKey]
                                                                          ? this.state.questionAverageByRelationship[originalRelationshipKey][questionID]
                                                                            ? (parseFloat(ansAvg).toFixed(1) / 5) * 100
                                                                            : 0 * 100
                                                                          : 0 * 100
                                                                      }%`,
                                                                    }}
                                                                  ></span>
                                                                </span>
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div className='w-30 mv1'>
                                                            {this.state.questionAverageByRelationship[originalRelationshipKey] ? (
                                                              this.state.questionAverageByRelationship[originalRelationshipKey][questionID] ? (
                                                                <div className='w-100 flex flex-row-reverse'>
                                                                  {Object.keys(this.state.userResponses[sectionID]).map((key, i) => {
                                                                    let count = 0;
                                                                    Object.keys(
                                                                      this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey]
                                                                    ).map((key, index) => {
                                                                      if (
                                                                        this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey][
                                                                          index
                                                                        ] == i
                                                                      ) {
                                                                        count++;
                                                                      }
                                                                    });

                                                                    if (
                                                                      this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey]
                                                                    ) {
                                                                      return <p className='w-100 mv0 tc'>{count}</p>;
                                                                    } else {
                                                                      return <p className='w-100 mv0 tc'>0</p>;
                                                                    }
                                                                  })}
                                                                </div>
                                                              ) : (
                                                                <div className='w-100 flex flex-row-reverse'>
                                                                  <p className='w-100 mv0 tc'>0</p>
                                                                  <p className='w-100 mv0 tc'>0</p>
                                                                  <p className='w-100 mv0 tc'>0</p>
                                                                  <p className='w-100 mv0 tc'>0</p>
                                                                  <p className='w-100 mv0 tc'>0</p>
                                                                  <p className='w-100 mv0 tc'>0</p>
                                                                </div>
                                                              )
                                                            ) : (
                                                              ''
                                                            )}
                                                          </div>
                                                        </div>
                                                      );
                                                      {
                                                        /* aaaaa */
                                                      }
                                                    })}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ''
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ''
                              )}

                              {/* EXTRA Competencies 6+ */}
                              {Object.keys(this.state.secLengthNoFreeText[key]).length >= 6 ? (
                                <div className='mb4 page-break user-report__content user-report__content--no-pad'>
                                  <div className='page-break average-rating-section'>
                                    <h2 className='f2 mv0 mt4'>
                                      Part 2: <strong>Step {sectionNum + 2}</strong>
                                    </h2>
                                    <h3 className='f5 gray'>Your detailed feedback (continued)</h3>

                                    <div className='ba b--moon-gray mb10 table page-break'>
                                      {Object.keys(this.state.sectionDetails[sectionID].questions).map((key, index) => {
                                        let questionID = key;

                                        return index >= 6 && this.state.sectionDetails[sectionID].questions[questionID]['answerType'] != 'FreeText' ? (
                                          <>
                                            <div key={questionID} className='row pt1 pb1 pl2 flex bt b--moon-gray'>
                                              <div className='w-30 pr3'>
                                                <p className='ma0'>{this.state.sectionDetails[sectionID].questions[questionID].questionTitle}</p>
                                              </div>
                                              <div className='w-70 flex flex-wrap'>
                                                <div className='w-100 flex table__row'>
                                                  <div className='w-20 mv1'>
                                                    <p className='ma0 blue'>
                                                      <strong>Self</strong>
                                                    </p>
                                                  </div>
                                                  <div className='w-50 mv1'>
                                                    <div className='w-100'>
                                                      <p className='flex ma0'>
                                                        <strong className='w-10'>{this.state.userIndividualAnswers[questionID].toFixed(1)}</strong>

                                                        <span className='w-100 bg-light-gray'>
                                                          <span
                                                            className='bg-blue h-100 db'
                                                            style={{ width: `${(this.state.userIndividualAnswers[questionID].toFixed(1) / 5) * 100}%` }}
                                                          ></span>
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className='w-30 mv1'>
                                                    <div className='w-100 flex flex-row-reverse'>
                                                      {Object.keys(this.state.userResponses[sectionID]).map((key, i) => {
                                                        return (
                                                          <p className='w-100 mv0 tc'>
                                                            <strong>{this.state.userIndividualAnswers[questionID] == i ? '1' : '0'}</strong>
                                                          </p>
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                </div>

                                                {Object.keys(this.state.completedReviewerAnswersByRelationship[questionID]).map((key, index) => {
                                                  let originalRelationshipKey = key;
                                                  let relationshipID = convertRelationshipTextToKey(key);
                                                  return (
                                                    <div key={relationshipID} className='w-100 flex table__row'>
                                                      <div className='w-20 mv1'>
                                                        <p className='ma0' style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}>
                                                          <strong>{this.state.current360Relationships[relationshipID]['name']}</strong>
                                                        </p>
                                                      </div>
                                                      <div className='w-50 mv1'>
                                                        <div className='w-100'>
                                                          <p className='flex ma0'>
                                                            <strong
                                                              className='w-10'
                                                              style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}
                                                            >
                                                              {average(
                                                                this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey]
                                                              ).toFixed(1)}
                                                            </strong>
                                                            <span className='w-100 bg-light-gray'>
                                                              <span
                                                                className='bg-blue h-100 db'
                                                                style={{
                                                                  background: this.state.current360Relationships[relationshipID]['colour'],
                                                                  width: `${(average(
                                                                    this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey]
                                                                  ).toFixed(1) /
                                                                    5) *
                                                                    100}%`,
                                                                }}
                                                              ></span>
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className='w-30 mv1'>
                                                        <div className='w-100 flex flex-row-reverse'>
                                                          {Object.keys(this.state.userResponses[sectionID]).map((key, i) => {
                                                            let count = 0;
                                                            Object.keys(
                                                              this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey]
                                                            ).map((key, index) => {
                                                              if (
                                                                this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey][index] ==
                                                                i
                                                              ) {
                                                                count++;
                                                              }
                                                            });

                                                            return <p className='w-100 mv0 tc'>{count}</p>;
                                                          })}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          ''
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}

                              {this.state.completedReviewerCommentsBySection[sectionID]
                                ? Object.keys(this.state.completedReviewerCommentsBySection[sectionID]).map((questionKey, i) => {
                                    return (
                                      <>
                                        <div className='mb2 average-rating-section-reflection mt4 page-break pt4 mt4'>
                                          {this.state.userAnswers[key] === undefined ? (
                                            i === 0 ? (
                                              <>
                                                <h2 className='f2 mv0 mt4'>
                                                  Part 2: <strong>Step {sectionNum + 2}</strong>
                                                </h2>
                                                <hr />
                                              </>
                                            ) : (
                                              <>
                                                <h2 className='f2 mv0 mt4'>
                                                  Part 2: <strong>Step {sectionNum + 2}</strong>
                                                </h2>
                                                <h3 className='f5 gray'>Your detailed feedback (continued)</h3>
                                              </>
                                            )
                                          ) : (
                                            ''
                                          )}
                                          <h4 className='pt4 f5'>
                                            <strong>{this.state.completedReviewerCommentsBySection[sectionID][questionKey]['title']}</strong>
                                          </h4>
                                        </div>
                                        {this.state.userCommentsBySection[sectionID] &&
                                        this.state.userCommentsBySection[sectionID][questionKey] !== undefined &&
                                        this.state.userCommentsBySection[sectionID][questionKey]['comment'] ? (
                                          <div className='average-rating-section-reflection'>
                                            <p>
                                              <strong>Self:</strong>
                                            </p>
                                            <p>{this.state.userCommentsBySection[sectionID][questionKey]['comment']}</p>
                                          </div>
                                        ) : (
                                          ''
                                        )}

                                        {Object.keys(this.state.completedReviewerCommentsBySection[sectionID][questionKey]['commentsByRelationship']).map(
                                          (relationshipName, o) => {
                                            return (
                                              <div className='average-rating-section-reflection' style={{ paddingTop: '4mm', paddingBottom: '0' }}>
                                                <p className={o > 0 ? 'mb0 pt4' : 'mb0'}>
                                                  <strong>{this.state.current360Relationships[relationshipName].name} Comments:</strong>
                                                </p>
                                                {Object.keys(
                                                  this.state.completedReviewerCommentsBySection[sectionID][questionKey]['commentsByRelationship'][
                                                    relationshipName
                                                  ]
                                                ).map((key) => {
                                                  return (
                                                    <p>
                                                      {
                                                        this.state.completedReviewerCommentsBySection[sectionID][questionKey]['commentsByRelationship'][
                                                          relationshipName
                                                        ][key]
                                                      }
                                                    </p>
                                                  );
                                                })}
                                              </div>
                                            );

                                            // return (
                                            //   <div className="average-rating-section-reflection" style={{paddingTop: '0', paddingBottom: '5mm'}}>
                                            //   <p>{ this.state.completedReviewerCommentsBySection[sectionID][questionKey]['comments'][key] }</p>
                                            //   </div>
                                            // )
                                          }
                                        )}
                                      </>
                                    );
                                  })
                                : ''}

                              <div className='mb5 page-break average-rating-section-reflection'>
                                <h2 className='f2 mv0 pt4'>
                                  Part 2: <strong>Step {sectionNum + 2}</strong>
                                </h2>
                                <h3 className='f5 gray'>Your detailed feedback</h3>
                                <hr className='' />
                                <h3 className='f4 mb1 pt3 mt1'>
                                  <strong>Reflection</strong>
                                </h3>
                                <h4 className='gray mb1'>What are the key themes or messages from the feedback in this section?</h4>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                                <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}

                  <div className='title-page page-break'>
                    <div className='w-60'>
                      <h1 className='f3 mv0 mb3 light-blue'>Part 3:</h1>
                      <h2 className='f2 mv0'>Handling Feedback</h2>
                    </div>
                  </div>

                  <div className='bg-white user-report__content'>
                    <h2 className='f2 mv0 mt4'>
                      Part 3: <strong>Handling Feedback</strong>
                    </h2>
                    <h3 className='f5 gray'>Responding to feedback</h3>
                    <hr className='' />

                    <h3 className='f5 mt4'>
                      <strong>Take some time</strong>
                    </h3>
                    <p>
                      Receiving feedback can be challenging. Even if the input is praise, you may have difficulty in knowing how to respond to it, or you may
                      feel embarrassed by it. When the feedback is constructive, emotional and psychological reactions can be complicated and can get in the way
                      of seeing where the real value lies in the information. Take some time to reflect and absorb the feedback before you consider your
                      response.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Receiving praise</strong>
                    </h3>
                    <p>Receiving praise is not as easy as it may seem.</p>
                    <p>
                      When people are given a compliment, whether professionally or personally, there are several ways they may react that reduces the impact of
                      the praise:
                    </p>
                    <p>
                      &bull; Devaluation: "It’s just me doing my job."
                      <br />
                      &bull; Distrust: "People are just saying that; they are just being nice."
                      <br />
                      &bull; Deflection: "My team does all the hard work for me."
                    </p>
                    <p>
                      All of these are common responses, but it is crucial to understand why you may use them. If these are normal reactions for you, consider
                      why you feel the need to use them.
                    </p>
                    <p>
                      Sometimes, self-critical people can find it awkward to accept praise, as it does not fit with their view of themselves. In some cases,
                      they set their personal bar too high for themselves, or anyone else, to reach realistically. If this sounds like you, make it a
                      developmental target to try to persuade yourself that perfection is not always necessary, to perform well in your job and your life.
                    </p>
                    <p>
                      Accepting a genuine compliment and positive feedback gracefully can be both modest and assertive. The best way to take positive feedback
                      is to thank the provider, genuinely and happily. They gave you the feedback for a reason, and they will most likely be happy to see it has
                      the desired effect.
                    </p>
                  </div>
                  <div className='page-break bg-white user-report__content'>
                    <h3 className='f5 mt4'>
                      <strong>Be open-minded</strong>
                    </h3>
                    <p>
                      Open your mind up to the potential for change. Remember that constructive feedback is not about you as a person, but about job
                      behaviours/actions. Because you have not come up to a necessary standard in some way, does not make you a bad person or a failure—it
                      certainly does not mean the person offering the feedback is suggesting you are. It merely means you have the scope to learn and improve.
                      The person providing feedback should be focused on helping you to do this, so try to see the input from that perspective.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Contextualise, analyse and confirm</strong>
                    </h3>
                    <p>
                      Remember that you have the right to analyse and then confirm the feedback you received for accuracy. Consider the context in which the
                      feedback was provided, to help support your thinking.
                    </p>
                    <p>
                      If you are uncertain about some of the feedback you have received, or feel unsure about how to respond to the feedback positively; talk to
                      a trusted colleague, manager, coach or colleague within HR/Learning. Book some time to sit down and discuss your concerns and ask them for
                      help interpreting the feedback. If needed, they can help you to build some effective developmental solutions in response to the feedback.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Move forward</strong>
                    </h3>
                    <p>
                      With any feedback, the critical response is to consider what you can do to improve in future. Can the people giving you feedback help?
                      Don’t be afraid to ask them for suggestions and assistance in making the necessary changes. All being well, they will be happy to do this.
                      Work with them to develop a plan of action.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Build your developmental plan</strong>
                    </h3>
                    <p>
                      All your feedback is intended to help you learn, develop and grow. Take the time to consider how you can use the feedback to help shape
                      your future development. What is your report telling you about your opportunities to develop? Reflect on how you can put this feedback
                      into action, by fleshing out your development plan with practical and positive actions and activities.
                    </p>
                  </div>
                  <div className='page-break bg-white user-report__content'>
                    <h2 className='f2 mv0'>
                      Part 3: <strong>Handling Feedback</strong>
                    </h2>
                    <h3 className='f5 gray'>Handling critical feedback</h3>
                    <hr className='' />

                    <p>
                      Receiving critical feedback about yourself from other people can sometimes result in strong emotional reactions, particularly where your
                      sense of who you are, or your self-concept, has been challenged.
                    </p>
                    <p>This is quite normal and may lead to some of the following emotions and behaviours being exhibited:</p>

                    <h3 className='f5 mt4'>
                      <strong>Shock</strong>
                    </h3>
                    <p>
                      When receiving feedback that people do not recognise as being part of who they are, they may consciously or unconsciously demonstrate
                      disbelief and a sense of shock. Typically, this is often followed by denial and a refusal to accept the facts or feedback. This is a
                      defence mechanism and is perfectly natural, but it can take an individual some time to overcome it.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Annoyance</strong>
                    </h3>
                    <p>
                      Annoyance manifests itself in various ways. People may be upset with themselves, but more often, it is directed at external parties,
                      including the people who provided the feedback or the person delivering the message.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Refusal</strong>
                    </h3>
                    <p>
                      In the refusal stage people may begin to be less annoyed, but still resist the message by challenging the validity of the process, or by
                      starting to make excuses.
                    </p>
                    <p>
                      Following this stage, people may appear to have accepted the message, by declaring that 'I understand what you are saying', but without
                      really meaning it. This may, in turn, lead to objections being raised further down the line, with the key stakeholders in the feedback
                      process.
                    </p>

                    <h3 className='f5 mt4'>
                      <strong>Acceptance</strong>
                    </h3>
                    <p>
                      Having had time to evaluate the information objectively and to come to terms with what people have said, acceptance usually occurs. This
                      stage represents an excellent opportunity for an individual to get some coaching or training to enhance their strengths or to address some
                      areas of development.
                    </p>
                  </div>

                  <div className='title-page page-break'>
                    <div className='w-60'>
                      <h1 className='f3 mv0 mb3 light-blue'>Part 4:</h1>
                      <h2 className='f2 mv0'>Your Development Plan</h2>
                    </div>
                  </div>

                  <div className='bg-white user-report__content'>
                    <h2 className='f2 mv0 mt4'>Your Development Plan</h2>
                    <h3 className='f5 gray'>Guide</h3>
                    <hr className='' />

                    <p>You need to create a development plan, with specific goals, to get the most out of your feedback.</p>
                    <p>There are three stages to creating an effective development plan:</p>
                    <p>
                      &bull; Reviewing your 360 feedback
                      <br />
                      &bull; Creating some SMART goals
                      <br />
                      &bull; Reviewing and tracking your progress
                    </p>
                    <p>If you follow these stages, particularly the regular review of your progress, you will get the most value from your feedback.</p>
                  </div>

                  <div className='page-break bg-white user-report__content'>
                    <h2 className='f2 mv0'>
                      Part 4: <strong>Step 1</strong>
                    </h2>
                    <h3 className='f5 gray'>Review your feedback</h3>
                    <hr className='' />

                    <p>Go back through the notes, reflections and observations that you have made throughout your report.</p>
                    <p>
                      Use these to build a summary of the key messages that stand out from the feedback. This summary will help you to focus your thinking when
                      it comes to creating some relevant goals.
                    </p>

                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                  </div>

                  <div className='page-break bg-white user-report__content'>
                    <h2 className='f2 mv0'>
                      Part 4: <strong>Step 2</strong>
                    </h2>
                    <h3 className='f5 gray'>My goals</h3>
                    <hr className='' />

                    <p>
                      Use your summary findings to guide you through setting a small number of developmental goals that are stretching but achievable. The
                      statements in the report can form a sound basis for your goals. We recommend that you put some dates into your diary, to review your
                      progress on your developmental goals.
                    </p>
                  </div>

                  <div className='page-break bg-white user-report__content'>
                    <h2 className='f2 mv0'>
                      Part 4: <strong>Step 2</strong>
                    </h2>
                    <h3 className='f5 gray'>Goal 1</h3>
                    <hr className='' />

                    <p>My goal is:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>Specific actions needed to deliver my goal:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>I will measure my success by:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>The support I need:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>When will I review my progress?</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>My deadline is:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                  </div>

                  <div className='page-break bg-white user-report__content'>
                    <h2 className='f2 mv0'>
                      Part 4: <strong>Step 2</strong>
                    </h2>
                    <h3 className='f5 gray'>Goal 2</h3>
                    <hr className='' />

                    <p>My goal is:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>Specific actions needed to deliver my goal:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>I will measure my success by:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>The support I need:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>When will I review my progress?</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>My deadline is:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                  </div>

                  <div className='page-break bg-white user-report__content'>
                    <h2 className='f2 mv0'>
                      Part 4: <strong>Step 2</strong>
                    </h2>
                    <h3 className='f5 gray'>Goal 3</h3>
                    <hr className='' />

                    <p>My goal is:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>Specific actions needed to deliver my goal:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>I will measure my success by:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>The support I need:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>When will I review my progress?</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>

                    <p>My deadline is:</p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>
                  </div>

                  <header className='page-break user-report__header'>
                    <img crossorigin='anonymous' src={this.props.current360.settings.logo} class='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>Good Luck</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Own the change you want to see! </strong>
                      </p>
                    </div>
                  </header>
                </div>
              </PDFExport>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <canvas ref={this.hiddenCanvas}></canvas>
          {this.state.error ? (
            <div className='error-404 bg-blue fixed left-0 top-0'>
              <h1 className='white mt4'>System Error - Error loading report</h1>
            </div>
          ) : (
            <LoadingState />
          )}
        </div>
      );
    }
  }
}

export default withAuthorisation(authCondition)(User360Report);
// export default User360Report
