import React, { Component } from 'react';
import AuthUserContext from '../components/authusercontext';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default class EmailConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: this.props.accountId,
      current360Id: this.props.current360Id,
      currentGroupId: this.props.current360Group,
      currentGroupUsers: this.props.currentGroupUsers,
      currentGroupUserReviewers: this.props.currentGroupUserReviewers,
      current360: this.props.current360,
      mailStatus: this.props.loadedSend,
    };
  }

  static contextType = AuthUserContext;

  checkEmails(e) {
    e.preventDefault();
    const reqBody = this.state;

    axios
      .post('/checkEmails', {
        reqBody,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data == 'an error') {
          this.setState({
            mailStatus: 'error',
          });
        } else {
          this.setState({
            mailStatus: [res.data.errors],
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const determineContent = () => {
      if (this.state.mailStatus.length > 0) {
        if (this.state.mailStatus[0].length == 0) {
          return (
            <span>
              The status of the emails is not ready to display
              <br />
              Please wait 30 seconds and try again
            </span>
          );
        } else if (this.state.mailStatus == 'error') {
          return <p className='tc'>There has been an error, please contact try again. Should the issue persist, please contact technical support</p>;
        } else {
          return (
            <div className='flex flex-wrap w100 bg--pale'>
              <div className='flex w-100 items-center'>
                <div className='w-20 pa3 mr2 tc'>
                  <h4 className=''>Email Event </h4>
                </div>
                <div className='bl tc b--moon-gray w-30 pa3 mr2'>
                  <h4 className=''>Recipient</h4>
                </div>
                <div className='bl tc b--moon-gray  w-30 pa3 mr2'>
                  <h4 className=' '>Resolution/Errors</h4>
                </div>
                <div className='bl tc b--moon-gray w-20 pa3 mr2'>
                  <h4 className=' '>When</h4>
                </div>
              </div>
              <div className='overflow-y-scroll w-100 bg--pale'>
                {this.state.mailStatus[0].map((key, i) => {
                  return key.Type == 'injection' ? (
                    <div index={i} className='flex w-100 items-center' key={key.ID}>
                      <div className='w-20 tc pa3 mr2'>
                        <strong>
                          <p className=' '>Email Generated : </p>
                        </strong>
                      </div>
                      <div className='bl tc b--moon-gray w-30 pa3 mr2'>
                        <strong>
                          <p className=''>{key.To}</p>
                        </strong>
                      </div>
                      <div className='bl tc b--moon-gray w-30 pa3 mr2'>
                        <strong>
                          <p className=''>{key.Because}</p>
                        </strong>
                      </div>
                      <div className='bl tc b--moon-gray w-20 pa3 mr2'>
                        <strong>
                          <p className=''>{new Date(key.When).toUTCString()}</p>
                        </strong>
                      </div>
                    </div>
                  ) : (
                    <div index={i} className='flex w-100 items-center' key={key.ID}>
                      <div className='w-20 pa3 mr2 tc'>
                        <strong>
                          <p className=''>{key.Type} : </p>
                        </strong>
                      </div>
                      <div className='bl tc b--moon-gray w-30 pa3 mr2'>
                        <strong>
                          <p className=''>{key.To}</p>
                        </strong>
                      </div>
                      <div className='bl tc b--moon-gray w-30 pa3 mr2'>
                        <strong>
                          <p className=' '>{key.Because}</p>
                        </strong>
                      </div>
                      <div className='bl tc b--moon-gray w-20 pa3 mr2'>
                        <strong>
                          <p className=' '>{new Date(key.When).toUTCString()}</p>
                        </strong>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
      } else {
        return <p className='center tc'>No Emails have been sent yet</p>;
      }
    };

    const reveal = determineContent();

    return (
      <div className=''>
        {reveal}
        <div className='mt3 mb3 flex justify-between'>
          <Link to={{ pathname: './' }} className='btn btn--back'>
            {' '}
            Return to edit group page
          </Link>
        </div>
      </div>
    );
  }
}
