import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

class Question extends Component {
  constructor(props) {
    super(props)
  }

  radioButton(radioNum, radioLabel, labelID) {
    return (
      <label key={labelID} className="relative w-100-l flex items-center justify-center-l mv2 bl-l b--light-silver">
        <input
          disabled={true}
          name={this.props.index}
          type="radio"
          className="absolute z-5 w-100 h-100 o-0 pointerinput-reset pointer radiobutton"
        />
        <span className="relative dib w1 h1 bg-light-gray overflow-hidden br-100 v-mid bg-animate bg-center radiobutton-wrapper">
          <div className="absolute top-0 left-0 w1 h1 ba bw2 b--transparent br-100"></div>
        </span>
        <div className="dib dn-l ml2 lh-solid">
          {radioNum} {radioLabel}
        </div>
      </label>
    );
  }

  render() {
    const { scaleLabels, reverseOrderedLabelIDs, question, answer, questionActive, questionnaireType } = this.props;
    if (question.answerType === "MultiChoice") {
      return (
        <div className="flex-l justify-between items-center bt b--light-silver pv3">
          <div className="w-40-l">
            <p className="reset-mar">
              <strong>{question.questionTitle}</strong>
            </p>
          </div>
          <div className="w-60-l flex-l justify-around items-center ml2">
            {reverseOrderedLabelIDs.map((labelID, i) => {
              const {score, text, frequency, sortOrder} = scaleLabels[labelID]
              const labelContent = `${text}${sortOrder > 0 ? ` (${frequency})` : ''}`;
              return this.radioButton(score, labelContent, labelID);
            })}
          </div>
        </div>
      );
    }
    if (question.answerType === "FreeText") {
      return (
        <div className="bt b--light-silver pv3">
          <p>
            <strong>{question.questionTitle}</strong>
          </p>
          {/*<a className="f6 link ph4 pv3 dib white bg-blue br2 w-100 br--top" href="#0">Click here to record an audio response</a>*/}
          <textarea
            disabled={!questionActive}
            required={question.required ? 'required' : ''}
            defaultValue={answer}
            rows="6"
            placeholder={questionnaireType === "Goal Tracker" ? "..." : "Type your response here"}
          ></textarea>
        </div>
      );
    }
    if (question.answerType === "Date") {
      return (
        <div className="bt b--light-silver">
          <p className="f6 pt3">{question.questionTitle}:</p>
          <DatePicker
            className="mt2 mb5 pa2 bg-light-gray input-reset bn outline-0 w-100"
            dateFormat="do MMM, yyyy"
            placeholderText="Select a date"
            selected={answer ? answer.toDate() : null}
            disabled={!questionActive}
          />
        </div>
      )
    }
  }
}

export default Question;
