import React, { Component } from 'react';

export default class RowTitle extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    return (
      <div className={this.props.containerClass}>
        {/* competency or statement title */}
        <p style={{ marginRight: '15px' }} className={this.props.pClass}>{this.props.title}</p>
      </div>
    );
  }
}
