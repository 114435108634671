import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { Link } from 'react-router-dom';
import { auth, db, storage } from '../../../../firebase';
import SiteFooterBrand from '../../../../components/site-footer-brand';
import SiteHeader from '../../../../components/site-header';
import SiteNav from '../../../../components/site-nav';
import FileUploader from 'react-firebase-file-uploader';
import { BlockPicker, ChromePicker, TwitterPicker } from 'react-color';
import Modal from '../../../../components/global/modal';
import withAuthorisation from '../../../../components/withauthorisation';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { breadcrumbSelect } from '../../../../helpers';
const authCondition = (authUser) => !!authUser;
//
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class Edit360Brand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current360: this.props.current360,
      accountData: this.props.accountData,
      logo: this.props.current360.settings.logo,
      primarycolour: this.props.current360.settings.primarycolour,
      secondarycolour: this.props.current360.settings.secondarycolour,
      buttoncolour: this.props.current360.settings.buttoncolour,
      isUploading: false,
      saving: false,
      error: null,
    };

    // // console.log(this.props.current360);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    // console.log(this.secondarycolour.value);

    this.setState({
      saving: true,
    });

    let brandingData = {
      settings: {
        logo: this.logo.value,
        primarycolour: this.primarycolour.value,
        secondarycolour: this.secondarycolour.value,
        buttoncolour: this.buttoncolour.value,
      },
    };
    // console.log(brandingData);

    this.props.current360.settings.logo = this.logo.value;
    this.props.current360.settings.primarycolour = this.primarycolour.value;
    this.props.current360.settings.secondarycolour = this.secondarycolour.value;
    this.props.current360.settings.buttoncolour = this.buttoncolour.value;

    db.doUpdate360Branding(this.props.match.params.accountId, this.props.match.params.id, brandingData).then((data) => {
      setTimeout(
        function() {
          this.setState({
            saving: false,
          });
        }.bind(this),
        500
      );
    });

    event.preventDefault();
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (file) => {
    file.then((filename) => {
      this.setState({ logoimg: filename, progress: 100, isUploading: false });
      storage.doGetUploadedImageURL(filename).then((url) => {
        this.setState({ logo: url });
      });
    });

    // this.setState({ logoimg: filename, progress: 100, isUploading: false });
    // storage.doGetUploadedImageURL(filename).then((url) => {
    //   this.setState({ logo: url })
    // })
  };

  render() {
    const { logo, primarycolour, secondarycolour, buttoncolour, error } = this.state;

    const isInvalid = logo === '';

    const pickerStyles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        primarycolorinput: {
          backgroundColor: primarycolour,
          color: primarycolour == '#FFFFFF' ? '#333' : '#FFF',
        },
        secondarycolorinput: {
          backgroundColor: secondarycolour,
          color: secondarycolour == '#FFFFFF' ? '#333' : '#FFF',
        },
        buttoncolorinput: {
          backgroundColor: buttoncolour,
          color: buttoncolour == '#FFFFFF' ? '#333' : '#FFF',
        },
      },
    });

    return (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={this.state.current360}
            userDetails={this.state.userDetails}
            accountTitle={this.state.accountTitle}
            accountData={this.props.accountData}
            current360Groups={this.props.current360Groups}
            current360Relationships={this.props.current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${this.props.match.params.accountId}`,
              },
              {
                title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                  this.props.current360.type
                } Projects`,
                to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(this.props.current360.type)}-projects/`,
              },
              {
                title: this.state.current360.title,
                to: '../../',
              },
              {
                title: 'Advanced Settings',
                to: '../',
              },
              {
                title: 'Brand',
                to: null,
              },
            ]}
          />

          <h1>
            {this.state.current360.title}: Brand Your {this.props.current360.type}
          </h1>

          <form className='bg-white mv4 ph4 pv4 shadow br2' onSubmit={this.handleSubmit}>
            <div className='flex'>
              <div className='w-50'>
                <p>
                  <strong>You may brand your {this.props.current360.type} by setting a logo and changing the colour scheme</strong>
                </p>
              </div>
            </div>

            <div className='w-100 mv3'>
              <label htmlFor='logo' className='f6 b db'>
                Client Logo
              </label>
              <p className='mb2 mt0'>
                <small>* Images should be uploaded at 400px x 112px. Images are cropped (not scaled) down.</small>
              </p>
              <input
                ref={(input) => (this.logo = input)}
                aria-describedby='email-desc'
                className='input-reset ba b--black-20 pa2 mb2 db w-100'
                value={logo}
                type='hidden'
              />

              <div className='flex items-center'>
                {logo && <img width='140' className='dib' src={logo} />}

                <div className='dib ml5'>
                  <FileUploader
                    accept='image/*'
                    name='logoimg'
                    randomizeFilename
                    storageRef={storage.doGetImageRef()}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                  />
                </div>
              </div>
            </div>

            <div className='w-80 mb3 flex flex-wrap justify-start'>
              <div className='w-40 pr3 dn'>
                <label htmlFor='primarycolour' className='f6 b db mb2'>
                  Header Colour
                </label>
                <input
                  ref={(input) => (this.primarycolour = input)}
                  aria-describedby='primarycolour-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  value={primarycolour}
                  onChange={(e) => {
                    return;
                  }}
                  onClick={(e) => {
                    this.setState({ displayPrimaryColourPicker: true });
                  }}
                  type='text'
                />
                {this.state.displayPrimaryColourPicker ? (
                  <div style={pickerStyles.popover}>
                    <div
                      style={pickerStyles.cover}
                      onClick={(e) => {
                        this.setState({ displayPrimaryColourPicker: false });
                      }}
                    />
                    <ChromePicker color={primarycolour} onChange={(color) => this.setState(byPropKey('primarycolour', color.hex))} />
                  </div>
                ) : null}
              </div>

              <div className='w-40 pr3 dn'>
                <label htmlFor='secondarycolour' className='f6 b db mb2'>
                  Header Text Colour
                </label>
                <input
                  ref={(input) => (this.secondarycolour = input)}
                  aria-describedby='secondarycolour-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  value={secondarycolour}
                  onChange={(e) => {
                    return;
                  }}
                  onClick={(e) => {
                    this.setState({ displaySecondaryColourPicker: true });
                  }}
                  type='text'
                />
                {this.state.displaySecondaryColourPicker ? (
                  <div style={pickerStyles.popover}>
                    <div
                      style={pickerStyles.cover}
                      onClick={(e) => {
                        this.setState({ displaySecondaryColourPicker: false });
                      }}
                    />
                    <ChromePicker color={secondarycolour} onChange={(color) => this.setState(byPropKey('secondarycolour', color.hex))} />
                  </div>
                ) : null}
              </div>

              <div className='w-40 pr3 d-none'>
                <label htmlFor='buttoncolour' className='f6 b db mb2'>
                  Highlight Colour
                  <br />
                  <small>Used for button styling.</small>
                </label>
                <input
                  ref={(input) => (this.buttoncolour = input)}
                  aria-describedby='buttoncolour-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  value={buttoncolour}
                  onChange={(e) => {
                    return;
                  }}
                  onClick={(e) => {
                    this.setState({ displayButtonColourPicker: true });
                  }}
                  type='text'
                />
                {this.state.displayButtonColourPicker ? (
                  <div style={pickerStyles.popover}>
                    <div
                      style={pickerStyles.cover}
                      onClick={(e) => {
                        this.setState({ displayButtonColourPicker: false });
                      }}
                    />
                    <TwitterPicker
                      colors={['#FE5D00', '#FE1300', '#034EFF', '#1FA81F', '#7223B1', '#333E5B', '#000000', '#E700C1']}
                      color={buttoncolour}
                      onChange={(color) => this.setState(byPropKey('buttoncolour', color.hex))}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className='w-100 mv2'>
              <button disabled={isInvalid} type='submit' className={this.state.saving ? 'btn btn--disabled' : 'btn'}>
                Save Branding
              </button>
              <span className={this.state.saving ? 'loading-spinner' : !this.state.firstInstance ? 'loading-done' : ''} />
              {error && <h3 className='red mt4 mb0'>{error.message}</h3>}
            </div>
          </form>

          <Link to={{ pathname: '../', current360: this.props.current360, accountData: this.props.accountData }} className='btn btn--back'>
            Return to Advanced Settings
          </Link>
        </section>
      </div>
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360Brand);
