import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from './signoutbutton';
import AuthUserContext from './authusercontext';
import { IconCog, IconPenLine, IconPhone, IconComputer, IconHome, IconGoals, Icon360s, IconTeam } from './../svg/svgs';

class SiteNav extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header className='site-header site-header--nav'>
        <div className='container'>
          <nav className='site-header__row'>
            <Link className='site-header__nav-btn' to={`/dashboard/${this.props.match.params.accountId}`}>
              <IconHome width='20' height='20' /> <span>Dashboard</span>
            </Link>
            <Link className='site-header__nav-btn' to={`/dashboard/${this.props.match.params.accountId}/360-projects/`}>
              <Icon360s width='20' height='20' /> <span>360's</span>
            </Link>
            <Link className='site-header__nav-btn' to={`/dashboard/${this.props.match.params.accountId}/goal-tracker-projects/`}>
              <IconGoals width='20' height='20' /> <span>Goal trackers</span>
            </Link>
            <Link className='site-header__nav-btn' to={`/dashboard/${this.props.match.params.accountId}/team-assessment-projects/`}>
              <IconTeam width='20' height='20' /> <span>Team Assessment</span>
            </Link>
            <Link className='site-header__nav-btn' to={`/dashboard/${this.props.match.params.accountId}/account/`}>
              <IconComputer width='20' height='20' /> <span>Account</span> <div className='site-header__num-credits'>{this.props.accountData.credits}</div>
            </Link>
            <Link className='site-header__nav-btn' to={`/contact/${this.props.match.params.accountId}/`}>
              <IconPhone width='20' height='20' /> <span>Contact/Help</span>
            </Link>
            {!this.props.removeLogoutButton ? <SignOutButton /> : ''}
          </nav>
        </div>
      </header>
    );
  }
}

export default SiteNav;
