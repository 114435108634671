import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { db } from '../../firebase';
import { PDFExport } from '@progress/kendo-react-pdf';
import canvg from 'canvg';
import Breadcrumbs from '../../components/breadcrumbs';
import { Link } from 'react-router-dom';
import { average, relationshipColor, convertRelationships, difference } from '../../helpers';
import RadarChart from '../../components/charts/radarchart';
import SiteHeader from '../../components/site-header';
import LoadingState from '../../components/global/loading-state';
import GoalTrackerReportPageTemplate from './goalTrackerReportPageTemplate';
import moment from 'moment';
import withAuthorisation from '../../components/withauthorisation';
const authCondition = (authUser) => !!authUser;
//

// const captions = {}

class UserGoalReport extends Component {
  constructor() {
    super();
    this.exportPDF = this.exportPDF.bind(this);
    this.convertSVGToImage = this.convertSVGToImage.bind(this);
    this.canvLoaded = false;
    this.hiddenCanvas = React.createRef();

    this.state = {
      loading: true,
      captions: {},
      userData: {},
      userId: null,
      groupId: null,
      current360Id: null,
    };
  }
  exportPDF = () => {
    this.report.save();
  };
  convertSVGToImage = (htmlString) => {
    // Take an SVG html string, convert it to static markup, then use canvg to render into hidden canvas, then return dataURL string
    let staticRadarChart = ReactDOMServer.renderToStaticMarkup(htmlString);
    let canv = this.hiddenCanvas.current;
    canv.getContext('2d');

    canvg(canv, staticRadarChart);
    return canv.toDataURL('image/png');
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      accountId: params.accountId,
      userId: params.userId,
      groupId: params.groupId,
      current360Id: params.id,
    });

    db.doGetCurrentGroup(this.props.match.params.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
      if (snapshot.data()) {
        this.setState({
          currentGroup: snapshot.data(),
        });

        db.getUserFromGroup(params.accountId, params.userId).onSnapshot((snapshot) => {
          let userFullName = `${snapshot.data()['forename']} ${snapshot.data()['surname']}`;
          let reportPdfFileName = `${snapshot.data()['forename']}-${snapshot.data()['surname']}-goal-tracker-report.pdf`;

          this.setState({
            userFullName: userFullName,
            reportPdfFileName: reportPdfFileName,
          });

          db.doGetCurrent360(params.accountId, params.id).then((snapshot) => {
            let questionnaireID = snapshot.data()['questionnaireID'];
            let current360Title = snapshot.data()['title'];

            this.setState({
              current360Title: current360Title,
            });

            // db.doGetSectionsList(questionnaireID).then((snapshot) => {
            db.doGetSectionsListOrdered(this.state.accountId, questionnaireID).onSnapshot((snapshot) => {
              if (snapshot.empty) {
                // console.log(this.state.accountId);
                db.doGetSectionsList(this.state.accountId, questionnaireID).onSnapshot((snapshot) => {
                  if (!snapshot.empty) {
                    this.getSectionQuestions(snapshot, questionnaireID);
                  }
                });
              } else {
                this.getSectionQuestions(snapshot, questionnaireID);
              }
            });
          });
        });
        // }).catch((data) => {
        //   // console.log('error');
        // })
      }
    });
  }

  getSectionQuestions(snapshot, questionnaireID) {
    // console.log('getSectionQuestions');
    let sections = {};
    let numSections = snapshot.docs.length;
    let userAnswers = {};

    snapshot.docs.map((doc, i) => {
      let sectionID = doc.id;
      sections[doc.id] = doc.data();

      db.doGetSectionQuestions(this.state.accountId, questionnaireID, sectionID).onSnapshot((snapshot) => {
        let questions = {};
        if (!snapshot.empty) {
          sections[sectionID]['numQuestions'] = snapshot.docs.length;
          // sections[sectionID]['questions'] = {}
          snapshot.docs.map((doc, i) => {
            questions[doc.id] = doc.data();
          });
        }

        sections[sectionID]['questions'] = questions;

        db.doGetGoalAnswers(
          this.state.accountId,
          this.state.userId,
          this.state.current360Id,
          this.state.groupId,
          questionnaireID,
          'self-review',
          sectionID
        ).onSnapshot((snapshot) => {
          if (snapshot.exists) {
            userAnswers[doc.id] = snapshot.data();
          }

          if (i == numSections - 1) {
            // got all questions...
            let questionnaireSections = sections;
            let questionDetails = {};
            let sectionDetails = questionnaireSections;
            let userResponses = {};
            let userComments = {};
            let userIndividualAnswers = [];
            let userIndividualResponses = [];

            // console.log(questionnaireSections);
            // console.log(userAnswers);

            this.setState({
              userAnswers: userAnswers,
              questionnaireSections: questionnaireSections,
              questionDetails: questionDetails,
              sectionDetails: sectionDetails,
              loading: false,
            });
          }
        });

        // // console.log(sections[doc.id]['created'].toDate());
        // // console.log( new Date( sections[doc.id]['created'] ) );
      });
    });
  }

  // getReportAnswers(snapshot) {
  //   let questionnaireSections = snapshot.data().sections
  //   let questionDetails = {}
  //   let sectionDetails = questionnaireSections
  //   let userAnswers = {}
  //   let userResponses = {}
  //   let userComments = {}
  //   let userIndividualAnswers = []
  //   let userIndividualResponses = []
  //
  //     // Get answers!
  //     db.doGetAnswers(params.userId, params.id, params.groupId, questionnaireID, "self-review").on('value', data => {
  //
  //       let userAnswers = data.val()
  //
  //       this.setState({
  //           userAnswers: userAnswers,
  //           questionnaireSections: questionnaireSections,
  //           questionDetails: questionDetails,
  //           current360Title: current360Title,
  //           sectionDetails: sectionDetails,
  //           userFullName: userFullName,
  //           reportPdfFileName: reportPdfFileName,
  //           loading: false,
  //         })
  //
  //
  //     })
  // }
  render() {
    if (!this.state.loading) {
      return (
        <div className='page-wrapper page-wrapper--report'>
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.props.match.params.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${this.props.current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
                },
                {
                  title: this.props.current360.title,
                  to: '../../../edit/',
                },
                {
                  title: this.state.currentGroup.title,
                  to: `../../../edit/group/${this.state.groupId}/`,
                },
                {
                  title: `Report & Development plan for ${this.state.userFullName}`,
                  to: null,
                },
              ]}
            />
          </section>
          <div
            className='user-report-view-only'
            style={{ height: '100vh', width: '100vw', paddingTop: 20, paddingBottom: 100, backgroundColor: 'rgb(82, 86, 89)', overflow: 'hidden' }}
          >
            <p className='tc'>
              <Link className='btn btn--back btn--white ml2 mr3' to={`../../../edit/group/${this.state.groupId}/`}>
                Back to group overview
              </Link>
              <button className='btn btn--white ml3 mr2' onClick={this.exportPDF}>
                Export as PDF
              </button>
            </p>
            <div
              style={{
                maxWidth: '210mm',
                height: '90vh',
                backgroundColor: 'white',
                boxShadow: '0px 0px 5px #000',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 100,
                padding: '0px',
                overflowX: 'scroll',
                overflowY: 'scroll',
              }}
            >
              <PDFExport
                paperSize={'A4'}
                forcePageBreak='.page-break'
                margin={{ bottom: 50 }}
                pageTemplate={GoalTrackerReportPageTemplate}
                fileName={this.state.reportPdfFileName}
                title=''
                subject=''
                keywords=''
                ref={(r) => (this.report = r)}
              >
                <div
                  className='user-report'
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '0px',
                  }}
                >
                  <header className='user-report__header'>
                    <img crossorigin='anonymous' src={this.props.current360.settings.logo} class='header-logo-img' />
                    <div className='w-80'>
                      <h1 className='user-report__header-title'>{this.state.current360Title}</h1>
                      <p className='user-report__header-sub-title'>
                        <strong>Goal Details</strong>
                      </p>
                      <p className='user-report__header-name'>For {this.state.userFullName}</p>
                    </div>
                  </header>
                  <div className='bg-white pa5'>
                    <h2 className='f2 mv0'>
                      <strong>Section 1</strong>
                    </h2>
                    <h2 className='f1 mv0'>Instructions</h2>

                    <div className='mv4'>
                      <h2 className='f2 mv0'>
                        <strong>Section 1.1:</strong>
                      </h2>
                      <h3 className='f5 gray'>Getting the most from your goal tracker</h3>
                      <p>
                        <strong>Why set goals?</strong>
                      </p>
                      <p>Goals are an essential tool and are key to ensuring your progress and personal fulfilment.</p>
                      <p>
                        <strong>Plan to achieve your goals</strong>
                      </p>
                      <p>
                        It is important to consider how you are going to monitor progress and ensure that anything that might impede progress can be easily
                        identified and dealt with. Good communication is key to this and regular meetings or review sessions can help to pre-empt difficulties.
                      </p>
                      <p>
                        You may also have specific processes or procedures in place that help monitor large-scale tasks. If your goals are personal, make sure
                        you review them regularly to check that you are still on track, if not then do something about it – don’t set yourself up for failure.
                      </p>
                      <p>
                        <strong>Feel the benefit</strong>
                      </p>
                      <p>
                        You should feel motivated by the challenge your goals represent. Use them to demonstrate your abilities and achievements; allowing them
                        to validate your successes and effectiveness.
                      </p>
                      <p>Goal setting can be used to great effect at any level and for any purpose, both in your work and personal life.</p>
                    </div>

                    <div className='mv4'>
                      <h2 className='f2 mv0'>
                        <strong>Section 1.2:</strong>
                      </h2>
                      <h3 className='f5 gray'>How your report is compiled</h3>
                      <p>
                        <strong>Goals</strong>
                      </p>
                      <p>The goals you set up on the goal tracker system.</p>
                      <p>
                        <strong>Specific actions</strong>
                      </p>
                      <p>The actions you identified that would enable you to deliver your goals.</p>
                      <p>
                        <strong>Measures of success</strong>
                      </p>
                      <p>The measures you chose to identify how you will know you have been successful in delivering your goal.</p>
                      <p>
                        <strong>Support needed</strong>
                      </p>
                      <p>Any people, resources, etc that you required to deliver your goals.</p>
                      <p>
                        <strong>Review point</strong>
                      </p>
                      <p>The date you will review your progress on your goals.</p>
                      <p>
                        <strong>Deadline</strong>
                      </p>
                      <p>The deadline set for the completion of your goals.</p>
                    </div>

                    <h2 className='f2 mv0 page-break'>
                      <strong>Section 2</strong>
                    </h2>
                    <h2 className='f1 mv0'>My Goals</h2>

                    {Object.keys(this.state.questionnaireSections).map((key, i) => {
                      let sectionKey = key;
                      // console.log(this.state.questionnaireSections);
                      // console.log(this.state.userAnswers);
                      if (this.state.userAnswers[sectionKey] && this.state.questionnaireSections[sectionKey]) {
                        return Object.keys(this.state.questionnaireSections[sectionKey].questions).map((questionKey, index) => {
                          // let questionKey = key;
                          // console.log(index % 4 === 0);
                          return (
                            <div className='mv1'>
                              {index === 0 ? (
                                <div>
                                  <h2 className='f2 mv0 page-break'>
                                    <strong>Section 2.{i + 1}:</strong>
                                  </h2>

                                  <h3 className='f3 blue'>
                                    {this.state.questionnaireSections[sectionKey].title}
                                    <br />
                                    <span className='f5 gray'>{this.state.questionnaireSections[sectionKey].description}</span>
                                  </h3>
                                </div>
                              ) : (
                                ''
                              )}
                              {index % 4 === 0 && index !== 0 ? (
                                <div className='mv4 page-break'>
                                  <span className='f5 gray'>{this.state.questionnaireSections[sectionKey].questions[questionKey].questionTitle}</span>
                                  {this.state.questionnaireSections[sectionKey].questions[questionKey].answerType == 'Date' ? (
                                    <div class='ba b--solid b--light-silver pv3 ph4 mt1'>
                                      <p class='black mb0'>{moment(this.state.userAnswers[sectionKey][questionKey].toDate()).format('MMMM Do YYYY')}</p>
                                    </div>
                                  ) : (
                                    <div class='ba b--solid b--light-silver pv3 ph4 mt1'>
                                      <p class='black mb0'>"{this.state.userAnswers[sectionKey][questionKey]}"</p>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className='mv4'>
                                  <span className='f5 gray'>{this.state.questionnaireSections[sectionKey].questions[questionKey].questionTitle}</span>
                                  {this.state.questionnaireSections[sectionKey].questions[questionKey].answerType == 'Date' ? (
                                    <div class='ba b--solid b--light-silver pv3 ph4 mt1'>
                                      <p class='black mb0'>{moment(this.state.userAnswers[sectionKey][questionKey].toDate()).format('MMMM Do YYYY')}</p>
                                    </div>
                                  ) : (
                                    <div class='ba b--solid b--light-silver pv3 ph4 mt1'>
                                      <p class='black mb0'>"{this.state.userAnswers[sectionKey][questionKey]}"</p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        });
                      } else {
                        // console.log('aaa');
                      }
                    })}

                    <h2 className='f2 mv0 page-break'>
                      <strong>Section 3:</strong>
                    </h2>
                    <h2 className='f1 mv0'>Reflections</h2>

                    <h2 className='f2 mv0 page-break'>
                      <strong>Section 3.1:</strong>
                    </h2>
                    <h3 className='f3 gray'>My Reflections</h3>

                    <p>
                      <strong>Use the space below to reflect on your goals, as required:</strong>
                    </p>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                    <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv3'></div>
                  </div>
                </div>
              </PDFExport>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <canvas ref={this.hiddenCanvas}></canvas>
          <LoadingState />
        </div>
      );
    }
  }
}

//
// export default withAuthorisation(authCondition)(UserGoalReport);
// export default UserGoalReport
export default withAuthorisation(authCondition)(UserGoalReport);
