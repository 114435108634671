import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import Breadcrumbs from '../../../components/breadcrumbs';
import SiteFooterBrand from '../../../components/site-footer-brand';
import SiteHeader from '../../../components/site-header';
import SiteNav from '../../../components/site-nav';

//
import { compose } from 'recompose';
import withAuthorisation from '../../../components/withauthorisation';
import withUser360s from '../../../components/withuser360s';
const authCondition = (authUser) => !!authUser;
//

class Edit360Basic extends Component {
  constructor(props) {
    super(props);

    let editLink;
    let editLinkQuestionnaire;
    let editLinkBrand;
    // // console.log(this.props.current360)
    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    window.scrollTo(0, 0);

    // this.editLinkBasic = `/dashboard/360s/${params.id}/edit/basic/`
    this.adminLink = `/dashboard/`;
    this.editLink = `/dashboard/360s/${params.id}/edit/`;
    this.editLinkQuestionnaire = `/dashboard/360s/${params.id}/edit/questionnaire/`;
    this.editLinkBrand = `/dashboard/360s/${params.id}/edit/brand/`;
  }
  render() {
    return (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={this.state.current360}
            accountData={this.props.accountData}
            current360Groups={this.props.current360Groups}
            current360Relationships={this.props.current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${this.state.accountId}`,
              },
              {
                title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                  this.props.current360.type
                } Projects`,
                to: `/dashboard/${this.props.match.params.accountId}/${this.props.current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
              },
              {
                title: this.state.current360.title,
                to: '../',
              },
              {
                title: 'Basic Settings',
                to: null,
              },
            ]}
          />

          <h1>{this.state.current360.title}: Basic Settings</h1>

          <div className='flex items-center justify-between w100 bg-white shadow pa4 mv4'>
            <div className='w-70'>
              <p className='ma0'>
                <strong>Questionnaire set-up</strong>
              </p>
            </div>
            <Link className='btn' to={{ pathname: 'questionnaire/', accountData: this.props.accountData, current360: this.props.current360 }}>
              Start
            </Link>
          </div>

          <div className='flex items-center justify-between w100 bg-white shadow pa4 mv4'>
            <div className='w-70'>
              <p className='ma0'>
                <strong>Brand your 360</strong>
                <br />
                You may brand your 360 by setting a logo and changing the colour scheme
              </p>
            </div>
            <Link className='btn' to={{ pathname: 'brand/', accountData: this.props.accountData, current360: this.props.current360 }}>
              Start
            </Link>
          </div>

          <Link to={{ pathname: '../', current360: this.state.current360, userData: this.state.userData }} className='btn btn--back'>
            Return to Project Overview
          </Link>
        </section>
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Edit360Basic);
