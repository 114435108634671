import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../../firebase';
import SiteFooterBrand from '../../../../components/site-footer-brand';
import SiteHeader from '../../../../components/site-header';
import SiteNav from '../../../../components/site-nav';
import EmailEditForm from '../../../../components/email-edit-form';
import Modal from '../../../../components/global/modal';
import withAuthorisation from '../../../../components/withauthorisation';
import Breadcrumbs from '../../../../components/breadcrumbs';
const authCondition = (authUser) => !!authUser;
//

class Edit360Email extends Component {
  constructor(props) {
    super(props);

    let editLinkQuestionnaire;

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      loaded360: false,
      loaded: false,
    };

    this.onSubmitEmailText = this.onSubmitEmailText.bind(this);
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    window.scrollTo(0, 0);

    db.doGetCurrent360EmailText(this.state.accountId, params.id).onSnapshot((snapshot) => {
      this.setState({
        current360EmailText: snapshot.data(),
        loaded: true,
      });
    });
  }
  onSubmitEmailText(e) {
    let relationshipData = {
      name: this.relationshipName.value,
      colour: this.relationshipColour.value,
      description: this.relationshipDescription.value,
    };

    // // console.log(relationshipData);
    db.doCreateNewRelationship(this.state.accountId, this.state.current360ID, this.relationshipLabel.value, relationshipData).then((data) => {
      this.setState({ showNewRelationshipModal: false });
    });

    e.preventDefault();
  }
  render() {
    return (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={this.state.current360}
            accountData={this.props.accountData}
            current360Groups={this.props.current360Groups}
            current360Relationships={this.props.current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${this.state.accountId}`,
              },
              {
                title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                  this.props.current360.type
                } Projects`,
                to: `/dashboard/${this.props.match.params.accountId}/${this.props.current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
              },
              {
                title: this.state.current360.title,
                to: '../../',
              },
              {
                title: 'Advanced Settings',
                to: '../',
              },
              {
                title: 'Email Text Settings',
                to: null,
              },
            ]}
          />

          <h1>{this.state.current360.title}: Email Text Settings</h1>

          {this.state.loaded ? (
            <div className='bg-white mv4 ph4 pv4 shadow br2'>
              <div className='flex'>
                <div className='w-50'>
                  <p>
                    <strong>Change Email Text</strong>
                  </p>
                  <p>
                    Here you can change the content of each reminder email sent out via a{' '}
                    {this.state.current360['type'] === 'Goal Tracker' ? 'goal tracker' : 'Questionnaire'} project
                  </p>
                </div>
              </div>
              {Object.keys(this.state.current360EmailText).map((key, i) => {
                return (
                  <EmailEditForm
                    key={key}
                    accountId={this.state.accountId}
                    current360Id={this.state.current360ID}
                    current360StatusCode={this.props.current360.statusCode}
                    emailNum={i + 1}
                    emailKey={key}
                    email={this.state.current360EmailText[key]}
                  />
                );
              })}
            </div>
          ) : (
            ''
          )}

          <Modal
            isOpen={this.state.showNewRelationshipModal}
            contentLabel='Add a new relationship'
            onClose={() => this.setState({ showNewRelationshipModal: false })}
            content={
              <div>
                <h2>Create a new Relationship</h2>
                <form className='mw6 center pt2 pb2' onSubmit={this.onSubmitEmailText}>
                  <label htmlFor='title'>Label</label>
                  <input
                    required
                    maxLength='3'
                    ref={(input) => (this.relationshipLabel = input)}
                    aria-describedby='name-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    type='text'
                    placeholder='Eg: LM'
                  />
                  <label htmlFor='title'>Name</label>
                  <input
                    required
                    ref={(input) => (this.relationshipName = input)}
                    aria-describedby='name-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    type='text'
                    placeholder='Eg: Line Manager'
                  />
                  <label htmlFor='title'>Colour</label>
                  <input
                    required
                    ref={(input) => (this.relationshipColour = input)}
                    aria-describedby='name-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    type='text'
                    placeholder='#000000'
                  />
                  <label htmlFor='title'>Description</label>
                  <textarea ref={(input) => (this.relationshipDescription = input)} />
                  <button className='btn' type='submit'>
                    Submit
                  </button>
                </form>
              </div>
            }
          />
          <Link to={{ pathname: '../', current360: this.props.current360, accountData: this.props.accountData }} className='btn btn--back'>
            Return to Advanced Settings
          </Link>
        </section>
      </div>
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360Email);
