import React, { Component } from 'react';
import DottedLines from './DottedLines';
import PageHeader from './PageHeader';

export default class GoalProgress extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='bg-white user-report__content'>
        <PageHeader h2Class='mt4' h2Text='Part 3: ' strong='Step 3' h3Text={`Progress on Goal ${this.props.num}:`} line={true} />
        <DottedLines numberOfLines={8} />
      </div>
    );
  }
}
