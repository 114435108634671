import React, { Component } from 'react';

class CreateNewProject extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {containerClass, projectType, isDashboard} = this.props;
    return (
      <div className={`create-new-panel ${containerClass} ${isDashboard ? 'create-new-panel__dashboard' : ''}`}>
        <div className={`create-new-panel__info ${isDashboard ? 'mb4' : ''}`}>
          <p className='create-new-panel__title lh-title'>
            <strong>Create a new {projectType} Project</strong>
          </p>
          <p className='create-new-panel__desc lh-copy'>Use this section to create a brand new {projectType} project</p>
        </div>
        <div>
          <button onClick={() => this.props.openNew360Modal(projectType)} className='btn btn--white'>
            Start Now
          </button>
        </div>
      </div>
    );
  }
}

export default CreateNewProject;
