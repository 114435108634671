import React, { Component } from 'react';
import ComparisonRow from './ComparsionRow';
import {valueCheck, percentageCheck} from '../../helpers'

export default class ComparisonTable extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props.state };
  }
  render() {
    let sectionID = this.props.sectionID;
    let key = this.props.sectionID;
    let nullLoop = this.props.nullLoop;
    let questionCount = this.props.questionCount;
    let countCheck = ['0', '1', '2', '3', '4', '5'];
    let pageSplit = this.props.pageSplit;
    // // console.log(key);
    // // console.log(sectionID);
    // // console.log(this.state.sectionDetails);
    // // console.log(this.props);
    return (
      <div className='ba b--moon-gray mb10 table page-break'>
        <div className='row flex bg-light-gray'>
          <div className='w-30 pt1 pb0'></div>
          <div className='w-70'>
            <div className='w-100 flex flex-wrap table__row pl1'>
              <div className='w-20 pt1 pb0'>
                <strong>Relationship</strong>
              </div>
              <div className='w-50 pt1 pb0 flex'>
                <strong>Average Rating</strong>
                <span
                  className='bg-blue h-100 db ml1 mr1 ba'
                  style={{
                    height: '70%',
                    width: '15px',
                  }}
                ></span>
                <strong>Current average rating</strong>
                <span
                  className='bg-blue h-100 db ml1 mr1 ba'
                  style={{
                    height: '70%',
                    width: '15px',
                    backgroundColor: `
                                                #0051ff`,
                    opacity: '0.4',
                  }}
                ></span>
                <strong>Previous average rating</strong>
              </div>
              <div className='w-30 pt1 pb0'>
                <div className='tc'>
                  <strong>Number of responses</strong>
                </div>
                <div className='w-100 flex'>
                  <p className='w-100 mv0 tc'>5</p>
                  <p className='w-100 mv0 tc'>4</p>
                  <p className='w-100 mv0 tc'>3</p>
                  <p className='w-100 mv0 tc'>2</p>
                  <p className='w-100 mv0 tc'>1</p>
                  <p className='w-100 mv0 tc'>?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt1 pb1 pl2 flex bt bb b--moon-gray'>
          <div className='w-30'>
            <p className='ma0 f6'>
              <strong>{this.state.sectionDetails[key].title}</strong>
              {/*<br/><small>{ this.state.sectionDetails[key].description }</small>*/}
            </p>
          </div>
          <div className='w-70'>
            <div className='w-100 flex flex-wrap table__row '>
              <div className='w-20 mv1'>
                <p className='ma0 blue'>
                  <strong>Self</strong>
                </p>
              </div>
              <div className='w-50 mv1'>
                <div className='w-100'>
                  <p className='flex ma0'>
                    <strong className='w-10'>{valueCheck((this.state.userAnswers[key] * 5).toFixed(1))}</strong>
                    <span className='w-100 bg-light-gray'>
                      <span className='bg-blue h-100 db' style={{ width: `${percentageCheck(this.state.userAnswers[key].toFixed(1)) * 100}%` }}></span>
                    </span>
                  </p>
                </div>
              </div>

              <div className='w-30 pv1'>
                <div className='w-100 flex'>
                  {Object.keys(this.state.userResponses[sectionID])
                    .reverse()
                    .map((key) => {
                      return (
                        <p className='w-100 mv0 tc'>
                          <strong>{this.state.userResponses[sectionID][key]}</strong>
                        </p>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* This is for the previous 360 reviewee score */}
            <div className='w-100 flex flex-wrap table__row '>
              <div className='w-20 mv1'>
                <p className='ma0 blue'>
                  <strong></strong>
                </p>
              </div>
              <div className='w-50 mv1'>
                <div className='w-100'>
                  <p className='flex ma0'>
                    <strong className='w-10'>{valueCheck(this.state.previous360Scores.userSectionAverage[key].toFixed(1))}</strong>
                    <span className='w-100 bg-light-gray'>
                      <span
                        className='bg-blue h-100 db'
                        style={{
                          width: `${(percentageCheck(this.state.previous360Scores.userSectionAverage[key].toFixed(1)) / 5) * 100}%`,
                          backgroundColor: `
                                                        #0051ff`,
                          opacity: '0.4',
                        }}
                      ></span>
                    </span>
                  </p>
                </div>
              </div>

              <div className='w-30 pv1'>
                <div className='w-100 flex'>
                  {Object.keys(this.state.previous360Scores.userSectionCount[sectionID])
                    .reverse()
                    .map((key) => {
                      return countCheck.includes(key) ? (
                        <p className='w-100 mv0 tc'>
                          <strong>{this.state.previous360Scores.userSectionCount[sectionID][key]}</strong>
                        </p>
                      ) : (
                        ''
                      );
                    })}
                </div>
              </div>
            </div>
            {/* aaaaa here */}
            {Object.keys(this.state.sectionAverageByRelationship)
              .reverse()
              .map((key, index) => {
                let originalRelationshipKey = this.state.current360Relationships[key].name;
                let relationshipID = key;
                let secAverage = parseFloat(this.state.sectionAverageByRelationship[relationshipID][sectionID]).toFixed(1);
                let prevSecAverage = parseFloat(this.state.previous360Scores.averageBySections[relationshipID][sectionID]).toFixed(1);
                return (
                  <>
                    <div key={relationshipID + 'prev'} className='w-100 flex table__row'>
                      <div className='w-20 mv1'>
                        <p className='ma0' style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}>
                          <strong>{this.state.current360Relationships[relationshipID]['name']}</strong>
                        </p>
                      </div>
                      <div className='w-50 mv1'>
                        <div className='w-100'>
                          <p className='flex ma0'>
                            <strong className='w-10' style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}>
                              {this.state.reviewerAnswersCountBySection[relationshipID][sectionID] !== undefined ? valueCheck(secAverage) : 'N/A'}
                            </strong>
                            <span className='w-100 bg-light-gray'>
                              <span
                                className='bg-blue h-100 db'
                                style={{
                                  background: this.state.current360Relationships[relationshipID]['colour'],
                                  width: `${this.state.reviewerAnswersCountBySection[relationshipID][sectionID] !== undefined ? (percentageCheck(secAverage) / 5) * 100 : 0}%`,
                                }}
                              ></span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className='w-30 mv1'>
                        <div className='w-100 flex flex-row-reverse'>
                          {Object.keys(this.state.reviewerAnswersCountBySection[relationshipID]).includes(sectionID)
                            ? Object.keys(this.state.reviewerAnswersCountBySection[relationshipID][sectionID]).map((val, i) => {
                                return val <= 5 ? (
                                  <p className='w-100 mv0 tc'>
                                    {this.state.reviewerAnswersCountBySection[relationshipID][sectionID] !== undefined
                                      ? isNaN(this.state.reviewerAnswersCountBySection[relationshipID][sectionID][val]) == false
                                        ? this.state.reviewerAnswersCountBySection[relationshipID][sectionID][val]
                                        : '0'
                                      : '0'}
                                  </p>
                                ) : (
                                  ''
                                );
                              })
                            : Object.keys(nullLoop).map((val) => {
                                return <p className='w-100 mv0 tc'>0</p>;
                              })}
                        </div>
                      </div>
                    </div>
                    <div key={relationshipID} className='w-100 flex table__row'>
                      <div className='w-20 mv1'>
                        <p className='ma0' style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}>
                          <strong></strong>
                        </p>
                      </div>
                      <div className='w-50 mv1'>
                        <div className='w-100'>
                          <p className='flex ma0'>
                            <strong className='w-10' style={{ color: this.state.current360Relationships[relationshipID]['colour'] }}>
                              {this.state.previous360Scores.averageBySections[relationshipID][sectionID] !== undefined ? valueCheck(prevSecAverage) : 'N/A'}
                            </strong>
                            <span className='w-100 bg-light-gray'>
                              <span
                                className='bg-blue h-100 db'
                                style={{
                                  backgroundColor: `
                                    ${this.state.current360Relationships[relationshipID]['colour']}`,
                                  opacity: 0.4,
                                  width: `${
                                    this.state.previous360Scores.averageBySections[relationshipID][sectionID] !== undefined ? (percentageCheck(prevSecAverage) / 5) * 100 : 0
                                  }%`,
                                  opacity: '0.4',
                                }}
                              ></span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className='w-30 mv1'>
                        <div className='w-100 flex flex-row-reverse'>
                          {Object.keys(this.state.previous360Scores.sectionAnswerCount[relationshipID]).includes(sectionID)
                            ? Object.keys(this.state.previous360Scores.sectionAnswerCount[relationshipID][sectionID]).map((val, i) => {
                                return val <= 5 ? (
                                  <p className='w-100 mv0 tc'>
                                    {this.state.previous360Scores.sectionAnswerCount[relationshipID][sectionID] !== undefined
                                      ? isNaN(this.state.previous360Scores.sectionAnswerCount[relationshipID][sectionID][val]) == false
                                        ? this.state.previous360Scores.sectionAnswerCount[relationshipID][sectionID][val]
                                        : '0'
                                      : '5'}
                                  </p>
                                ) : (
                                  ''
                                );
                              })
                            : Object.keys(nullLoop).map((val) => {
                                return <p className='w-100 mv0 tc'>0</p>;
                              })}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            {/* aaaaaa */}
          </div>
        </div>

        {Object.keys(this.state.sectionDetails[sectionID].questions).map((key, index) => {
          let questionID = key;
          questionCount++;
          let whichQuest = this.state.sectionDetails[sectionID].questions[questionID].sortOrder;
          // console.log(whichQuest % 4);
          //mb4 page-break user-report__content user-report__content--no-pad
          return index <= pageSplit - 1 && this.state.sectionDetails[sectionID].questions[questionID]['answerType'] !== 'FreeText' ? (
            //this bit is to allow account for pagebreaking every 4 questions but allows for the 1st question to be rendered on page 1
            (whichQuest % 4 >= 1 && whichQuest !== 0) || whichQuest === 0 ? (
              <ComparisonRow state={this.state} nullLoop={nullLoop} questionID={questionID} sectionID={sectionID} />
            ) : (
              ''
            )
          ) : (
            ''
          );
        })}
      </div>
    );
  }
}
