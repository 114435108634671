import React from 'react';
import { convertRelationshipTextToKey } from '../helpers';
import { db } from '../firebase';
import axios from 'axios';

class UserReviewerEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      savingReviewer: false,
      editingReviewer: false,
      showSendingModal: true,
      reminderText: 'Send Reminder',
      loading: false,
    };

    this.onSaveReviewer = this.onSaveReviewer.bind(this);
    this.onEditReviewer = this.onEditReviewer.bind(this);
    this.openReviewerDashboard = this.openReviewerDashboard.bind(this);
  }

  componentDidMount() {
    const {accountId, reviewerID, revieweeID, current360ID, currentGroupId, emailStatus, reviewerEmailStatuses, reviewerErrors, completed} = this.props
    if (reviewerErrors && reviewerErrors.length > 0) {
      // if current status is already delivery then nothing further needs to happen
      if (emailStatus !== 'delivery') {
        if ((reviewerEmailStatuses && reviewerEmailStatuses.includes('delivery')) || completed) {
          // updates to delivery status on firebase when delivery has been successful for first time. 
          db.doUpdateReviewerEmailStatus(accountId, reviewerID, revieweeID, current360ID, currentGroupId, 'delivery')
        } else {
          let recentStatus = reviewerEmailStatuses[0]
          if (emailStatus !== recentStatus) {
            // will update status on firebase when it has changed from the current one
            db.doUpdateReviewerEmailStatus(accountId, reviewerID, revieweeID, current360ID, currentGroupId, recentStatus)
          }
          this.setState({ recentStatus });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroupTasks !== this.props.currentGroupTasks) {
      this.setState({ loading: false });
    }
  }

  openReviewerDashboard(reviewerID) {
    const win = window.open(
      `${process.env.REACT_APP_SELF_REVIEW_DOMAIN}/${this.props.accountId}/${this.props.current360ID}/review/${this.props.reviewerID}/`,
      '_blank'
    );
    if (win != null) {
      win.focus();
    }
  }

  sendReminderEmail(e) {
    e.preventDefault();
    this.setState({ reminderText: 'Generating Email' });
    const reqBody = {
      accountId: this.props.accountId,
      userEmail: this.props.accountData.email,
      current360Id: this.props.current360ID,
      currentGroupId: this.props.currentGroupId,
      current360: this.props.current360,
      currentReviewer: {
        ID: this.props.reviewerID,
        revieweeID: this.props.revieweeID,
        name: this.props.name,
        email: this.props.email,
        relationship: this.props.relationship,
      },
      email: this.props.accountData.email,
    };

    // console.log(reqBody);

    axios
      .post('/sendReminderEmail', {
        reqBody,
        cookies: window.localStorage.getItem('Auth'),
      })
      .then((res) => {
        // console.log(res);

        if (!res.data.error) {
          /////////////
          let taskNum = 1;
          for (const task in this.props.currentGroupTasks) {
            const taskData = this.props.currentGroupTasks[task];
            if (taskData.completedDate) {
              taskNum++;
            }
          }

          /////////////
          setTimeout(
            () =>
              this.setState({
                sendingSuccess: true,
                sendingComplete: true,
                reminderText: 'Reminder Sent',
              }),
            1000
          );
        } else {
          this.setState({
            sendingSuccess: true,
            sendingComplete: true,
            reminderText: 'There Has Been An Error',
          });
        }

        db.updateReviewerRecievedInfo(this.props.accountId, this.props.reviewerID, this.props.current360ID, this.props.currentGroupId, this.props.revieweeID);
      })
      .catch((err) => {
        // console.log(err.toJSON());
        this.setState({
          sendingSuccess: true,
          sendingComplete: true,
          reminderText: 'There Has Been An Error',
        });
      });
  }

  onSaveReviewer(e) {
    e.preventDefault();

    let userName = this.reviewerName.value;
    let userEmail = this.reviewerEmail.value.toLowerCase();
    let reviewerRelationship = this.props.current360.type !== 'Team Assessment' ? this.relationship.value : 'TM';
    let reviewerId = this.props.reviewerID;

    this.setState({
      savingReviewer: true,
    });

    if (userName && userEmail && reviewerId && reviewerRelationship) {
      db.doUpdateReviewer(
        this.props.accountId,
        reviewerId,
        userName,
        userEmail,
        reviewerRelationship,
        this.props.revieweeID,
        this.props.current360ID,
        this.props.currentGroupId
      ).then((snapshot) => {
        this.setState({
          savingReviewer: false,
          editingReviewer: false,
        });
      });
    } else {
      this.setState({
        savingReviewer: false,
        editingReviewer: false,
      });
    }
  }

  onEditReviewer(e) {
    this.setState({
      editingReviewer: true,
    });
  }

  render() {
    const {reviewerEmailStatuses, reviewerErrors, emailRecieved, completed, reviewerEventReasons, emailStatus} = this.props
    const recentStatus = this.state.recentStatus
    const sendingErrorsFunc = () => {
        if (this.props.currentGroupTasks == undefined) {
          return (
            <p>
              <strong>Email Status: Status not available yet</strong>
            </p>
          );
        } else if ((this.state.loading !== true && this.props.currentGroupTasks[1].completedDate == false) || !emailRecieved) {
          return (
            <p>
              <strong>Email Status: Emails not sent yet</strong>
            </p>
          );
        } else {
          // There is event data on Sparkpost (so within 10 day storage period)
          if (reviewerErrors && reviewerErrors.length > 0) {
            if (reviewerEmailStatuses.includes('delivery') || completed) {
              return (
                <p className='has-tooltip' data-tooltip={reviewerEventReasons['delivery']}>
                  <strong>Email Status: Email Delivered</strong>
                </p>
              );
            } else {
                return (
                  <p className='has-tooltip' data-tooltip={reviewerEventReasons[recentStatus]}>
                    <strong>Email Status: Error sending email</strong>
                  </p>
                );
            }
          } else if (emailStatus === 'delivery' || completed) {
            // No record on Sparkpost but Firebase data has delivery status /review completed, so must have had email delivered
            return (
              <p className='has-tooltip' data-tooltip='success'>
                <strong>Email Status: Email Delivered</strong>
              </p>
            );
          } else if (emailRecieved) {
            // No record on Sparkpost or Firebase, not completed, email process did take place though, so request was sent to Sparkpost
            return (
              <p className='has-tooltip' data-tooltip='Email relayed to service provider but no record of events after this. Resending should provide more information.'>
                <strong>Email Status: Email with service provider</strong>
              </p>
            );
          } else if (emailStatus !== 'delivery') {
            // No longer within Sparkpost 10 day storage period, but event type (not delivered) was logged on Firebase
            return (
              <p className='has-tooltip' data-tooltip='Error data no longer available. Please check email address is correct and re-send'>
                <strong>Email Status: Error sending email</strong>
              </p>
            );
          }
        }
    };

    const sendErrorsCall = sendingErrorsFunc();

    return this.state.loading ? (
      'Loading'
    ) : (
      <form className='pa2' onChange={this.onEditReviewer} onSubmit={this.onSaveReviewer}>
        <div className='flex items-end'>
          <div className='w-100 pl3 pr3'>
            <label htmlFor='forename'>Name</label>
            <input
              required
              ref={(input) => (this.reviewerName = input)}
              defaultValue={this.props.name}
              aria-describedby='forename-desc'
              type='text'
              placeholder='Joe'
            />
          </div>
          <div className='w-100 pl3 pr3'>
            <label htmlFor='useremail'>Email</label>
            <input
              required
              ref={(input) => (this.reviewerEmail = input)}
              defaultValue={this.props.email}
              aria-describedby='useremail-desc'
              type='email'
              placeholder='email@example.com'
            />
          </div>
          {this.props.current360.type !== 'Team Assessment' ? (
            <div className='w-100 pl3 pr3'>
              <label htmlFor='useremail'>Relationship</label>
              <select ref={(input) => (this.relationship = input)} className='grey xl white'>
                {this.props.current360Relationships
                  ? Object.keys(this.props.current360Relationships).map((relationshipKey) => {
                      return (
                        <option
                          defaultValue={relationshipKey}
                          value={relationshipKey}
                          selected={convertRelationshipTextToKey(this.props.relationship) == relationshipKey}
                        >
                          {this.props.current360Relationships[relationshipKey]['name']}
                        </option>
                      );
                    })
                  : ''}
              </select>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='flex items-center'>
          <div className='w-100 pl3 pr3'>
            <p>
              Reviewer ID: {this.props.reviewerID}
              <br />
              <strong className={this.props.completed ? 'green' : 'red'}>{this.props.completed ? 'Review completed' : 'Review not complete'}</strong>
              {this.props.current360.createdDate <= 1632543899869 ? '' : sendErrorsCall}
            </p>
          </div>
          <div className='pl3 mb3'>
            <button className={this.state.savingReviewer || !this.state.editingReviewer ? 'btn btn--disabled mb2' : 'btn mb2'} type='submit'>
              {this.state.savingReviewer ? 'Saving' : 'Save'} reviewer
            </button>
          </div>
          {this.props.current360.type !== 'Team Assessment' ? (
            <div className='pl3 mb3'>
              <button
                className={this.state.savingReviewer ? 'btn btn--green btn--disabled mb2' : 'btn btn--green mb2'}
                onClick={(e) => this.sendReminderEmail(e)}
              >
                {this.state.reminderText}
              </button>
            </div>
          ) : (
            <></>
          )}
          <div className='pl3 mb3'>
            <button className='btn mb2' onClick={() => this.openReviewerDashboard(this.props.reviewerID)}>
              View Review
            </button>
          </div>
          <div className='pl3 mb3'>
            <button className='btn btn--red mb2' onClick={() => this.props.deleteReviewer(this.props.reviewerID)}>
              Delete
            </button>
          </div>
        </div>
        {!this.props.last ? <hr /> : ''}
      </form>
    );
  }
}

export default UserReviewerEditForm;
