import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { IconHome } from './../svg/svgs'

class BreadCrumbs extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <p className="breadcrumbs">
      {
        Object
          .keys(this.props.links)
          .map((key, i) => {
            return (
              <span key={key}>
              {
                (i > 0) ? <span>&nbsp;&rarr;&nbsp;</span> : ''
              }
              {
                (this.props.links[key].to) ?
                  <Link to={{ pathname: this.props.links[key].to, accountData: this.props.accountData, current360: this.props.current360, userDetails: this.props.userDetails, current360Groups: this.props.current360Groups, current360Relationships: this.props.current360Relationships, accountTitle: this.props.accountTitle }}>
                  {
                    (this.props.links[key].title === "Dashboard") ?
                      <IconHome width="16" height="16" />
                    : this.props.links[key].title
                  }

                  </Link>
                : this.props.links[key].title
              }
              </span>
            )
          })
      }
      </p>
    );
  }
}

export default BreadCrumbs
