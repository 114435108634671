import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import withAuthorisation from '../components/withauthorisation'
import SiteHeader from '../components/site-header'
import SiteNav from '../components/site-nav'
const authCondition = (authUser) => !!authUser
//

class Landing extends Component {
  render() {
    return (
      <main>
        <SiteHeader />
        <SiteNav />
        <section className="mw8-l center pa4 ph4-l">
          <h1>Landing</h1>
          <p><Link className="pa2" to="/signin/">Sign In</Link> / <Link className="pa2" to="/signup/">Register</Link></p>
        </section>
      </main>
    );
  }
}

//
// export default withAuthorisation(authCondition)(Landing);
// export default withRouter(Landing);
export default Landing
