import React, { Component } from 'react';
import { average, convertRelationshipTextToKey, valueCheck, percentageCheck } from '../../helpers';

class QuestionRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testState: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading });
  }

  componentDidUpdate(prevProps, prevState) {
    // when loading is complete, gets the height of the valid questionRow ref and sends it back to detailedFeedbackChart
    if (prevState.loading !== this.state.loading) {
      if (this.questionRow.clientHeight) {
        const rowHeight = this.questionRow.clientHeight;
        this.props.getRowHeight(rowHeight);
      }
    }
  }

  render() {
    const {
      sectionID,
      questionID,
      questionIndex,
      questionTitle,
      reviewerCalcs,
      reviewerCountArr,
      reviewerAnswerCountArr,
      revieweeAnswerCountArr,
      allUserPercentArr,
      colours,
    } = this.props;
    const revieweeAnswerVal = this.props.revieweeAnswersObj[questionID].toFixed(1);
    const reviewerAnswerVal = this.props.reviewerAnswersObj[questionID].toFixed(1);
    return (
      <div
        key={questionID}
        className='row pt1 pb1 pl2 flex bt b--moon-gray page-break'
        // adds a valid reference for the first question row of each section
        ref={(questionRow) => {
          this.questionRow = questionIndex === 0 ? questionRow : '';
        }}
      >
        <div className='w-30 pr3 class'>
          <p className='ma0'>{questionTitle}</p>
        </div>
        <div className='w-70 flex flex-wrap'>
          <div className='w-100 flex table__row'>
            <div className='w-20 mv1'>
              <p className='ma0 gray'>
                <strong>Distribution</strong>
              </p>
            </div>
            <div className='w-50 mv1'>
              <div className='w-100 h-100'>
                <p className='flex ma0 h-100'>
                  <strong className='w-10'></strong>

                  <span className='w-100 bg-light-gray flex'>
                    {allUserPercentArr[questionID].reverse().map((ans, k) => {
                      return <span className='h-100 db' style={{ width: `${ans.toFixed(1)}%`, backgroundColor: `${colours[k]}` }} />;
                    })}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className='w-100 flex table__row'>
            <div className='w-20 mv1'>
              <p className='ma0 blue'>
                <strong>Self</strong>
              </p>
            </div>
            <div className='w-50 mv1'>
              <div className='w-100'>
                <p className='flex ma0'>
                  <strong className='w-10'>{valueCheck(revieweeAnswerVal)}</strong>

                  <span className='w-100 bg-light-gray'>
                    <span className='bg-blue h-100 db' style={{ width: `${(percentageCheck(revieweeAnswerVal) / 5) * 100}%` }}></span>
                  </span>
                </p>
              </div>
            </div>
            <div className='w-30 mv1'>
              <div className='w-100 flex flex-row-reverse'>
                {revieweeAnswerCountArr[sectionID][questionID].map((ans) => {
                  return (
                    <p className='w-100 mv0 tc'>
                      <strong>{ans}</strong>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='w-100 flex table__row'>
            <div className='w-20 mv1'>
              <p className='ma0 gray'>
                <strong>All Reviewers</strong>
              </p>
            </div>
            <div className='w-50 mv1'>
              <div className='w-100'>
                <p className='flex ma0'>
                  <strong className='w-10'>{valueCheck(reviewerAnswerVal)}</strong>

                  <span className='w-100 bg-light-gray'>
                    <span className='bg-gray h-100 db' style={{ width: `${(percentageCheck(reviewerAnswerVal) / 5) * 100}%` }}></span>
                  </span>
                </p>
              </div>
            </div>
            <div className='w-30 mv1'>
              <div className='w-100 flex flex-row-reverse'>
                {reviewerAnswerCountArr[questionID].map((ans) => {
                  return (
                    <p className='w-100 mv0 tc'>
                      <strong>{ans}</strong>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          {Object.keys(reviewerCalcs)
            .reverse()
            .map((key, index) => {
              let originalRelationshipKey = key;
              let relationshipID = convertRelationshipTextToKey(key);
              const relationship = this.props.current360Relationships[relationshipID];
              const reviewerCalc = reviewerCalcs[originalRelationshipKey];
              const questionAnswers = reviewerCalc.sectionAnswersByType.answersBySection[sectionID][questionID];
              return (
                <div key={relationshipID} className='w-100 flex table__row'>
                  <div className='w-20 mv1'>
                    <p className='ma0' style={{ color: relationship['colour'] }}>
                      <strong>{reviewerCalc !== undefined ? relationship['name'] : ''}</strong>
                    </p>
                  </div>
                  <div className='w-50 mv1'>
                    <div className='w-100'>
                      <p className='flex ma0'>
                        <strong className='w-10' style={{ color: relationship['colour'] }}>
                          {reviewerCalc !== undefined ? (questionAnswers.length !== 0 ? parseFloat(average(questionAnswers)).toFixed(1) : 'N/A') : ''}
                        </strong>
                        <span className='w-100 bg-light-gray'>
                          <span
                            className='bg-blue h-100 db'
                            style={{
                              background: relationship['colour'],
                              width: reviewerCalc !== undefined ? `${(parseFloat(average(questionAnswers)).toFixed(1) / 5) * 100}%` : '0%',
                            }}
                          ></span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='w-30 mv1'>
                    <div className='w-100 flex flex-row-reverse'>
                      {reviewerCountArr[originalRelationshipKey][sectionID] !== undefined
                        ? reviewerCountArr[originalRelationshipKey][sectionID][questionID].map((val, index) => {
                            return <p className='w-100 mv0 tc'>{val}</p>;
                          })
                        : ''}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default QuestionRow;
