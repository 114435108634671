import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../logo-colour.svg'
import { auth } from '../firebase'

const INITIAL_STATE = {
  email: '',
  error: null,
  message: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState({
          email: '',
          error: null,
          message: "You have been sent an email with instructions to reset your password"
        });
      })
      .catch(error => {
        this.setState({ error });
        this.setState({ message: null });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <form className="sign-in__form bg--white shadow animated fadeInUp" onSubmit={this.onSubmit} >
        <div className="mb3">
          <p><Link to={{ pathname: "../" }}>Back to login</Link></p>
        </div>
        <h3>Reset your password</h3>
        {
          (!this.state.message) ?
            <div>
              <input
                name="email"
                value={this.state.email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
              <button className="btn" disabled={isInvalid} type="submit">
                Reset My Password
              </button>
            </div>
          : <div>
            <p>{ this.state.message }</p>
          </div>
        }

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}


export default PasswordForgetForm
