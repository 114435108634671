import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SuperAdminNotification extends Component {
  render() {
    let dashboardLink = `/admin/`
    return (
      <div className="w-100 bg-dark-gray white pa2 flex items-center justify-center">
        <p className="mt3 mb3">You are currently viewing this account as a Super Admin user <Link className="btn btn--white ml2" to={ dashboardLink }>Return to super-admin dashboard</Link></p>
      </div>
    );
  }
}

export default SuperAdminNotification
