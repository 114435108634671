export function convertRelationships(relationship) {
  switch (relationship) {
    case 'DR':
      return 'Direct Report';
      break;
    case 'LM':
      return 'Line Manager';
      break;
    case 'P':
      return 'Peer';
      break;
    case 'O':
      return 'Other';
      break;
    default:
      return 'None';
  }
}
export function convertRelationshipTextToKey(relationship) {
  switch (relationship) {
    case 'Direct Report':
      return 'DR';
      break;
    case 'Line Manager':
      return 'LM';
      break;
    case 'Peer':
      return 'P';
      break;
    case 'Other':
      return 'O';
      break;
    default:
      return relationship;
  }
}
export function relationshipColor(relationship) {
  switch (relationship) {
    case 'DR':
      return '#54b99c';
      break;
    case 'LM':
      return '#dcdc00';
      break;
    case 'P':
      return '#5b4898';
      break;
    case 'O':
      return '#1c9ad7';
      break;
    default:
      return '#FFFFFF';
  }
}

export function average(array) {
  if (array.length) {
    return array.reduce((a, b) => a + b) / array.length;
  } else {
    return 0;
  }
}

export function convertTaskNames(taskNum) {
  switch (taskNum) {
    case '1':
      return 'Send instructions';
      break;
    case '2':
      return 'Send first reminders';
      break;
    case '3':
      return 'Send second reminders';
      break;
    case '4':
      return 'Send final reminders';
      break;
    default:
      return 'Default';
  }
}

export function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

export function difference(i, v) {
  return i - v;
}

export const sleep = async (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const arrayGenerator = (n) => {
  let arr = Array(n).fill('');
  return arr;
};

export const valueCheck = (n) => {
  if (!n || isNaN(+n)) {
    return 'N/A'
  }
  if (+n === 0.0) {
    return 'N/A'
  } else {
    return n
  }
};

export const percentageCheck = (n) => {
  if (!n || isNaN(+n)) {
    return 0
  }
  if (+n === 0.0) {
    return 0
  } else {
    return n
  }
};

export const urlConversion = (str) => {
  return str.replace(/\s/g, '-').toLowerCase();
};

export const breadcrumbSelect = (type) => {
  switch (type) {
    case 'Goal Tracker':
      return 'goal-tracker';
    case 'Team Assessment':
      return 'team-assessment';
    default:
      return '360';
  }
};

export const projectTypeText = (projectType, standard360, goalTracker, teamAssessment) => {
  if (projectType === '360') {
    return standard360;
  } else if (projectType === 'Goal Tracker') {
    return goalTracker;
  } else {
    return teamAssessment;
  }
};

export const emptyObjectCheck = (obj) => {
  if (obj) {
    if (Object.keys(obj).length > 0) {
      return true;
    }
    return false;
  } else {
    return false;
  }
};
