import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { auth, db, storage } from '../firebase';
import FileUploader from 'react-firebase-file-uploader';
import { BlockPicker, ChromePicker, TwitterPicker } from 'react-color';
import Modal from '../components/global/modal';
import withAuthorisation from '../components/withauthorisation';
import firebase from 'firebase';
import { mfaLogin, smsTokVerify, checkMFA } from '../firebase/auth';
import SignInForm from '../components/signinform';
import axios from 'axios';
const authCondition = (authUser) => !!authUser;
//
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: this.props.match.params.accountId,
      accountData: this.props.accountData,
      logo: this.props.accountData.logo,
      primarycolour: this.props.accountData.primarycolour,
      secondarycolour: this.props.accountData.secondarycolour,
      buttoncolour: this.props.accountData.buttoncolour,
      title: this.props.accountData.title,
      email: this.props.accountData.email,
      fname: this.props.accountData.fname,
      lname: this.props.accountData.lname,
      allowBrandEdit: this.props.accountData.clientname == '' ? true : false,
      isUploading: false,
      saving: false,
      showNewUserModal: false,
      error: null,
      passwordError: null,
      currentPassword: '',
      newPassword: '',
      invitingUser: false,
      invitationSent: false,
      userObjToRevokeAccess: null,
      accountObjToRevokeAccess: null,
      showRevokeAccessConfirmationModal: false,
      revokingUserAccess: false,
      removeMfaModal: false,
      smsEnroll: false,
      removingMFA: {
        state: 'Confirm',
        error: '',
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAccountSubmit = this.handleAccountSubmit.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.handleNewUserInvite = this.handleNewUserInvite.bind(this);
    this.openNewUserModal = this.openNewUserModal.bind(this);
    this.revokeUserAccess = this.revokeUserAccess.bind(this);
  }

  componentDidMount() {
    const mfa = checkMFA().multiFactor.enrolledFactors;
    if (mfa.length > 0) {
      this.setState({ enrolled: true });
    } else {
      this.setState({ enrolled: false, verified: checkMFA().emailVerified });
    }
  }

  openNewUserModal = (e) => {
    e.preventDefault();
    this.setState({
      showNewUserModal: true,
    });
  };

  revokeUserAccess = (e) => {
    e.preventDefault();
    this.setState({
      revokingUserAccess: true,
    });
    db.doRevokeUserAccess(this.state.accountId, this.state.userObjToRevokeAccess, this.state.accountObjToRevokeAccess).then((data) => {
      // update account on this page
      db.doGetAccountBranding(this.state.accountId).onSnapshot((snapshot) => {
        this.setState({
          accountData: snapshot.data(),
          userObjToRevokeAccess: null,
          accountObjToRevokeAccess: null,
          showRevokeAccessConfirmationModal: false,
          revokingUserAccess: false,
        });
      });
    });
  };
  handleNewUserInvite = (e) => {
    e.preventDefault();
    this.setState({
      invitingUser: true,
    });

    let userForename = this.forename.value;
    let userSurname = this.surname.value;
    let userEmail = this.useremail.value;

    // check if email already exists in account user lists:
    if (this.state.accountData.adminUsers && this.state.accountData.adminUsers.some((obj) => obj.email === userEmail)) {
      this.setState({
        invitingUser: false,
      });
      alert('This user already has access to your account');
      return;
    } else {
      db.doCreateAlternateAdminUser(this.state.accountId, this.state.title, this.state.accountData.adminUsers, userForename, userSurname, userEmail)
        .then((accountExistsInDB) => {
          if (accountExistsInDB) {
            // Send email to user to let them know they have been granted access.
            let selfReviewLink = process.env.REACT_APP_ADMIN_DOMAIN;
            let emailTitle = `You have been granted access to the ${this.state.title} Boomerang account`;
            let selfReviewButtonText = `Click here to Login to your account`;
            let emailBodyCopy = `You have been granted access to a new Boomerang account, click the link below to login.`;

            // Send Email!
            axios
              .post(`/newAdminUser`, {
                reqBody: {
                  userEmail: this.props.userEmail,
                  to: userEmail,
                  name: `${userForename} ${userSurname}`,
                  title: `Hi ${userForename} ${userSurname}`,
                  subjectLine: emailTitle,
                  btnText: selfReviewButtonText,
                  emailBodyCopy: emailBodyCopy,
                  brandLogo: this.state.accountData.logo,
                  url: selfReviewLink,
                },
                cookies: window.localStorage.getItem('Auth'),
              })
              .then((res) => {
                // console.log('SENT');

                db.doGetAccountBranding(this.state.accountId).onSnapshot((snapshot) => {
                  this.setState({
                    accountData: snapshot.data(),
                    invitingUser: false,
                    invitationSent: true,
                  });
                });
              })
              .catch((err) => {
                // Handle errors here however you like, or use a React error boundary
                console.error('Failed to send feedback. Error: ', err);
              });
          } else {
            // Send registration email with link to invitation URL
            let selfReviewLink = `${process.env.REACT_APP_ADMIN_DOMAIN}/access/${this.state.accountId}/${userEmail}/`;
            let emailTitle = `You have been granted access to the ${this.state.title} Boomerang account`;
            let selfReviewButtonText = `Click here to create to your account`;
            let emailBodyCopy = `You have been granted access to a Boomerang account, click the link below to create an account.`;

            // Send Email!
            axios
              .post(`/newAdminUser`, {
                reqBody: {
                  userEmail: this.props.userEmail,
                  to: userEmail,
                  name: `${userForename} ${userSurname}`,
                  title: `Hi ${userForename} ${userSurname}`,
                  subjectLine: emailTitle,
                  btnText: selfReviewButtonText,
                  emailBodyCopy: emailBodyCopy,
                  brandLogo: this.state.accountData.logo,
                  url: selfReviewLink,
                },
                cookies: window.localStorage.getItem('Auth'),
              })
              .then((res) => {
                // console.log('SENT');

                db.doGetAccountBranding(this.state.accountId).onSnapshot((snapshot) => {
                  this.setState({
                    accountData: snapshot.data(),
                    invitingUser: false,
                    invitationSent: true,
                  });
                });
              })
              .catch((err) => {
                // Handle errors here however you like, or use a React error boundary
                console.error('Failed to send feedback. Error: ', err);
              });
          }
        })
        .catch((err) => {
          alert('There was an error adding this user, please contact the System Administrator');
        });
    }
  };

  handleAccountSubmit = (event) => {
    this.setState({
      saving: true,
    });
    let accountData = {
      title: this.title.value,
      fname: this.fname.value,
      lname: this.lname.value,
    };

    this.props.accountData.title = this.title.value;
    this.props.accountData.fname = this.fname.value;
    this.props.accountData.lname = this.lname.value;

    db.doUpdateAccountData(this.props.match.params.accountId, accountData).then((data) => {
      setTimeout(
        function() {
          this.setState({
            saving: false,
          });
        }.bind(this),
        500
      );
    });

    event.preventDefault();
  };

  handlePasswordSubmit = (event) => {
    this.setState({
      saving: true,
    });

    if (this.currentPassword.value && this.newPassword.value) {
      auth
        .doReauthenticateUser(this.currentPassword.value)
        .then(() => {
          auth
            .doPasswordUpdate(this.newPassword.value)
            .then((data) => {
              setTimeout(
                function() {
                  this.setState({
                    saving: false,
                    passwordError: null,
                  });
                }.bind(this),
                500
              );
            })
            .catch((error) => {
              // console.log(error);
              this.setState({
                saving: false,
                passwordError: error.message,
              });
            });
        })
        .catch((error) => {
          // console.log(error);
          this.setState({
            saving: false,
            passwordError: 'Incorrect password',
          });
        });
    } else {
      this.setState({
        saving: false,
        passwordError: 'Please enter both old and new passwords',
      });
    }

    // let accountData = {
    //   title: this.title.value,
    //   fname: this.fname.value,
    //   lname: this.lname.value
    // }
    //
    // this.props.accountData.title = this.title.value
    // this.props.accountData.fname = this.fname.value
    // this.props.accountData.lname = this.lname.value
    //

    event.preventDefault();
  };

  handleSubmit = (event) => {
    // console.log(this.secondarycolour.value);

    this.setState({
      saving: true,
    });

    let brandingData = {
      logo: this.logo.value,
      primarycolour: this.primarycolour.value,
      secondarycolour: this.secondarycolour.value,
      buttoncolour: this.buttoncolour.value,
    };

    this.props.accountData.logo = this.logo.value;
    this.props.accountData.primarycolour = this.primarycolour.value;
    this.props.accountData.secondarycolour = this.secondarycolour.value;
    this.props.accountData.buttoncolour = this.buttoncolour.value;

    // db.doUpdate360Branding(this.props.match.params.accountId, this.props.match.params.id, brandingData).then((data) => {
    db.doUpdateAccountData(this.props.match.params.accountId, brandingData).then((data) => {
      setTimeout(
        function() {
          this.setState({
            saving: false,
          });
        }.bind(this),
        500
      );
    });

    event.preventDefault();
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };

  handleProgress = (progress) => {
    this.setState({ progress });
  };
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (file) => {
    file.then((filename) => {
      this.setState({ logoimg: filename, progress: 100, isUploading: false });
      storage.doGetUploadedImageURL(filename).then((url) => {
        this.setState({ logo: url });
      });
    });
  };

  loginModal = (e) => {
    this.setState({ loginModal: false });

    //get the phone number input, removing any spaces
    let phoneNo = this.phoneNumber.value.replace(' ', '');

    //check if they have entered country code
    let phoneArr = phoneNo.split('');
    if (phoneArr[0] === '0') {
      phoneArr.splice(0, 1);
      phoneNo = `+44${phoneArr.join('')}`;
    }
    //if they have included a 44 at the start but not the '+', add that
    if (!phoneNo.includes('+')) {
      phoneNo = `+${phoneNo}`;
    }

    //if there is an instance of recaptcha already within the state i.e, they clicked off mid way through set up
    //then dont render a new one, use the one from the state
    let recaptchaVerifier;
    if (!this.state.reCaptcha) {
      recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('mfaBtnAccountPage'), {
        size: 'invisible',
        callback: function(response) {
          // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
          // console.log('response.data');
        },
        error: function() {},
      });
    } else {
      recaptchaVerifier = this.state.reCaptcha;
    }
    this.setState({ reCaptcha: recaptchaVerifier });
    mfaLogin(phoneNo, recaptchaVerifier)
      .then((verificationID) => {
        if (verificationID.code === 'auth/invalid-phone-number') {
          this.setState({ smsErrorModal: true, smsError: verificationID });
        } else {
          // console.log(verificationID.toString());
          this.setState({ promptVerify: true, smsID: verificationID });
        }
      })
      .catch((err) => {
        // console.log('there has been an error with the reCaptcha');
        // console.log(err);
      });
  };

  enableSMS = (e) => {
    e.preventDefault();
    this.setState({ loginModal: true });
  };

  submitSMS = (e) => {
    const smsCode = this.smsVerify.value;
    smsTokVerify(this.state.smsID, smsCode)
      .then((res) => {
        // console.log(res);
        this.setState({ smsEnroll: true });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  emailVerification = (e) => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        this.setState({ emailVerifySent: 'Email Sent' });
      })
      .catch((err) => {
        this.setState({ emailVerifySent: 'There has been an error' });
      });
  };

  removeMFA = () => {
    if (this.state.removingMFA.state === 'Confirm') {
      this.setState({ removingMFA: { state: 'Removing' } });
      const reqBody = {
        uid: firebase.auth().currentUser.uid,
        userEmail: this.props.userEmail,
      };
      axios
        .post('/removeMFA', { cookies: window.localStorage.getItem('Auth'), reqBody })
        .then((res) => {
          // console.log(res);
          this.setState({ removingMFA: { state: 'Close' } });
        })
        .catch((err) => {
          // console.log(err);
          this.setState({ removingMFA: { state: 'Error', error: err } });
        });
    } else {
      this.setState({ removeMfaModal: false, loginModal: true });
    }
  };

  render() {
    const { logo, primarycolour, secondarycolour, buttoncolour, title, email, fname, lname, error, passwordError } = this.state;

    const isInvalid = logo === '';

    const pickerStyles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
        primarycolorinput: {
          backgroundColor: primarycolour,
          color: primarycolour == '#FFFFFF' ? '#333' : '#FFF',
        },
        secondarycolorinput: {
          backgroundColor: secondarycolour,
          color: secondarycolour == '#FFFFFF' ? '#333' : '#FFF',
        },
        buttoncolorinput: {
          backgroundColor: buttoncolour,
          color: buttoncolour == '#FFFFFF' ? '#333' : '#FFF',
        },
      },
    });

    return (
      <div className='container container--slim'>
        <section className='welcome'>
          <div className='container'>
            <div className='welcome__row'>
              <h1>
                <span>Your</span> Account Settings
              </h1>
            </div>
          </div>
        </section>
        <section className='page-wrapper'>
          <form className='bg-white mb4 ph4 pv4 shadow br2' onSubmit={this.handleAccountSubmit}>
            <div className='flex'>
              <div className='w-50'>
                <p>
                  <strong>Change Your Personal Details</strong>
                </p>
              </div>
            </div>

            <div className='w-100 mb3 flex flex-wrap'>
              <div className='w-100'>
                <label htmlFor='title' className='f6 b db mb2'>
                  Account Title
                </label>
                <input
                  ref={(input) => (this.title = input)}
                  aria-describedby='title-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  defaultValue={title}
                  type='text'
                />
              </div>

              <div className='w-40 pr3'>
                <label htmlFor='fname' className='f6 b db mb2'>
                  First Name
                </label>
                <input
                  ref={(input) => (this.fname = input)}
                  aria-describedby='fname-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  defaultValue={fname}
                  type='text'
                />
              </div>

              <div className='w-40 pr3'>
                <label htmlFor='lname' className='f6 b db mb2'>
                  Last Name
                </label>
                <input
                  ref={(input) => (this.lname = input)}
                  aria-describedby='lname-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  defaultValue={lname}
                  type='text'
                />
              </div>
            </div>

            <div className='w-100 mv2'>
              <button disabled={isInvalid} type='submit' className={this.state.saving ? 'btn btn--disabled' : 'btn'}>
                Update My Details
              </button>
              <span className={this.state.saving ? 'loading-spinner' : !this.state.firstInstance ? 'loading-done' : ''} />
              {error && <h3 className='red mt4 mb0'>{error.message}</h3>}
            </div>
          </form>

          <form className='bg-white mb4 ph4 pv4 shadow br2' onSubmit={this.handlePasswordSubmit}>
            <div className='flex'>
              <div className='w-50'>
                <p>
                  <strong>Update Your Password</strong>
                </p>
              </div>
            </div>

            <div className='w-100 mb3 flex flex-wrap'>
              <div className='w-40 pr3'>
                <label htmlFor='title' className='f6 b db mb2'>
                  Old Password
                </label>
                <input
                  ref={(input) => (this.currentPassword = input)}
                  aria-describedby='title-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  type='password'
                />
              </div>

              <div className='w-40 pr3'>
                <label htmlFor='fname' className='f6 b db mb2'>
                  New Password
                </label>
                <input
                  ref={(input) => (this.newPassword = input)}
                  aria-describedby='fname-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  type='password'
                />
              </div>
            </div>

            <div className='w-100 mv2'>
              <button disabled={isInvalid} type='submit' className={this.state.saving ? 'btn btn--disabled' : 'btn'}>
                Update My Password
              </button>
              <span className={this.state.saving ? 'loading-spinner' : !this.state.firstInstance ? 'loading-done' : ''} />
              {passwordError && <h4 className='red mt4 mb0'>{passwordError}</h4>}
            </div>
          </form>

          {this.state.allowBrandEdit ? (
            <form className='bg-white mv4 ph4 pv4 shadow br2' onSubmit={this.handleSubmit}>
              <div className='flex'>
                <div className='w-50'>
                  <p>
                    <strong>Change Default Brand Colours</strong>
                  </p>
                </div>
              </div>

              <div className='w-100 mv3'>
                <label htmlFor='logo' className='f6 b db'>
                  Client Logo
                </label>
                <p className='mb2 mt0'>
                  <small>* Images should be uploaded at 400px x 112px. Images are cropped (not scaled) down.</small>
                </p>
                <input
                  ref={(input) => (this.logo = input)}
                  aria-describedby='email-desc'
                  className='input-reset ba b--black-20 pa2 mb2 db w-100'
                  value={logo}
                  type='hidden'
                />

                <div className='flex items-center'>
                  {logo && <img width='140' className='dib' src={logo} />}

                  <div className='dib ml5'>
                    <FileUploader
                      accept='image/*'
                      name='logoimg'
                      randomizeFilename
                      storageRef={storage.doGetImageRef()}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                    />
                  </div>
                </div>
              </div>

              <div className='w-100 mb3 flex flex-wrap'>
                <div className='w-40 pr3 dn'>
                  <label htmlFor='primarycolour' className='f6 b db mb2'>
                    Header Colour
                  </label>
                  <input
                    ref={(input) => (this.primarycolour = input)}
                    aria-describedby='primarycolour-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    value={primarycolour}
                    onChange={(e) => {
                      return;
                    }}
                    onClick={(e) => {
                      this.setState({ displayPrimaryColourPicker: true });
                    }}
                    type='text'
                  />
                  {this.state.displayPrimaryColourPicker ? (
                    <div style={pickerStyles.popover}>
                      <div
                        style={pickerStyles.cover}
                        onClick={(e) => {
                          this.setState({ displayPrimaryColourPicker: false });
                        }}
                      />
                      <ChromePicker color={primarycolour} onChange={(color) => this.setState(byPropKey('primarycolour', color.hex))} />
                    </div>
                  ) : null}
                </div>

                <div className='w-40 pr3 dn'>
                  <label htmlFor='secondarycolour' className='f6 b db mb2'>
                    Header Text Colour
                  </label>
                  <input
                    ref={(input) => (this.secondarycolour = input)}
                    aria-describedby='secondarycolour-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    value={secondarycolour}
                    onChange={(e) => {
                      return;
                    }}
                    onClick={(e) => {
                      this.setState({ displaySecondaryColourPicker: true });
                    }}
                    type='text'
                  />
                  {this.state.displaySecondaryColourPicker ? (
                    <div style={pickerStyles.popover}>
                      <div
                        style={pickerStyles.cover}
                        onClick={(e) => {
                          this.setState({ displaySecondaryColourPicker: false });
                        }}
                      />
                      <ChromePicker color={secondarycolour} onChange={(color) => this.setState(byPropKey('secondarycolour', color.hex))} />
                    </div>
                  ) : null}
                </div>

                <div className='w-40 pr3 d-none'>
                  <label htmlFor='buttoncolour' className='f6 b db mb2'>
                    Highlight Colour
                    <br />
                    <small>Used for button styling.</small>
                  </label>
                  <input
                    ref={(input) => (this.buttoncolour = input)}
                    aria-describedby='buttoncolour-desc'
                    className='input-reset ba b--black-20 pa2 mb2 db w-100'
                    value={buttoncolour}
                    onChange={(e) => {
                      return;
                    }}
                    onClick={(e) => {
                      this.setState({ displayButtonColourPicker: true });
                    }}
                    type='text'
                  />
                  {this.state.displayButtonColourPicker ? (
                    <div style={pickerStyles.popover}>
                      <div
                        style={pickerStyles.cover}
                        onClick={(e) => {
                          this.setState({ displayButtonColourPicker: false });
                        }}
                      />
                      <TwitterPicker
                        colors={['#FE5D00', '#FE1300', '#034EFF', '#1FA81F', '#7223B1', '#333E5B', '#000000', '#E700C1']}
                        color={buttoncolour}
                        onChange={(color) => this.setState(byPropKey('buttoncolour', color.hex))}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className='w-100 mv2'>
                <button disabled={isInvalid} type='submit' className={this.state.saving ? 'btn btn--disabled' : 'btn'}>
                  Save Branding
                </button>
                <span className={this.state.saving ? 'loading-spinner' : !this.state.firstInstance ? 'loading-done' : ''} />
                {error && <h3 className='red mt4 mb0'>{error.message}</h3>}
              </div>
            </form>
          ) : (
            ''
          )}

          <div className='bg-white mb4 ph4 pv4 shadow br2'>
            <div className='flex w-100 justify-between'>
              <div>
                <p>
                  <strong>Users with access to this account</strong>
                </p>
                {this.state.accountData.adminUsers ? (
                  <>
                    {Object.keys(this.state.accountData.adminUsers).map((key, index) => {
                      return (
                        <>
                          <p>
                            <strong>Name: </strong>
                            {this.state.accountData.adminUsers[key]['fname']} {this.state.accountData.adminUsers[key]['lname']}
                            <br />
                            <strong>Email: </strong>
                            {this.state.accountData.adminUsers[key]['email']}
                          </p>
                          <button
                            className='btn btn--red'
                            onClick={() => {
                              this.setState({
                                showRevokeAccessConfirmationModal: true,
                                accountObjToRevokeAccess: {
                                  id: this.state.accountId,
                                  title: this.state.title,
                                },
                                userObjToRevokeAccess: {
                                  email: this.state.accountData.adminUsers[key]['email'],
                                  fname: this.state.accountData.adminUsers[key]['fname'],
                                  id: this.state.accountData.adminUsers[key]['id'],
                                  lname: this.state.accountData.adminUsers[key]['lname'],
                                },
                              });
                            }}
                          >
                            Revoke Access
                          </button>
                          {Object.keys(this.state.accountData.adminUsers).length > 1 && index < Object.keys(this.state.accountData.adminUsers).length - 1 ? (
                            <hr className='bb bt-0 b--light-silver mv4' />
                          ) : (
                            ''
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <p>No alternate users setup for this account</p>
                )}
              </div>
              <div>
                <button className='btn' onClick={this.openNewUserModal}>
                  Create New Admin User
                </button>
              </div>
            </div>
          </div>
          {/*<div className='bg-white mb4 ph4 pv4 shadow br2'>
            {this.state.verified ? (
              <div className='flex w-100 justify-between'>
                <div>
                  <p>
                    <strong>Enable Multi-Factor Authentication</strong>
                  </p>
                  {!this.state.enrolled ? (
                    <div>
                      <p>
                        Please enter your mobile number below (please include country code). After clicking the submit button, you will be prompted to reverify
                        your details. Upon logging in, you will be sent a text message with a one-time password to enter onto your platform.
                        <br />
                        <br />
                        Once this has been set up, you will be required to enter your email, password and passcode (sent via SMS) each time you log in.
                      </p>
                      <p>
                        Phone Number (including country code)
                        <br />
                        e.g. +447700900973
                      </p>
                      <div className='w-50'>
                        <input type='text' placeholder='+447700900973' ref={(input) => (this.phoneNumber = input)} />
                      </div>
                      <div id='mfaBtnAccountPage' className='mfaBtnAccountPage'></div>
                      <button id='' className='btn' onClick={(e) => this.enableSMS(e)}>
                        Submit
                      </button>
                    </div>
                  ) : (
                    <p>Your account currently already has Multi-Factor Authentication enabled</p>
                  )}
                </div>
              </div>
            ) : (
              <div className='flex w-100 justify-between'>
                <div>
                  <p>
                    <strong>Enable Multi-Factor Authentication</strong>
                  </p>
                  {!this.state.enrolled ? (
                    <div>
                      <p>
                        In order to enable multi-factor authentication your email address needs to be verified. Please click the button below to send a
                        verification email to your registered email address.
                        <br />
                        <br />
                        If you have verified your email address using the button below and are still not being given the option to set up multi-factor
                        authentication then please refresh the page.
                      </p>
                      <button id='' className='btn' onClick={(e) => this.emailVerification(e)}>
                        {this.state.emailVerifySent ? this.state.emailVerifySent : 'Submit'}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <p>Your account currently already has Multi-Factor Authentication enabled</p>
                      <button
                        className='btn'
                        onClick={() => {
                          this.setState({ removeMfaModal: true });
                        }}
                      >
                        Remove Multi-Factor Authentication
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
                    </div>*/}
        </section>

        <Modal
          isOpen={this.state.showNewUserModal}
          contentLabel='Add a new User to this Account'
          onClose={() => this.setState({ showNewUserModal: false })}
          content={
            <div>
              <h2>Add a new admin user to this Account</h2>
              <p>
                This user will recieve an invitation email to administer the <strong>{this.state.accountData.title}</strong> account
              </p>
              <form className='mw6 center pt2 pb2' onSubmit={this.handleNewUserInvite}>
                <label htmlFor='forename'>First Name</label>
                <input required ref={(input) => (this.forename = input)} aria-describedby='forename-desc' type='text' placeholder='Joe' />
                <label htmlFor='surname'>Last Name</label>
                <input required ref={(input) => (this.surname = input)} aria-describedby='surname-desc' type='text' placeholder='Blogs' />
                <label htmlFor='useremail'>Email</label>
                <input required ref={(input) => (this.useremail = input)} aria-describedby='useremail-desc' type='email' placeholder='email@example.com' />
                <button className='btn' disabled={this.state.invitingUser} type='submit'>
                  Invite User
                </button>
                <span className={this.state.invitingUser ? 'loading-spinner' : ''} />
              </form>
              {this.state.invitationSent ? <p>User has been granted access to this account</p> : ''}
              <p className='red'>{this.state.newUserModalErrorMessage}</p>
            </div>
          }
        />

        <Modal
          isOpen={this.state.showRevokeAccessConfirmationModal}
          contentLabel='Add a new User to this Account'
          onClose={() => this.setState({ showRevokeAccessConfirmationModal: false })}
          content={
            <div>
              <h2>Are you sure you want to revoke access for this user?</h2>
              <button className='btn btn--red' disabled={this.state.revokingUserAccess} onClick={this.revokeUserAccess}>
                Confirm
              </button>
              <p className='red'>{this.state.newUserModalErrorMessage}</p>
            </div>
          }
        />

        <Modal
          isOpen={this.state.loginModal}
          contentLabel='Prompt user to log in'
          onClose={() => {
            this.setState({ loginModal: false });
          }}
          content={<SignInForm page={this.state.removingMFA.state === 'Close' ? 'modal' : 'mfa'} closeModal={() => this.loginModal()} />}
        />

        <Modal
          isOpen={this.state.promptVerify}
          contentLabel='Prompt user to log in'
          onClose={() => {
            this.setState({ promptVerify: false });
          }}
          content={
            this.state.smsEnroll ? (
              <div>
                <h1>Success</h1>
                <p>You have successfully enabled SMS Multi-Factor Authentication</p>
                <button
                  className='btn'
                  onClick={() => {
                    this.setState({ promptVerify: false, loginModal: true, loginModalVerify: true });
                  }}
                >
                  Close
                </button>
              </div>
            ) : (
              <div>
                <h1>Please Enter SMS Code</h1>
                <input type='text' ref={(input) => (this.smsVerify = input)} />
                <button
                  className='btn'
                  onClick={(e) => {
                    this.submitSMS(e);
                  }}
                >
                  Submit
                </button>
              </div>
            )
          }
        />
        <Modal
          isOpen={this.state.smsErrorModal}
          contentLabel='Error with SMS Enroll'
          onClose={() => {
            this.setState({ smsErrorModal: false });
          }}
          content={
            this.state.smsError ? (
              <div>
                <h2>There has been an error</h2>
                {this.state.smsError.message === 'Invalid format.' ? (
                  <p>Please enter the phone number in a valid format e.g. +447700900973</p>
                ) : (
                  <p>{this.state.smsError.message}</p>
                )}
                <button
                  className='btn'
                  onClick={(e) => {
                    this.setState({ smsEnrollModa: false });
                  }}
                >
                  Close
                </button>
              </div>
            ) : (
              ''
            )
          }
        />
        <Modal
          isOpen={this.state.removeMfaModal}
          contentLabel='Remove Multi-Factor Authentication from this account'
          onClose={() => this.setState({ removeMfaModal: false, removingMFA: { state: '', error: '' } })}
          content={
            <div>
              <h2>Are you sure you wish to remove Multi-Factor Authentication from this account?</h2>
              <button className='btn btn--red' disabled={this.state.removingMFA.state === 'Removing'} onClick={() => this.removeMFA()}>
                {this.state.removingMFA.state}
              </button>
              {this.state.removingMFA.state === 'Close' ? <p>Multi-Factor Authentication removed</p> : ''}
              <p className='red'></p>
            </div>
          }
        />
      </div>
    );
  }
}

//
export default withAuthorisation(authCondition)(Account);
