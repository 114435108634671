import React, { Component } from 'react';
import { db } from '../firebase';
import withAuthorisation from '../components/withauthorisation';
import Dropdown from '../components/global/dropdown';
import List360 from '../components/list360';
import Modal from '../components/global/modal';
import Autocomplete from 'react-autocomplete';
import CreateNewProject from '../components/CreateNewProject';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
const authCondition = (authUser) => !!authUser;

let btnBrandCol;

class TeamAssessmentProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      settingUpProject: false,
      noprojects: true,
      accountId: this.props.match.params.accountId,
      accountData: this.props.accountData,
      user360s: {},
      clientList: '',
      clientName: '',
      showNew360Modal: false,
      new360Type: 'Team Assessment',
      filteredByClient: null,
    };

    this.createNew360 = this.createNew360.bind(this);
    this.closeNew360Modal = this.closeNew360Modal.bind(this);
    this.openNew360Modal = this.openNew360Modal.bind(this);
    this.fireClientFilter = this.fireClientFilter.bind(this);
    this.duplicate360 = this.duplicate360.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let clientList = {};
    let archivedTeamAssessments = {};
    let activeTeamAssessments = {};
    let clientItemList = [];
    let clientItemListFilter = [
      {
        id: -1,
        title: 'All clients',
        selected: false,
        key: 'All',
      },
    ];
    let activeGroupedByClient = {};

    db.doGet360s(this.state.accountId).then((snapshot) => {
      if (!snapshot.empty) {
        // creates an array of child arrays, each child consists of the 360ID [0] and the data for that 360 [1]
        // then filters out any 360s that aren't of the Team Assessment type
        const filtered360s = snapshot.docs.map((doc) => [doc.id, doc.data()]).filter((data) => data[1].type === 'Team Assessment');

        let teamAssessments = {};
        let count = 0;
        filtered360s.forEach((tA) => {
          const id = tA[0];
          const data = tA[1];
          const clientName = data['client'];
          // adds each Assessment to the teamAssessmennt object
          teamAssessments[id] = data;
          if (clientName) {
            // adds client to clientList with an empty array value
            if (!clientList[clientName]) clientList[[clientName]] = [];
            // [adds data for each Team Assessment to the clientList]
            clientList[clientName].push(data);
          }
          // puts each Assessment in an archived or active object. Also adds active Assessments as children of clients in another object
          if (data['archived']) {
            archivedTeamAssessments[id] = data;
          } else {
            activeTeamAssessments[id] = data;
            if (!activeGroupedByClient[clientName]) {
              activeGroupedByClient[clientName] = {};
            }
            activeGroupedByClient[clientName][id] = data;
          }

          if (clientItemListFilter.find((item) => item.key === clientName) === undefined) {
            clientItemListFilter.push({
              id: count,
              title: clientName,
              selected: false,
              key: clientName,
            });
            clientItemList.push({
              id: count,
              label: clientName,
            });
            count++;
          }
        });

        let user360s = {};
        snapshot.docs.map((doc) => {
          user360s[doc.id] = doc.data();
        });
        if (user360s) {
          this.setState({
            teamAssessments: teamAssessments,
            archivedTeamAssessments: archivedTeamAssessments,
            activeTeamAssessments: activeTeamAssessments,
            clientItemList: clientItemList,
            clientItemListFilter: clientItemListFilter,
            activeGroupedByClient: activeGroupedByClient,
            user360s: user360s,
            clientList: clientList,
            noprojects: false,
            loading: false,
          });
        } else {
          this.setState({
            noprojects: true,
            loading: false,
          });
        }
      } else {
        this.setState({
          noprojects: true,
          loading: false,
        });
      }
    });
  }

  closeNew360Modal() {
    this.setState({ showNew360Modal: false });
  }

  openNew360Modal(type) {
    this.setState({ showNew360Modal: true, new360Type: type });
  }

  createNew360(e) {
    db.doCreate360(this.state.accountId, this.title.value, this.state.clientName, this.state.new360Type, this.state.accountData)
      .then((data) => {
        let new360ID = data.id;
        this.setState({
          clientName: '',
        });
        db.doCreateNewQuestionnaire(this.state.accountId, this.state.new360Type).then((data) => {
          let newQuestionnaireID = data.id;
          db.doAssignQuestionnaireTo360(this.state.accountId, newQuestionnaireID, new360ID).then((data) => {
            db.addDefaultQuestionnaireLabels(this.state.accountId, newQuestionnaireID).then(() => {
              this.setState({
                settingUpProject: false,
              });
              this.closeNew360Modal();
              this.props.history.push(`/dashboard/${this.state.accountId}/360s/${new360ID}/edit/`);
            })
          });
        });
      })
      .catch((error) => {
        this.setState(byPropKey('error', error));
      });
    e.preventDefault();
  }

  fireClientFilter(id, key) {
    if (id === -1) {
      this.setState({
        filteredByClient: null,
      });
    } else {
      this.setState({
        filteredByClient: key,
      });
    }
  }

  duplicate360(accountID, title, index, comparisonCheck) {
    db.doDuplicate360(accountID, title, index, comparisonCheck)
      .then((new360ID) => {
        this.props.history.push(`/dashboard/${this.state.accountId}/360s/${new360ID}/edit/`);
      })
      .catch((err) => {});
  }

  render() {
    return (
      <div className='container container--slim'>
        <section className='welcome'>
          <div className='container'>
            <div className='welcome__row'>
              <h1>
                <span>Your</span> Team Assessment Projects
              </h1>
            </div>
          </div>
        </section>
        {this.state.loading ? (
          <p>Loading</p>
        ) : (
          <section className='page-wrapper'>
            {Object.keys(this.state.activeTeamAssessments).length ? (
              <div>
                <div className='mb3 flex justify-end'>
                  {!this.props.accountData.clientname ? (
                    <div>
                      <p>
                        <strong>Filter by:</strong>
                        <Dropdown title='All clients' list={this.state.clientItemListFilter} setTitleOnSelect={true} resetThenSet={this.fireClientFilter} />
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {Object.keys(this.state.activeGroupedByClient)
                  .sort()
                  .map((key) => {
                    return this.state.filteredByClient ? (
                      this.state.filteredByClient === key ? (
                        <div className='client-group' key={key}>
                          {Object.keys(this.state.activeGroupedByClient[key]).map((key) => {
                            return (
                              <List360
                                key={key}
                                index={key}
                                userDetails={this.props.userDetails}
                                selectedAccount={this.state.accountId}
                                accountTitle={this.state.accountTitle}
                                current360={this.state.teamAssessments[key]}
                                accountId={this.state.accountId}
                                duplicate360={this.duplicate360}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      <div className='client-group' key={key}>
                        <p>
                          <strong>{key}</strong>
                        </p>
                        {Object.keys(this.state.activeGroupedByClient[key]).map((key) => {
                          return (
                            <List360
                              key={key}
                              index={key}
                              userDetails={this.props.userDetails}
                              selectedAccount={this.state.accountId}
                              accountTitle={this.state.accountTitle}
                              current360={this.state.teamAssessments[key]}
                              accountId={this.state.accountId}
                              duplicate360={this.duplicate360}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            ) : (
              ''
            )}
            <div className='flex w-100 justify-center'>
              <CreateNewProject projectType='Team Assessment' containerClass='create-new-panel--team-assessment' openNew360Modal={this.openNew360Modal} />
            </div>
            {Object.keys(this.state.archivedTeamAssessments).length ? (
              <div>
                <div className='mv4'>
                  <h1 className='mb2'>Archived Team Assessment projects</h1>
                  <p>These Team Assessment projects are currently archived, you are still able to view all the Reports associated to them</p>
                </div>
                {Object.keys(this.state.archivedTeamAssessments).map((key) => {
                  return <List360 key={key} index={key} current360={this.state.teamAssessments[key]} accountId={this.state.accountId} />;
                })}
              </div>
            ) : (
              ''
            )}
          </section>
        )}

        <Modal
          isOpen={this.state.showNew360Modal}
          contentLabel='Add a new Team Assessment'
          onClose={() => this.closeNew360Modal()}
          content={
            <div>
              <h2>Create a new Team Assessment project</h2>
              <form onSubmit={this.createNew360}>
                <label htmlFor='title' className='f6 b db mb2'>
                  Project Name
                </label>
                <input
                  required
                  ref={(input) => (this.title = input)}
                  aria-describedby='name-desc'
                  className='input-reset ba b--black-20 pa2 mb4 db w-100'
                  type='text'
                  placeholder='Name your Team Assessment'
                />
                {!this.props.accountData.clientname ? (
                  <label htmlFor='client' className='f6 b db mb2'>
                    Client
                  </label>
                ) : (
                  ''
                )}

                {this.state.showNew360Modal && !this.props.accountData.clientname ? (
                  <Autocomplete
                    ref={(el) => (this.client = el)}
                    items={this.state.clientItemList}
                    shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    getItemValue={(item) => item.label}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        className='input-reset bb bl br b--black-20 pa2 db w-100'
                        style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                      >
                        {item.label}
                      </div>
                    )}
                    renderInput={(props) => (
                      <input
                        required
                        aria-describedby='client-desc'
                        {...props}
                        className='input-reset ba b--black-20 pa2 mb4 db w-100'
                        type='text'
                        placeholder='Select a Client, or create new'
                      />
                    )}
                    value={this.state.clientName}
                    onChange={(e) => this.setState({ clientName: e.target.value })}
                    onSelect={(value) => this.setState({ clientName: value })}
                    wrapperStyle={{
                      position: 'relative',
                      zIndex: '999',
                    }}
                    menuStyle={{
                      top: '100%',
                      left: 0,
                      position: 'absolute',
                    }}
                  />
                ) : (
                  <input
                    required
                    aria-describedby='client-desc'
                    ref={(el) => (this.client = el)}
                    defaultValue={this.props.accountData.clientname}
                    className='input-reset ba b--black-20 pa2 mb4 db w-100'
                    type={this.props.accountData.clientname ? `hidden` : `text`}
                    placeholder='Select a Client, or create new'
                  />
                )}
                <button className='btn' disabled={this.state.settingUpProject} type='submit'>
                  Submit
                </button>
              </form>
            </div>
          }
        />
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(TeamAssessmentProjects);
