import React, { Component } from 'react';
import { arrayGenerator, valueCheck, percentageCheck } from '../../helpers';
import QuestionRow from './questionRow';

class DetailedFeedbackChart extends Component {
  constructor(props) {
    super(props);
    this.getRowHeight = this.getRowHeight.bind(this);

    this.state = {
      rowLimit: 6,
    };
  }

  getRowHeight(height) {
    // calculates rowLimit to prevent overflow in charts when exported to PDF
    let rowLimit = 6;
    if (height > 124) {
      rowLimit = 3;
    } else if (height > 109) {
      rowLimit = 4;
    } else if (height > 95) {
      rowLimit = 5;
    }
    this.setState({ rowLimit });
  }

  render() {
    const rowLimit = this.state.rowLimit;

    const {
      sectionID,
      sectionNum,
      questions,
      secLengthNoFreeText,
      revieweeCalcs,
      sectionDetails,
      allUserPercentArr,
      allUserPercentSecArr,
      colours,
      userCount,
      revieweeAnswersObj,
      revieweeAnswerCountArr,
      reviewerAnswersObj,
      reviewerAnswersAverage,
      reviewerAnswerCountArr,
      reviewerAnswerCountBySec,
      reviewerCalcs,
      current360Relationships,
      reviewerCountArr,
      reviewerSecCount,
      sectionsLegacyMode,
      loading,
    } = this.props;

    let revieweeResponses = [...userCount[sectionID]].reverse()

    // Uses rowlimit to calculate how many pages will be needed, creates an array of respective length and maps through it to print chart
    return arrayGenerator(Math.ceil(secLengthNoFreeText[sectionID] / rowLimit)).map((c, oIndex) => {
      // assigns indexes to split the chart to prevent overflow in PDF format
      const breakStart = rowLimit * oIndex;
      const breakEnd = rowLimit * (oIndex + 1);
      return (
        <>
          {secLengthNoFreeText[sectionID] <= 0 ? (
            ''
          ) : (
            <>
              {revieweeCalcs.averagesBySection[sectionID].toFixed(1) !== 0 && revieweeCalcs.averagesBySection[sectionID] !== undefined ? (
                <>
                  <div key={sectionID} id={sectionID} className='mb4 page-break user-report__content user-report__content--no-pad'>
                    <div className='average-rating-section'>
                      <h2 className='f2 mv0 mt4'>
                        Part 2: <strong>Step {sectionNum + 2}</strong>
                      </h2>
                      <h3 className='f5 gray'>Your detailed feedback{oIndex > 0 ? ' (continued)' : ''}</h3>
                      <div className='ba b--moon-gray mb10 table page-break'>
                        <div className='row flex bg-light-gray'>
                          <div className='w-30 pt1 pb0'></div>
                          <div className='w-70'>
                            <div className='w-100 flex flex-wrap table__row pl1'>
                              <div className='w-20 pt1 pb0'>
                                <strong>Relationship</strong>
                              </div>
                              <div className='w-50 pt1 pb0'>
                                <strong>Average Rating</strong>
                              </div>
                              <div className='w-30 pt1 pb0'>
                                <div className='tc'>
                                  <strong>Number of responses</strong>
                                </div>
                                <div className='w-100 flex'>
                                  {[5, 4, 3, 2, 1, '?'].map((c) => {
                                    return <p className='w-100 mv0 tc'>{c}</p>;
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {oIndex > 0 ? (
                          <></>
                        ) : (
                          <div className='row pt1 pb1 pl2 flex bt bb b--moon-gray'>
                            <div className='w-30'>
                              <p className='ma0 f6'>
                                <strong>{sectionDetails[sectionID].title}</strong>
                              </p>
                            </div>
                            <div className='w-70'>
                              <div className='w-100 flex table__row'>
                                <div className='w-20 mv1'>
                                  <p className='ma0 gray'>
                                    <strong>Distribution</strong>
                                  </p>
                                </div>
                                <div className='w-50 mv1'>
                                  <div className='w-100 h-100'>
                                    <p className='flex ma0 h-100'>
                                      <strong className='w-10'></strong>

                                      <span className='w-100 bg-light-gray flex'>
                                        {allUserPercentSecArr[sectionID].reverse().map((ans, k) => {
                                          return <span className='h-100 db' style={{ width: `${ans.toFixed(1)}%`, backgroundColor: `${colours[k]}` }} />;
                                        })}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className='w-30 mv1'>
                                  <div className='w-100 flex flex-row-reverse'>
                                    {allUserPercentSecArr[sectionID].reverse().map((ans) => {
                                      return <p className='w-100 mv0 tc'>{ans.toFixed(0)}%</p>;
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className='w-100 flex flex-wrap table__row '>
                                <div className='w-20 mv1'>
                                  <p className='ma0 blue'>
                                    <strong>Self</strong>
                                  </p>
                                </div>
                                <div className='w-50 mv1'>
                                  <div className='w-100'>
                                    <p className='flex ma0'>
                                    <strong className='w-10'>{valueCheck(revieweeCalcs.averagesBySection[sectionID].toFixed(1))}</strong>
                                      <span className='w-100 bg-light-gray'>
                                        <span className='bg-blue h-100 db' style={{ width: `${(percentageCheck(revieweeCalcs.averagesBySection[sectionID].toFixed(1)) / 5) * 100}%` }} ></span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className='w-30 pv1'>
                                  <div className='w-100 flex'>
                                    {revieweeResponses.map((response, n) => {
                                      return isNaN(response.toString()) ? <></> : <p key={n} className='w-100 mv0 tc'><strong>{response}</strong></p>
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className='w-100 flex table__row'>
                                <div className='w-20 mv1'>
                                  <p className='ma0 gray'>
                                    <strong>All Reviewers</strong>
                                  </p>
                                </div>
                                <div className='w-50 mv1'>
                                  <div className='w-100'>
                                    <p className='flex ma0'>
                                      <strong className='w-10'>{valueCheck(reviewerAnswersAverage[sectionID].toFixed(1))}</strong>
                                      <span className='w-100 bg-light-gray'>
                                        <span
                                          className='bg-gray h-100 db'
                                          style={{ width: `${(percentageCheck(reviewerAnswersAverage[sectionID].toFixed(1)) / 5) * 100}%` }}
                                        ></span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className='w-30 mv1'>
                                  <div className='w-100 flex flex-row-reverse'>
                                    {reviewerAnswerCountBySec[sectionID].map((ans) => {
                                      return (
                                        <p className='w-100 mv0 tc'>
                                          <strong>{ans}</strong>
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              {Object.keys(reviewerCalcs)
                                .reverse()
                                .map((key, index) => {
                                  if (Object.keys(reviewerCalcs[key].answersBySection).length > 0) {
                                    let originalRelationshipKey = current360Relationships[key].name;
                                    let relationshipID = key;
                                    let secAverage = parseFloat(reviewerCalcs[relationshipID].averagesBySection[sectionID]).toFixed(1);
                                    //go to here
                                    return (
                                      <div key={relationshipID} className='w-100 flex table__row'>
                                        <div className='w-20 mv1'>
                                          <p className='ma0' style={{ color: current360Relationships[relationshipID]['colour'] }}>
                                            <strong>{current360Relationships[relationshipID]['name']}</strong>
                                          </p>
                                        </div>
                                        <div className='w-50 mv1'>
                                          <div className='w-100'>
                                            <p className='flex ma0'>
                                              <strong className='w-10' style={{ color: current360Relationships[relationshipID]['colour'] }}>
                                                {secAverage ? valueCheck(secAverage) : valueCheck(reviewerCalcs[relationshipID].averagesBySection[sectionID].toFixed(1))}
                                              </strong>
                                              <span className='w-100 bg-light-gray'>
                                                <span
                                                  className='bg-blue h-100 db'
                                                  style={{
                                                    background: current360Relationships[relationshipID]['colour'],
                                                    width: `${
                                                      (secAverage ? percentageCheck(secAverage) / 5 : percentageCheck(reviewerCalcs[relationshipID].averagesBySection[sectionID].toFixed(1)) / 5) * 100
                                                    }%`,
                                                  }}
                                                ></span>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className='w-30 mv1'>
                                          <div className='w-100 flex flex-row-reverse'>
                                            {Object.keys(reviewerSecCount[relationshipID]).includes(sectionID)
                                              ? reviewerSecCount[relationshipID][sectionID].map((val, i) => {
                                                  return (
                                                    <p className='w-100 mv0 tc'>
                                                      {reviewerSecCount[relationshipID][sectionID] !== undefined ? (isNaN(val) == false ? val : '0') : '0'}
                                                    </p>
                                                  );
                                                })
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        )}

                        {Object.keys(questions)
                          .sort((a, b) => {
                            return sectionsLegacyMode ? questions[a].created - questions[b].created : questions[a].sortOrder - questions[b].sortOrder;
                          })
                          .map((key, index) => {
                            let questionID = key;
                            return index >= breakStart && index < breakEnd && questions[questionID]['answerType'] != 'FreeText' ? (
                              <>
                                <QuestionRow
                                  sectionIndex={sectionNum}
                                  questionIndex={index}
                                  sectionID={sectionID}
                                  questionID={questionID}
                                  questionTitle={questions[questionID].questionTitle}
                                  allUserPercentArr={allUserPercentArr}
                                  colours={colours}
                                  revieweeAnswersObj={revieweeAnswersObj}
                                  revieweeAnswerCountArr={revieweeAnswerCountArr}
                                  reviewerAnswersObj={reviewerAnswersObj}
                                  reviewerAnswerCountArr={reviewerAnswerCountArr}
                                  reviewerCalcs={reviewerCalcs}
                                  current360Relationships={current360Relationships}
                                  reviewerCountArr={reviewerCountArr}
                                  getRowHeight={this.getRowHeight}
                                  loading={loading}
                                />
                              </>
                            ) : (
                              ''
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </>
          )}
        </>
      );
    });
  }
}

export default DetailedFeedbackChart;
