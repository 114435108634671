import React, { Component } from 'react';

export default class RelationshipLabel extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    return (
      <div className={this.props.containerClass}>
        <p className={this.props.pClass}>
          <strong>{this.props.relationship}</strong>
        </p>
      </div>
    );
  }
}
