import React, { Component } from 'react';
import {valueCheck, percentageCheck} from '../../helpers'

export default class HorizontalBar extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    const {containerClass, pClass, valueRating, barRating} = this.props
    return (
      <div className={containerClass}>
        <div className='w-100'>
          <p className={pClass}>
            {/* rating value */}
            <strong className='w-10'>{valueRating}</strong>
            <span className='w-100 bg-light-gray'>
              {/* rating bar */}
              <span className='bg-blue h-100 db' style={{ width: `${barRating * 100}%` }}></span>
            </span>
          </p>
        </div>
      </div>
    );
  }
}
