import React, { Component } from 'react';
import {valueCheck, percentageCheck} from '../../helpers'

export default class ComparsionRow extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    let questionID = this.props.questionID;
    let sectionID = this.props.sectionID;
    let nullLoop = this.props.nullLoop;
    return (
      <div key={questionID + sectionID} className='row pt1 pb1 pl2 flex bt b--moon-gray page-break'>
        <div className='w-30 pr3 class'>
          <p className='ma0'>{this.state.sectionDetails[sectionID].questions[questionID].questionTitle}</p>
        </div>
        <div className='w-70 flex flex-wrap'>
          <div className='w-100 flex table__row'>
            <div className='w-20 mv1'>
              <p className='ma0 blue'>
                <strong>Self</strong>
              </p>
            </div>
            <div className='w-50 mv1'>
              <div className='w-100'>
                <p className='flex ma0'>
                  <strong className='w-10'>{valueCheck(this.state.userIndividualAnswers[questionID].toFixed(1))}</strong>

                  <span className='w-100 bg-light-gray'>
                    <span className='bg-blue h-100 db' style={{ width: `${(percentageCheck(this.state.userIndividualAnswers[questionID].toFixed(1)) / 5) * 100}%` }}></span>
                  </span>
                </p>
              </div>
            </div>
            <div className='w-30 mv1'>
              <div className='w-100 flex flex-row-reverse'>
                {/* This loops through and displays the answer counts for the user questions, not the section average */}
                {Object.keys(this.state.userResponses[sectionID]).map((key, i) => {
                  return (
                    <p className='w-100 mv0 tc'>
                      <strong>{this.state.userIndividualAnswers[questionID] == i ? '1' : '0'}</strong>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='w-100 flex flex-wrap table__row '>
            <div className='w-20 mv1'>
              <p className='ma0 blue'>
                <strong></strong>
              </p>
            </div>
            <div className='w-50 mv1'>
              <div className='w-100'>
                <p className='flex ma0'>
                  <strong className='w-10'>{valueCheck(parseFloat(this.state.previous360Scores.userAnswers[sectionID].answers[questionID]).toFixed(1))}</strong>
                  <span className='w-100 bg-light-gray'>
                    <span
                      className='bg-blue h-100 db'
                      style={{
                        width: `${percentageCheck(parseFloat(this.state.previous360Scores.userAnswers[sectionID].answers[questionID]).toFixed(1) / 5) * 100}%`,
                        backgroundColor: `
                                          #0051ff`,
                        opacity: '0.4',
                      }}
                    ></span>
                  </span>
                </p>
              </div>
            </div>
            <div className='w-30 mv1'>
              <div className='w-100 flex flex-row-reverse'>
                {Object.keys(this.state.previous360Scores.userQuestionCount[sectionID][questionID]).map((key, i) => {
                  return (
                    <p className='w-100 mv0 tc'>
                      <strong>{this.state.previous360Scores.userQuestionCount[sectionID][questionID][key]}</strong>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          {Object.keys(this.state.current360Relationships)
            .reverse()
            .map((key, index) => {
              let originalRelationshipKey = key;
              let relationshipID = key;
              return (
                <>
                  <div key={key} className='w-100 flex table__row'>
                    <div className='w-20 mv1'>
                      <p className='ma0' style={{ color: this.state.current360Relationships[key]['colour'] }}>
                        <strong>{this.state.current360Relationships[key]['name']}</strong>
                      </p>
                    </div>
                    <div className='w-50 mv1'>
                      <div className='w-100'>
                        <p className='flex ma0'>
                          <strong className='w-10' style={{ color: this.state.current360Relationships[key]['colour'] }}>
                              {this.state.questionAverageByRelationship[key] !== undefined ? valueCheck(parseFloat(this.state.questionAverageByRelationship[originalRelationshipKey][questionID]).toFixed(1)) : 'N/A'}
                          </strong>
                          <span className='w-100 bg-light-gray'>
                            <span
                              className='bg-blue h-100 db'
                              style={{
                                background: this.state.current360Relationships[key]['colour'],
                                width:
                                this.state.questionAverageByRelationship[key] !== undefined ? `${percentageCheck(parseFloat(this.state.questionAverageByRelationship[key][questionID]).toFixed(1) / 5)* 100}%` : '0%',
                              }}
                            ></span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='w-30 mv1'>
                      <div className='w-100 flex flex-row-reverse'>
                        {this.state.questionAverageByRelationship[key] !== undefined
                          ? Object.keys(this.state.userResponses[sectionID]).map((key, i) => {
                              let count = 0;
                              Object.keys(this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey]).map((key, index) => {
                                if (this.state.completedReviewerAnswersByRelationship[questionID][originalRelationshipKey][index] == i) {
                                  count++;
                                }
                              });

                              return <p className='w-100 mv0 tc'>{count}</p>;
                            })
                          : Object.keys(nullLoop).map((key) => {
                              return <p className='w-100 mv0 tc'>0</p>;
                            })}
                      </div>
                    </div>
                  </div>
                  <div key={key + 'prev'} className='w-100 flex table__row'>
                    <div className='w-20 mv1'>
                      <p className='ma0' style={{ color: this.state.current360Relationships[key]['colour'] }}>
                        <strong></strong>
                      </p>
                    </div>
                    <div className='w-50 mv1'>
                      <div className='w-100'>
                        <p className='flex ma0'>
                          <strong className='w-10' style={{ color: this.state.current360Relationships[key]['colour'] }}>
                            {valueCheck(parseFloat(this.state.previous360Scores.averageByQuestion[originalRelationshipKey][questionID]).toFixed(1))}
                          </strong>
                          <span className='w-100 bg-light-gray'>
                            <span
                              className='bg-blue h-100 db'
                              style={{
                                background: `${this.state.current360Relationships[key]['colour']}`,
                                width: `${
                                  percentageCheck(parseFloat(this.state.previous360Scores.averageByQuestion[originalRelationshipKey][questionID]).toFixed(1) / 5) * 100
                                }%`,
                                opacity: '0.4',
                              }}
                            ></span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className='w-30 mv1'>
                      <div className='w-100 flex flex-row-reverse'>
                        {Object.keys(this.state.previous360Scores.reviewerAnswerCount[originalRelationshipKey][sectionID][questionID]).map((key, i) => {
                          return (
                            <p className='w-100 mv0 tc'>
                              {this.state.previous360Scores.reviewerAnswerCount[originalRelationshipKey][sectionID][questionID][key]}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              );
              {
                /* aaaaa */
              }
            })}
        </div>
      </div>
    );
  }
}
