import React, { Component } from 'react';

export default class TitlePage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='title-page page-break'>
        <div className='w-60'>
          {this.props.h1Text ? <h1 className='f3 mv0 mb3 light-blue'>{this.props.h1Text}</h1> : <></>}
          <h2 className={`f2 mv0 ${this.props.h2Class}`}>{this.props.h2Text}</h2>
        </div>
      </div>
    );
  }
}
