import React from 'react';
import { auth } from '../firebase';
import { IconCross } from './../svg/svgs'

const SignOutButton = () =>
  <button
    className="site-header__nav-btn"
    type="button"
    onClick={auth.doSignOut}
  ><IconCross width="20" height="20" /> <span>Sign Out</span></button>

export default SignOutButton;
