import React, { Component } from 'react';
import { compose } from 'recompose';
import { db } from '../firebase';
import withAuthorisation from '../components/withauthorisation';
import withUser360s from '../components/withuser360s';
import { Link } from 'react-router-dom';
import Dropdown from '../components/global/dropdown';
import List360 from '../components/list360';
import Breadcrumbs from '../components/breadcrumbs';
import Modal from '../components/global/modal';
import Autocomplete from 'react-autocomplete';
import { lightBoxStyles } from '../constants/lightbox.js';
import CreateNewProject from '../components/CreateNewProject'

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
const authCondition = (authUser) => !!authUser;
// // console.log(authUser)
// const aaa = foo

let btnBrandCol;

class All360Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      settingUpProject: false,
      noprojects: true,
      accountId: this.props.match.params.accountId,
      accountData: this.props.accountData,
      // selectedAccount: null,
      // accounts: this.props.userDetails.accounts,
      // userDetails: this.props.userDetails,
      // accountTitle: this.props.accountTitle,
      user360s: {},
      clientList: '',
      clientName: '',
      showNew360Modal: false,
      new360Type: '360',
      filteredByClient: null,
    };

    this.createNew360 = this.createNew360.bind(this);
    this.closeNew360Modal = this.closeNew360Modal.bind(this);
    this.openNew360Modal = this.openNew360Modal.bind(this);
    this.fireClientFilter = this.fireClientFilter.bind(this);
    this.duplicate360 = this.duplicate360.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let clientList = {};

    db.doGet360s(this.state.accountId).then((snapshot) => {
      if (!snapshot.empty) {
        let user360s = {};
        snapshot.docs.map((doc) => {
          user360s[doc.id] = doc.data();
        });

        if (user360s) {
          // Loop through clients list and create list of 360s grouped by client name...
          Object.keys(user360s).map((key) => {
            let clientName = user360s[key]['client'];
            if (clientName) {
              if (!clientList[clientName]) clientList[[clientName]] = [];

              clientList[clientName].push(user360s[key]);
            }
          });

          this.setState({
            user360s: user360s,
            clientList: clientList,
            noprojects: false,
            loading: false,
          });
        } else {
          this.setState({
            noprojects: true,
            loading: false,
          });
        }
      } else {
        this.setState({
          noprojects: true,
          loading: false,
        });
      }
    });
  }

  // componentDidUpdate(){
  //   db.defaultBtnColours(this.state.accountId).then((res) => {
  //     const defaultCol = "#0051ff"
  //       const accData = res.data()
  //       if(accData.buttoncolour){
  //         // console.log(accData.buttoncolour)
  //         if(accData.buttoncolour !== defaultCol){
  //           btnBrandCol = accData.buttoncolour

  //           const btnList2 = document.querySelectorAll('.btn')
  //           const h1Col = document.querySelectorAll('h1')
  //           const h2Col = document.querySelectorAll('h2')
  //           const newPanel = document.querySelectorAll('.create-new-panel')
  //           // console.log(newPanel)
  //           for (let i = 0; i < btnList2.length; i++) {
  //             if (!btnList2[i].className.includes('btn--white')){
  //               btnList2[i].style.backgroundColor = btnBrandCol
  //             }
  //           }
  //           for (let i = 0; i < h1Col.length; i++) {
  //               h1Col[i].style.color = btnBrandCol
  //           }
  //           for (let i = 0; i < h2Col.length; i++) {
  //               h2Col[i].style.color = btnBrandCol
  //           }
  //           for (let i = 0; i < newPanel.length; i++) {
  //             if (!newPanel[i].className.includes('create-new-panel--goal-tracker')){
  //               newPanel[i].style.backgroundColor = btnBrandCol
  //             }
  //           }

  //         }
  //         else{
  //           return
  //         }
  //       }
  //       else{
  //         return
  //       }
  //   })

  // }

  closeNew360Modal() {
    this.setState({ showNew360Modal: false });
  }
  openNew360Modal(type) {
    this.setState({ showNew360Modal: true, new360Type: type });
  }

  createNew360(e) {
    // // console.log(this.state.accountData);
    db.doCreate360(this.state.accountId, this.title.value, this.state.clientName, this.state.new360Type, this.state.accountData, this.quickthreesixty.checked)
      .then((data) => {
        let new360ID = data.id;
        this.setState({
          clientName: '',
        });
        // console.log('new360ID = ' + new360ID);

        db.doCreateNewQuestionnaire(this.state.accountId, this.state.new360Type).then((data) => {
          let newQuestionnaireID = data.id;

          // console.log('newQuestionnaireID' + newQuestionnaireID);
          // console.log('new360ID' + new360ID);

          db.doAssignQuestionnaireTo360(this.state.accountId, newQuestionnaireID, new360ID).then((data) => {
            this.setState({
              settingUpProject: false,
            });

            db.addDefaultQuestionnaireLabels(this.state.accountId, newQuestionnaireID);

            if (this.quickthreesixty.checked) {
              // populating the questionnaire
              db.doPopulateQuick360Questionnaire(this.state.accountId, newQuestionnaireID, new360ID).then((data) => {
                // Close pop-up
                this.closeNew360Modal();

                // Forward to edit page
                this.props.history.push(`/dashboard/${this.state.accountId}/360s/${new360ID}/edit/`);
              });
            } else {
              // Close pop-up
              this.closeNew360Modal();

              // Forward to edit page
              this.props.history.push(`/dashboard/${this.state.accountId}/360s/${new360ID}/edit/`);
            }
          });
        });
      })
      .catch((error) => {
        this.setState(byPropKey('error', error));
      });

    e.preventDefault();
  }

  fireClientFilter(id, key) {
    if (id === -1) {
      this.setState({
        filteredByClient: null,
      });
    } else {
      this.setState({
        filteredByClient: key,
      });
    }
  }

  duplicate360(accountID, title, index, comparisonCheck) {
    //duplicates the current 360 and returns the new 360 ID
    db.doDuplicate360(accountID, title, index, comparisonCheck)
      .then((new360ID) => {
        // console.log(new360ID);
        this.props.history.push(`/dashboard/${this.state.accountId}/360s/${new360ID}/edit/`);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  render() {
    let activeGroupedByClient = {};
    let active360s = {};
    let achived360s = {};
    let clientItemList = [];
    let clientItemListFilter = [
      {
        id: -1,
        title: 'All clients',
        selected: false,
        key: 'All',
      },
    ];

    return (
      <div className='container container--slim'>
        <section className='welcome'>
          <div className='container'>
            <div className='welcome__row'>
              <h1>
                <span>Your</span> 360 Projects
              </h1>
            </div>
          </div>
        </section>
        {this.state.loading ? (
          <p>Loading</p>
        ) : (
          <section className='page-wrapper'>
            {// map achived/incomplete/active projects
            Object.keys(this.state.user360s)
              .reverse()
              .map((key) => {
                if (this.state.user360s[key]['type'] === '360') {
                  if (this.state.user360s[key]['archived']) {
                    achived360s[[key]] = this.state.user360s[key];
                  } else {
                    active360s[[key]] = this.state.user360s[key];
                  }
                }
              })}

            {Object.keys(active360s).length ? (
              <div>
                <div className='mb3 flex justify-end'>
                  {Object.keys(this.state.clientList).map((key, i) => {
                    clientItemListFilter.push({
                      id: i,
                      title: key,
                      selected: false,
                      key: key,
                    });
                  })}
                  {!this.props.accountData.clientname ? (
                    <div>
                      <p>
                        <strong>Filter by:</strong>
                        <Dropdown title='All clients' list={clientItemListFilter} setTitleOnSelect={true} resetThenSet={this.fireClientFilter} />
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {Object.keys(active360s).map((key) => {
                  if (!activeGroupedByClient[[this.state.user360s[key].client]]) {
                    activeGroupedByClient[[this.state.user360s[key].client]] = {};
                  }
                  activeGroupedByClient[[this.state.user360s[key].client]][[key]] = active360s[key];
                })}
                {Object.keys(activeGroupedByClient)
                  .sort()
                  .map((key) => {
                    return this.state.filteredByClient ? (
                      this.state.filteredByClient === key ? (
                        <div className='client-group' key={key}>
                          {Object.keys(activeGroupedByClient[key]).map((key) => {
                            return (
                              <List360
                                key={key}
                                index={key}
                                userDetails={this.props.userDetails}
                                selectedAccount={this.state.accountId}
                                accountTitle={this.state.accountTitle}
                                current360={this.state.user360s[key]}
                                accountId={this.state.accountId}
                                duplicate360={this.duplicate360}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      <div className='client-group' key={key}>
                        <p>
                          <strong>{key}</strong>
                        </p>
                        {Object.keys(activeGroupedByClient[key]).map((key) => {
                          return (
                            <List360
                              key={key}
                              index={key}
                              userDetails={this.props.userDetails}
                              selectedAccount={this.state.accountId}
                              accountTitle={this.state.accountTitle}
                              current360={this.state.user360s[key]}
                              accountId={this.state.accountId}
                              duplicate360={this.duplicate360}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            ) : (
              ''
            )}

            <div className='flex w-100 justify-center'>
              <CreateNewProject projectType='360' containerClass='' openNew360Modal={this.openNew360Modal} />
            </div>

            {Object.keys(achived360s).length ? (
              <div>
                <div className='mv4'>
                  <h1 className='mb2'>Archived 360 projects</h1>
                  <p>These 360 projects are currently archived, you are still able to view all the Reports associated to these projects</p>
                </div>
                {Object.keys(achived360s).map((key) => {
                  return <List360 key={key} index={key} current360={this.state.user360s[key]} accountId={this.state.accountId} />;
                })}
              </div>
            ) : (
              ''
            )}
          </section>
        )}

        {Object.keys(this.state.clientList).map((key, i) => {
          clientItemList.push({
            id: i,
            label: key,
          });
        })}

        <Modal
          isOpen={this.state.showNew360Modal}
          contentLabel='Add a new 360'
          onClose={() => this.closeNew360Modal()}
          content={
            <div>
              <h2>Create a new {this.state.new360Type}</h2>
              <form onSubmit={this.createNew360}>
                <label htmlFor='title' className='f6 b db mb2'>
                  Project Name
                </label>
                <input
                  required
                  ref={(input) => (this.title = input)}
                  aria-describedby='name-desc'
                  className='input-reset ba b--black-20 pa2 mb4 db w-100'
                  type='text'
                  placeholder='Name your 360'
                />
                {!this.props.accountData.clientname ? (
                  <label htmlFor='client' className='f6 b db mb2'>
                    Client
                  </label>
                ) : (
                  ''
                )}

                {this.state.showNew360Modal && !this.props.accountData.clientname ? (
                  <Autocomplete
                    ref={(el) => (this.client = el)}
                    items={clientItemList}
                    shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    getItemValue={(item) => item.label}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.id}
                        className='input-reset bb bl br b--black-20 pa2 db w-100'
                        style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                      >
                        {item.label}
                      </div>
                    )}
                    renderInput={(props) => (
                      <input
                        required
                        aria-describedby='client-desc'
                        {...props}
                        className='input-reset ba b--black-20 pa2 mb4 db w-100'
                        type='text'
                        placeholder='Select a Client, or create new'
                      />
                    )}
                    value={this.state.clientName}
                    onChange={(e) => this.setState({ clientName: e.target.value })}
                    onSelect={(value) => this.setState({ clientName: value })}
                    wrapperStyle={{
                      position: 'relative',
                      zIndex: '999',
                    }}
                    menuStyle={{
                      top: '100%',
                      left: 0,
                      position: 'absolute',
                    }}
                  />
                ) : (
                  <input
                    required
                    aria-describedby='client-desc'
                    ref={(el) => (this.client = el)}
                    defaultValue={this.props.accountData.clientname}
                    className='input-reset ba b--black-20 pa2 mb4 db w-100'
                    type={this.props.accountData.clientname ? `hidden` : `text`}
                    placeholder='Select a Client, or create new'
                  />
                )}

                <label htmlFor='quickthreesixty' className='b db mv4'>
                  <input aria-describedby='quickthreesixty-desc' id='quickthreesixty' type='checkbox' ref={(input) => (this.quickthreesixty = input)} />{' '}
                  <small>Select to pre-load "Boomerang Leadership 360" questions</small>
                </label>
                <button className='btn' disabled={this.state.settingUpProject} type='submit'>
                  Submit
                </button>
              </form>
            </div>
          }
        />
      </div>
    );
  }
}
//

export default withAuthorisation(authCondition)(All360Projects);

// export default compose(
//   withAuthorisation(authCondition),
//   withUser360s(authCondition)
// )(Dashboard)
