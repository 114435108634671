import React, { Component } from 'react';
import { compose } from 'recompose';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import LoadingState from '../../components/global/loading-state';
import Breadcrumbs from '../../components/breadcrumbs';
import SiteFooterBrand from '../../components/site-footer-brand';
import SiteHeader from '../../components/site-header';
import SiteNav from '../../components/site-nav';
import RunOptions from '../../components/runoptions';
import withAuthorisation from '../../components/withauthorisation';
import withUser360s from '../../components/withuser360s';
import { urlConversion, projectTypeText } from '../../helpers';
const authCondition = (authUser) => !!authUser;

let btnBrandCol;

class Edit360 extends Component {
  constructor(props) {
    super(props);

    let editLinkBasic = '';

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      current360Relationships: this.props.current360Relationships,
      current360Groups: this.props.current360Groups,
      loaded360: false,
      launched: false,
      loaded: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;

    this.editLinkBasic = `/dashboard/360s/${params.id}/edit/basic/`;
    this.editLinkStandard = `/dashboard/360s/${params.id}/edit/standard/`;

    let groups = {};

    db.doGetCurrent360Groups(this.state.accountId, this.state.current360ID)
      .then((res) => {
        res.forEach((doc) => {
          groups[doc.id] = doc.data();
        });

        // console.log(groups);
        let launchedCheck;
        for (const id in groups) {
          if (this.state.current360Groups[id].activeTask > 1) {
            launchedCheck = true;
          }
        }
        this.setState({ launched: launchedCheck });

        const getBranding = new Promise((resolve, err) =>
          db.get360Branding(this.state.accountId, this.state.current360ID).then((response) => {
            resolve(response);
          })
        );
        db.get360details(this.state.accountId, this.state.current360ID)
          .then((res) => {
            if (res.data().comparisonProject) {
              this.setState({
                comparisonCheck: res.data().comparisonProject,
                loaded360: true,
                launched: true,
              });
              if (this.state.current360.duplicatedFrom) {
                db.getPreviousGroupName(this.state.accountId, this.state.current360.duplicatedFrom).then((doc) => {
                  this.setState({
                    prevous360Name: doc.data().title,
                  });
                });
              }
            } else {
              this.setState({
                comparisonCheck: res.data().comparisonProject,
                loaded360: true,
              });
            }
          })
          .catch((err) => {
            // console.log(err);
          });
        getBranding
          .then((res) => {
            // console.log(res.data());
            const defaultCol = '#0051ff';
            const accData = res.settings;
            if (accData.resetbrand == false) {
              if (accData.buttoncolour !== defaultCol) {
                this.setState({
                  btnBrandCol: accData.buttoncolour,
                  loaded360: true,
                });
              }
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });

    // db.doGetCurrent360(params.id).then((data)=> {
    //   if(data.val()) {
    //     this.setState({
    //       current360: data.val(),
    //       current360ID: data.key,
    //       loaded360: true
    //     })
    //   }
    // })
  }

  componentDidUpdate(prevProps) {
    if (this.state.launched === undefined) {
      let groups = {};
      db.doGetCurrent360Groups(this.state.accountId, this.state.current360ID).then((res) => {
        res.forEach((doc) => {
          groups[doc.id] = doc.data();
        });

        let launchedCheck;
        let check = 0;
        for (const id in groups) {
          if (groups[id].activeTask > 1) {
            check++;
            // console.log(check);
          }
        }
        if (check > 0) {
          launchedCheck = true;
        } else {
          launchedCheck = false;
        }
        this.setState({ launched: launchedCheck, loaded: true });
      });
    }
  }

  render() {
    const projectType = this.props.current360.type;
    return (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={this.props.current360}
            accountData={this.props.accountData}
            current360Groups={this.props.current360Groups}
            current360Relationships={this.props.current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${this.state.accountId}`,
              },
              {
                title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${projectType} Projects`,
                to: `/dashboard/${this.props.match.params.accountId}/${urlConversion(projectType)}-projects/`,
              },
              {
                title: this.props.current360.title,
                to: null,
              },
            ]}
          />

          {this.state.current360.duplicatedFrom ? (
            <div className='w100 flex justify-between'>
              <h1 style={this.state.btnBrandCol ? { color: `${this.state.btnBrandCol}` } : {}}>Set Up {projectType} Project</h1>
              <h1>
                You are comparing against:{' '}
                <a
                  style={this.state.btnBrandCol ? { color: `${this.state.btnBrandCol}`, textDecoration: 'none' } : { textDecoration: 'none', color: '#0051ff' }}
                  href={`/dashboard/${this.state.accountId}/360s/${this.state.current360.duplicatedFrom}/edit/`}
                >
                  {this.state.prevous360Name}
                </a>
              </h1>
            </div>
          ) : (
            <h1 style={this.state.btnBrandCol ? { color: `${this.state.btnBrandCol}` } : {}}>Set Up {projectType} Project</h1>
          )}
          <div className='flex justify-between items-center w100 bg-white shadow pa4 mb3 br2'>
            <div className='w-70'>
              <p className='ma0'>
                <strong>{projectType === 'Goal Tracker' ? 'Goal Tracker' : 'Questionnaire'} Settings</strong>
              </p>
              {/*

                <p className="ma0">You can set-up the questionnaire and brand your 360 project here</p>
              */}
              <p className='ma0'>
                {this.state.launched
                  ? `Quesionnaire settings cannot be changed after an email has been sent within a ${projectTypeText(projectType, 'group', 'group', 'team')}`
                  : projectType === 'Goal Tracker'
                  ? 'You can add and edit goals and brand your project here'
                  : 'You can set-up the questionnaire here'}
              </p>
            </div>
            {this.state.launched ? (
              <button className='btn' disabled>
                Unavailable
              </button>
            ) : (
              <Link
                disabled={this.state.launched}
                className='btn'
                to={{
                  pathname: 'questionnaire/',
                  accountData: this.props.accountData,
                  current360: this.props.current360,
                  current360Relationships: this.props.current360Relationships,
                  current360Groups: this.props.current360Groups,
                }}
              >
                <span>Set Up {projectType === 'Goal Tracker' ? 'Goals' : 'Questionnaire'}</span>
              </Link>
            )}
          </div>

          <div className='flex justify-between items-center w100 bg-white shadow pa4 mb3 br2'>
            <div className='w-70'>
              <p className='ma0'>
                <strong>Advanced Settings</strong>
              </p>
              {this.state.launched ? (
                <p className='ma0'>
                  Advanced settings cannot be changed after an email has been sent within a {projectTypeText(projectType, 'group', 'group', 'team')}`.
                </p>
              ) : (
                <p className='ma0'>
                  You can change general settings for your project here. For example,{' '}
                  {projectTypeText(projectType, "the relationship titles of your 'reviewer groups', and ", '', '')} the copy of the emails you send.
                </p>
              )}
            </div>
            {this.state.launched ? (
              <button className='btn' disabled>
                Unavailable
              </button>
            ) : (
              <Link
                className='btn'
                disabled={this.state.loaded360}
                to={{
                  pathname: 'standard/',
                  accountData: this.props.accountData,
                  current360: this.props.current360,
                  current360Relationships: this.props.current360Relationships,
                  current360Groups: this.props.current360Groups,
                }}
              >
                Set Up Advanced Settings
              </Link>
            )}
          </div>

          {/*
            <div className="flex justify-between items-center w100 bg-white shadow pa4 mb3 br2">
              <div className="w-70">
                <p className="ma0"><strong>Professional settings</strong></p>
                <p className="ma0">Here you can change your report settings</p>
              </div>
              <Link className="btn" to={ this.editLinkBasic }>Setup Professional settings</Link>
            </div>
            */}

          {this.props.current360.statusCode < 1 ? (
            <div className='mv5'>
              <h2 style={this.state.btnBrandCol ? { color: `${this.state.btnBrandCol}` } : {}}>
                {/* Add {projectTypeText(projectType, 'group', 'group', 'team')}s to your {projectType} project */}
                Add groups to your {projectType} project
              </h2>
              <p>
                Please setup a {projectType === 'Goal Tracker' ? 'goal' : 'questionnaire with at least two sections/compentencies'} before creating a new{' '}
                {projectTypeText(projectType, 'user group', 'user group', 'team')}.
              </p>
              {/* {projectType === 'Goal Tracker' ? (
                <p>Please setup a goal before creating a new user group.</p>
              ) : (
                <p>Please setup a questionnaire with at least two sections/compentencies before creating a new user group.</p>
              )} */}
              <Link className='btn' to='questionnaire/'>
                Set up a {projectType === 'Goal Tracker' ? 'goal' : 'questionnaire'} now
              </Link>
            </div>
          ) : (
            <RunOptions
              current360ID={this.props.match.params.id}
              current360={this.props.current360}
              current360Type={projectType}
              current360Relationships={this.state.current360Relationships}
              current360Groups={this.state.current360Groups}
              accountData={this.state.accountData}
              accountId={this.state.accountId}
              btnBrandCol={this.state.btnBrandCol}
            />
          )}
        </section>
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Edit360);
