import React, { Component } from 'react';
import { arrayGenerator } from '../../helpers';

export default class DottedLines extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    // uses arrayGenerator function to create an array which is mapped and prints a dotted line a specified number of times
    return arrayGenerator(this.props.numberOfLines).map(() => <div className='w-100 bb bl-0 br-0 bt-0 b--dashed b--light-silver pv2 mb1'></div>);
  }
}
