import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import Autocomplete from 'react-autocomplete';
import LoadingState from '../../../components/global/loading-state';
import Breadcrumbs from '../../../components/breadcrumbs';
import SiteFooter from '../../../components/site-footer';
import SiteHeader from '../../../components/site-header';
import SiteNav from '../../../components/site-nav';
import QuestionnaireSectionEditor from '../../../components/questionnairesectioneditor';
import QuestionPreview from '../../../components/questionpreview';
import Modal from '../../../components/global/modal';
import { breadcrumbSelect } from '../../../helpers';

//
import { compose } from 'recompose';
import withAuthorisation from '../../../components/withauthorisation';
import withUser360s from '../../../components/withuser360s';
const authCondition = (authUser) => !!authUser;

class QuestionnairePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      sectionsLegacyMode: false,
    };
  }
  componentDidMount() {
    const { accountId, current360 } = this.state;
    let sectionsLegacyMode = false;
    db.getQuestionnaireSections(accountId, current360.questionnaireID).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        let sections = {};
        let sectionNum = 0;
        let numSections = snapshot.docs.length;
        snapshot.docs.map((doc) => {
          let sectionID = doc.id;
          const sectionData = doc.data();
          let scaleLabels = {};
          let reverseOrderedLabelIDs = []

          if (sectionData['title']) {
            sections[sectionID] = sectionData;
          }

          // updates sectionsLegacyMode if no valid sortOrder value is found
          if (sectionsLegacyMode === false && sectionData.sortOrder === undefined) {
            sectionsLegacyMode = true;
          }

          sectionNum++;

          db.doGetQuestionnaireLabels(accountId, current360.questionnaireID).then((res) => {
            if (res.docs.length > 0) {
              res.docs.map((label) => {
                scaleLabels[label.id] = label.data();
                reverseOrderedLabelIDs.push(label.id);
              });
            } else {
              // adds legacy default labels
              scaleLabels = {
                rating0: { score: 0, text: 'Unable to comment', frequency: '', sortOrder: 0 },
                rating1: { score: 1, text: 'Never', frequency: '0% of the time', sortOrder: 1 },
                rating2: { score: 2, text: 'Rarely', frequency: '1% - 35% of the time', sortOrder: 2 },
                rating3: { score: 3, text: 'Some of the time', frequency: '36% - 59% of the time', sortOrder: 3 },
                rating4: { score: 4, text: 'Quite often', frequency: '60% - 89% of the time', sortOrder: 4 },
                rating5: { score: 5, text: 'Frequently if not always', frequency: 'over 90% of the time', sortOrder: 5 },
              };
              reverseOrderedLabelIDs = Object.keys(scaleLabels)
            }

            reverseOrderedLabelIDs
              .sort((a,b) => {
                return scaleLabels[a].sortOrder - scaleLabels[b].sortOrder
              })
              .reverse()

            db.getQuestions(accountId, current360.questionnaireID, sectionID).then((snapshot) => {
              let questions = {};
              let legacyMode = false;
              if (sections[sectionID]) {
                snapshot.docs.map((doc) => {
                  if (doc.data().key === undefined) {
                    let dataObj = new Object();
                    dataObj = doc.data();
                    dataObj.legacyKey = doc.id;
                    questions[doc.id] = dataObj;
                  } else {
                    questions[doc.id] = doc.data();
                  }
                });

                const toOrder = Object.keys(questions).map((i) => questions[i]);
                //it will always check the first one in the array which will always be the oldest as they are saved in date order
                if (toOrder.length != 0) {
                  if (toOrder[0].sortOrder === undefined) {
                    legacyMode = true;
                  }
                }

                if (legacyMode === false) {
                  toOrder.sort((a, b) => {
                    if (a.sortOrder < b.sortOrder) {
                      return -1;
                    }
                    if (a.sortOrder > b.sortOrder) {
                      return 1;
                    }
                  });
                }
                sections[sectionID]['questions'] = toOrder;
              }
              if (sectionNum == numSections) {
                let requiredQuestionsList = {};
                // Get pure list of all quesions
                Object.keys(sections).map((sectionKey, v) => {
                  Object.assign(requiredQuestionsList, sections[sectionKey]['questions']);
                });

                // Remove all non-required questions
                Object.keys(requiredQuestionsList).map((i, v) => {
                  if (requiredQuestionsList[i]['required'] == 'false') {
                    delete requiredQuestionsList[i];
                  }
                });

                this.setState({
                  questions: sections,
                  requiredQuestionsList: requiredQuestionsList,
                  scaleLabels,
                  reverseOrderedLabelIDs,
                  questionsLoaded: true,
                  sectionsLegacyMode,
                  loading: false,
                });
              }
            });

          });
        });
      }
    });
  }

  render() {
    const {
      match: { params },
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.state.loading && !this.state.questions ? (
          <LoadingState />
        ) : (
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.state.current360}
              userDetails={this.state.userDetails}
              accountTitle={this.state.accountTitle}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.state.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(this.props.current360.type)}-projects/`,
                },
                {
                  title: this.state.current360.title,
                  to: '../../',
                },
                {
                  title: `${this.props.current360.type === 'Goal Tracker' ? 'Goal' : 'Questionnaire'} setup`,
                  to: '../',
                },
                {
                  title: `Preview ${this.props.current360.type === 'Goal Tracker' ? 'Goals' : 'Questionnaire'}`,
                  to: null,
                },
              ]}
            />

            <h1>
              {this.state.current360.title}: Preview {this.props.current360.type === 'Goal Tracker' ? 'Goals' : 'Questionnaire'}
            </h1>

            {this.state.current360.type == 'Goal Tracker' ? (
              <div>
                <h1 className='mb0'>'My Goals'</h1>
              </div>
            ) : (
              <div>
                <h1 className='mb0'>Complete your self review</h1>
                <div className='w-80 mt3'>
                  <p>When rating your own behaviour or effectiveness, try to be as objective as possible, without being unduly modest</p>
                  <p>Where possible, give as many concrete examples as you can, to support your ratings</p>
                  <p>Consider your behaviour or effectiveness over an extended period, not just the most recent events that come quickly to mind</p>
                </div>
              </div>
            )}

            {Object.keys(this.state.questions)
              .sort((a, b) => {
                const questions = this.state.questions;
                return this.state.sectionsLegacyMode
                  ? questions[a].created - questions[b].created
                  : questions[a].sortOrder - questions[b].sortOrder;
              })
              .map((key, i) => {
              // Check question exists
              if (this.state.questions[key].questions) {
                return (
                  <QuestionPreview
                    key={key}
                    index={key}
                    title={this.state.questions[key].title}
                    description={this.state.questions[key].description}
                    questions={this.state.questions[key].questions}
                    answers={null}
                    questionUpdated={this.questionUpdated}
                    questionnaireType={this.state.current360 ? this.state.current360.type : null}
                    showSavedModal={this.showSavedModal}
                    goalActive={this.state.currentGoalID == i + 1 || this.state.currentGoalID == null ? true : false}
                    scaleLabels={this.state.scaleLabels}
                    reverseOrderedLabelIDs={this.state.reverseOrderedLabelIDs}
                  />
                );
              } else {
                return false;
              }
            })}
            <Link to={{ pathname: '../' }} className='btn btn--back'>
              Return to Questionnaire Set-Up
            </Link>
          </section>
        )}
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(QuestionnairePreview);
