import React, { Component } from 'react'
import reactCSS from 'reactcss'
import { auth, db, storage } from '../firebase'
import firebase from 'firebase/app'
import { signup } from '../firebase/signup'
import FileUploader from "react-firebase-file-uploader"
import { BlockPicker, ChromePicker, TwitterPicker } from 'react-color'
import Modal from '../components/global/modal'
import logo from '../logo-colour.svg'
//

const INITIAL_STATE = {
  fname: '',
  lname: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: ""
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

//

class RegisterAccessForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: this.props.fname,
      lname: this.props.lname,
      email: this.props.email,
      passwordOne: '',
      passwordTwo: '',
      error: ""

    };
  }

  onSubmit = (event) => {
    event.preventDefault();

    const {
      fname,
      lname,
      email,
      passwordOne,
    } = this.state;

    // create new user, after deleting temp user:
    db.doDeleteTempUserAndCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create user in DB
        db.doCreateAccessUser(authUser.user.uid, fname, lname, email, this.props.accountId)
          .then(() => {
            this.props.history.push(`/dashboard/${this.props.accountId}/`)
          })
          .catch(error => {
            this.setState(byPropKey('error', error));
          });

      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });



  }

  render() {
     const {
      fname,
      lname,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      fname === '' ||
      lname === '';

    return (
      <form className="sign-in__form bg--white shadow animated fadeInUp" onSubmit={this.onSubmit} >
        <img src={ logo } />

        <input autoComplete="off" hidden aria-describedby="fname-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={fname} type="text" placeholder="First Name" />
        <input autoComplete="off" hidden aria-describedby="lname-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={lname} type="text" placeholder="Surname" />
        <input autoComplete="off" hidden aria-describedby="email-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={email} type="text" placeholder="Email Address" />


        <p>You have been granted access to a Boomerang account - please create a password below</p>
        <label htmlFor="password" className="f6 b db mb2">Password</label>
        <input autoComplete="new-password" aria-describedby="password-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={passwordOne} onChange={event => this.setState(byPropKey('passwordOne', event.target.value))} type="password" placeholder="Password" />

        <label htmlFor="passwordrepeat" className="f6 b db mb2">Confirm Password</label>
        <input autoComplete="new-password" aria-describedby="passwordrepeat-desc" className="input-reset ba b--black-20 pa2 mb2 db w-100" value={passwordTwo} onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))} type="password" placeholder="Confirm Password" />

        <div className="w-100 mv2">
          <button className="btn" disabled={isInvalid} type="submit">Submit</button>
          { error && <h3 className="red mt4 mb0">{error.message}</h3> }
        </div>
      </form>
    );
  }
}

export default RegisterAccessForm;
