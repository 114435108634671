import React from 'react';
import ReportPageTemplate from './reportpagetemplate'

export default class TeamAssessmentReportPageTemplate extends React.Component {
  render() {

    return this.props.pageNum > 1 && (
      <ReportPageTemplate pageNum={this.props.pageNum} reportType='Team Assessment Report' />
    )
  }
}
