import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/site-header'
import { compose } from 'recompose'
import { db } from '../firebase'
import withSuperUser from '../components/withsuperuser'
import logo from '../logo.svg'
import SignOutButton from '../components/signoutbutton'
const authCondition = (authUser) => !!authUser
//

class ViewAccountsPage extends Component {
  constructor(props) {
    super(props)
    let accountsList
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    db.doGetAccountsList().then((snapshot) => {
      let accountsList = {}
      snapshot.docs.map(doc => {
        accountsList[doc.id] = doc.data()
      })
      this.accountsList = accountsList
      this.setState({
        loading: false
      })
    })
  }
  render() {
    const { loading } = this.state;
    return (
      <main>
        <header className='site-header'>
          <div className='container'>
            <div className='site-header__row'>
              <img src={logo} className="header-logo mr4" />
              <p className="reset-mar">Howdy, <strong>Super Admin!</strong></p>
              <div className="flex items-center">
                <Link className="btn btn--white ml2" to="/admin/accounts/">View Accounts</Link> <Link className="btn btn--white ml2 mr4" to="/admin/signup/">Create New Account</Link>
                <SignOutButton />
              </div>
            </div>
          </div>
        </header>
        <section className="page-wrapper">
          <div className="container container--slim">
            <h1>Showing all Boomerang accounts</h1>
            {
              (!loading) ?
                Object
                  .keys(this.accountsList)
                  .reverse()
                  .map((key) => {
                      let editLink = `/dashboard/${key}/`
                      return (
                        <div key={key} className="individual-360-strip mv4">
                          <p className="reset-mar">{ this.accountsList[key]['title'] }<br/><small><strong>ID: </strong>{ key }</small></p>
                          <div className="individual-360-strip__actions">
                            <Link className="btn" to={ editLink }>View/Manage Account</Link>
                          </div>
                        </div>
                      )


                  })

              : ''
            }
          </div>
        </section>
      </main>
    );
  }
}

export default withSuperUser(authCondition)(ViewAccountsPage)
