import React, { Component } from 'react';
import axios from 'axios';
import { compose } from 'recompose';
import { db } from '../../../firebase';
import { Link } from 'react-router-dom';
import LoadingState from '../../../components/global/loading-state';
import Breadcrumbs from '../../../components/breadcrumbs';
import SiteFooterBrand from '../../../components/site-footer-brand';
import SiteHeader from '../../../components/site-header';
import SiteNav from '../../../components/site-nav';
import Modal from '../../../components/global/modal';
import RunOptions from '../../../components/runoptions';
import UserReviewerEditForm from '../../../components/userreviewereditform';
import withAuthorisation from '../../../components/withauthorisation';
import withUser360s from '../../../components/withuser360s';
import { breadcrumbSelect } from '../../../helpers';
import AuthUserContext from '../../../components/authusercontext';
const authCondition = (authUser) => !!authUser;

class Edit360GroupUser extends Component {
  constructor(props) {
    super(props);

    let editLinkBasic = '';

    // console.log(this.props);

    this.state = {
      accountId: this.props.match.params.accountId,
      groupId: this.props.match.params.groupId,
      current360ID: this.props.match.params.id,
      groupId: this.props.match.params.groupId,
      userId: this.props.match.params.userId,
      accountData: this.props.accountData,
      current360: this.props.current360,
      loaded360: false,
      loading: true,
      savingUser: false,
      savingReviewer: false,
      showNewReviewerModal: false,
      showDeletingUserModal: false,
      reviewerToDelete: null,
      loadedSend: null,

      showSendingModal: false,
      sendingSuccess: false,
      sendingError: false,
      sendingComplete: false,
    };

    this.onSaveUser = this.onSaveUser.bind(this);
    this.updateReviewerRelationship = this.updateReviewerRelationship.bind(this);
    this.deleteReviewer = this.deleteReviewer.bind(this);
    this.confirmReviewerDeletion = this.confirmReviewerDeletion.bind(this);
    this.closeSendingModal = this.closeSendingModal.bind(this);
    this.onSubmitReviewer = this.onSubmitReviewer.bind(this);
    this.closeNewReviewerModal = this.closeNewReviewerModal.bind(this);
    this.openNewReviewerModal = this.openNewReviewerModal.bind(this);
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    window.scrollTo(0, 0);

    let currentGroup
    let currentUser
    let currentGroupUser

    db.doGetCurrentGroup(this.state.accountId, this.state.current360ID, this.state.groupId).onSnapshot((snapshot) => {
      if (snapshot.data()) {
        currentGroup = snapshot.data()

        db.doGetCurrentUser(this.state.accountId, this.state.current360ID, this.state.groupId, this.state.userId).onSnapshot((snapshot) => {
          currentUser = snapshot.data()

          db.doGetCurrentGroupUser(this.state.accountId, this.state.current360ID, this.state.groupId, this.state.userId).then((doc) => {
            currentGroupUser = doc.data()
            
            db.doGetCurrentGroupUserReviewers(this.state.accountId, this.state.current360ID, this.state.groupId, this.state.userId).onSnapshot((snapshot) => {
              let reviewers = {};
              if (snapshot.docs) {
                snapshot.docs.map((doc, i) => {
                  reviewers[doc.id] = doc.data();
                });
              }
  
              // console.log(reviewers);
  
              db.doGetCurrent360Relationships(this.state.accountId, this.state.current360ID).then((snapshot) => {
                let relationships = {};
                let userActions = [];
                if (snapshot.docs) {
                  snapshot.docs.map((doc, i) => {
                    relationships[doc.id] = doc.data();
                  });
                }
  
                db.doGetCurrentGroupTasks(this.state.accountId, this.state.current360ID, this.state.groupId).onSnapshot((snapshot) => {
                  let currentGroupTasks = {};
                  if (!snapshot.empty) {
                    snapshot.docs.map((doc, i) => {
                      currentGroupTasks[doc.id] = doc.data();
                    });
                  }

                  const UID = this.state.userId;
                  const reqBody = {
                    accountId: this.state.accountId,
                    accountEmail: this.state.accountData.email,
                    current360Id: this.state.current360ID,
                    currentGroupId: this.state.groupId,
                    currentGroupUsers: { [UID]: currentUser },
                    currentGroupUserReviewers: reviewers,
                    current360: this.state.current360,
                  };

                  axios
                    .post('/checkEmails', {
                      reqBody,
                    })
                    .then((res) => {
                      let reviewerEmailStatuses = {}
                      let reviewerErrors = {}
                      let reviewerEventReasons = {}
                      let revieweeEmailStatuses = []
                      let revieweeErrors = []
                      let revieweeEventReasons = {}

                      if (res.data.errors.length > 0) {
                        const getTimestamp = (str) => {
                          const date = new Date(str)
                          const timestamp = date.getTime();
                          return timestamp
                        }
                        // sorts events by most recent first
                        let messageEvents = res.data.errors.sort((a,b) => {
                          return getTimestamp(b.When) - getTimestamp(a.When)
                        })
                        messageEvents.forEach(event => {
                          // Separates events by reviewee
                          if (event.To === currentUser.email) {
                            revieweeErrors.push(event)
                            // breaks down message events to only those which have made it through sparkpost and attempted sending to the reviewee
                            if (event.Type !== 'injection') {
                              revieweeEmailStatuses.push(event.Type)
                              if (!currentGroupUser.emailStatus) {
                                db.doUpdateCurrentGroupUser(this.state.accountId, this.state.current360ID, this.state.groupId, this.state.userId, 'emailStatus', event.Type)
                              } else {
                                if (currentGroupUser.emailStatus !== 'delivery' && event.Type === 'delivery') {
                                  db.doUpdateCurrentGroupUser(this.state.accountId, this.state.current360ID, this.state.groupId, this.state.userId, 'emailStatus', 'delivery')
                                }
                              }
                              // creates an object with the event type as key and most recent corresponding reason as value
                              if (!revieweeEventReasons[event.Type]) {
                                revieweeEventReasons[event.Type] = event.Because
                              }
                            }
                          } else {
                            for (const revID in reviewers) {
                              const reviewerEmail = reviewers[revID].email
                              if (!reviewerEmailStatuses[revID]) {
                                reviewerEmailStatuses[revID] = []
                              }
                              if (!reviewerErrors[revID]) {
                                reviewerErrors[revID] = []
                              }
                              if (!reviewerEventReasons[revID]) {
                                reviewerEventReasons[revID] = {}
                              }
                              // Separates events by reviewer
                              if (event.To === reviewerEmail) {
                                // breaks down message events to only those which have made it through sparkpost and attempted sending to the reviewer
                                if (event.Type !== 'injection') {
                                  // creates an array of just the event types
                                  reviewerEmailStatuses[revID].push(event.Type)
                                  // creates an object with the event type as key and most recent corresponding reason as value
                                  if (!reviewerEventReasons[revID][event.Type]) {
                                    reviewerEventReasons[revID][event.Type] = event.Because
                                  }
                                }
                                reviewerErrors[revID].push(event)
                              }
                            }
                          }
                        })
                      }
                      this.setState({
                        currentGroup,
                        currentUser,
                        currentGroupUser,
                        currentUserReviewers: reviewers,
                        current360Relationships: relationships,
                        userActions,
                        currentGroupTasks,
                        reviewerEmailStatuses,
                        reviewerErrors,
                        reviewerEventReasons,
                        revieweeEmailStatuses,
                        revieweeErrors,
                        revieweeEventReasons,
                        loadedSend: [res.data.errors],
                        loading: false,
                      });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                });
              });
            });
          })
        });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.loadedSend == null && this.state.currentUserReviewers !== undefined) {
      this.setState({ loadedSend: 'loading' });
    } else if (this.state.loadedSend == null && this.state.currentGroupUsersReviewers !== undefined && this.state.loading == true) {
      this.setState({
        loading: false,
        loadedSend: [],
      });
    }
  }

  closeSendingModal() {
    this.setState({
      showSendingModal: false,
      sendingSuccess: false,
      sendingError: false,
      sendingComplete: false,
    });
  }

  onSaveUser(e) {
    e.preventDefault();

    let userForename = this.revieweeForename.value;
    let userSurname = this.revieweeSurname.value;
    let userEmail = this.revieweeEmail.value.toLowerCase();

    this.setState({
      savingUser: true,
    });

    db.doUpdateCurrentUser(this.state.accountId, this.state.current360ID, this.state.groupId, this.state.userId, userForename, userSurname, userEmail).then(
      (snapshot) => {
        this.setState({
          savingUser: false,
        });
      }
    );
  }

  updateReviewerRelationship() {
    // console.log('updateReviewerRelationship');
  }

  deleteReviewer(reviewerID) {
    this.setState({
      showDeletingUserModal: true,
      reviewerToDelete: reviewerID,
    });
  }
  confirmReviewerDeletion() {
    // console.log('deleteReviewer - ' + this.state.reviewerToDelete);

    db.doDeleteReviewer(this.state.accountId, this.state.reviewerToDelete).then(() => {
      db.doDeleteReviewerFromReviewee(this.state.accountId, this.state.reviewerToDelete, this.state.current360ID, this.state.groupId, this.state.userId).then(
        (data) => {
          this.setState({
            showDeletingUserModal: false,
            reviewerToDelete: null,
          });
          window.location.reload();
        }
      );
    });
  }

  closeNewReviewerModal() {
    this.newReviewFormRef.reset();

    this.setState({
      showNewReviewerModal: false,
      duplicateEmail: false,
    });
  }
  openNewReviewerModal() {
    this.setState({
      showNewReviewerModal: true,
    });
  }

  onSubmitReviewer(e) {
    // this.setState({
    //   showSendingModal: true
    // })
    e.preventDefault();

    let formRef = e.currentTarget;

    // // console.log(formRef)
    let fullname = `${this.state.currentUser.forename} ${this.state.currentUser.surname}`;
    let reviewerFullname = `${this.forename.value} ${this.surname.value}`;
    let reviewerEmail = this.email.value.toLowerCase();
    let reviewerRelationship = this.state.current360.type !== 'Team Assessment' ? this.relationship.value : 'TM';
    let nameArr = reviewerFullname.split(' ');

    const emailArr = Object.keys(this.state.currentUserReviewers).map((key) => {
      return this.state.currentUserReviewers[key].email;
    });
    // console.log(emailArr);
    if (emailArr.includes(reviewerEmail)) {
      this.setState({ duplicateEmail: true });
      return;
    }
    // console.log(fullname, reviewerFullname, reviewerEmail, reviewerRelationship);
    // if( !this.state.userReviewsEmails.includes(reviewerEmail) ) {
    db.doCreateNonAdminUser(this.state.accountId, nameArr[0], nameArr[1], reviewerEmail, true).then((userRes) => {
      db.createNewReviewer(
        this.state.accountId,
        fullname,
        reviewerFullname,
        reviewerEmail,
        reviewerRelationship,
        this.state.current360ID,
        this.state.groupId,
        this.state.current360.questionnaireID,
        this.state.userId
      )
        .then((reviewerID) => {
          if (reviewerID) {
            // // console.log(this.state.accountId, reviewerID, reviewerFullname, this.state.current360ID, this.state.groupId, this.state.userId, reviewerRelationship, reviewerEmail);
            db.assignReviewerToReviewee(
              this.state.accountId,
              reviewerID,
              reviewerFullname,
              this.state.current360ID,
              this.state.groupId,
              this.state.userId,
              reviewerRelationship,
              reviewerEmail
            ).then((data) => {
              // formRef.reset()
              this.closeNewReviewerModal();
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    });

    // } else {
    //   this.setState({
    //     showUserDuplicateError: true
    //   })
    // }
  }

  render() {
    const projectType = this.state.current360.type;
    const {revieweeEmailStatuses, revieweeErrors, revieweeEventReasons, currentGroupUser, reviewerEmailStatuses, reviewerErrors, reviewerEventReasons} = this.state

    const sendingErrorsFunc = () => {
      let matchCount = 0;

      if (projectType !== 'Team Assessment') {
        if (this.state.currentGroupTasks !== undefined && this.state.loadedSend !== null && this.state.currentGroupTasks[1].completedDate == false) {
          return (
            <p>
              <strong>Email Status: Emails not sent yet</strong>
            </p>
          );
        } else if (this.state.currentGroupTasks !== undefined && this.state.loadedSend !== null && this.state.currentGroupTasks[1].completedDate !== false) {
          for (let i = 0; i < this.state.loadedSend.length; i++) {
            if (
              this.state.loadedSend[i] !== undefined &&
              this.state.loadedSend[i].To == this.state.email &&
              this.state.loadedSend[i].Because !== 'success' &&
              this.state.loadedSend[i].Because !== undefined
            ) {
              matchCount++;
              return (
                <p className='has-tooltip' data-tooltip={this.state.loadedSend[i].Because}>
                  <strong>Email Status: Error sending email</strong>
                </p>
              );
            } else if (this.state.loadedSend[i] !== undefined && this.state.loadedSend[i].To == 'n/a') {
              matchCount++;
              return (
                <p className='has-tooltip' data-tooltip={this.state.loadedSend[i].Because}>
                  <strong>Email Status: Email sent with legacy provider</strong>
                </p>
              );
            } else if (
              this.state.loadedSend[i] !== undefined &&
              this.state.loadedSend[i].To == this.state.email &&
              this.state.loadedSend[i].Because !== 'success' &&
              this.state.loadedSend[i].Type !== 'deliver'
            ) {
              matchCount++;
              return (
                <p className='has-tooltip' data-tooltip={this.state.loadedSend[i].Because}>
                  <strong>Email Status: Email Delivered</strong>
                </p>
              );
            }
          }
        }
      }

      if (this.state.currentGroupTasks == undefined) {
        return (
          <p>
            <strong>Email Status: Status not available yet</strong>
          </p>
        );
      } else if (this.state.loading !== true && this.state.currentGroupTasks[1].completedDate == false) {
        return (
          <p>
            <strong>Email Status: Emails not sent yet</strong>
          </p>
        );
      } else {
        // There is event data on Sparkpost (so within 10 day storage period)
        if (revieweeErrors && revieweeErrors.length > 0) {
          if (revieweeEmailStatuses.includes('delivery')) {
            return (
              <p className='has-tooltip' data-tooltip={revieweeEventReasons['delivery']}>
                <strong>Email Status: Email Delivered</strong>
              </p>
            );
          } else {
              return (
                <p className='has-tooltip' data-tooltip={revieweeEventReasons[revieweeEmailStatuses[0]]}>
                  <strong>Email Status: Error sending email</strong>
                </p>
              );
          }
        } else if (currentGroupUser.emailStatus === 'delivery' || currentGroupUser.completed) {
          // No record on Sparkpost but Firebase data has delivery status /review completed, so must have had email delivered
          return (
            <p className='has-tooltip' data-tooltip='success'>
              <strong>Email Status: Email Delivered</strong>
            </p>
          );
        } else if (currentGroupUser.emailRecieved) {
          // No record on Sparkpost or Firebase, not completed, email process did take place though, so request was sent to Sparkpost
          return (
            <p className='has-tooltip' data-tooltip='Email relayed to service provider but no record of events after this. Resending should provide more information.'>
              <strong>Email Status: Email with service provider</strong>
            </p>
          );
        } else if (currentGroupUser.emailStatus !== 'delivery') {
          // No longer within Sparkpost 10 day storage period, but event type (not delivered) was logged on Firebase
          return (
            <p className='has-tooltip' data-tooltip='Error data no longer available. Please check email address is correct and re-send'>
              <strong>Email Status: Error sending email</strong>
            </p>
          );
        }
      }
    };

    const sendErrorsCall = sendingErrorsFunc();

    return (
      <div className='page-wrapper'>
        {this.state.loading ? (
          <LoadingState />
        ) : this.state.error ? (
          <div className='tc'>
            <h3>Error loading User</h3>
            <Link
              class='btn btn--back'
              to={{
                pathname: `/dashboard/${this.props.match.params.accountId}/`,
              }}
            >
              <span>Return to Dashboard</span>
            </Link>
          </div>
        ) : (
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.state.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(this.props.current360.type)}-projects/`,
                },
                {
                  title: this.state.current360.title,
                  to: '../../../../',
                },
                {
                  title: this.state.currentGroup.title,
                  to: '../../',
                },
                {
                  title: `${this.state.currentUser.forename} ${this.state.currentUser.surname}`,
                  to: null,
                },
              ]}
            />

            {projectType !== 'Team Assessment' ? (
              <>
                <h1>Editing user</h1>

                <div className='pa2 bg-white'>
                  <form className='flex items-end pa2' onSubmit={this.onSaveUser}>
                    <div className='w-100 pl1 pr1'>
                      <label htmlFor='forename'>First Name</label>
                      <input
                        required
                        ref={(input) => (this.revieweeForename = input)}
                        defaultValue={this.state.currentUser.forename}
                        aria-describedby='revieweeForename-desc'
                        type='text'
                        placeholder='Joe'
                      />
                    </div>
                    <div className='w-100 pl1 pr1'>
                      <label htmlFor='surname'>Last Name</label>
                      <input
                        required
                        ref={(input) => (this.revieweeSurname = input)}
                        defaultValue={this.state.currentUser.surname}
                        aria-describedby='revieweeSurname-desc'
                        type='text'
                        placeholder='Blogs'
                      />
                    </div>
                    <div className='w-100 pl1 pr1'>
                      <label htmlFor='useremail'>Email</label>
                      <input
                        required
                        ref={(input) => (this.revieweeEmail = input)}
                        defaultValue={this.state.currentUser.email}
                        aria-describedby='revieweeEmail-desc'
                        type='email'
                        placeholder='email@example.com'
                      />
                    </div>
                    <div className='pl2 mb3 flex relative'>
                      <button className={this.state.savingUser ? 'btn btn--disabled mb2' : 'btn mb2'} type='submit'>
                        {this.state.savingUser ? 'Saving' : 'Save'}
                      </button>
                    </div>
                  </form>
                  <div className='pl3'>{this.state.current360.createdDate <= 1632543899869 ? '' : sendErrorsCall}</div>
                </div>
              </>
            ) : (
              <></>
            )}

            <h1 className='mt4'>Editing reviewers for this {projectType !== 'Team Assessment' ? 'user' : 'team'}</h1>

            <div className='pa2 bg-white'>
              {Object.keys(this.state.currentUserReviewers).length != 0 ? (
                <div>
                  {Object.keys(this.state.currentUserReviewers).map((key, i) => {
                    return (
                      <UserReviewerEditForm
                        accountData={this.props.accountData}
                        reviewerID={key}
                        revieweeID={this.state.userId}
                        accountId={this.state.accountId}
                        current360={this.state.current360}
                        current360ID={this.state.current360ID}
                        currentGroupId={this.state.groupId}
                        currentUser={this.state.currentUser}
                        name={this.state.currentUserReviewers[key]['name']}
                        email={this.state.currentUserReviewers[key]['email']}
                        relationship={this.state.currentUserReviewers[key]['relationship']}
                        completed={this.state.currentUserReviewers[key]['completed']}
                        current360Relationships={this.state.current360Relationships}
                        deleteReviewer={this.deleteReviewer}
                        currentGroupTasks={this.state.currentGroupTasks}
                        errors={this.state.loadedSend[0]}
                        authTok={this.context.za}
                        reviewerEmailStatuses={reviewerEmailStatuses[key]}
                        reviewerErrors={reviewerErrors[key]}
                        reviewerEventReasons={reviewerEventReasons[key]}
                        emailRecieved={this.state.currentUserReviewers[key].emailRecieved}
                        emailStatus={this.state.currentUserReviewers[key].emailStatus}
                      />
                    );
                  })}

                  <div className='pa2'>
                    <button
                      className={this.state.addingReviewer ? 'btn btn--green btn--disabled mb2' : 'btn btn--green mb2'}
                      onClick={() => this.openNewReviewerModal()}
                    >
                      Add new reviewer
                    </button>
                  </div>
                </div>
              ) : (
                <div className='pa2'>
                  <p>No reviewers setup for this user</p>
                  <button
                    className={this.state.addingReviewer ? 'btn btn--green btn--disabled mb2' : 'btn btn--green mb2'}
                    onClick={() => this.openNewReviewerModal()}
                  >
                    Add Reviewer
                  </button>
                </div>
              )}
            </div>

            <Link to={{ pathname: '../../' }} className='btn btn--back mt3'>
              Back To Group Overview
            </Link>

            <Modal
              isOpen={this.state.showDeletingUserModal}
              contentLabel='Delete this reviewer?'
              onClose={() =>
                this.setState({
                  showDeletingUserModal: false,
                  reviewerToDelete: null,
                })
              }
              hideCloseButton={false}
              content={
                <div>
                  <h2>Are you sure you want to delete this reviewer?</h2>
                  <p>Click confirm below to remove</p>
                  <button onClick={this.confirmReviewerDeletion} className='btn'>
                    Confirm
                  </button>
                </div>
              }
            />

            <Modal
              isOpen={this.state.showSendingModal}
              contentLabel='Reviewer Confirmation'
              hideCloseButton={true}
              onClose={() => this.closeSendingModal()}
              content={
                <div className='tc'>
                  {!this.state.sendingComplete ? (
                    <h1 className='mt0 mb2'>Sending Reviewer Notification</h1>
                  ) : (
                    <h1 className='mt0 mb2'>Notification Complete</h1>
                  )}

                  {!this.state.sendingComplete ? <div className='loading-spinner loading-spinner--lonely' /> : ''}
                  {this.state.sendingError ? (
                    <p className='red'>There was an error assigning one or more Reviewers, please check Reviewer details and try again</p>
                  ) : (
                    ''
                  )}
                  {this.state.sendingSuccess ? (
                    <p>An email has been sent to the Reviewer notifiying them to complete a 360 on behalf of their colleague.</p>
                  ) : (
                    ''
                  )}
                  {this.state.sendingComplete ? (
                    <button className='btn' onClick={this.closeSendingModal}>
                      Close
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              }
            />

            <Modal
              isOpen={this.state.showNewReviewerModal}
              contentLabel='Add new reviewer'
              hideCloseButton={false}
              onClose={() => this.closeNewReviewerModal()}
              content={
                <form onSubmit={this.onSubmitReviewer} ref={(el) => (this.newReviewFormRef = el)}>
                  <h3>Adding new reviewer for {this.state.currentUser.forename ? this.state.currentUser.forename : this.state.currentUser.name}</h3>
                  <div className=''>
                    <div className='w-100'>
                      <label htmlFor='forename' className='b db mb1'>
                        First Name
                      </label>
                      <input
                        required
                        ref={(input) => (this.forename = input)}
                        aria-describedby='forename-desc'
                        className='input-reset bn pa3 mb2 db w-100 bg-light-blue'
                        type='text'
                        placeholder='Type first name here'
                      />
                    </div>
                    <div className='w-100'>
                      <label htmlFor='surname' className='b db mb1'>
                        Last Name
                      </label>
                      <input
                        required
                        ref={(input) => (this.surname = input)}
                        aria-describedby='surname-desc'
                        className='input-reset bn pa3 mb2 db w-100 bg-light-blue'
                        type='text'
                        placeholder='Type last name here'
                      />
                    </div>
                    <div className='w-100'>
                      <label htmlFor='email' className='b db mb1'>
                        Email
                      </label>
                      <input
                        required
                        ref={(input) => (this.email = input)}
                        aria-describedby='email-desc'
                        className='input-reset bn pa3 mb2 db w-100 bg-light-blue'
                        type='email'
                        placeholder='Type email here'
                      />
                    </div>
                    {projectType !== 'Team Assessment' ? (
                      <div className='w-100'>
                        <label htmlFor='surname' className='b db mb1'>
                          Relationship
                        </label>
                        <select ref={(input) => (this.relationship = input)} className='grey xl white'>
                          {this.state.current360Relationships
                            ? Object.keys(this.state.current360Relationships).map((relationshipKey) => {
                                return (
                                  <option defaultValue={relationshipKey} value={relationshipKey}>
                                    {this.state.current360Relationships[relationshipKey]['name']}
                                  </option>
                                );
                              })
                            : ''}
                        </select>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {this.state.duplicateEmail ? <strong className='red'>Someone with this email address is aready a reviewer for this person</strong> : ''}
                  <div className='w-100 pt4'>
                    <button className='btn w-100' type='submit'>
                      Add reviewer
                    </button>
                  </div>
                </form>
              }
            />
          </section>
        )}
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Edit360GroupUser);
