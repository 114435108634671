import React from 'react';
// import { withRouter } from 'react-router-dom'
import SignInForm from '../components/signinform';
import SiteHeader from '../components/site-header';
import withAuthorisation from '../components/withauthorisation';
import { Helmet } from 'react-helmet-prepend';
const authCondition = (authUser) => !!authUser;
//

const SignInPage = ({ history }) => (
  <main>
    <section className='sign-in'>
      <SignInForm history={history} page={'sign-in'} />
    </section>
  </main>
);

export default SignInPage;
// export default withAuthorisation(authCondition)(SignInPage);
