import React, { Component } from 'react';
import TitlePage from '../TitlePage';
import PageHeader from '../PageHeader';
import DFChartHeadings from '../charts/DFChartHeadings';
import DFChartSections from '../charts/DFChartSections';
import DFChartQuestions from '../charts/DFChartQuestions';
import FreeTextTeamFeedback from './FreeTextTeamFeedback';
import { arrayGenerator } from '../../helpers';

export default class TeamDetailedFeedbackSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading });
  }

  render() {
    const {loading} = this.state
    const {sectionDetails, reviewerAnswersAverageSection, reviewerSectionResponses, reviewerAnswersAverageQuestion, freeTextAnswers, reviewerQuesionResponses, questionDetails} = this.props
    if (!loading) {
      return (
        <>
          <TitlePage h1Text='Part 2:' h2Text='Team Detailed Feedback' />
          <div className='bg-white page-break user-report__content'>
            <PageHeader h2Class='mt4' h2Text='Part 2: ' strong='Step 1' h3Text='Navigating the detail' line={true} />
            <p>
              This section of the report looks in detail at the feedback that the team has received in each competency area. It shows the average
              feedback rating from each team member for every statement (behaviour/action).
            </p>
            <p>The statement ratings are also added together to show the headline ratings for each competency.</p>
            <p>
              Where the team received higher ratings for statements, it means that it is consistent when displaying that behaviour/action. Conversely,
              delivering consistently lowly-rated statements (behaviours/actions), can point to areas where the team might need to change/improve.
            </p>
            <p>You can also use this area of the report to help you identify any potential ‘outlier’ ratings.</p>
          </div>
          {Object.keys(sectionDetails)
            .sort((a, b) => {
              return sectionDetails[a].sortOrder - sectionDetails[b].sortOrder;
            })
            .map((sectionID, sectionIndex) => {
              // filter to just get multichoice questions
              const multiChoiceQuestions = Object.keys(sectionDetails[sectionID].questions).filter(qid => {
                return sectionDetails[sectionID].questions[qid].answerType === "MultiChoice"
              })
              // calculate how many pages will be needed to prevent overflow
              const multiChoicePagesCount = Math.ceil(multiChoiceQuestions.length / 27)

              return (
                <div key={sectionID}>
                  {/* multichoice Feedback */}
                  {multiChoiceQuestions.length > 0 && (
                    arrayGenerator(multiChoicePagesCount).map((page, pageIndex) => {
                      const lowerLimit = pageIndex * 27
                      const upperLimit = (pageIndex + 1) * 27
                      return (
                        <div key={sectionID + pageIndex} className='mb4 page-break user-report__content user-report__content--no-pad'>
                          <div className='average-rating-section'>
                            <PageHeader h2Class='mt4' h2Text='Part 2: ' strong={`Step ${sectionIndex + 2}`} h3Text={`Your detailed feedback${pageIndex > 0 ? ' (continued)' : ''}`} />
                            <div className='ba b--moon-gray mb10 table page-break'>
                              <DFChartHeadings />
                              {pageIndex === 0 && <DFChartSections
                                sectionTitle={sectionDetails[sectionID].title}
                                relationshipTitle='Team Members'
                                relationshipColor='#F563B9'
                                sectionAverage={reviewerAnswersAverageSection[sectionID]}
                                sectionResponses={reviewerSectionResponses[sectionID]}
                              />}
                              {multiChoiceQuestions.map((questionID, questionIndex) => {
                                const question = sectionDetails[sectionID].questions[questionID];
                                const questionAverage = reviewerAnswersAverageQuestion[questionID];
                                if (questionIndex >= lowerLimit && questionIndex < upperLimit) {
                                  return (
                                    <DFChartQuestions
                                      key={sectionID + pageIndex + questionID}
                                      questionID={questionID}
                                      questionTitle={question.questionTitle}
                                      relationshipTitle='Team Members'
                                      relationshipColor='#F563B9'
                                      questionAverage={questionAverage}
                                      questionResponses={reviewerQuesionResponses[questionID]}
                                    />
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  )}
                  {/* freetext Feedback */}
                  {freeTextAnswers[sectionID] && (
                    Object.keys(freeTextAnswers[sectionID])
                      .sort((a, b) => {
                        return questionDetails[a].sortOrder - questionDetails[b].sortOrder;
                      })
                      .map((questionID, questionIndex) => {
                        const answers = freeTextAnswers[sectionID][questionID].filter(answer => {
                          return answer.length > 0
                        })
                        return (
                          <FreeTextTeamFeedback key={sectionID + questionID} sectionID={sectionID} sectionIndex={sectionIndex} questionDetails={questionDetails} questionID={questionID} questionIndex={questionIndex} answers={answers} loading={loading} />
                        )
                      })
                  )}
                </div>
              )
            })
          }
        </>
      )
    } else {
      return <p>loading</p>;
    }
  }
}

