import React, { Component } from 'react';
// import ReactExport from "react-export-excel";
import ReactExport from 'react-data-export';
import { db } from '../firebase';
import moment from 'moment';
import Modal from './global/modal';
import { projectTypeText } from '../helpers';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportGroupButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalWindow: false,
      clicked: false,
      loading: true,
      excelData: null,
      groupUsers: this.props.groupusers,
      accountId: this.props.accountId,
      current360Id: this.props.current360Id,
      currentGroupId: this.props.currentGroupId,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ modalWindow: false });
  }
  openModal(type) {
    this.getExcelData();
    this.setState({ modalWindow: true });
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  //this calls the firestore db and loads the data into state for export
  getExcelData() {
    let data = [];
    let excelData = [];
    let count = 0;

    this.setState({
      clicked: true,
    });
    // MUSH THROUGH THE REPORT DATA OBJECT
    let userCount = 0;

    // a check to see if there is any data within the exceldata in the state, if there is then it doesn't make another db call
    if (!this.state.excelData) {
      this.setState({ excelData: null });
      Object.keys(this.state.groupUsers).map((revieweeKey) => {
        userCount++;
        // Loop through each users Reviewer List
        db.doGetCurrentGroupUserReviewers(this.state.accountId, this.state.current360Id, this.state.currentGroupId, revieweeKey).onSnapshot((snapshot) => {
          count++;

          if (this.props.projectType === 'Team Assessment') {
            if (snapshot.docs) {
              snapshot.docs.map((doc, i) => {
                data.push([doc.data().name ? doc.data().name : 'N/A', doc.data().completed ? 'Completed' : 'Incomplete']);
              });
            }
          } else {
            data.push([
              `${this.state.groupUsers[revieweeKey].forename} ${this.state.groupUsers[revieweeKey].surname}`,
              this.state.groupUsers[revieweeKey].email,
              '',
              'Self',
              this.state.groupUsers[revieweeKey].completed ? 'Completed' : 'Incomplete',
            ]);

            if (snapshot.docs) {
              snapshot.docs.map((doc, i) => {
                data.push([
                  `${this.state.groupUsers[revieweeKey].forename} ${this.state.groupUsers[revieweeKey].surname}`,
                  this.state.groupUsers[revieweeKey].email,
                  doc.data().name ? doc.data().name : 'N/A',
                  doc.data().relationship,
                  doc.data().completed ? 'Completed' : 'Incomplete',
                ]);
              });
            }
          }

          if (Object.keys(this.state.groupUsers).length == userCount) {
            // uses 2 columns for Team Assessments and 5 otherwise
            excelData =
              this.props.projectType !== 'Team Assessment'
                ? [
                    {
                      columns: [
                        { title: 'Reviewee', width: { wpx: 200 } },
                        { title: 'Email', width: { wpx: 200 } },
                        { title: 'Reviewer Name', width: { wpx: 200 } },
                        { title: 'Relationship', width: { wpx: 200 } },
                        { title: 'Review Status', width: { wpx: 200 } },
                      ],
                      data: data,
                    },
                  ]
                : [
                    {
                      columns: [
                        { title: 'Reviewer Name', width: { wpx: 200 } },
                        { title: 'Review Status', width: { wpx: 200 } },
                      ],
                      data: data,
                    },
                  ];

            this.setState({
              excelData: excelData,
            });
          }
        });
      });

      //this else is used when this function is called and theres already excel data loaded
    } else {
      return;
    }
  }

  render() {
    return (
      <>
        {!this.state.loading ? (
          <div>
            <button className='btn' onClick={() => this.openModal()}>
              Export {projectTypeText(this.props.projectType, 'Group', 'Group', 'Team')} Spreadsheet
            </button>
            <Modal
              isOpen={this.state.modalWindow}
              contentLabel='Test'
              onClose={() => this.closeModal()}
              content={
                <div className='pa2'>
                  <h3>Confirm Export</h3>
                  <p>
                    Please click below to confirm your export:
                    <br />
                    <strong>
                      group-
                      {this.props.groupinfo.title}
                      -export-
                      {moment(Date.now()).format('DD-MM-YY')}
                      .xls
                    </strong>
                  </p>
                  <div className='tc'>
                    <ExcelFile
                      filename={`group-${this.props.groupinfo.title}-export-${moment(Date.now()).format('DD-MM-YY')}`}
                      element={
                        <button
                          className='btn'
                          onClick={() => {
                            this.setState({ clicked: false, modalWindow: false });
                          }}
                        >
                          Confirm Export
                        </button>
                      }
                    >
                      <ExcelSheet dataSet={this.state.excelData} name={`group-${this.props.groupinfo.title}-export-${moment(Date.now()).format('DD-MM-YY')}`} />
                    </ExcelFile>
                  </div>
                </div>
              }
            />
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default ExportGroupButton;
