import React, { Component } from 'react';

export default class Contents extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.state };
  }
  render() {
    const item = (line, h2Content, h2Class, h4Content, h1Content) => {
      return (
        <>
          {h1Content ? <h1>{h1Content}</h1> : <></>}
          {h2Content ? (
            <h2 className={`f3 ${h2Class}`}>
              <strong>{h2Content}</strong>
            </h2>
          ) : (
            <></>
          )}
          {h4Content ? <h4 className='f4 mb4 mt0'>{h4Content}</h4> : <></>}
          {line ? <hr /> : <></>}
        </>
      );
    };
    return (
      <div className='user-report__contents'>
        {item(true, '', '', '', 'Contents')}
        {item(true, 'Instructions', 'mv4 mb3', '')}
        {this.props.partTitles.map((title, index) => {
          return <div key={index}>{item(index + 1 < this.props.partTitles.length, `Part ${index + 1}`, 'mt4 mb0', title, '')}</div>;
        })}
      </div>
    );
  }
}
