import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import Autocomplete from 'react-autocomplete';
import LoadingState from '../../../components/global/loading-state';
import Breadcrumbs from '../../../components/breadcrumbs';
import SiteFooter from '../../../components/site-footer';
import SiteHeader from '../../../components/site-header';
import SiteNav from '../../../components/site-nav';
import StaticQuestion from '../../../components/staticquestion';
import { IconPlusCircle } from '../../../svg/svgs';
import { breadcrumbSelect } from '../../../helpers';

//
import { compose } from 'recompose';
import withAuthorisation from '../../../components/withauthorisation';
import withUser360s from '../../../components/withuser360s';
const authCondition = (authUser) => !!authUser;
//

let existing360ItemList = [];

class Edit360QuestionnaireSection extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    this.state = {
      loading: true,
      id: params.questionnaireSectionId,
      current360: this.props.current360,
      userDetails: this.props.userDetails,
      accountTitle: this.props.accountTitle,
      current360Id: params.id,
      accountId: params.accountId,
    };

    this.addQuestion = this.addQuestion.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.updateDesc = this.updateDesc.bind(this);
    this.questionUp = this.questionUp.bind(this);
    this.questionDown = this.questionDown.bind(this);
    this.orderAfterDeletion = this.orderAfterDeletion.bind(this);
  }

  componentDidMount() {
    // console.log(this.state.accountId, this.state.current360.questionnaireID, this.state.id);
    db.doGetSectionInfo(this.state.accountId, this.state.current360.questionnaireID, this.state.id).then((snapshot) => {
      if (snapshot.data()) {
        this.setState({
          section: snapshot.data(),
        });

        db.doGetSectionQuestions(this.state.accountId, this.state.current360.questionnaireID, this.state.id).onSnapshot((snapshot) => {
          let questions = {};
          let legacyMode = false;
          snapshot.docs.map((doc) => {
            if (doc.data().key === undefined) {
              let dataObj = new Object();
              dataObj = doc.data();
              dataObj.legacyKey = doc.id;
              questions[doc.id] = dataObj;
            } else {
              questions[doc.id] = doc.data();
            }
          });

          const toOrder = Object.keys(questions).map((i) => questions[i]);
          //it will always check the first one in the arrray which will always be the oldest as they are saved in date orderr
          if (toOrder.length != 0) {
            if (toOrder[0].sortOrder === undefined) {
              legacyMode = true;
            }
          }

          if (legacyMode === false) {
            toOrder.sort((a, b) => {
              if (a.sortOrder < b.sortOrder) {
                return -1;
              }
              if (a.sortOrder > b.sortOrder) {
                return 1;
              }
            });
          }

          this.setState({
            loading: false,
            questions: toOrder,
            legacy: legacyMode,
          });
        });
      }
    });
  }

  addQuestion(e) {
    e.preventDefault();
    let questionData = {
      answerType: this.answerType.value,
      questionTitle: this.questionTitle.value,
      required: this.questionRequired.value,
      sortOrder: Object.keys(this.state.questions).length,
    };

    db.doCreateNewQuestion(this.state.accountId, this.state.current360.questionnaireID, this.state.id, questionData);

    e.target.reset();

    e.preventDefault();
  }

  updateTitle(e) {
    db.doUpdateSectionTitle(this.state.accountId, this.state.current360.questionnaireID, this.state.id, e.target.value);
  }

  updateDesc(e) {
    db.doUpdateSectionDesc(this.state.accountId, this.state.current360.questionnaireID, this.state.id, e.target.value);
  }

  questionUp(e, orderBy) {
    e.preventDefault();
    let startArray = this.state.questions;
    let toIncrease = { ...startArray[orderBy - 1] };
    let toReduce = { ...startArray[orderBy] };
    toIncrease.sortOrder = toIncrease.sortOrder + 1;
    toReduce.sortOrder = toReduce.sortOrder - 1;
    startArray[orderBy - 1] = toReduce;
    startArray[orderBy] = toIncrease;

    db.updateQuestion(this.state.accountId, this.state.current360.questionnaireID, this.state.id, toReduce.legacyKey, toReduce).then((res) => {
      db.updateQuestion(this.state.accountId, this.state.current360.questionnaireID, this.state.id, toIncrease.legacyKey, toIncrease).then(() => {
        this.setState({
          startArray,
        });
      });
    });
  }

  questionDown(e, orderBy) {
    e.preventDefault();
    let startArray = this.state.questions;
    let toIncrease = { ...startArray[orderBy] };
    let toReduce = { ...startArray[orderBy + 1] };
    toIncrease.sortOrder = toIncrease.sortOrder + 1;
    toReduce.sortOrder = toReduce.sortOrder - 1;
    startArray[orderBy + 1] = toReduce;
    startArray[orderBy] = toIncrease;
    db.updateQuestion(this.state.accountId, this.state.current360.questionnaireID, this.state.id, toReduce.legacyKey, toReduce).then((res) => {
      db.updateQuestion(this.state.accountId, this.state.current360.questionnaireID, this.state.id, toIncrease.legacyKey, toIncrease).then(() => {
        this.setState({
          startArray,
        });
      });
    });
  }

  //after deleting a question, the questions after the deleted one need to be updated to reduce their sort order by 1
  orderAfterDeletion(e, questionID, sort) {
    e.preventDefault();

    let questions = [...this.state.questions];

    questions.splice(sort, 1);

    questions.forEach((question, i) => {
      if (i >= sort) {
        if (question.sortOrder !== undefined) {
          question.sortOrder = question.sortOrder - 1;
          db.updateQuestion(this.state.accountId, this.state.current360.questionnaireID, this.state.id, question.legacyKey, question);
        }
      }
    });
    //update the state with the new array
    this.setState({
      ...this.state,
      questions: questions,
    });
  }

  render() {
    console.log('test');
    // const { match: { params } } = this.props
    // let coreSettingsLink = `/dashboard/360s/${params.id}/edit/basic/`
    // alert(coreSettingsLink);
    return (
      <div className='page-wrapper'>
        {this.state.loading ? (
          <LoadingState />
        ) : (
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.state.current360}
              userDetails={this.state.userDetails}
              accountTitle={this.state.accountTitle}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.state.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(this.props.current360.type)}-projects/`,
                },
                {
                  title: this.state.current360.title,
                  to: '../../',
                },
                {
                  title: 'Questionnaire setup',
                  to: '../',
                },
                {
                  title: 'Editing Section',
                  to: null,
                },
              ]}
            />

            <h1>{this.state.current360.title}: Questionnaire Set-Up</h1>

            <div className='bg-white mv4 ph4 pv4 shadow br2'>
              {this.state.current360.type == 'Goal Tracker' ? (
                <div>
                  <p>
                    <strong>Editing Goal Tracker step</strong>
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    <strong>Editing Questionnaire Competency</strong>
                  </p>
                </div>
              )}

              <div className='accordion__item mb2'>
                <label className='mb2 db'>Title:</label>
                <input
                  required
                  onBlur={this.updateTitle}
                  type='text'
                  className='input-reset ba b--black-20 bg-light-blue pa2 mb4 db w-100 outline-0'
                  defaultValue={this.state.section.title}
                />
                <label className='mb2 db'>Description:</label>
                <textarea onBlur={this.updateDesc} rows='4' defaultValue={this.state.section.description} />

                <div className='flex'>
                  <div className='w-60 tc'>
                    <p className='bl br b--light-silver'>Statement</p>
                  </div>

                  <div className='w-40 flex justify-around'>
                    <p className='br w-100 tc b--light-silver'>Type</p>
                    <p className='br w-100 tc b--light-silver'>Setting</p>
                    <p className='br w-100 tc b--light-silver'>Action</p>
                  </div>
                </div>

                <form action='/' className='flex br3 mb3 mt2 pv3 bg--footerColor' onSubmit={this.addQuestion}>
                  <div className='w-60 pl3'>
                    <input
                      required
                      ref={(input) => (this.questionTitle = input)}
                      className='reset-mar transparent no-border color--white'
                      type='text'
                      placeholder='Type a new question here'
                    />
                  </div>

                  <div className='w-40 flex justify-around items-center'>
                    <div className='bl w-100 tc b--gray'>
                      <select ref={(input) => (this.answerType = input)} className='bg-transparent bn white'>
                        {this.state.current360.type !== 'Goal Tracker' ? <option value='MultiChoice'>Rating</option> : ''}

                        <option value='FreeText'>Text</option>
                        {this.state.current360.type == 'Goal Tracker' ? <option value='Date'>Date</option> : ''}
                      </select>
                    </div>

                    <div className='bl w-100 tc b--gray'>
                      <select ref={(input) => (this.questionRequired = input)} className='bg-transparent bn white'>
                        <option value='true'>Mandatory</option>
                        <option value='false'>Optional</option>
                      </select>
                    </div>

                    <div className='bl w-100 tc b--gray'>
                      <button className='has-tooltip' data-tooltip='Add' type='submit'>
                        <IconPlusCircle width='25' height='25' fill='#00dca8' />
                      </button>
                    </div>
                  </div>
                </form>

                {this.state.questions
                  ? this.state.questions
                      // .reverse()
                      .map((key, i) => {
                        return (
                          <StaticQuestion
                            key={this.state.questions[i].id === undefined ? this.state.questions[i].legacyKey : this.state.questions[i].id}
                            index={this.state.questions[i].id === undefined ? this.state.questions[i].legacyKey : this.state.questions[i].id}
                            docID={this.state.questions[i].legacyKey}
                            sort={this.state.questions[i].sortOrder}
                            accountId={this.state.accountId}
                            questionTitle={this.state.questions[i].questionTitle}
                            answerType={this.state.questions[i].answerType}
                            questionRequired={this.state.questions[i].required}
                            questionDate={this.state.questions[i].questionDate}
                            questionnaireID={this.state.current360.questionnaireID}
                            current360Type={this.state.current360.type}
                            sectionID={this.state.id}
                            questionUp={this.questionUp}
                            questionDown={this.questionDown}
                            upBtnDisabled={i === 0}
                            downBtnDisabled={this.state.questions.length - 1 <= i}
                            legacyMode={this.state.legacy}
                            orderAfterDeletion={this.orderAfterDeletion}
                          />
                        );
                      })
                  : ''}
              </div>
            </div>

            <Link to={{ pathname: '../', current360: this.props.current360, accountData: this.props.accountData }} className='btn btn--back'>
              Save and Return to Questionnaire
            </Link>
          </section>
        )}
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Edit360QuestionnaireSection);
