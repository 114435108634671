import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { breadcrumbSelect } from '../helpers';
import SiteHeader from '../components/site-header';
import SiteNav from '../components/site-nav';
import LoadingState from '../components/global/loading-state';
import Breadcrumbs from '../components/breadcrumbs';
import RevieweeDetails from '../components/revieweedetails';
import Modal from '../components/global/modal';
import DatePicker from 'react-datepicker';
import ExportGroupButton from '../components/exportgroupbutton';
import EmailConfirmation from '../components/emailConfirmation';
import AuthUserContext from '../components/authusercontext';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
//
import { compose } from 'recompose';
import withAuthorisation from '../components/withauthorisation';
import withUser360s from '../components/withuser360s';

import SendEmails from '../components/sendEmails';
import axios from 'axios';

const authCondition = (authUser) => !!authUser;
//

let twoWeeksFromNow = new Date();
twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);

class GroupEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      current360Relationships: this.props.current360Relationships,
      current360Groups: this.props.current360Groups,
      accountData: this.props.accountData,
      loading: true,
      error: false,
      current360Id: null,
      currentGroupId: null,
      currentGroup: null,
      questionnaireSections: null,
    };
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    window.scrollTo(0, 0);

    const {
      match: { params },
    } = this.props;

    this.dashboardLink = `/dashboard/${this.state.accountId}/`;
    this.editLink = `/dashboard/${this.state.accountId}/360s/${params.id}/edit/`;

    let emailReminderText = {};
    let currentGroupUsersReviewers = {};
    let currentGroup = {};

    db.doGetCurrentGroup(this.state.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
      if (snapshot.data()) {
        currentGroup = snapshot.data();
        db.doGetCurrentGroupTasks(this.state.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
          let currentGroupTasks = {};
          if (!snapshot.empty) {
            snapshot.docs.map((doc, i) => {
              currentGroupTasks[doc.id] = doc.data();
            });
          }
          db.doGetCurrentGroupUsers(this.state.accountId, params.id, params.groupId).onSnapshot((snapshot) => {
            let currentGroupUsers = {};
            if (!snapshot.empty) {
              snapshot.docs.map((doc, i) => {
                currentGroupUsers[doc.id] = doc.data();
              });
            }
            db.doGetCurrentGroupUsersReviewers(this.state.accountId, params.id, params.groupId, currentGroupUsers, this.state.current360.type).then(
              (currentGroupUsersReviewersData) => {
                currentGroupUsersReviewers = currentGroupUsersReviewersData;
                db.getEmailReminderText(this.state.accountId, params.id).then((snapshot) => {
                  emailReminderText = snapshot.data();
                  let questionnaireSections = {};
                  db.doGetSectionsListOrdered(this.state.accountId, this.state.current360.questionnaireID).onSnapshot((snapshot) => {
                    if (snapshot.empty) {
                      db.doGetSectionsList(this.state.accountId, this.state.current360.questionnaireID).onSnapshot((snapshot) => {
                        snapshot.docs.map((doc, i) => {
                          questionnaireSections[doc.id] = doc.data();
                        });
                        this.setState({
                          questionnaireSections: questionnaireSections,
                        });
                      });
                    } else {
                      snapshot.docs.map((doc, i) => {
                        questionnaireSections[doc.id] = doc.data();
                      });
                      this.setState({
                        current360Id: params.id,
                        currentGroupId: params.groupId,
                        currentGroup: currentGroup,
                        currentGroupTasks: currentGroupTasks,
                        currentGroupUsers: currentGroupUsers,
                        currentGroupUsersReviewers: currentGroupUsersReviewers,
                        emailReminderText: emailReminderText,
                        questionnaireSections: questionnaireSections,
                      });
                    }
                  });
                });
              }
            );
          });
        });
      } else {
        this.setState({
          loading: false,
          error: true,
        });
      }
    });
  }

  componentDidUpdate() {
    const updateProcess = (currentGroupUserReviewers) => {
      this.setState({ loadedSend: 'loading' });
      const reqBody = {
        accountId: this.state.accountId,
        accountEmail: this.state.accountData.email,
        current360Id: this.state.current360Id,
        currentGroupId: this.state.currentGroupId,
        currentGroupUsers: this.state.currentGroupUsers,
        currentGroupUserReviewers: currentGroupUserReviewers,
        current360: this.state.current360,
      };
      axios
        .post('/checkEmails', {
          reqBody,
        })
        .then((res) => {
          this.setState({
            loadedSend: [res.data.errors],
            loading: false,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    };

    if (this.state.current360.type !== 'Goal Tracker') {
      if (this.state.loadedSend == null && this.state.currentGroupUsersReviewers !== undefined && this.state.currentGroupTasks[1].completedDate !== false) {
        updateProcess(this.state.currentGroupUsersReviewers);
      } else if (this.state.loadedSend == null && this.state.currentGroupUsersReviewers !== undefined && this.state.loading == true) {
        this.setState({
          loading: false,
          loadedSend: [],
        });
      }
    } else {
      if (this.state.loadedSend == null && this.state.questionnaireSections !== null) {
        updateProcess({});
      } else if (this.state.loadedSend == null && this.state.currentGroupUsersReviewers !== undefined && this.state.loading == true) {
        this.setState({
          loading: false,
          loadedSend: [],
        });
      }
    }
  }

  render() {
    const {
      match: { params },
    } = this.props;

    let theState = this.state;

    return (
      <div className='page-wrapper'>
        {this.state.loading ? (
          <LoadingState />
        ) : this.state.error ? (
          <div className='tc'>
            <h3>Error loading Group</h3>
            <Link
              class='btn btn--back'
              to={{
                pathname: `/dashboard/${this.props.match.params.accountId}/`,
              }}
            >
              <span>Return to Dashboard</span>
            </Link>
          </div>
        ) : (
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.state.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${
                    this.props.current360.type
                  } Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(this.props.current360.type)}-projects/`,
                },
                {
                  title: this.state.current360.title,
                  to: '../../',
                },
                {
                  title: this.state.currentGroup.title,
                  to: './',
                },
                {
                  title: 'Full Group Email Events',
                  to: null,
                },
              ]}
            />

            <h1>Full Group Email Events</h1>

            <div className='bg--white mv4 ph4 pv4 shadow br2'>
              <div className='pa2 mt 4'>
                <EmailConfirmation
                  buttonLabel='Send Emails Modal'
                  accountId={theState.accountId}
                  current360Id={theState.current360Id}
                  current360Group={theState.currentGroupId}
                  currentGroupUsers={theState.currentGroupUsers}
                  currentGroupUserReviewers={theState.currentGroupUsersReviewers}
                  current360={theState.current360}
                  activeTask={theState.current360Groups[theState.currentGroupId].activeTask}
                  loadedSend={theState.loadedSend}
                />
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(GroupEvents);
