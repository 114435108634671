import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from './authusercontext';
import { db, firebase, auth } from '../firebase';
import loadingGif from '../loading.gif';
import { Link } from 'react-router-dom';
import SiteHeader from '../components/site-header';
import SuperAdminNotification from '../components/super-admin-notification';
import SiteNav from '../components/site-nav';
import SiteFooterBrand from '../components/site-footer-brand';
import { Helmet } from 'react-helmet';
import logo from '../logo.svg';
import Modal from './global/modal';
import SignInForm from './signinform';
import axios from 'axios';

let accountData = {};

const withAuthorisation = (authCondition) => (Component) => {
  class WithAuthorisation extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // currentAccountId: props.match.params.accountId,
        // current360Id: props.match.params.id,
        // current360: props.location.current360,
        // userDetails: props.location.userDetails,
        // accountTitle: props.location.accountTitle,
        // accountBranding: props.location.accountBranding,
        accountData: props.location.accountData ? props.location.accountData : null,
        current360: props.location.current360 ? props.location.current360 : null,
        current360Relationships: props.location.current360Relationships ? props.location.current360Relationships : null,
        current360Groups: props.location.current360Groups ? props.location.current360Groups : null,
        error: false,
        showselector: false,
        errorText: '',
        loading: true,
        modalWindow: false,
      };

      this.loadAccount = this.loadAccount.bind(this);
      this.populateAccountData = this.populateAccountData.bind(this);
      // this.loadAccountAndSelected360 = this.loadAccountAndSelected360.bind(this);
      this.load360 = this.load360.bind(this);
    }

    checkCookie(initialPageLoad) {
      // const sessionCookie = parseInt(getCookie('sessionExp'));
      const sessionCookie = parseInt(localStorage.getItem('sessionExp'));
      // // console.log(sessionCookie);

      //this is for 5 minutes in milliseconds
      const expiresIn = 60 * 5 * 1000;

      if (initialPageLoad && new Date().getTime() + expiresIn > sessionCookie) {
        this.props.history.push('/');
        return true;
      }
      if (sessionCookie) {
        if (new Date().getTime() + expiresIn > sessionCookie) {
          this.setState({
            modalWindow: true,
          });
          return true;
        } else {
          if (this.state.modalWindow) {
            this.setState({ modalWindow: false });
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.setState({
          modalWindow: true,
        });
        return true;
      }
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (!firebase.auth.currentUser) {
          this.props.history.push('/');
        } else {
          if (!authCondition(authUser) && firebase.auth.currentUser.email !== 'admin@boomerang-360.com') {
            this.props.history.push('/');
          } else {
            if (authUser.email === 'admin@boomerang-360.com') {
              if (this.props.history.location.pathname === '/') {
                this.props.history.push('/admin/');
              }
            } else {
              if (this.props.history.location.pathname === '/') {
                this.props.history.push('/dashboard/');
              }
            }
            if (
              !localStorage.getItem('loginAuth') &&
              !localStorage.getItem('Auth') &&
              !localStorage.getItem('sessionExp') &&
              firebase.auth.currentUser.email !== 'admin@boomerang-360.com'
            ) {
              this.logOut();
            } else {
              if (this.checkCookie(true)) {
                this.loadAccount();
              } else {
                axios
                  .post('/sessionLogin', { token: localStorage.getItem('loginAuth') })
                  .then((res) => {
                    window.localStorage.setItem('sessionExp', res.data.options.maxAge);
                    window.localStorage.setItem('Auth', res.data.cookie);
                    this.loadAccount();
                  })
                  .catch((err) => {
                    // console.log(err);
                    this.props.history.push('/');
                  });
              }
            }
          }
        }
      });
    }

    componentWillUnmount() {
      clearInterval(this.state.intervalID);
    }

    logOut(e) {
      auth.doSignOut();
    }

    loadAccount() {
      if (this.state.accountData && this.state.accountData.fname) {
        if (this.props.match.params.id) {
          this.load360();
        } else {
          this.setState({
            loading: false,
          });
        }

        // alert("no need to load 360 again!")
      } else {
        const checkCookie = setInterval(() => {
          this.checkCookie();
        }, 10000);

        const currentState = this.state;
        this.setState({ ...currentState, intervalID: checkCookie });
        db.doGetLoggedInUserDetails(firebase.auth.currentUser.uid)
          .then((data) => {
            let userDetails = {};

            if (firebase.auth.currentUser.email === 'admin@boomerang-360.com') {
              db.doGetLoggedInUserDetails(this.props.match.params.accountId).then((data) => {
                userDetails = data.data();
                this.populateAccountData(userDetails);
              });
            } else {
              userDetails = data.data();
              this.populateAccountData(userDetails);
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    }

    populateAccountData(userDetails) {
      if (this.props.history.location.pathname === '/dashboard/') {
        this.setState({
          error: false,
          loading: false,
          showselector: true,
        });
      } else {
        let accountVerified = false;
        for (let acc of userDetails.accounts) {
          if (acc.id === this.props.match.params.accountId) {
            accountVerified = true;
            // Get Account Branding
            db.doGetAccountBranding(acc.id).onSnapshot((snapshot) => {
              let accountBranding = snapshot.data();
              let accountTitle = snapshot.data().accountTitle;

              this.setState({
                accountData: { ...accountBranding, ...userDetails, accountTitle },
              });

              if (this.props.match.params.id) {
                this.load360();
              } else {
                this.setState({
                  loading: false,
                });
              }
            });
          }
        }

        if (!accountVerified) {
          // console.log('Error loading account!');
          this.setState({
            error: true,
            loading: false,
          });
        }
      }
    }

    loadAccountAndSelected360() {
      //
    }

    load360() {
      if (!this.state.current360 || !this.state.current360Relationships || !this.state.current360Groups) {
        db.doGetCurrent360(this.props.match.params.accountId, this.props.match.params.id).then((snapshot) => {
          let current360 = snapshot.data();
          current360['id'] = snapshot.id;
          this.setState({
            current360: snapshot.data(),
          });

          db.doGetCurrent360Relationships(this.props.match.params.accountId, this.props.match.params.id).then((snapshot) => {
            let relationships = {};
            snapshot.docs.map((doc, i) => {
              relationships[doc.id] = doc.data();
            });

            this.setState({
              current360Relationships: relationships,
            });

            db.doGetCurrent360Groups(this.props.match.params.accountId, this.props.match.params.id).then((snapshot) => {
              let groups = {};
              snapshot.docs.map((doc, i) => {
                groups[doc.id] = doc.data();
              });

              this.setState({
                current360Groups: groups,
                loading: false,
              });
            });
          });
        });
      } else {
        const checkCookie = setInterval(
          () => {
            this.checkCookie();
          },
          process.env.REACT_APP_FIREBASE_DB === 'staging' || typeof process.env.REACT_APP_FIREBASE_DB === 'undefined' ? 60000 : 300000
        ); // 5 minutes or 1 minute (for staging)
        // console.log('360 in state');
        this.setState({
          loading: false,
          intervalID: checkCookie,
        });
        // db.doGetAccountBranding(this.state.currentAccountId).then((data) => {
        //   this.setState({
        //     accountBranding: data.val(),
        //     loading: false
        //   })
        // })
      }
    }

    render() {
      const { accountData, current360, current360Relationships, current360Groups } = this.state;
      const { loading, error, showselector } = this.state;

      if (loading) {
        return (
          <div className='page-loading-state'>
            <img src={loadingGif} />
          </div>
        );
      }
      if (error) {
        return (
          <div className='page-error'>
            <div>
              <p>There was an error loading this page.</p>
              <p>
                <Link className='btn btn--white' to='/dashboard/'>
                  Return to dashboard
                </Link>
              </p>
            </div>
          </div>
        );
      }
      if (showselector) {
        return <AuthUserContext.Consumer>{(authUser) => (authUser ? <Component {...this.props} /> : '')}</AuthUserContext.Consumer>;
      }
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              // ? null
              <main>
                <Helmet>
                  <style>
                    {`
                    @font-face {
                      font-family: "Poppins-Medium";
                      src: url("${process.env.REACT_APP_ADMIN_DOMAIN}/Poppins-Medium.ttf") format("truetype");
                    }
                    @font-face {
                      font-family: "Poppins-Light";
                      src: url("${process.env.REACT_APP_ADMIN_DOMAIN}/Poppins-Light.ttf") format("truetype");
                    }
                    @font-face {
                      font-family: "Poppins-Bold";
                      src: url("${process.env.REACT_APP_ADMIN_DOMAIN}/Poppins-Bold.ttf") format("truetype");
                    }
                    @font-face {
                      font-family: "Poppins-SemiBold";
                      src: url("${process.env.REACT_APP_ADMIN_DOMAIN}/Poppins-SemiBold.ttf") format("truetype");
                    }
                    .page-break:empty {
                      margin: 0;
                      line-height: 0;
                    }
                    .site-header {
                      background: ${accountData.resetbrand ? '#FFFFFF' : '#0051ff'};
                      color: ${accountData.resetbrand ? '#333333' : '#FFFFFF'};
                    }
                    .site-header--nav {
                      box-shadow: ${accountData.resetbrand ? '0 6px 6px 0px rgba(0,0,0,0.15);' : 'none;'};
                    }
                    .site-header--nav:before {
                      display: ${accountData.resetbrand ? 'none;' : 'block;'};
                    }
                    h1,h2,h3,h4,h5,h6 {
                      color: ${accountData.resetbrand ? '#333E5B;' : '#0051ff'};
                    }
                    .btn:not(.btn--white) {
                      background-color: ${accountData.resetbrand && accountData.buttoncolour ? accountData.buttoncolour : accountData.primarycolour};
                    }
                    .create-new-panel:not(.create-new-panel--goal-tracker, .create-new-panel--team-assessment) {
                      background: ${accountData.resetbrand ? '#333E5B;' : '#0051ff'};
                    }
                    .create-new-panel:is(.create-new-panel--team-assessment) {
                      background-color: #3b4e7e;
                    }
                    .create-new-panel__dashboard {
                      display: block;
                      width: 31%;
                    }
                    .create-new-panel__title {
                      margin-bottom: 0.5rem;
                    }
                    .welcome {
                      color: ${accountData.resetbrand ? '#333E5B;' : '#0051ff'};
                    }
                    .welcome:before {
                      background: ${accountData.resetbrand ? 'transparent;' : 'transparent;'};
                    }
                    .site-header__nav-btn {
                      color: ${accountData.resetbrand ? '#333333' : '#FFFFFF'};
                      fill: ${accountData.resetbrand ? '#333333' : '#FFFFFF'};
                    }
                    .site-header__nav-btn span:before {
                      background: ${accountData.resetbrand ? '#333333' : '#FFFFFF'};
                    }
                    body {
                      color: ${accountData.resetbrand ? '#333333' : '#333333'};

                    }
                    ${accountData.resetbrand ? `.bg--pale { background-color: #ebeff2 !important; }` : ``}
                    .header-logo {
                      background-image: url(${accountData.logo.includes('boomerang-logo') ? logo : accountData.logo});
                    }
                    .site-footer {
                      background: ${accountData.resetbrand ? '#FFFFFF;' : '#3b4e7e'};
                    }
                    .user-report {
                      color: ${accountData.resetbrand ? '#333E5B;' : '#001e64;'}
                    }
                    .user-report .title-page {
                      background: ${accountData.resetbrand ? '#333E5B;' : '#001e64;'}
                    }
                    .user-report h1,
                    .user-report h2,
                    .user-report h3,
                    .user-report h4,
                    .user-report h5,
                    .user-report h6 {
                      color: ${accountData.resetbrand ? '#333E5B;' : '#001e64;'}
                    }
                    .user-report__header {
                      background-color: ${current360 && current360.settings ? '#FFFFFF' : '#000000'};
                    }
                    .user-report__header-title {
                      color: ${accountData.resetbrand ? '#333E5B !important;' : '#001e64 !important;'}
                    }
                    .user-report__header-sub-title {
                      color: ${accountData.resetbrand ? '#333E5B;' : '#001e64;'}
                    }
                    .user-report__header-name {
                      color: ${accountData.resetbrand ? '#333E5B;' : '#001e64;'}
                    }
                    .user-report .header-logo {
                      background-image: url(${current360 && current360.settings ? current360.settings.logo : accountData.logo}) !important;
                    }
                    `}
                  </style>
                </Helmet>
                {firebase.auth.currentUser.email === `admin@boomerang-360.com` ? <SuperAdminNotification /> : ''}
                <SiteHeader accountData={accountData} />
                <SiteNav accountData={accountData} {...this.props} removeLogoutButton={firebase.auth.currentUser.email === `admin@boomerang-360.com`} />
                <Component
                  accountData={accountData}
                  current360={current360}
                  current360Relationships={current360Relationships}
                  current360Groups={current360Groups}
                  userEmail={authUser.email}
                  {...this.props}
                />
                <SiteFooterBrand />
                <Modal
                  isOpen={this.state.modalWindow}
                  contentLabel='User session has expired'
                  onClose={(e) => this.logOut(e)}
                  hideCloseButton={true}
                  content={
                    <div>
                      <h2 className='tc'>Please Note</h2>
                      <p>Your session has expired, please sign in again below and you we be returned to the current page.</p>
                      <p></p>

                      <SignInForm page={'modal'} history={this.props.history} />
                    </div>
                  }
                />
              </main>
            ) : null
          // : <Component />
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorisation);
};

export default withAuthorisation;
