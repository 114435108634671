import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from './authusercontext';
import { firebase } from '../firebase';

const withSuperUser = (authCondition) => (Component) => {
  class WithSuperUser extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        if (!authCondition(authUser) || authUser.email != "admin@boomerang-360.com") {
          this.props.history.push("/")
        }
      });
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => authUser
            ? <Component userEmail={authUser.email} {...this.props} />
            : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithSuperUser);

}

export default withSuperUser;
