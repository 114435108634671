import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../../../components/site-header'
import SiteNav from '../../../components/site-nav'
import withAuthorisation from '../../../components/withauthorisation'
const authCondition = (authUser) => !!authUser
//

class Edit360Pro extends Component {
  render() {
    return (
      <div className="page-wrapper">
        <section className="container container--slim">
          <h1>Edit Standard Settings</h1>
        </section>
      </div>
    );
  }
}

//
export default withAuthorisation(authCondition)(Edit360Pro);
