import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import { convertTaskNames, sleep, breadcrumbSelect, projectTypeText, emptyObjectCheck } from '../../../helpers';
import LoadingState from '../../../components/global/loading-state';
import Breadcrumbs from '../../../components/breadcrumbs';
import RevieweeDetails from '../../../components/revieweedetails';
import Modal from '../../../components/global/modal';
import DatePicker from 'react-datepicker';
import ExportGroupButton from '../../../components/exportgroupbutton';
import AuthUserContext from '../../../components/authusercontext';
import UserReviewerEditForm from '../../../components/userreviewereditform';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import withAuthorisation from '../../../components/withauthorisation';
import SendEmails from '../../../components/sendEmails';
import axios from 'axios';
import ExportScoresButton from '../../../components/exportScoresButton';

const authCondition = (authUser) => !!authUser;

let twoWeeksFromNow = new Date();
twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);

let oneDayFromNow = new Date();
oneDayFromNow.setDate(oneDayFromNow.getDate() + 1);

class Edit360Group extends Component {
  constructor(props) {
    super(props);

    this.onSubmitNewUser = this.onSubmitNewUser.bind(this);
    this.requestMoreLicenses = this.requestMoreLicenses.bind(this);
    this.closeSendingModal = this.closeSendingModal.bind(this);
    this.succesfullyCompletedEmailSendActions = this.succesfullyCompletedEmailSendActions.bind(this);
    this.exportGroup = this.exportGroup.bind(this);
    this.closeUserModal = this.closeUserModal.bind(this);
    this.closeModalAndMoveGroup = this.closeModalAndMoveGroup.bind(this);
    this.openUserModal = this.openUserModal.bind(this);
    this.onSubmitMoveUser = this.onSubmitMoveUser.bind(this);
    this.openMovingUserModal = this.openMovingUserModal.bind(this);
    this.openDeletingUserModal = this.openDeletingUserModal.bind(this);
    this.openRemindingUserModal = this.openRemindingUserModal.bind(this);
    this.editUser = this.editUser.bind(this);
    this.redirectToReport = this.redirectToReport.bind(this);
    this.deleteThisGroup = this.deleteThisGroup.bind(this);
    this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    this.updateDeadlineDate = this.updateDeadlineDate.bind(this);
    this.promptCreditsModal = this.promptCreditsModal.bind(this);
    this.reviewCompletion = this.reviewCompletion.bind(this);
    this.updateincompleteReviewers = this.updateincompleteReviewers.bind(this);
    this.deleteReviewer = this.deleteReviewer.bind(this);
    this.confirmReviewerDeletion = this.confirmReviewerDeletion.bind(this);
    this.onSubmitReviewer = this.onSubmitReviewer.bind(this);

    let dashboardLink;
    let editLink;

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      current360Relationships: this.props.current360Relationships,
      current360Groups: this.props.current360Groups,
      accountData: this.props.accountData,
      incompleteReviewers: [],
      reminderText: 'Send Reminder',
      loading: true,
      error: false,
      current360Id: null,
      currentGroupId: null,
      currentGroup: null,
      questionnaireSections: null,
      showNewUserModal: false,
      showSendingModal: false,
      showMaxUsersModal: false,
      newLicensesRequestComplete: false,
      newLicensesRequestError: false,
      showDeleteGroupModal: false,
      showMovingUserModal: false,
      showEmailSendModal: false,
      newUserModalErrorMessage: '',
      userMoved: false,
      userToMoveID: null,
      userToDeleteID: null,
      userToRemindID: null,
      individualReminderEmailSent: false,
      newGroup: null,
      deadlineDate: twoWeeksFromNow,
      loadedSend: null,
      sending: null,
      reviewerEventsLoaded: false,
    };
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;

    const {accountId, current360, accountData, current360ID} = this.state

    this.dashboardLink = `/dashboard/${this.state.accountId}/`;
    this.editLink = `/dashboard/${this.state.accountId}/360s/${params.id}/edit/`;

    const current360Id = params.id
    const currentGroupId = params.groupId

    db.doGetCurrentGroup(accountId, params.id, params.groupId).onSnapshot((snapshot) => {
      if (snapshot.data()) {
        const currentGroup = snapshot.data()

        db.doGetCurrentGroupTasks(accountId, params.id, params.groupId).onSnapshot((snapshot) => {
          let currentGroupTasks = {};
          if (!snapshot.empty) {
            snapshot.docs.map((doc, i) => {
              currentGroupTasks[doc.id] = doc.data();
            });
          }

          db.doGetCurrentGroupUsers(accountId, params.id, params.groupId).onSnapshot((snapshot) => {
            let currentGroupUsers = {};
            let teamId
            if (!snapshot.empty) {
              snapshot.docs.map((doc, i) => {
                currentGroupUsers[doc.id] = doc.data();
                if (this.state.current360.type === 'Team Assessment') {
                  teamId = doc.id
                }
              });
            }

            db.doGetCurrentGroupUsersReviewers(accountId, params.id, params.groupId, currentGroupUsers, current360.type).then(
              (currentGroupUsersReviewers) => {
                const numberOfReviewers = Object.keys(currentGroupUsersReviewers).length
                
                if (this.state.current360.type === 'Team Assessment') {
                  db.doGetCurrentGroupUserReviewers(accountId, params.id, params.groupId, teamId).onSnapshot((snapshot) => {
                    let reviewers = {};
                    let counter = 0
                    if (snapshot) {
                      snapshot.docs.forEach((doc) => {
                        reviewers[doc.data().reviewerID] = doc.data();
                        counter++
                      });
                    }
                    const groupReport = Object.values(reviewers).filter((rev) => rev.completed === true).length

                    if (numberOfReviewers === counter) {
                      this.setState({
                        groupReport,
                        userId: teamId,
                        reviewers,
                      });
                    }
                  });
                }
                db.doGetCurrentUser(accountId, current360ID, params.groupId, teamId).onSnapshot((snapshot) => {
                  const currentUser = snapshot.data()
                  db.doGetCurrentGroupUser(accountId, current360ID, params.groupId, teamId).then((doc) => {
                    const currentGroupUser = doc.data()

                    db.getEmailReminderText(accountId, params.id).then((snapshot) => {
                      this.setState({
                        emailReminderText: snapshot.data(),
                      });
                      let questionnaireSections = {};
                      db.doGetSectionsListOrdered(accountId, current360.questionnaireID).onSnapshot((snapshot) => {
                        if (snapshot.empty) {
                          db.doGetSectionsList(accountId, current360.questionnaireID).onSnapshot((snapshot) => {
                            snapshot.docs.map((doc, i) => {
                              questionnaireSections[doc.id] = doc.data();
                            });
                            this.setState({
                              current360Id,
                              currentGroupId,
                              currentGroup,
                              currentGroupTasks,
                              currentGroupUsers,
                              currentGroupUsersReviewers,
                              questionnaireSections: questionnaireSections,
                              currentUser,
                              currentGroupUser,
                              loading: false,
                            });
                          });
                        } else {
                          snapshot.docs.map((doc, i) => {
                            questionnaireSections[doc.id] = doc.data();
                          });
                          this.setState({
                            current360Id,
                            currentGroupId,
                            currentGroup,
                            currentGroupTasks,
                            currentGroupUsers,
                            currentGroupUsersReviewers,
                            questionnaireSections: questionnaireSections,
                            currentUser,
                            currentGroupUser,
                            loading: false,
                          });
                        }
                      });
                    });
                  })
                })
              }
            );
          });
        });
      } else {
        this.setState({
          loading: false,
          error: true,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {accountId, accountData, current360ID, currentGroupId, currentGroupUsers, currentGroupUsersReviewers, current360, userId, reviewers} = this.state
    if (prevState.current360Id !== this.state.current360Id) {
      if (this.state.current360 !== 'undefined' && this.state.questionnaireSections !== null) {
        if (this.state.current360.type === '360') {
          if (this.state.loadedSend == null && this.state.currentGroupUsersReviewers !== undefined && this.state.currentGroupTasks[1].completedDate !== false) {
            this.setState({ loadedSend: 'loading' });
            const reqBody = {
              accountId: this.state.accountId,
              accountEmail: this.state.accountData.email,
              current360Id: this.state.current360Id,
              currentGroupId: this.state.currentGroupId,
              currentGroupUsers: this.state.currentGroupUsers,
              currentGroupUserReviewers: this.state.currentGroupUsersReviewers,
              current360: this.state.current360,
            };
            //const TOK = localStorage.getItem('Auth')
            const TOK = this.context.za;
  
            axios
              .post(
                '/checkEmails',
                {
                  reqBody,
                },
                { timeout: 10000 }
              )
              .then((res) => {
                this.setState({
                  loadedSend: [res.data.errors],
                  loading: false,
                });
              })
              .catch((err) => {
                console.error(err);
                this.setState({
                  loadedSend: [],
                  loading: false,
                });
              });
          } else if (this.state.loadedSend == null && this.state.currentGroupUsersReviewers !== undefined && this.state.loading == true) {
            this.setState({
              loading: false,
              loadedSend: [],
            });
          }
        } else {
          if (this.state.currentGroupTasks !== undefined && this.props.current360.type !== 'Team Assessment') {
            if (this.state.loadedSend == null && this.state.currentGroupTasks[1].completedDate !== false && this.state.currentGroupUsers !== undefined) {
              this.setState({ loadedSend: 'loading' });
              const reqBody = {
                accountId: this.state.accountId,
                accountEmail: this.state.accountData.email,
                current360Id: this.state.current360Id,
                currentGroupId: this.state.currentGroupId,
                currentGroupUsers: this.state.currentGroupUsers,
                currentGroupUserReviewers: {},
                current360: this.state.current360,
              };
              //const TOK = localStorage.getItem('Auth')
              const TOK = this.context.za;
              axios
                .post(
                  '/checkEmails',
                  {
                    reqBody,
                  },
                  { timeout: 10000 }
                )
                .then((res) => {
                  this.setState({
                    loadedSend: [res.data.errors],
                    loading: false,
                  });
                })
                .catch((err) => {
                  console.error(err);
                  this.setState({
                    loadedSend: [],
                    loading: false,
                  });
                });
            } else if (this.state.loadedSend == null && this.state.currentGroupUsersReviewers !== undefined && this.state.loading == true) {
              this.setState({
                loading: false,
                loadedSend: [],
              });
            }
          } else {

            if (this.state.currentGroupTasks && this.state.currentGroupTasks[1].completedDate !== false) {
              const reqBody = {
                accountId: accountId,
                accountEmail: accountData.email,
                current360Id: current360ID,
                currentGroupId: currentGroupId,
                currentGroupUsers: currentGroupUsers,
                currentGroupUserReviewers: currentGroupUsersReviewers,
                current360: current360,
              };

              axios
              .post('/checkEmails', {
                reqBody,
              })
              .then((res) => {
                db.doGetCurrentUser(accountId, current360ID, currentGroupId, userId).onSnapshot((snapshot) => {
                  const currentUser = snapshot.data()
                  db.doGetCurrentGroupUser(accountId, current360ID, currentGroupId, userId).then((doc) => {
                    const currentGroupUser = doc.data()
                    let reviewerEmailStatuses = {}
                    let reviewerErrors = []
                    let reviewerEventReasons = {}
                    if (res.data.errors.length > 0) {
                      const getTimestamp = (str) => {
                        const date = new Date(str)
                        const timestamp = date.getTime();
                        return timestamp
                      }
                      // sorts events by most recent first
                      let messageEvents = res.data.errors.sort((a,b) => {
                        return getTimestamp(b.When) - getTimestamp(a.When)
                      })
                      messageEvents.forEach(event => {
                        for (const revID in reviewers) {
                          const reviewerEmail = reviewers[revID].email
                          if (!reviewerEmailStatuses[revID]) {
                            reviewerEmailStatuses[revID] = []
                          }
                          if (!reviewerErrors[revID]) {
                            reviewerErrors[revID] = []
                          }
                          reviewerErrors.push(event)
                          if (!reviewerEventReasons[revID]) {
                            reviewerEventReasons[revID] = {}
                          }
                          // Separates events by reviewer
                          if (event.To === reviewerEmail) {
                            // breaks down message events to only those which have made it through sparkpost and attempted sending to the reviewer
                            if (event.Type !== 'injection') {
                              // creates an array of just the event types
                              reviewerEmailStatuses[revID].push(event.Type)
                              // reviewerEmailStatuses.push(event.Type)
                              // creates an object with the event type as key and most recent corresponding reason as value
                              if (!reviewerEventReasons[revID][event.Type]) {
                                reviewerEventReasons[revID][event.Type] = event.Because
                              }
                            }
                            reviewerErrors[revID].push(event)
                          }
                        }
                      })
                    }
                    this.setState({
                      reviewerEmailStatuses,
                      reviewerErrors,
                      reviewerEventReasons,
                      reviewerEventsLoaded: true,
                    });
                  })
                })
              })
            } else {
              let reviewerErrors = {}
              let reviewerEmailStatuses = {}
              let reviewerEventReasons = {}
              for (const reviewerID in this.state.reviewers) {
                reviewerErrors[reviewerID] = []
                reviewerEmailStatuses[reviewerID] = []
                reviewerEventReasons[reviewerID] = {}
              }
              this.setState({
                reviewerErrors,
                reviewerEmailStatuses,
                reviewerEventReasons,
                reviewerEventsLoaded: true,
              });
            }
          }
        }
      }
    }
  }

  closeSendingModal() {
    this.setState({ showSendingModal: false });
  }
  exportGroup() {
    alert('Exporting!');
  }
  openMovingUserModal(userID) {
    this.setState({ showMovingUserModal: true, userToMoveID: userID });
  }
  openDeletingUserModal(userID) {
    this.setState({ showDeletingUserModal: true, userToDeleteID: userID });
    this.setState({ showDeletingUserModal: true, userToDeleteID: userID });
  }
  openRemindingUserModal(userID) {
    this.setState({ showRemindingUserModal: true, userToRemindID: userID });
  }
  editUser(userID) {
    this.props.history.push(`user/${userID}/`);
  }
  redirectToReport(userReportLink) {
    this.props.history.push(userReportLink);
  }
  openUserModal() {
    this.setState({ showNewUserModal: true });
  }
  closeUserModal() {
    this.setState({
      showNewUserModal: false,
      newUserModalErrorMessage: '',
    });
  }
  closeModalAndMoveGroup(newGroupLink) {
    this.setState({
      showMovingUserModal: false,
    });
    this.props.history.push(newGroupLink);
    window.location.reload();
  }

  deleteThisGroup() {
    db.doDeleteGroup(this.state.accountId, this.state.current360Id, this.state.currentGroupId, this.state.currentGroupUsers).then((data) => {
      this.props.history.push(`/dashboard/${this.state.accountId}/360s/${this.state.current360Id}/edit/`);
    });
  }

  updateDeadlineDate(date) {
    db.doUpdateDeadlineDate(this.state.accountId, this.state.current360Id, this.state.currentGroupId, Date.parse(date)).then((data) => {
      this.setState({ deadlineDate: date });
      alert('Completion date updated');
    });
  }

  requestMoreLicenses(e) {
    let numberoflicenses = this.numberoflicenses.value;
    window.emailjs
      .send('mailgun', process.env.REACT_APP_EMAILJS_LICENSE_REQUEST_TEMPLATE_ID, {
        senderEmail: `no-reply@boomerang-360.com`,
        receiverEmail: `pete@boomerang-hr.com`,
        requestedAccountEmail: this.props.accountData.email,
        requestedLicenses: numberoflicenses,
      })
      .then((res) => {
        this.setState({
          newLicensesRequestComplete: true,
          newLicensesRequestError: false,
        });
      })
      .catch((err) => {
        // Handle errors here however you like, or use a React error boundary
        console.error('Failed to send feedback. Error: ', err);
        this.setState({
          newLicensesRequestComplete: false,
          newLicensesRequestError: true,
        });
      });

    e.preventDefault();
  }

  onSubmitNewUser(e) {
    let userForename = this.forename.value;
    let userSurname = this.surname.value;
    let userEmail = this.useremail.value.toLowerCase();
    if (this.state.current360.type !== 'Team Assessment') {
      // create user
      db.doCreateNonAdminUser(this.state.accountId, userForename, userSurname, userEmail).then((userData) => {
        // add to group
        db.addNewUserToGroup(this.state.accountId, userData, this.state.current360Id, this.state.currentGroupId, this.state.current360.questionnaireID)
          .then(() => {
            // close modal
            this.closeUserModal();
          })
          .catch((error) => {
            // error
            this.setState({
              newUserModalErrorMessage: error,
            });
          });
      });
    } else {
      db.doUpdateCurrentUser(
        this.state.accountId,
        this.state.current360Id,
        this.state.currentGroupId,
        this.state.userId,
        userForename,
        userSurname,
        userEmail
      ).then((snapshot) => {
        this.setState({
          savingUser: false,
        });
      });
    }
    e.preventDefault();
  }

  onSubmitReviewer(e) {
    e.preventDefault();
    let fullname = this.state.currentGroup.title;
    let reviewerFullname = `${this.forename.value} ${this.surname.value}`;
    let reviewerEmail = this.useremail.value.toLowerCase();
    let reviewerRelationship = 'TM';
    let nameArr = reviewerFullname.split(' ');
    const emailArr = Object.keys(this.state.currentGroupUsersReviewers).map((key) => {
      return this.state.currentGroupUsersReviewers[key].email;
    });
    if (emailArr.includes(reviewerEmail)) {
      this.setState({ duplicateEmail: true, newUserModalErrorMessage: 'Reviewer already exists in this Team!' });
      return;
    }
    db.doCreateNonAdminUser(this.state.accountId, nameArr[0], nameArr[1], reviewerEmail, true).then((userRes) => {
      db.createNewReviewer(
        this.state.accountId,
        fullname,
        reviewerFullname,
        reviewerEmail,
        reviewerRelationship,
        this.state.current360ID,
        this.state.currentGroupId,
        this.state.current360.questionnaireID,
        this.state.userId
      )
        .then((reviewerID) => {
          if (reviewerID) {
            db.assignReviewerToReviewee(
              this.state.accountId,
              reviewerID,
              reviewerFullname,
              this.state.current360ID,
              this.state.currentGroupId,
              this.state.userId,
              reviewerRelationship,
              reviewerEmail
            ).then((data) => {
              this.closeUserModal();
              window.location.reload();
            });
          }
        })
        .catch((err) => {});
    });
  }

  onSubmitMoveUser(e) {
    e.preventDefault();
    if (this.newGroup.value) {
      db.moveUserToGroup(
        this.props.match.params.accountId,
        this.props.match.params.id,
        this.state.userToMoveID,
        this.props.match.params.groupId,
        this.newGroup.value
      ).then((data) => {
        this.setState({
          newGroup: this.newGroup.value,
          userMoved: true,
        });
      });
    } else {
      alert('Select a group');
    }
  }

  succesfullyCompletedEmailSendActions(groupID, reminderNumber, mailsSent) {
    db.update360StatusCode(this.state.accountId, this.state.current360Id, 2);
    db.updateGroupTaskCompleted(this.state.accountId, this.state.current360Id, groupID, reminderNumber, this.props.userEmail);
    db.updateGroupActiveTask(this.state.accountId, this.state.current360Id, groupID, reminderNumber + 1);
    this.setState({ sending: 'sent' });
    if (reminderNumber == 1) {
      this.props.accountData.credits = this.props.accountData.credits - mailsSent;
      db.updateCreditsAvailable(this.state.accountId, this.state.current360Id, this.props.accountData.credits);
    }
    if (reminderNumber == 1) {
      db.updateGroupDeadlineDate(this.state.accountId, this.state.current360Id, this.state.currentGroupId, this.state.deadlineDate);
    }
    if (reminderNumber < 4) {
      db.updateNextTaskDueDate(this.state.accountId, this.state.current360Id, groupID, reminderNumber + 1);
    }

    for (const userID in this.state.currentGroupUsers) {
      db.doUpdateCurrentGroupUser(
        this.state.accountId,
        this.state.current360ID,
        this.state.currentGroupId,
        userID,
        'emailRecieved',
        true,
      )
    }

    for (const n in this.state.currentGroupUsersReviewers) {
      db.updateReviewerRecievedInfo(
        this.state.accountId,
        n,
        this.state.current360ID,
        this.state.currentGroupId,
        this.state.currentGroupUsersReviewers[n].userID
      );
    }
  }

  promptCreditsModal() {
    this.setState({
      showMaxUsersModal: true,
    });
  }

  confirmDeleteUser(e) {
    db.deleteUserFromGroup(this.state.accountId, this.state.current360ID, this.state.currentGroupId, this.state.userToDeleteID).then(() => {
      this.setState({
        userToDeleteID: null,
        showDeletingUserModal: false,
      });
    });
    e.preventDefault();
  }

  confirmReviewerDeletion() {
    db.doDeleteReviewer(this.state.accountId, this.state.reviewerToDelete).then(() => {
      db.doDeleteReviewerFromReviewee(
        this.state.accountId,
        this.state.reviewerToDelete,
        this.state.current360ID,
        this.state.currentGroupId,
        this.state.userId
      ).then((data) => {
        this.setState({
          showDeletingUserModal: false,
          reviewerToDelete: null,
        });
        window.location.reload();
      });
    });
  }

  sendReviewerInstructions() {
    let mailsSent = 0;
    let numOfUsers = Object.keys(this.state.currentGroupUsersReviewers).length;
    let checkComplete = () => {
      if (mailsSent == numOfUsers) {
        return true;
      } else {
        return false;
      }
    };
    if (numOfUsers == 0) {
      this.setState({
        sendingEmails: false,
      });
    } else {
      Object.keys(this.state.currentGroupUsersReviewers).map((key, index) => {
        // Limit EMAIL.JS calls to 1 per second...
        sleep(2000 * numOfUsers - 2000 * index).then(() => {
          // BLEUGH! - this basically increases the timeout a few milliseconds seconds at a time to mimic async/await...
          // Only send to incomplete users
          if (this.state.currentGroupUsersReviewers[key].completed) {
            mailsSent++;
            if (checkComplete()) {
              this.setState({
                sendingEmails: false,
              });
            }
          } else {
            // window.emailjs
            //   .send(
            //     "mailgun",
            //     process.env.REACT_APP_EMAILJS_REVIEW_TEMPLATEID,
            //     {
            //       senderEmail: "no-reply@boomerang-360.com",
            //       receiverEmail: this.state.currentGroupUsersReviewers[key][
            //         "email"
            //       ],
            //       revieweeName: this.state.currentGroupUsersReviewers[key][
            //         "currentRevieweeName"
            //       ],
            //       reviewerName: this.state.currentGroupUsersReviewers[key][
            //         "name"
            //       ],
            //       reviewLink: `${process.env.REACT_APP_SELF_REVIEW_DOMAIN}/${
            //         this.state.accountId
            //       }/${this.state.current360ID}/review/${key}/`,
            //       current360Title: this.state.current360Title,
            //       clientLogo: this.state.current360.settings
            //         ? this.state.current360.settings.logo
            //         : this.state.accountData.logo,
            //     }
            //   )
            mailsSent++;
            db.updateReviewerRecievedInfo(
              this.state.accountId,
              key,
              this.state.current360ID,
              this.state.currentGroupId,
              this.state.currentGroupUsersReviewers[key]['userID']
            );
            if (checkComplete()) {
              this.setState({
                sendingEmails: false,
              });
            }
            // .catch((err) => {
            //   console.error("Failed to send feedback. Error: ", err);
            //   mailsSent++;
            //   if (checkComplete()) {
            //     // console.log("finished");
            //     this.setState({
            //       sendingEmails: false,
            //     });
            //   }
            // });

            // })
          }
        });
      });
    }
  }

  lockReviewers(e) {
    this.setState({
      currentGroup: {
        ...this.state.currentGroup,
        locked: document.getElementById('lockReviewersCheck').checked,
      },
    });
    db.lockGroupReviewers(this.state.accountId, this.state.currentGroupId, this.state.current360Id, document.getElementById('lockReviewersCheck').checked)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  }

  showGroupReportModal() {
    console.log('this.state.groupReport', this.state.groupReport);
    if (this.state.groupReport >= 1) {
      if (this.state.current360.type === 'Team Assessment') {
        this.props.history.push(`${this.state.userId}/team-report/`);
      } else {
        this.props.history.push(`report/`);
      }
    } else {
      this.setState({ groupReportModal: true });
    }
  }

  reviewCompletion(reviewee, reviewer) {
    let theState = this.state.groupReportInfo;
    if (theState) {
      theState.push({ reviewee, reviewer });
      this.setState({ groupReportInfo: theState });

      if (theState.length === Object.keys(this.state.currentGroupUsers).length) {
        let revieweeComplete = 0;

        theState.map((item, i) => {
          let compCheck = { [item.reviewee.revieweeID]: { self: 0, reviewer: 0 } };
          if (item.reviewee.completionPercentage === 100) {
            compCheck[item.reviewee.revieweeID].self++;
          }
          Object.keys(item.reviewer).map((ID, n) => {
            if (item.reviewer[ID].completed === true) {
              compCheck[item.reviewee.revieweeID].reviewer++;
            }
          });

          if (compCheck[item.reviewee.revieweeID].self > 0 && compCheck[item.reviewee.revieweeID].reviewer > 0) {
            revieweeComplete++;
          }
          this.setState({ groupReport: revieweeComplete });
        });
      }
    } else {
      this.setState({
        groupReportInfo: [{ reviewee, reviewer }],
      });
    }
  }

  populateAnswers = () => {
    db.doGetSectionsAndQuestionDetailsList(this.state.accountId, this.state.current360.questionnaireID).then((res) => {
      db.fillInAnswers(
        this.state.accountId,
        this.state.current360.questionnaireID,
        this.state.current360ID,
        this.state.currentGroupId,
        this.state.currentGroupUsers,
        this.state.currentGroupUsersReviewers,
        res,
        this.state.current360.type
      );
      alert('Reviews are being populated, if there are a lot of reviewers, please allow a moment for the numbers below to update');
    });
  };

  updateMissing = () => {
    alert('Reviews are being marked appropriately');
    db.markAsComplete(this.state.accountId, this.state.current360Id, this.state.currentGroupUsersReviewers)
      .then((res) => {
        alert('Reviews have been corrected');
      })
      .catch((err) => {});
  };

  updateincompleteReviewers = (reviewerData) => {
    let incompleteReviewers = [];
    for (const revID in reviewerData) {
      if (!reviewerData[revID].completed) {
        incompleteReviewers.push([revID, reviewerData[revID]]);
      }
    }
    this.setState({
      incompleteReviewers: incompleteReviewers,
    });
  };

  sendReminderEmail(e) {
    e.preventDefault();
    this.setState({ reminderText: 'Generating Email' });
    const incompleteReviewers = this.state.incompleteReviewers;
    const currentTeamID = Object.keys(this.state.currentGroupUsers)[0];
    if (incompleteReviewers) {
      let resDataErrors = [];
      incompleteReviewers.map((r) => {
        const reqBody = {
          accountId: this.state.accountId,
          userEmail: this.state.accountData.email,
          current360Id: this.state.current360ID,
          currentGroupId: this.state.currentGroupId,
          current360: this.state.current360,
          currentReviewer: {
            ID: r[0],
            revieweeID: currentTeamID,
            name: r[1].name,
            email: r[1].email,
            relationship: r[1].relationship,
          },
          email: this.props.accountData.email,
        };

        axios
          .post('/sendReminderEmail', {
            reqBody,
            cookies: window.localStorage.getItem('Auth'),
          })
          .then((res) => {
            if (res.data.error) {
              resDataErrors.push(res.data.error);
            }
            db.updateReviewerRecievedInfo(this.state.accountId, r[0], this.state.current360ID, this.state.currentGroupId, currentTeamID);
            return Promise.resolve();
          })
          .then(() => {
            if (resDataErrors.length > 0) {
              this.setState({
                sendingSuccess: true,
                sendingComplete: true,
                reminderText: 'There Has Been An Error',
              });
            } else {
              setTimeout(
                () =>
                  this.setState({
                    sendingSuccess: true,
                    sendingComplete: true,
                    reminderText: 'Reminder Sent',
                  }),
                1000
              );
            }
          })
          .catch((err) => {
            console.log('err', err);
            this.setState({
              sendingSuccess: true,
              sendingComplete: true,
              reminderText: 'There Has Been An Error',
            });
          });
      });
    }
  }

  deleteReviewer(reviewerID) {
    this.setState({
      showDeletingUserModal: true,
      reviewerToDelete: reviewerID,
    });
  }

  render() {
    const {
      match: { params },
    } = this.props;

    let theState = this.state;

    const projectType = this.props.current360.type;

    const {reviewers, reviewerEventsLoaded, currentGroupUsersReviewers, reviewerErrors} = this.state

    return (
      <div className='page-wrapper'>
        {this.state.loading ? (
          <LoadingState />
        ) : this.state.error ? (
          <div className='tc'>
            <h3>Error loading Group</h3>
            <Link
              class='btn btn--back'
              to={{
                pathname: `/dashboard/${this.props.match.params.accountId}/`,
              }}
            >
              <span>Return to Dashboard</span>
            </Link>
          </div>
        ) : (
          <section className='container container--slim'>
            <Breadcrumbs
              current360={this.props.current360}
              accountData={this.props.accountData}
              current360Groups={this.props.current360Groups}
              current360Relationships={this.props.current360Relationships}
              links={[
                {
                  title: 'Dashboard',
                  to: `/dashboard/${this.state.accountId}`,
                },
                {
                  title: `${this.props.accountData.accountTitle ? this.props.accountData.accountTitle : this.props.accountData.title} ${projectType} Projects`,
                  to: `/dashboard/${this.props.match.params.accountId}/${breadcrumbSelect(projectType)}-projects/`,
                },
                {
                  title: this.state.current360.title,
                  to: '../../',
                },
                {
                  title: this.state.currentGroup.title,
                  to: null,
                },
              ]}
            />

            <h1>
              {/* Edit {projectType !== 'Team Assessment' ? 'Group' : 'Team'}: {this.state.currentGroup.title} */}
              Edit Group: {this.state.currentGroup.title}
            </h1>
            {document.location.href.includes('localhost') || document.location.href.includes('staging') ? (
              this.props.userEmail === 'admin@boomerang-360.com' ? (
                <div className='w-full flex justify-between'>
                  <button
                    className='btn'
                    onClick={() => {
                      this.populateAnswers();
                    }}
                  >
                    Populate Scores
                  </button>
                  <ExportScoresButton
                    accountId={this.state.accountId}
                    current360Id={this.state.current360Id}
                    currentGroupId={this.state.currentGroupId}
                    questionnaireID={this.state.current360.questionnaireID}
                    currentGroupUsersReviewers={this.state.currentGroupUsersReviewers}
                    currentGroupUsers={this.state.currentGroupUsers}
                    questionnaireSections={this.state.questionnaireSections}
                  />
                  <button
                    className='btn'
                    onClick={() => {
                      this.updateMissing();
                    }}
                  >
                    Mark Reviews Complete
                  </button>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
            <div className='bg--white mv4 ph4 pv4 shadow br2'>
              {Object.keys(this.state.currentGroupUsers).length == 0 ? (
                projectType !== 'Team Assessment' ? (
                  <div className='pa2'>
                    <h3>This group has no users.</h3>
                    <button className='btn' onClick={() => this.setState({ showNewUserModal: true })}>
                      Add User
                    </button>
                  </div>
                ) : (
                  <></>
                )
              ) : // <div className='pa2'>
              //   <h3>This group has no users.</h3>
              //   <button className='btn' onClick={() => this.setState({ showNewUserModal: true })}>
              //     Add User
              //   </button>
              // </div>
              this.state.current360.createdDate <= 1632543899869 ? (
                <div className='user-table pa2'>
                  {projectType === '360' ? <h3>Reviewees</h3> : <h3>Users</h3>}
                  <div className='flex justify-between'>
                    <div className='w-60'>
                      {projectType === '360' ? (
                        <>
                          <p>
                            You can see the completion status of each reviewee in this group, along with the people (raters) they have requested feedback from.
                          </p>
                          <p>
                            If you need to change an individuals deadline, enter the new deadline and it will update. Additionally, you can move reviewees to
                            different groups, if required.{' '}
                          </p>
                          <p>Reports are also produced below.</p>
                        </>
                      ) : (
                        <>
                          <p>You can see the completion status of each participant in this group.</p>
                          <p>
                            You can use the 'Actions' drop-down menu to move participants to different groups, send out reminders, if required; and produce
                            reports.
                          </p>
                        </>
                      )}
                    </div>
                    <button
                      className='btn'
                      disabled={this.state.currentGroup.activeTask != 1 ? 'disabled' : ''}
                      onClick={() => this.setState({ showNewUserModal: true })}
                    >
                      Add new
                    </button>
                  </div>

                  <div className='pv4 bg--pale'>
                    <div className='flex'>
                      <div className={projectType === '360' ? 'w-30 tc br b--moon-gray' : 'w-20 tc br b--moon-gray'}>
                        <p className='mv2'>{projectType === '360' ? <strong>Reviewee</strong> : <strong>User</strong>}</p>
                      </div>

                      {projectType === '360' ? (
                        <div className='w-30 tc br b--moon-gray'>
                          <p className='mv2'>
                            <strong>
                              Reviewers
                              <br />
                              <small>selected/completed</small>
                            </strong>
                          </p>
                        </div>
                      ) : (
                        <div className='w-30 tc br b--moon-gray'>
                          <p className='mv2'>
                            <strong>Goal Status</strong>
                          </p>
                        </div>
                      )}

                      {projectType === '360' ? (
                        <div className='w-10 tc br b--moon-gray'>
                          <p className='mv2'>
                            <strong>Deadline</strong>
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {projectType === '360' ? (
                        <div className='w-30 tc b--moon-gray'>
                          <p className='mv2'>
                            <strong>Action</strong>
                          </p>
                        </div>
                      ) : (
                        <div className='w-30 tc b--moon-gray'>
                          <p className='mv2'>
                            <strong>Action</strong>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='flex'>
                      <div className='w-30 tc' />
                      {projectType == '360' ? (
                        <div className='w-30 tc br b--moon-gray'>
                          <p className='flex mb0'>
                            {Object.keys(this.state.current360Relationships).map((key, i) => {
                              return (
                                <strong
                                  key={key}
                                  data-tooltip={this.state.current360Relationships[key]['name']}
                                  className={
                                    i + 1 === Object.keys(this.state.current360Relationships).length ? 'w-25 has-tooltip' : 'w-25 br b--moon-gray has-tooltip'
                                  }
                                >
                                  {key}
                                </strong>
                              );
                            })}
                          </p>
                        </div>
                      ) : (
                        <div className='w-30 tc br b--moon-gray'>
                          <p className='flex mb0'>
                            {// Loop thru sections (goals)
                            Object.keys(this.state.questionnaireSections).map((key, i) => (
                              <strong className={i == 0 ? 'w-100' : 'w-100 bl b--moon-gray'}>{i + 1}</strong>
                            ))}
                          </p>
                        </div>
                      )}

                      {projectType == '360' ? <div className='w-10 tc br b--moon-gray' /> : ''}

                      <div className='w-30 tc b--moon-gray' />
                    </div>

                    {this.state.currentGroupUsers
                      ? Object.keys(this.state.currentGroupUsers)
                          .reverse()
                          .map((key) => (
                            <RevieweeDetails
                              key={key}
                              index={key}
                              type={projectType}
                              current360Relationships={this.state.current360Relationships}
                              questionnaireId={this.state.current360.questionnaireID}
                              questionnaireSections={this.state.questionnaireSections}
                              current360Id={params.id}
                              currentGroupId={params.groupId}
                              groupUserDetails={this.state.currentGroupUsers[key]}
                              projectStatusCode={this.state.current360.statusCode}
                              current360={this.state.current360}
                              userDetails={this.state.userDetails}
                              accountTitle={this.state.accountTitle}
                              accountId={this.state.accountId}
                              openMovingUserModal={this.openMovingUserModal}
                              openDeletingUserModal={this.openDeletingUserModal}
                              openRemindingUserModal={this.openRemindingUserModal}
                              editUser={this.editUser}
                              redirectToReport={this.redirectToReport}
                              loadedSend={this.state.loadedSend}
                              currentTaskNo={this.state.currentGroup.activeTask}
                              accountData={this.state.accountData}
                              sending={this.state.sending}
                              duplicatedFrom={this.state.duplicatedFrom}
                              updateincompleteReviewers={this.updateincompleteReviewers}
                            />
                          ))
                      : ''}

                    {this.state.currentGroup.activeTask != 1 ? (
                      <div className='tc pt3'>
                        <p>Users cannot be added to a group once Instruction have been sent</p>
                      </div>
                    ) : (
                      ''
                    )}
                    {projectType === '360' ? (
                      <div className='w-90 center flex justify-between'>
                        <div>
                          <button onClick={() => this.showGroupReportModal()} className='btn'>
                            Group Report
                          </button>
                        </div>
                        <div className='flex mr-5'>
                          <p className='w-90 tr pb0 pt1 pr2 mt1'>
                            <strong>Lock Reviewers?</strong>
                          </p>
                          <input
                            id={'lockReviewersCheck'}
                            onChange={(e) => {
                              this.lockReviewers(e);
                            }}
                            checked={this.state.currentGroup.locked}
                            type='checkbox'
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                <div className='user-table pa2'>
                  {/* {projectType === 'Team Assessment' && emptyObjectCheck(this.state.currentGroupUsersReviewers) ? (
                    <button onClick={() => this.showGroupReportModal()} className='btn mb5'>
                      Team Report
                    </button>
                  ) : (
                    <></>
                  )} */}
                  <h3>{projectTypeText(projectType, 'Reviewees', 'Users', 'Reviewers')}</h3>
                  <div className='flex justify-between'>
                    <div className='w-60'>
                      <p>
                        You can see the completion status of each {projectTypeText(projectType, 'reviewee', 'participant', 'reviewer')} in this
                        {/* group */}
                        {projectTypeText(projectType, ' group, along with the people (raters) they have requested feedback from.', ' group.', ' team.')}
                      </p>
                      {projectType !== 'Goal Tracker' ? (
                        <>
                          {projectType === '360' ? (
                            <p>
                              If you need to change an individuals deadline, enter the new deadline and it will update. Additionally, you can move reviewees to
                              different groups, if required.{' '}
                            </p>
                          ) : (
                            <></>
                          )}
                          <p>Reports are also produced below.</p>
                        </>
                      ) : (
                        <>
                          <p>
                            You can use the 'Actions' drop-down menu to move participants to different groups, send out reminders, if required; and produce
                            reports.
                          </p>
                        </>
                      )}
                    </div>
                    <button
                      className='btn'
                      disabled={this.state.currentGroup.activeTask != 1 ? 'disabled' : ''}
                      onClick={() => this.setState({ showNewUserModal: true })}
                    >
                      Add new
                    </button>
                  </div>
                  {projectType !== 'Team Assessment' ? (
                    <div className='pv4 bg--pale'>
                      <div className='flex'>
                        <div className={projectType !== 'Goal Tracker' ? 'w-30 tc br b--moon-gray' : 'w-20 tc br b--moon-gray'}>
                          <p className='mv2'>
                            {projectType !== 'Goal Tracker' ? projectType === '360' ? <strong>Reviewee</strong> : <strong>Team</strong> : <strong>User</strong>}
                          </p>
                        </div>
                        {projectType !== 'Team Assessment' ? (
                          <div className='w-20 tc br b--moon-gray'>
                            <p className='mv2'>
                              <strong>Email Status</strong>
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}

                        {projectType !== 'Goal Tracker' ? (
                          <div className='w-30 tc br b--moon-gray'>
                            <p className='mv2'>
                              <strong>
                                Reviewers
                                <br />
                                <small>selected/completed</small>
                              </strong>
                            </p>
                          </div>
                        ) : (
                          <div className='w-30 tc br b--moon-gray'>
                            <p className='mv2'>
                              <strong>Goal Status</strong>
                            </p>
                          </div>
                        )}

                        {projectType === '360' ? (
                          <div className='w-10 tc br b--moon-gray'>
                            <p className='mv2'>
                              <strong>Deadline</strong>
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className={`w-${projectType !== 'Team Assessment' ? '3' : '4'}0 tc b--moon-grayw-30 tc b--moon-gray`}>
                          <p className='mv2'>
                            <strong>Action</strong>
                          </p>
                        </div>
                      </div>
                      {projectType !== 'Team Assessment' ? (
                        <>
                          <div className='flex'>
                            <div className='w-30 tc' />
                            <div className={projectType === '360' ? 'w-20 tc br b--moon-gray' : 'w-10 tc br b--moon-gray'} />
                            {projectType == '360' ? (
                              <div className='w-30 tc br b--moon-gray'>
                                <p className='flex mb0'>
                                  {Object.keys(this.state.current360Relationships).map((key, i) => {
                                    return (
                                      <strong
                                        key={key}
                                        data-tooltip={this.state.current360Relationships[key]['name']}
                                        className={
                                          i + 1 === Object.keys(this.state.current360Relationships).length
                                            ? 'w-25 has-tooltip'
                                            : 'w-25 br b--moon-gray has-tooltip'
                                        }
                                      >
                                        {key}
                                      </strong>
                                    );
                                  })}
                                </p>
                              </div>
                            ) : (
                              <div className='w-30 tc br b--moon-gray'>
                                <p className='flex mb0'>
                                  {/* Loop thru sections (goals) */}
                                  {this.state.loading ? (
                                    <>
                                      <strong className='w-100 bl b--moon-gray'>{''}</strong>
                                    </>
                                  ) : (
                                    <>
                                      {Object.keys(this.state.questionnaireSections).map((key, i) => (
                                        <strong className={i == 0 ? 'w-100' : 'w-100 bl b--moon-gray'}>{i + 1}</strong>
                                      ))}
                                    </>
                                  )}
                                </p>
                              </div>
                            )}

                            {projectType === '360' ? <div className='w-10 tc br b--moon-gray' /> : ''}

                            <div className='w-30 tc b--moon-gray' />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {this.state.currentGroupUsers
                        ? Object.keys(this.state.currentGroupUsers)
                            .reverse()
                            .map((key) => (
                              <RevieweeDetails
                                key={key}
                                index={key}
                                type={projectType}
                                current360Relationships={this.state.current360Relationships}
                                questionnaireId={this.state.current360.questionnaireID}
                                questionnaireSections={this.state.questionnaireSections}
                                current360Id={params.id}
                                currentGroupId={params.groupId}
                                groupUserDetails={this.state.currentGroupUsers[key]}
                                projectStatusCode={this.state.current360.statusCode}
                                current360={this.state.current360}
                                userDetails={this.state.userDetails}
                                accountTitle={this.state.accountTitle}
                                accountId={this.state.accountId}
                                openMovingUserModal={this.openMovingUserModal}
                                openDeletingUserModal={this.openDeletingUserModal}
                                openRemindingUserModal={this.openRemindingUserModal}
                                editUser={this.editUser}
                                redirectToReport={this.redirectToReport}
                                reviewCompletion={this.reviewCompletion}
                                loadedSend={this.state.loadedSend}
                                currentTaskNo={this.state.currentGroup.activeTask}
                                accountData={this.state.accountData}
                                duplicatedFrom={this.state.duplicatedFrom}
                                updateincompleteReviewers={this.updateincompleteReviewers}
                              />
                            ))
                        : ''}

                      {this.state.currentGroup.activeTask != 1 ? (
                        <div className='tc pt3'>
                          <p>Users cannot be added to a group once Instruction have been sent</p>
                        </div>
                      ) : (
                        ''
                      )}
                      {projectType !== 'Goal Tracker' ? (
                        <div className='w-90 center flex justify-between'>
                          <div>
                            <button onClick={() => this.showGroupReportModal()} className='btn'>
                              Group Report
                            </button>
                          </div>
                          {projectType === '360' ? (
                            <div className='flex mr-5'>
                              <p className='w-90 tr pb0 pt1 pr2 mt1'>
                                <strong>Lock Reviewers?</strong>
                              </p>
                              <input
                                id={'lockReviewersCheck'}
                                onChange={(e) => {
                                  this.lockReviewers(e);
                                }}
                                checked={this.state.currentGroup.locked}
                                type='checkbox'
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <>
                      {reviewers && reviewerEventsLoaded ? 
                        currentGroupUsersReviewers ? 
                        (
                          Object.keys(currentGroupUsersReviewers)
                            .reverse()
                            .map((key, reviewerIndex) => (
                              <>
                                {reviewerErrors ?  
                                <UserReviewerEditForm
                                  accountData={this.props.accountData}
                                  reviewerID={key}
                                  revieweeID={this.state.userId}
                                  accountId={this.state.accountId}
                                  current360={this.state.current360}
                                  current360ID={this.state.current360ID}
                                  currentGroupId={this.state.currentGroupId}
                                  currentUser={this.state.currentUser}
                                  name={reviewers[key]['name']}
                                  email={reviewers[key]['email']}
                                  relationship={reviewers[key]['relationship']}
                                  completed={reviewers[key]['completed']}
                                  current360Relationships={this.state.current360Relationships}
                                  deleteReviewer={this.deleteReviewer}
                                  currentGroupTasks={this.state.currentGroupTasks}
                                  reviewerEmailStatuses={this.state.reviewerEmailStatuses[key]}
                                  reviewerErrors={this.state.reviewerErrors[key]}
                                  reviewerEventReasons={this.state.reviewerEventReasons[key]}
                                  emailRecieved={this.state.reviewers[key].emailRecieved}
                                  emailStatus={this.state.reviewers[key].emailStatus}
                                  reviewerIndex={reviewerIndex}
                                />
                               : 
                                <></>
                                }
                              </>
                            ))
                        )
                        : 
                        <></>
                      : 
                        <LoadingState />
                      }
                    </>
                  )}
                </div>
              )}

              {projectType === 'Team Assessment' && emptyObjectCheck(this.state.currentGroupUsersReviewers) ? (
                <button onClick={() => this.showGroupReportModal()} className='btn mt4 mb4'>
                  Team Report
                </button>
              ) : (
                <></>
              )}

              {this.state.currentGroupUsers ? (
                <div className='pa2 mt4'>
                  <h3>Set Completion Date</h3>
                  <p>
                    Select a date {projectTypeText(projectType, 'below where all Self-reviews', 'when all Goal Trackers', 'when all reviews')} must be
                    completed.
                  </p>

                  <DatePicker
                    className='mt2 mb5 pa2 bg-light-gray input-reset bn outline-0 w-100'
                    dateFormat='do MMM, yyyy'
                    placeholderText='Select a date'
                    minDate={oneDayFromNow}
                    selected={this.state.currentGroup.deadlineDate ? this.state.currentGroup.deadlineDate : this.state.deadlineDate}
                    onChange={(date) => this.updateDeadlineDate(date)}
                  />
                </div>
              ) : (
                ''
              )}

              {this.state.currentGroupUsers && projectType !== 'Team Assessment' ? (
                this.state.currentGroupTasks[1].completedDate == false ? (
                  <div className='pa4 mt4 bg--pale flex justify-between items-center'>
                    <div>
                      <h3 className='mb2'>(Optional) Edit Email Text</h3>
                      <p className='ma0'>You can edit the text of the instructional emails that get sent out via the settings panel.</p>
                    </div>
                    <Link
                      className='btn'
                      to={{
                        pathname: '../../standard/email/',
                        accountData: this.props.accountData,
                        current360: this.props.current360,
                        current360Relationships: this.state.current360Relationships,
                        current360Groups: this.state.current360Groups,
                      }}
                    >
                      Edit Email Text
                    </Link>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              {(projectType !== 'Team Assessment' && emptyObjectCheck(this.state.currentGroupUsers)) ||
              (projectType === 'Team Assessment' && emptyObjectCheck(this.state.currentGroupUsersReviewers)) ? (
                <div className='user-tasks pa2 mt4'>
                  <h3>Tasks</h3>
                  <div className={projectType !== 'Goal Tracker' ? 'flex ml5' : 'flex'}>
                    <div className='w-40 tc br b--moon-gray'>
                      <p className='mv2'>
                        <strong>Task</strong>
                      </p>
                    </div>
                    {projectType !== 'Goal Tracker' ? (
                      <div className='w-20 tc br b--moon-gray'>
                        <p className='mv2'>
                          <strong>Perfomed</strong>
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='w-30 tc'>
                      <p className='mv2'>
                        <strong>Actioned By</strong>
                      </p>
                    </div>
                  </div>

                  {projectType !== 'Goal Tracker'
                    ? this.state.currentGroupTasks
                      ? Object.keys(this.state.currentGroupTasks).map((key, index) => {
                          return (
                            <div key={key} index={key} className='flex items-center ml5'>
                              <div className='w-40 br b--moon-gray'>
                                <p className='mv2'>
                                  <SendEmails
                                    loggedIn={this.props.userEmail}
                                    showModalButtonLabel={convertTaskNames(key)}
                                    modalHeader={convertTaskNames(key)}
                                    modalContentLabel='Send New Emails'
                                    accountId={this.state.accountId}
                                    current360Id={this.state.current360Id}
                                    current360Group={this.state.currentGroupId}
                                    currentGroupUsers={this.state.currentGroupUsers}
                                    currentGroupUserReviewers={this.state.currentGroupUsersReviewers}
                                    current360={this.state.current360}
                                    activeTask={this.state.currentGroup.activeTask}
                                    succesfullyCompletedEmailSendActions={this.succesfullyCompletedEmailSendActions}
                                    theKey={key}
                                    promptCreditsModal={this.promptCreditsModal}
                                    redits={this.props.accountData.credits}
                                  />
                                </p>
                              </div>
                              <div className='w-20 tc br b--moon-gray'>
                                <p className='mv2'>
                                  <strong>
                                    {this.state.currentGroupTasks[key].completedDate
                                      ? new Date(this.state.currentGroupTasks[key].completedDate).toLocaleDateString('en-GB')
                                      : '-'}
                                  </strong>
                                </p>
                              </div>
                              <div className='w-30 tc'>
                                <p className='mv2'>
                                  <strong>{this.state.currentGroupTasks[key].user ? this.state.currentGroupTasks[key].user : '-'}</strong>
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : ''
                    : this.state.questionnaireSections
                    ? Object.keys(this.state.questionnaireSections).map((key, index) => {
                        let sectionTaskNum = index + 1;
                        return (
                          <div key={key} index={key} className='flex items-center'>
                            <div className='w-40 pl4 br b--moon-gray'>
                              <p className='mv2 ml5'>
                                <SendEmails
                                  current360Type={projectType}
                                  loggedIn={this.props.userEmail}
                                  credits={this.props.accountData.credits}
                                  showModalButtonLabel={`Send Goal ${sectionTaskNum}`}
                                  modalHeader={`Send Goal ${sectionTaskNum}`}
                                  modalContentLabel={`Send Goal ${sectionTaskNum}`}
                                  accountId={theState.accountId}
                                  current360Id={theState.current360Id}
                                  current360Group={theState.currentGroupId}
                                  currentGroupUsers={theState.currentGroupUsers}
                                  currentGroupUserReviewers={theState.currentGroupUsersReviewers}
                                  current360={theState.current360}
                                  activeTask={this.state.currentGroup.activeTask}
                                  succesfullyCompletedEmailSendActions={this.succesfullyCompletedEmailSendActions}
                                  theKey={index + 1}
                                  promptCreditsModal={this.promptCreditsModal}
                                />
                              </p>
                            </div>
                            <div className='w-30 tc'>
                              <p className='mv2'>
                                <strong>
                                  {this.state.currentGroupTasks[sectionTaskNum] && this.state.currentGroupTasks[sectionTaskNum].user
                                    ? this.state.currentGroupTasks[sectionTaskNum].user
                                    : '-'}
                                </strong>
                              </p>
                            </div>
                          </div>
                        );
                      })
                    : ''}

                  {/* {projectType !== 'Team Assessment' ? (
                    <>
                      {projectType === '360'
                        ? this.state.currentGroupTasks
                          ? Object.keys(this.state.currentGroupTasks).map((key, index) => {
                              return (
                                <div key={key} index={key} className='flex items-center ml5'>
                                  <div className='w-40 br b--moon-gray'>
                                    <p className='mv2'>
                                      <SendEmails
                                        loggedIn={this.props.userEmail}
                                        showModalButtonLabel={convertTaskNames(key)}
                                        modalHeader={convertTaskNames(key)}
                                        modalContentLabel='Send New Emails'
                                        accountId={this.state.accountId}
                                        current360Id={this.state.current360Id}
                                        current360Group={this.state.currentGroupId}
                                        currentGroupUsers={this.state.currentGroupUsers}
                                        currentGroupUserReviewers={this.state.currentGroupUsersReviewers}
                                        current360={this.state.current360}
                                        activeTask={this.state.currentGroup.activeTask}
                                        succesfullyCompletedEmailSendActions={this.succesfullyCompletedEmailSendActions}
                                        theKey={key}
                                        promptCreditsModal={this.promptCreditsModal}
                                        redits={this.props.accountData.credits}
                                      />
                                    </p>
                                  </div>
                                  <div className='w-20 tc br b--moon-gray'>
                                    <p className='mv2'>
                                      <strong>
                                        {this.state.currentGroupTasks[key].completedDate
                                          ? new Date(this.state.currentGroupTasks[key].completedDate).toLocaleDateString('en-GB')
                                          : '-'}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className='w-30 tc'>
                                    <p className='mv2'>
                                      <strong>{this.state.currentGroupTasks[key].user ? this.state.currentGroupTasks[key].user : '-'}</strong>
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          : ''
                        : this.state.questionnaireSections
                        ? Object.keys(this.state.questionnaireSections).map((key, index) => {
                            let sectionTaskNum = index + 1;
                            return (
                              <div key={key} index={key} className='flex items-center'>
                                <div className='w-40 pl4 br b--moon-gray'>
                                  <p className='mv2 ml5'>
                                    <SendEmails
                                      current360Type={projectType}
                                      loggedIn={this.props.userEmail}
                                      credits={this.props.accountData.credits}
                                      showModalButtonLabel={`Send Goal ${sectionTaskNum}`}
                                      modalHeader={`Send Goal ${sectionTaskNum}`}
                                      modalContentLabel={`Send Goal ${sectionTaskNum}`}
                                      accountId={theState.accountId}
                                      current360Id={theState.current360Id}
                                      current360Group={theState.currentGroupId}
                                      currentGroupUsers={theState.currentGroupUsers}
                                      currentGroupUserReviewers={theState.currentGroupUsersReviewers}
                                      current360={theState.current360}
                                      activeTask={this.state.currentGroup.activeTask}
                                      succesfullyCompletedEmailSendActions={this.succesfullyCompletedEmailSendActions}
                                      theKey={index + 1}
                                      promptCreditsModal={this.promptCreditsModal}
                                    />
                                  </p>
                                </div>
                                <div className='w-30 tc'>
                                  <p className='mv2'>
                                    <strong>
                                      {this.state.currentGroupTasks[sectionTaskNum] && this.state.currentGroupTasks[sectionTaskNum].user
                                        ? this.state.currentGroupTasks[sectionTaskNum].user
                                        : '-'}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : ''}
                    </>
                  ) : (
                    <button
                      className={
                        this.state.incompleteReviewers && this.state.incompleteReviewers < 1 ? 'btn btn--green btn--disabled mb2' : 'btn btn--green mb2'
                      }
                      onClick={(e) => this.sendReminderEmail(e)}
                    >
                      {this.state.reminderText}
                    </button>
                  )} */}
                </div>
              ) : (
                ''
              )}

              <div className='pa2 mt4'>
                <h3>{projectTypeText(projectType, 'Group', 'Group', 'Team')} Options</h3>
                <div className='flex justify-between'>
                  {Object.keys(this.state.currentGroupUsers).length > 0 ? (
                    <ExportGroupButton
                      groupinfo={this.state.currentGroup}
                      accountId={this.state.accountId}
                      current360Id={this.state.current360Id}
                      currentGroupId={this.state.currentGroupId}
                      groupusers={this.state.currentGroupUsers}
                      projectType={projectType}
                    />
                  ) : (
                    ''
                  )}

                  {this.state.current360.createdDate <= 1632543899869 ? (
                    ''
                  ) : (
                    <Link className='btn' to={{ pathname: 'events' }}>
                      View All Email Events
                    </Link>
                  )}

                  <button
                    onClick={(e) => {
                      this.setState({ showDeleteGroupModal: true });
                    }}
                    className='btn btn--red'
                  >
                    Delete {projectTypeText(projectType, 'Group', 'Group', 'Team')}
                  </button>
                </div>
              </div>

              <Modal
                isOpen={this.state.showDeleteGroupModal}
                contentLabel={`Delete ${projectTypeText(projectType, 'Group', 'Group', 'Team')}`}
                onClose={() => this.setState({ showDeleteGroupModal: false })}
                content={
                  <div className='tc'>
                    <h2>Delete this {projectTypeText(projectType, 'Group', 'Group', 'Team')}</h2>
                    <button
                      onClick={() => {
                        this.setState({ showDeleteGroupModal: false });
                      }}
                      className='btn btn--cancel mt3 mh2'
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        this.deleteThisGroup();
                      }}
                      className='btn btn--red mt3 mh2'
                    >
                      <span>Delete</span>
                    </button>
                  </div>
                }
              />

              <Modal
                isOpen={this.state.showMaxUsersModal}
                contentLabel='You do not have enough credits for this amount of users'
                onClose={() =>
                  this.setState({
                    showMaxUsersModal: false,
                    newLicensesRequestComplete: false,
                    newLicensesRequestError: false,
                  })
                }
                content={
                  <div>
                    <h2>You do not have enough Licenses for this amount of users</h2>
                    <p>
                      To add additional licences to your account, fill out the request form below or email{' '}
                      <a href='mailto:help@boomerang-hr.com'>help@boomerang-hr.com</a>
                    </p>
                    <div className='flex flex-wrap bg--pale w-80 pa2'>
                      <p className='w-50 mv0'>
                        <strong>Users</strong>
                      </p>
                      <p className='w-50 mv0'>
                        <strong>Price</strong>
                      </p>
                      <p className='w-50 mv0'>11-49</p>
                      <p className='w-50 mv0'>£55</p>
                      <p className='w-50 mv0'>50-149</p>
                      <p className='w-50 mv0'>£45</p>
                      <p className='w-50 mv0'>150+</p>
                      <p className='w-50 mv0'>£35</p>
                    </div>
                    <div className='w-80 text-center'>
                      <p>
                        <small>* Per-user license fee applicable from 10 users (per year) upwards</small>
                      </p>
                    </div>
                    <form onSubmit={this.requestMoreLicenses}>
                      <label htmlFor='numberoflicenses'>Number of Licenses</label>
                      <input
                        required
                        ref={(input) => (this.numberoflicenses = input)}
                        aria-describedby='numberoflicenses-desc'
                        type='number'
                        defaultValue='50'
                      />

                      {this.state.newLicensesRequestComplete ? (
                        <p>Thanks for requesting more licenses, one of our team will be in touch shortly</p>
                      ) : (
                        <button type='submit' className='btn'>
                          Request Licenses
                        </button>
                      )}
                      {this.state.newLicensesRequestError ? (
                        <p className='red'>
                          There was an error requesting your licenses, please email <a href='mailto:help@boomerang-hr.com'>help@boomerang-hr.com</a>
                        </p>
                      ) : (
                        ''
                      )}
                    </form>
                  </div>
                }
              />

              <Modal
                isOpen={this.state.showNewUserModal}
                // contentLabel={`Add a new ${{projectTypeText(projectType, 'User', 'User', 'Reviewer')}} to this ${{projectTypeText(projectType, 'Group', 'Group', 'Team')}}`}
                contentLabel={`Add a new ${projectTypeText(projectType, 'User', 'User', 'Reviewer')} to this ${projectTypeText(
                  projectType,
                  'Group',
                  'Group',
                  'Team'
                )}`}
                onClose={() => this.setState({ showNewUserModal: false, newUserModalErrorMessage: '' })}
                content={
                  <div>
                    <h2>Add a new {projectTypeText(projectType, 'User to this Group', 'User to this Group', 'Reviewer to this Team')}</h2>
                    <form className='mw6 center pt2 pb2' onSubmit={projectType !== 'Team Assessment' ? this.onSubmitNewUser : this.onSubmitReviewer}>
                      <label htmlFor='forename'>First Name</label>
                      <input required ref={(input) => (this.forename = input)} aria-describedby='forename-desc' type='text' placeholder='Joe' />
                      <label htmlFor='surname'>Last Name</label>
                      <input required ref={(input) => (this.surname = input)} aria-describedby='surname-desc' type='text' placeholder='Blogs' />
                      <label htmlFor='useremail'>Email</label>
                      <input
                        required
                        ref={(input) => (this.useremail = input)}
                        aria-describedby='useremail-desc'
                        type='email'
                        placeholder='email@example.com'
                      />
                      <button className='btn' type='submit'>
                        Add {projectTypeText(projectType, 'User', 'User', 'Reviewer')}
                      </button>
                    </form>
                    <p className='red'>{this.state.newUserModalErrorMessage}</p>
                  </div>
                }
              />

              <Modal
                isOpen={this.state.showSendingModal}
                contentLabel='Preparing to send 360 to selected group'
                onClose={() => this.setState({ deleting: false })}
                hideCloseButton={true}
                content={
                  <div className='tc'>
                    {this.state.sendingEmails ? <h1 className='mv0'>Preparing to send 360 to selected group</h1> : <h1 className='mv0'>Notifications Sent</h1>}

                    {this.state.sendingEmails ? (
                      <p className='mt3'>Please wait</p>
                    ) : (
                      <div className='pt4'>
                        <button className='btn btn--cancel' onClick={this.closeSendingModal}>
                          Close
                        </button>
                      </div>
                    )}
                  </div>
                }
              />

              <Modal
                isOpen={this.state.showMovingUserModal}
                contentLabel='Select an alternate group'
                onClose={() => this.setState({ showMovingUserModal: false })}
                hideCloseButton={false}
                content={
                  <div>
                    <h2>Select an alternate group</h2>
                    <form className='mw6 center pt2 pb2' onSubmit={this.onSubmitMoveUser}>
                      {!this.state.userMoved ? (
                        <div>
                          <select ref={(input) => (this.newGroup = input)} className='large blue mb4'>
                            <option value=''>Select a group</option>
                            {Object.keys(this.state.current360Groups).map((key, index) => {
                              if (key != this.props.match.params.groupId) {
                                return (
                                  <option key={key} value={key}>
                                    {this.state.current360Groups[key]['title']}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <button className='btn' type='submit'>
                            Save
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p>User moved successfully</p>
                          <button
                            className='btn'
                            onClick={() =>
                              this.closeModalAndMoveGroup(
                                `/dashboard/${this.props.match.params.accountId}/360s/${this.props.match.params.id}/edit/group/${this.state.newGroup}/`
                              )
                            }
                          >
                            View Group
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                }
              />

              <Modal
                isOpen={this.state.showDeletingUserModal}
                contentLabel='Delete this User?'
                onClose={() => this.setState({ showDeletingUserModal: false })}
                hideCloseButton={false}
                content={
                  <div>
                    <h2>Are you sure you want to delete this {projectTypeText(projectType, 'user', '', 'reviewer')}?</h2>
                    <p>Click confirm below to remove</p>
                    <button onClick={projectType !== 'Team Assessment' ? this.confirmDeleteUser : this.confirmReviewerDeletion} className='btn'>
                      {/* <button onClick={this.confirmDeleteUser} className='btn'> */}
                      Confirm
                    </button>
                  </div>
                }
              />
              <Modal
                isOpen={this.state.groupReportModal}
                contentLabel='Insufficient Data for Comparison Report'
                onClose={() =>
                  this.setState({
                    groupReportModal: false,
                  })
                }
                hideCloseButton={false}
                content={
                  <div>
                    <h2 className='tc'>Please Note</h2>
                    <p>The report has not been able to generate for the following reason(s):</p>
                    <ul>
                      <li>{`In order for this report to generate, there needs to be a completed ${
                        projectType === 'Team Assessment' ? '' : 'self-review and '
                      }review.`}</li>
                    </ul>
                    <div className='w-100 tc'>
                      <button
                        className='btn btn--red'
                        onClick={(e) => {
                          this.setState({ groupReportModal: false });
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                }
              />
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(Edit360Group);
