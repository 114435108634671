import React from 'react';
import AuthUserContext from './authusercontext';
import { firebase } from '../firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
        loading: true,
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        authUser ? this.setState({ authUser }) : this.setState({ authUser: null });

        this.setState({
          loading: false,
        });
      });
    }

    render() {
      const { authUser } = this.state;
      const { loading } = this.state;
      // // console.log(authUser)
      if (loading) {
        return (
          <div className='page-loading-state'>
            <p>Authenticating</p>
          </div>
        );
      }
      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};
export default withAuthentication;
