import React, { Component } from 'react'
import AuthUserContext from './authusercontext'
import logo from '../logo.svg'
import { Link } from 'react-router-dom'

class SiteHeader extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <header className='site-header'>
        <div className='container'>
          <div className='site-header__row'>
            <p className="reset-mar">Welcome, <strong>{this.props.accountData.fname} {this.props.accountData.lname}&nbsp;&nbsp;</strong>
            {
              (this.props.accountData.accounts.length > 1) ? (
                <Link to={`/dashboard/`}>Switch account</Link>
              ) : ''
            }
            </p>
            <div className="header-logo"></div>
          </div>
        </div>
      </header>
    );
  }
}

export default SiteHeader
