import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../../firebase';
import Modal from '../../../../components/global/modal';
import withAuthorisation from '../../../../components/withauthorisation';
import Breadcrumbs from '../../../../components/breadcrumbs';
import LoadingState from '../../../../components/global/loading-state';
const authCondition = (authUser) => !!authUser;

class EditMultichoiceLabels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: this.props.match.params.accountId,
      current360ID: this.props.match.params.id,
      current360: this.props.current360,
      accountData: this.props.accountData,
      current360Groups: this.props.current360Groups,
      current360Relationships: this.props.current360Relationships,
      labelEditingModalOpen: false,
      labelEdited: false,
      loading: true,
    };

    this.updateEditState = this.updateEditState.bind(this);
    this.onSubmitLabelEdit = this.onSubmitLabelEdit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const {accountId, current360} = this.state;
    let scaleLabels = {};

    const addLabelsAndUpdateState = (labels) => {
      let orderedLabelIDs = []
      labels.docs.map((doc) => {
        scaleLabels[doc.id] = doc.data();
        orderedLabelIDs.push(doc.id)
      });
      orderedLabelIDs
        .sort((a,b) => {
          return scaleLabels[a].sortOrder - scaleLabels[b].sortOrder
        })
      
      this.setState({scaleLabels, orderedLabelIDs, loading: false});
    }

    db.doGetQuestionnaireLabels(accountId, current360.questionnaireID)
      .then((res) => {
        if (res.docs.length > 0) {
          addLabelsAndUpdateState(res)
        } else {
          db.addDefaultQuestionnaireLabels((accountId, current360.questionnaireID))
            .then(() => {
              db.doGetQuestionnaireLabels(accountId, current360.questionnaireID)
                .then((res) => {
                  addLabelsAndUpdateState(res)
                })
            })
        }
    });
  }

  updateEditState() {
    this.setState({ labelEdited: true });
  }

  onSubmitLabelEdit(e) {
    e.preventDefault();
    this.setState({labelEditingModalOpen: true});
    const {accountId, current360, scaleLabels} = this.state
    let newScaleLabels = scaleLabels;
    let labelsToAdd = {};
    for (const labelID in scaleLabels) {
      const labelText = this[labelID].value;
      if (labelText.length > 0) {
        labelsToAdd[labelID] = labelText;
        newScaleLabels[labelID].text = labelText;
      }
    }

    const labelsToUpdate = Object.keys(labelsToAdd)

    const updateLabel = (labelID) => {
      return new Promise(function(resolve, reject) {
        db.updateQuestionnaireLabel(accountId, current360.questionnaireID, labelID, labelsToAdd[labelID])
          .then(() => {
            resolve(true)
          })
      })
    }

    const updateEditedLabels = labelsToUpdate.map(labelID => {
      return updateLabel(labelID)
    })

    Promise.all(updateEditedLabels)
      .then(values => {
        if (values.length === updateEditedLabels.length) {
          this.setState({
            scaleLabels: newScaleLabels,
            labelEdited: false,
          });
        }
      })
    e.preventDefault();
  }

  render() {
    const {scaleLabels, orderedLabelIDs, current360, accountData, current360Groups, current360Relationships, accountId, labelEdited, labelEditingModalOpen, loading} = this.state
    return (
      <div className='page-wrapper'>
        <section className='container container--slim'>
          <Breadcrumbs
            current360={current360}
            accountData={accountData}
            current360Groups={current360Groups}
            current360Relationships={current360Relationships}
            links={[
              {
                title: 'Dashboard',
                to: `/dashboard/${accountId}`,
              },
              {
                title: `${accountData.accountTitle ? accountData.accountTitle : accountData.title} ${
                  current360.type
                } Projects`,
                to: `/dashboard/${accountId}/${current360.type === 'Goal Tracker' ? 'goal-tracker' : '360'}-projects/`,
              },
              {
                title: current360.title,
                to: '../../',
              },
              {
                title: 'Advanced Settings',
                to: '../',
              },
              {
                title: 'Label Settings',
                to: null,
              },
            ]}
          />

          <h1>{current360.title}: Label Settings</h1>

          {!loading ? (
            <div className='bg-white mv4 ph4 pv4 shadow br2'>
              <div className='flex'>
                <div className='w-100'>
                  <h2>Change Label Text</h2>
                  <p>
                      When completing a review, a user will have various MultiChoice options to select from. Here you can edit the labels applied to each
                      answer option. If you wish to get an idea of how your labels will appear, when part of the questionnaire, use the preview button at the
                      bottom of this page.
                    </p>
                </div>
              </div>
              <form action='/' className='pv3' onSubmit={this.onSubmitLabelEdit}>
                {orderedLabelIDs.map((labelID, labelIndex) => {
                  const labelData = scaleLabels[labelID];
                  return (
                    <div key={labelID}>
                      <h3>Rating Scale: {labelIndex}</h3>
                      <p>
                        <strong>Label Frequency: </strong>
                        {labelData.frequency}
                      </p>
                      <label htmlFor={labelID} className='f7 b db mb2'>
                        <strong>Label Text</strong>
                      </label>
                      <input
                        ref={(input) => (this[labelID] = input)}
                        aria-describedby={`rating ${labelIndex}`}
                        className='input-reset ba b--black-20 pa2 mb2 db w-100'
                        type='text'
                        placeholder={labelData.text}
                        onChange={this.updateEditState}
                      />
                      <hr className='mv4' />
                    </div>
                  )
                })}
                <button className={labelEdited ? 'btn' : 'btn btn--disabled'} type='submit' disabled={!labelEdited}>
                  Save
                </button>
              </form>
            </div>
          ) : (
            <LoadingState />
          )}
          <Link to={{ pathname: '../', current360: this.props.current360, accountData: this.props.accountData }} className='btn btn--back'>
            Return to Advanced Settings
          </Link>

          <Modal
            isOpen={labelEditingModalOpen}
            contentLabel='Add a new 360'
            onClose={() => this.setState({ labelEditingModalOpen: false })}
            content={
              <div>
                <h2>{labelEdited ? 'Submitting Label Edits' : 'Label Edits Complete'}</h2>
                {!labelEdited && (
                  <button className='btn' onClick={() => this.setState({ labelEditingModalOpen: false })}>Close</button>
                )}
              </div>
            }
          />
        </section>
      </div>
    );
  }
}

export default withAuthorisation(authCondition)(EditMultichoiceLabels);
