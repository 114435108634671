import React, { Component } from 'react';

class DFChartSections extends Component {
  render() {
    const sectionTitle = this.props.sectionTitle;
    const relationshipTitle = this.props.relationshipTitle;
    const relationshipColor = this.props.relationshipColor;
    const sectionAverage = this.props.sectionAverage;
    const sectionResponses = this.props.sectionResponses;
    return (
      <div className='row pt1 pb1 pl2 flex bt bb b--moon-gray'>
        <div className='w-30'>
          <p className='ma0 f6'>
            <strong>{sectionTitle}</strong>
          </p>
        </div>
        <div className='w-70'>
          <div className='w-100 flex flex-wrap table__row '>
            <div className='w-20 mv1'>
              <p className='ma0' style={{ color: `${relationshipColor}` }}>
                <strong>{relationshipTitle}</strong>
              </p>
            </div>
            <div className='w-50 mv1'>
              <div className='w-100'>
                <p className='flex ma0'>
                  <strong className='w-10'>{sectionAverage.toFixed(1)}</strong>
                  <span className='w-100 bg-light-gray'>
                    <span className='h-100 db' style={{ width: `${(sectionAverage / 5) * 100}%`, backgroundColor: `${relationshipColor}` }}></span>
                  </span>
                </p>
              </div>
            </div>
            <div className='w-30 mv1'>
              <div className='w-100 flex flex-row-reverse'>
                {sectionResponses.map((r, i) => (
                  <p key={i} className='w-100 mv0 tc'>{r}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DFChartSections;
