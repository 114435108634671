import React, { Component } from 'react';
import { compose } from 'recompose';
import { db, firebase } from '../firebase';
import withAuthorisation from '../components/withauthorisation';
import withUser360s from '../components/withuser360s';
import { Link } from 'react-router-dom';
import List360 from '../components/list360';
import SiteHeader from '../components/site-header';
import SiteNav from '../components/site-nav';
import SiteFooterBrand from '../components/site-footer-brand';
import loadingGif from '../loading.gif';
import logo from '../logo-colour.svg';

const authCondition = (authUser) => !!authUser;

class SelectAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      userAccounts: null,
    };
  }
  componentDidMount() {
    db.doGetLoggedInUserDetails(firebase.auth.currentUser.uid).then((data) => {
      let userDetails = data.data();
      // console.log(userDetails)
      if (userDetails.accounts.length > 1) {
        // Display account selector!
        this.setState({
          loading: false,
          userAccounts: userDetails.accounts,
        });
      } else {
        // Forward to account dashboard
        this.props.history.push(`/dashboard/${userDetails.accounts[0].id}/`);
      }
    });
  }
  render() {
    return (
      <>
        {!this.state.loading ? (
          <main>
            <section className='sign-in'>
              <div className='sign-in__form bg--white shadow animated fadeInUp tc'>
                <img src={logo} className='boomerang-logo' />
                <h2>Select an account to manage:</h2>
                <div className=''>
                  {Object.keys(this.state.userAccounts).map((key, index) => {
                    return (
                      <Link className='bg-blue white db pa3 mt3 no-underline tc' to={`/dashboard/${this.state.userAccounts[key]['id']}/`}>
                        {this.state.userAccounts[key]['title']}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </section>
          </main>
        ) : (
          ''
        )}
      </>
    );
  }
}
//

export default withAuthorisation(authCondition)(SelectAccount);

// export default compose(
//   withAuthorisation(authCondition),
//   withUser360s()
// )(SelectAccount)
