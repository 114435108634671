import React, { Component } from 'react'
import logo from '../logo.svg'

class SiteFooterBrand extends Component {
  render() {
    return (
      <footer className='site-footer'>
        <div className="header-logo"></div>
      </footer>
    );
  }
}

export default SiteFooterBrand
