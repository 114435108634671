import React, { Component } from 'react';
import axios from 'axios';
import Modal from './global/modal';
import { db } from '../firebase';

export default class SendEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: this.props.accountId,
      current360Id: this.props.current360Id,
      currentGroupId: this.props.current360Group,
      currentGroupUsers: this.props.currentGroupUsers,
      currentGroupUserReviewers: this.props.currentGroupUserReviewers,
      current360: this.props.current360,
      activeTask: this.props.activeTask,
      userEmail: this.props.loggedIn,
      mailTo: [],
      isModalOpen: false,
      loading: false,
      theKey: this.props.theKey,
    };
  }
  componentDidMount() {
    db.doGetCurrent360ReviewerEmailText(this.state.accountId, this.props.current360Id).onSnapshot((snapshot) => {
      if (snapshot.data() === undefined) {
        const current360EmailText = [
          {
            body_text: this.props.current360.type !== 'Team Assessment' ? '[NAME] has requested you to complete their review.' : 'Please complete your team review for [NAME]',
            button_text: 'Click here to complete their review',
            title: 'Hi [NAME]!',
          },
        ];
        this.setState({
          current360EmailText: current360EmailText,
          loaded: true,
        });
      } else {
        this.setState({
          current360EmailText: snapshot.data(),
          loaded: true,
        });
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        activeTask: this.props.activeTask,
        currentGroupUsers: this.props.currentGroupUsers,
        currentGroupUserReviewers: this.props.currentGroupUserReviewers,
      });
    }
  }

  updateEmail(groupID, reminderNumber, mailsSent) {
    this.props.succesfullyCompletedEmailSendActions(groupID, reminderNumber, mailsSent);
  }

  showCreditsModal() {
    this.props.promptCreditsModal();
  }

  closeModal(e) {
    this.setState({ isModalOpen: false });
  }

  openModal(e) {
    this.setState({ isModalOpen: true });
  }

  sendEmails(e) {
    e.preventDefault();
    const checkType = (type) => {
      if (type === '360') {
        return Object.keys(this.state.currentGroupUsers).length;
      } else if (type === 'Team Assessment') {
        if (this.state.currentGroupUserReviewers) {
          return Object.keys(this.state.currentGroupUserReviewers).length;
        }
      }
    };
    let numOfUsers = checkType(this.state.current360.type);
    let reminderNumber;
    if (this.state.activeTask === '') {
      reminderNumber = 1;
    }

    if (reminderNumber == 1 && numOfUsers > this.props.credits) {
      this.setState({
        isModalOpen: false,
      });
      this.showCreditsModal();
    } else if (this.props.current360.type !== 'Goal Tracker') {
      const saveWriting = this.state.currentGroupUserReviewers;
      const reviewerKeys = Object.keys(saveWriting);
      const limiter = 2;
      //reviewer email addresses
      const checking = reviewerKeys.map((i) => saveWriting[i].email);
      const checkArr = {};
      const reviewerIds = {};
      //loop through the email addresses while comparing them against eachother
      //count the number of matches you find, once number of matches is over the limiter value, add it to an array
      //check if the email address is already in the array before adding it to it
      //loop through the reviewers
      for (let i = 0; i < reviewerKeys.length; i++) {
        //for comparing against the limiter so you only add them to the array if there are a certain number of repeats
        let reviewerCheck = 0;
        let arrZ = [];
        let combObj = {
          ids: arrZ,
          nullCheck: false,
        };
        //loop through the reviewer email addresses
        for (const [n, v] of checking.entries()) {
          if (saveWriting[reviewerKeys[i]].email === checking[n]) {
            //there has been an email match so +1 to the counter
            reviewerCheck++;
            arrZ.push(reviewerKeys[n]);
            if (saveWriting[reviewerKeys[n]].currentReviewerID === null) {
              combObj.nullCheck = true;
            }
          }
          if (n == checking.length - 1 && reviewerCheck >= limiter) {
            if (!Object.values(checkArr).includes(saveWriting[reviewerKeys[i]].email)) {
              checkArr[reviewerKeys[i]] = saveWriting[reviewerKeys[i]].email;
              reviewerIds[saveWriting[reviewerKeys[i]].email] = combObj;
            }
          }
        }
      }

      const reqBody = this.state;
      reqBody.combineSend = checkArr;
      reqBody.combineSendIds = reviewerIds;
      this.setState({ isModalOpen: true, loading: 'Processing Request' });
      axios
        .post(
          '/send360Emails',
          {
            reqBody,
            cookies: window.localStorage.getItem('Auth'),
          },
          { timeout: 45000, timeoutErrorMessage: 'Timed Out' }
        )
        .then((res) => {
          this.updateEmail(this.state.currentGroupId, this.state.activeTask, Object.keys(this.state.currentGroupUsers).length);
          if (res.data.status == 'sending') {
            this.setState({
              emailStatuses: res.data,
              loading: 'The emails are being sent, you can now close this window',
            });
          } else {
            this.setState({
              loading: res.data.status,
            });
          }
        })
        .catch((err) => {
          let errorJSON = err.toJSON();
          console.log('errorJSON', errorJSON);
          if (errorJSON.message === 'Request failed with status code 400') {
            this.setState({
              loading: 'There has been an error generating some or all of the emails',
            });
          } else if (errorJSON.message === 'Request failed with status code 500') {
            this.setState({
              loading: 'There has been an error generating some or all of the emails',
            });
          }
          if (err == 'Error: Timed Out') {
            this.updateEmail(this.state.currentGroupId, this.state.activeTask, Object.keys(this.state.currentGroupUsers).length);
            this.setState({
              loading: 'The emails are being sent, you can now close this window.',
            });
          } else {
            this.setState({
              loading: 'There has been an error generating some or all of the emails',
            });
          }
        });
    } else {
      const reqBody = this.state;
      this.setState({ isModalOpen: true, loading: 'Processing Request' });
      axios
        .post('/goalTrackerEmail', {
          reqBody,
          cookies: window.localStorage.getItem('Auth'),
        })
        .then((res) => {
          this.setState({
            emailStatuses: res.data,
            loading: 'The emails are being sent, you can now close this window',
          });

          this.updateEmail(this.state.currentGroupId, this.state.activeTask, Object.keys(this.state.currentGroupUsers).length);
        })
        .catch((err) => {});
    }
  }

  render() {
    return (
      <div className='ml4'>
        {Object.keys(this.state.currentGroupUsers).length == 0 ? (
          <button disabled={true} className='btn' onClick={(e) => this.sendEmails(e)}>
            {this.props.showModalButtonLabel}
          </button>
        ) : this.state.activeTask.toString() != this.state.theKey ? (
          <button disabled={true} className='btn' onClick={(e) => this.sendEmails(e)}>
            {this.props.showModalButtonLabel}
          </button>
        ) : (
          <button disabled={false} className='btn' onClick={(e) => this.sendEmails(e)}>
            {this.props.showModalButtonLabel}
          </button>
        )}

        <Modal
          isOpen={this.state.isModalOpen}
          contentLabel={this.props.modalContentLabel}
          onClose={(e) => {
            this.closeModal(e);
          }}
          hideCloseButton={this.state.loading === 'Processing Request'}
          content={
            <div className='center w-100 tc'>
              <h3>{this.props.modalHeader}</h3>
              <div className='centerForm tc'>
                {this.state.loading === false ? (
                  <div>
                    <button onClick={(e) => this.sendEmails(e)} className='btn' type='submit'>
                      submit
                    </button>
                  </div>
                ) : (
                  <span> {this.state.loading}</span>
                )}
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
