import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';

class EmailEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      firstInstance: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();

    if (this.props.reviewerReminder) {
      // console.log('reviewer');
      let emailData = {};
      emailData = {
        body_text: this.emailBodyText.value,
        button_text: this.emailButtonText.value,
        title: this.emailTitle.value,
      };
      this.setState({
        saving: true,
      });
      if (this.emailKey.value == this.props.emailKey) {
        // Update email data
        db.doUpdateReviewerEmailText(this.props.accountId, this.props.current360Id, this.props.emailKey, emailData).then((data) => {
          // Update email props
          this.props.email.body_text = emailData.body_text;
          this.props.email.button_text = emailData.button_text;
          this.props.email.title = emailData.title;

          setTimeout(
            function() {
              this.setState({
                saving: false,
                firstInstance: false,
              });
            }.bind(this),
            500
          );
        });
      } else {
        // Remove and recreate email (as the Key has changed)
        db.doDeleteemail(this.props.accountId, this.props.current360Id, this.props.emailKey).then((data) => {
          db.doCreateNewemail(this.props.accountId, this.props.current360Id, this.emailKey.value, emailData).then((data) => {
            setTimeout(
              function() {
                this.setState({
                  saving: false,
                  firstInstance: false,
                });
              }.bind(this),
              500
            );
          });
        });
      }

      // // console.log(emailData)
    } else {
      // console.log('reviewee');
      let emailData = {};
      emailData = {
        body_text: this.emailBodyText.value,
        button_text: this.emailButtonText.value,
        title: this.emailTitle.value,
      };
      this.setState({
        saving: true,
      });
      if (this.emailKey.value == this.props.emailKey) {
        // Update email data
        db.doUpdateEmailText(this.props.accountId, this.props.current360Id, this.props.emailKey, emailData).then((data) => {
          // Update email props
          this.props.email.body_text = emailData.body_text;
          this.props.email.button_text = emailData.button_text;
          this.props.email.title = emailData.title;

          setTimeout(
            function() {
              this.setState({
                saving: false,
                firstInstance: false,
              });
            }.bind(this),
            500
          );
        });
      } else {
        // Remove and recreate email (as the Key has changed)
        db.doDeleteemail(this.props.accountId, this.props.current360Id, this.props.emailKey).then((data) => {
          db.doCreateNewemail(this.props.accountId, this.props.current360Id, this.emailKey.value, emailData).then((data) => {
            setTimeout(
              function() {
                this.setState({
                  saving: false,
                  firstInstance: false,
                });
              }.bind(this),
              500
            );
          });
        });
      }

      // // console.log(emailData)
    }
  }
  handleChange(e) {
    // console.log(this.state.current360);
    // let questionData = {
    //   'answerType': this.answerType.value,
    //   'questionTitle': this.questionTitle.value,
    //   'required': this.questionRequired.value
    // }
    e.preventDefault();
  }
  render() {
    return (
      <form action='/' className='pv3' onSubmit={this.handleSubmit}>
        {!this.props.reviewerReminder ? (
          <p>
            <strong>{this.props.emailNum < 0 ? 'Complete Self-review email' : `Reminder Number ${this.props.emailNum}`}</strong>
          </p>
        ) : (
          ''
        )}
        <div>
          <input
            maxLength='3'
            disabled
            hidden
            required
            ref={(input) => (this.emailKey = input)}
            type='text'
            className='w-100'
            defaultValue={this.props.emailKey}
          />
          <div className='w-100'>
            <label className='db'>Title</label>
            <input required ref={(input) => (this.emailTitle = input)} type='text' className='w-100' defaultValue={this.props.email.title} />
          </div>
          <div className='w-100'>
            <label className='db'>Body Text</label>
            <textarea ref={(input) => (this.emailBodyText = input)} type='text' className='w-100' defaultValue={this.props.email.body_text} />
          </div>
          <div className='w-100'>
            <label className='db'>Button Text</label>
            <input required ref={(input) => (this.emailButtonText = input)} type='text' className='w-100' defaultValue={this.props.email.button_text} />
          </div>
        </div>

        <button type='submit' className={this.state.saving ? 'btn btn--disabled' : 'btn'}>
          Save Email
        </button>
        <span className={this.state.saving ? 'loading-spinner' : !this.state.firstInstance ? 'loading-done' : ''} />
        <hr className='mt4' />
      </form>
    );
  }
}

//
export default EmailEditForm;
