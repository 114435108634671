import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SiteFooter extends Component {
  constructor(props) {
    super(props)

    // this.state = {
    //   username: props.userDetails.username
    // }
  }
  render() {
    // let adminLink = "/dashboard/"
    let backLink = this.props.backLink
    let backText = this.props.backText
    return (
      <footer className='site-footer'>
        <Link className="f6 link ph4 pv3 dib white bg-green br2" to={ backLink }>{ backText }</Link>
      </footer>
    );
  }
}

export default SiteFooter
